import { Action, ThunkAction } from '@reduxjs/toolkit';
import { RootReducerState } from '../root-reducer';
import { ErrorReducerActionTypes } from './error.reducer';

export const AuthError = (): ThunkAction<
  void,
  RootReducerState,
  unknown,
  Action
> => async (dispatch) => {
  dispatch({
    type: ErrorReducerActionTypes.AUTH_ERROR,
  });
};
