import { useEffect, useState } from 'react';
import { IUser } from '../../../utils/types';
import { urlAPI } from '../../../utils/urlApi';
import { Button, DropdownItem, TextField } from 'components';
import { Dropdown } from 'components';

interface AddEditDeviceProps {
  onCancel?: () => void;
  onSubmit?: (state: AddEditDeviceState) => void;
  state?: AddEditDeviceState;
  type?: 'add' | 'edit';
}

export type AddEditDeviceState = {
  id?: string;
  nama_node?: string;
  Device_Type_id?: string;
  device_name?: string;
};

export interface type_node {
  id: number;
  type_name: string;
  unit: string;
}

type ErrorState = {
  id?: boolean;
  nama_node?: boolean;
  Device_Type_id?: boolean;
};

const AddEditDevice: React.FC<AddEditDeviceProps> = ({
  onCancel,
  onSubmit,
  state = {
    id: '',
    nama_node: '',
    Device_Type_id: '',
    device_name: '',
  },
  type = 'add',
}) => {
  const [formState, setFormState] = useState<AddEditDeviceState>(state);
  const [types, setTypes] = useState<type_node[]>([]);
  const [error, setError] = useState<ErrorState>({
    id: false,
    nama_node: false,
    Device_Type_id: false,
  });

  useEffect(() => {
    async function fetchData() {
      const item = localStorage.getItem('user');

      if (!item) console.log('Not logged in');
      else {
        const userFromLocal: IUser = JSON.parse(item);

        const res = await fetch(urlAPI + '/device/filter', {
          headers: {
            Authorization: 'Bearer ' + userFromLocal.token,
          },
        });

        const data = await res.json();

        setTypes(data.deviceTypes);
      }
    }

    fetchData();
  }, []);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    let err: ErrorState = {};
    if (!formState.id) err.id = true;
    if (!formState.nama_node) err.nama_node = true;
    if (!formState.Device_Type_id) err.Device_Type_id = true;

    if (Object.keys(err).length > 0) setError(err);
    else if (onSubmit) onSubmit(formState);
  };

  const handleChangeTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormState({
      ...formState,
      [name]: value,
    });

    setError({
      ...error,
      [name]: false,
    });
  };

  const handleChangeDropdown = (val: string, name: string) => {
    const findDeviceType = types.find((type) => type.id.toString() === val);

    setFormState({
      ...formState,
      [name]: val,
      device_name: findDeviceType?.type_name || '',
    });

    setError({
      ...error,
      [name]: false,
    });
  };

  return (
    <div className="bg-white flex flex-col z-10 items-center rounded-lg px-16 py-12 fixed top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4 shadow-lg">
      <h2 className="text-title-2 font-extrabold mb-11">
        {type === 'edit' ? 'Edit' : 'Add New'} Device
      </h2>
      <form onSubmit={(e) => e.preventDefault()} className="flex flex-col mb-9">
        <TextField
          onChange={handleChangeTextField}
          value={formState.id}
          disabled={type === 'edit'}
          name="id"
          fullWidth
          labelText="Device ID"
          placeholder="Masukan device ID"
          className="mb-4"
          errorMessage="This field is required"
          isError={error.id}
        />
        <TextField
          onChange={handleChangeTextField}
          value={formState.nama_node}
          name="nama_node"
          fullWidth
          labelText="Device Name"
          placeholder="Masukan nama device"
          className="mb-4"
          errorMessage="This field is required"
          isError={error.nama_node}
        />
        <Dropdown
          value={formState.device_name}
          name="Device_Type_id"
          onChange={handleChangeDropdown}
          labelText="Device Type"
          width={340}
          placeholder="Pilih tipe device"
          errorMessage="This field is required"
          isError={error.Device_Type_id}
        >
          {types.map((val) => (
            <DropdownItem key={val.id} value={val.id.toString()}>
              {val.type_name}
            </DropdownItem>
          ))}
        </Dropdown>
      </form>
      <div className="flex items-center">
        <Button onClick={onCancel} className="mr-4" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSubmit} className="w-20">
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddEditDevice;
