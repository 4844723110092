import { useState } from 'react';

interface InputFileProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string;
  labelText?: string;
  imagePreview?: boolean;
  imageUrl?: string;
}

const InputFile: React.FC<InputFileProps> = ({
  labelText,
  inputClassName,
  imagePreview,
  imageUrl,
  ...otherProps
}) => {
  const [file, setFile] = useState<File | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    if (file && file[0] !== null) setFile(file[0]);
  };

  return (
    <div>
      {labelText ? (
        <label className="block text-caption-1 font-bold text-blue-light mb-1.5">
          {labelText}
        </label>
      ) : null}
      {imagePreview ? (
        <img
          style={{ maxWidth: '141px' }}
          className="mb-2"
          src={imageUrl ? imageUrl : file ? URL.createObjectURL(file) : ''}
          alt=""
        />
      ) : null}
      <input
        {...otherProps}
        onChange={handleChange}
        className={inputClassName}
        type="file"
      />
    </div>
  );
};

export default InputFile;
