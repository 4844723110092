const MachineProductivity = () => {
  return (
    <div className="rounded-lg px-8 py-7 border border-grey-1">
      <div className="grid grid-cols-7 grid-rows-2 font-semibold text-caption-1 items-center py-2.5 gap-2.5">
        <div className="row-span-2">Machine Name</div>
        <div className="row-span-2">Machine Electricity Cost</div>
        <div className="col-span-5 text-center border-b border-blue-lighter pb-2">
          Produced from contact freezer
        </div>
        <div>Contact Freezer 1</div>
        <div>Contact Freezer 2</div>
        <div>Contact Freezer 3</div>
        <div>Contact Freezer 4</div>
        <div>Contact Freezer 5</div>
      </div>
      {/* Put the children here later */}
      <div className="grid grid-cols-7 text-caption-1 py-2.5 mt-11 gap-2.5">
        <div></div>
        <div className="font-semibold">Total Avg Productivity</div>
      </div>
      <div className="grid grid-cols-7 text-caption-1 py-2.5 gap-2.5">
        <div></div>
        <div>1.776 IDR / Inner Pan</div>
      </div>
    </div>
  );
};

export default MachineProductivity;
