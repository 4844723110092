import classNames from 'classnames';
import { useState } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  CardUnit,
  ColdStorageUnitHeadcountPerformance,
  ColdStorageUnitMCO,
  ColdStorageUnitProcessingSpeed,
  ColdStorageUnitProduction,
  ColdStorageUnitProductQuantity,
  ColdStorageUnitWorkers,
  DatePicker,
  Switch,
} from 'components';

const ColdStorageUnitDashboardPage = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [realtime, setRealtime] = useState<boolean>(false);

  const handleChangeRealtime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    setRealtime(checked);
  };

  return (
    <div style={{ minWidth: 1200 }}>
      <h1 className="text-heading font-bold mb-3">
        Cold Storage Unit Dashboard
      </h1>
      <Breadcrumb className="mb-5">
        <BreadcrumbItem href="/unit/">Home</BreadcrumbItem>
        <BreadcrumbItem href="/unit/cold-storage-unit">
          Cold Storage Unit
        </BreadcrumbItem>
        <BreadcrumbItem isActive>Dashboard</BreadcrumbItem>
      </Breadcrumb>
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <Switch
            onChange={handleChangeRealtime}
            checked={realtime}
            withoutMessage
            className="mr-3"
          />
          <span className="text-caption-1 font-semibold text-grey">
            Realtime
          </span>
        </div>
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <DatePicker
            disabled={realtime}
            selected={startDate}
            // @ts-ignore
            onChange={(date) => setStartDate(date)}
            dateFormat="E, d MMM yyyy"
            containerClassName="mr-3.5"
          />
          <DatePicker
            disabled={realtime}
            selected={endDate}
            // @ts-ignore
            onChange={(date) => setEndDate(date)}
            dateFormat="E, d MMM yyyy"
            popperPlacement="bottom-end"
          />
        </div>
      </div>
      <div
        className={classNames(
          'grid gap-x-4 mb-8',
          realtime ? 'grid-cols-5' : 'grid-cols-6'
        )}
      >
        {!realtime ? (
          <>
            <CardUnit color="blue" title="Total MC Input:" value="1.5 ton" />
            <CardUnit color="green" title="Total MC Output:" value="1.5 ton" />
            <CardUnit color="purple" title="Total MC Stock:" value="50 MC" />
            <CardUnit color="yellow" title="Total Takeout Order:" value="12" />
            <CardUnit
              color="blue"
              title="Total Takeout Order Finished:"
              value="1.5"
            />
            <CardUnit color="green" title="Total MCO Stack:" value="50 MCO" />
          </>
        ) : (
          <>
            <CardUnit color="blue" title="Today's MC Input:" value="1.5 ton" />
            <CardUnit
              color="green"
              title="Today's MC Output:"
              value="1.5 ton"
            />
            <CardUnit color="purple" title="Current MC Stock:" value="50 MC" />
            <CardUnit color="yellow" title="Current MCO Stock:" value="12" />
            <CardUnit color="blue" title="Current Takeout Speed:" value="1.5" />
          </>
        )}
      </div>
      <div className="grid grid-cols-2 gap-6">
        <ColdStorageUnitProductQuantity />
        <ColdStorageUnitWorkers />
        <ColdStorageUnitProduction />
        <ColdStorageUnitMCO />
        {!realtime && <ColdStorageUnitHeadcountPerformance />}
        {!realtime && <ColdStorageUnitProcessingSpeed />}
      </div>
    </div>
  );
};

export default ColdStorageUnitDashboardPage;
