const ShortLeavePermissionTable = () => {
  return (
    <div className="rounded-lg px-8 py-7 border border-grey-1">
      <div
        style={{ gridTemplateColumns: '80px repeat(5, 1fr)' }}
        className="grid font-semibold text-caption-1 py-2.5"
      >
        <div>No</div>
        <div>Time Mode</div>
        <div>Name</div>
        <div>Reason</div>
        <div>Status</div>
        <div>Time approved/pending/rejected</div>
      </div>
      {/* Put the children here later */}
    </div>
  );
};

export default ShortLeavePermissionTable;
