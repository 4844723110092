import {
  Breadcrumb,
  BreadcrumbItem,
  DatePicker,
  ManagementSupplierAnalysisBarChart,
  ManagementSupplierAnalysisStackChart,
  ManagementSupplierAnalysisTable,
} from 'components';
import { useState } from 'react';

const ManagementSupplierAnalysisPage = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  return (
    <div className="user-management">
      <h1 className="text-heading font-bold mb-3">Supplier Analysis</h1>
      <Breadcrumb className="mb-5">
        <BreadcrumbItem href="/management/">Home</BreadcrumbItem>
        <BreadcrumbItem isActive>Supplier Analysis</BreadcrumbItem>
      </Breadcrumb>
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <DatePicker
            selected={startDate}
            // @ts-ignore
            onChange={(date) => setStartDate(date)}
            dateFormat="E, d MMM yyyy"
            containerClassName="mr-3.5"
          />
          <DatePicker
            selected={endDate}
            // @ts-ignore
            onChange={(date) => setEndDate(date)}
            dateFormat="E, d MMM yyyy"
            popperPlacement="bottom-end"
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-6 mb-10">
        <ManagementSupplierAnalysisBarChart
          chartTitle="Size Accuracy Performance"
          yAxisTitle="Size Accuracy"
          averageTitle="Average Size Accuracy"
          withHighest={false}
        />
        <ManagementSupplierAnalysisBarChart
          chartTitle="Yield Performance"
          yAxisTitle="Yield (%)"
          averageTitle="Average Yield"
          customizeAverage={(value) => `${value} %`}
        />
        <ManagementSupplierAnalysisBarChart
          chartTitle="Standard Deviation Performance"
          yAxisTitle="Standard Deviation"
          averageTitle="Average Standard Deviation"
        />
        <ManagementSupplierAnalysisBarChart
          chartTitle="Cost Material Performancce"
          yAxisTitle="IDR / kg"
          averageTitle="Average Material Cost Performance"
          customizeAverage={(value) => `${value} IDR/kg`}
        />
        <ManagementSupplierAnalysisStackChart yAxisTitle="Percentage (%)" />
      </div>
      <ManagementSupplierAnalysisTable />
    </div>
  );
};

export default ManagementSupplierAnalysisPage;
