import { Checkbox } from '..';

interface MultiSelectDropdownItemProps {
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  state?: Record<string, boolean | string>;
  value?: string;
  id?: string;
  type?: 'checkbox' | 'radio';
  name?: string;
  checked?: boolean;
}

const MultiSelectDropdownItem: React.FC<MultiSelectDropdownItemProps> = ({
  handleChange,
  state = {},
  value = '',
  id,
  children,
  type = 'checkbox',
  checked,
  name = '',
}) => {
  return (
    <div className="flex items-center py-2 focus:outline-none">
      {type === 'checkbox' ? (
        <Checkbox
          checked={Boolean(state[value])}
          value={value}
          onChange={handleChange}
          id={id}
        />
      ) : (
        <input
          onChange={handleChange}
          id={id}
          name={name}
          value={value}
          type="radio"
          checked={state[name] === value}
        />
      )}
      <label
        htmlFor={id}
        className="text-caption-1 ml-2 mt-0.5 text-black font-semibold"
      >
        {children}
      </label>
    </div>
  );
};

export default MultiSelectDropdownItem;
