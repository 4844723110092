import { Reducer, Action } from '@reduxjs/toolkit';

export type ErrorState = {
  authError: boolean;
};

export enum ErrorReducerActionTypes {
  AUTH_ERROR = 'AUTH_ERROR',
  AUTH_ERROR_CLOSE = 'AUTH_ERROR_CLOSE',
}

interface ErrorAction extends Action<any> {
  payload: any;
}

const INITIAL_STATE: ErrorState = {
  authError: false,
};

export const errorReducer: Reducer<ErrorState, ErrorAction> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case ErrorReducerActionTypes.AUTH_ERROR:
      return {
        ...state,
        authError: true,
      };
    case ErrorReducerActionTypes.AUTH_ERROR_CLOSE:
      return {
        ...state,
        authError: false,
      };
    default:
      return state;
  }
};
