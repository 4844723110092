import { Link } from 'react-router-dom';

interface CardHomeProps {
  Icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  heading?: string;
  subheading?: string;
  to?: string;
}

const CardHome: React.FC<CardHomeProps> = ({
  Icon,
  heading,
  subheading,
  to = '#',
}) => {
  return (
    <Link to={to}>
      <div className="border-4 border-white-snow rounded-2xl relative overflow-hidden">
        <div
          style={{ clipPath: 'polygon(0 0, 100% 0%, 70% 100%, 0% 100%)' }}
          className="bg-blue-lighter w-36 h-28 absolute top-0 left-0 z-0"
        ></div>
        <div className="flex items-center z-10 relative h-28 mb-6">
          {Icon && <Icon className="ml-9 mr-4" />}
          <h2 className="text-title font-extrabold text-black">{heading}</h2>
        </div>
        <p className="text-subheading px-11 pb-11">{subheading}</p>
      </div>
    </Link>
  );
};

export default CardHome;
