import { TableGrid } from 'components';
import { useQuery } from 'react-query';
import { urlAPITablet, namaUnitFinalSorting } from 'utils';
import { TableAdapter, TableData, transformTableData } from 'utils/table-adapter';

const FinalSortingHLUnitProcessLogTable: React.FC<StringDate> = ({start_date, end_date}) => {

    const cols: Array<string> = [
      'date',
      'containerInput',
      'supplierInput',
      'productInput',
      'speciesInput',
      'sizeInput',
      'weightInput',
      'containerOutput',
      'supplierOutput',
      'productOutput',
      'speciesOutput',
      'sizeOutput',
      'weightOutput'
    ];

    const transformWeight = (weight: any) => {
      return (weight !== '' ? `${weight} kg` : '-');
    }

    const header = [
      "Date",
      {
        main: "Input",
        sub: ["Container", "Supplier", "Product", "Species", "Size", "Weight"]
      },
      {
        main: "Output",
        sub: ["Container", "Supplier", "Product", "Species", "Size", "Weight"]
      }
    ]
  
    const { data } = useQuery<ITableGridPackage>(['final-sorting-production-log', start_date, end_date], async () => {
      console.log('run');
      const result = await fetch(urlAPITablet + `/${namaUnitFinalSorting}/report/production/json?start_date=${start_date}&end_date=${end_date}`);
      const data = await result.json();
      const tableData: TableData = new TableData(data?.data);
      // console.log(JSON.stringify(tableData.plain));
      const tableAdapter = new TableAdapter(tableData, cols);
      const tableGridData = tableAdapter.getTableGridData();
      const dataInput = await transformTableData(tableGridData?.tableGridData?.data, 'weightInput', transformWeight);
      const dataOutput = await transformTableData(dataInput, 'weightOutput', transformWeight);
      tableGridData.tableGridData.data = await dataOutput;
      console.log('row size', tableData.getRowSize());
      console.log('table grid data', JSON.stringify(tableGridData));
      return tableGridData;
    });

  
    return (
      <div  className="rounded-lg px-5 py-4 border border-grey-1 mb-9 max-w-none">
        <TableGrid
        // className="mx-5"
        // fixWidth="6vw"
          columns={[
            {
              Component: (
                <div
                key="process-log-main-column"
                style={{gridTemplateColumns: `repeat(${header.reduce((a,b)=> typeof b === 'object' ? (a + b.sub.length) : a + 1, 0)}, 1fr)`}}
                className="grid grid-rows-2 items-center grid-flow-col"
                >
                {
                    header.map((item, i) => (
                        typeof item === 'object' ?
                        (
                            <div key={i} className={`h-full row-span-6 col-span-${item.sub.length} border-r ${!i ? 'border-l' : ''}`}>
                            <div className={`row-span-3 col-span-${item.sub.length} border-b`}>{item.main}</div>
                            <div className={`row-span-3 col-span-${item.sub.length} grid grid-cols-${item.sub.length} grid-rows-1`}>
                                {
                                    item.sub.map((subitem, j) => (
                                        <div key={j} className={`row-span-3 col-span-1 ${j === item.sub.length-1 ? '' : 'border-r'}`}>{subitem}</div>
                                    ))
                                }
                            </div>
                            </div>
                        )
                        :
                        <div key={i} className={`table h-full row-span-6 col-span-1 border-r ${!i ? 'border-l' : ''}`}>
                            <span className='table-cell align-middle'>{item}</span>
                        </div>
                    ))
                }
                </div>
              ),
              cols: cols,
              width: '14fr',
              rows: data?.rowSize,
              merge: data?.tableGridData?.merge,
            },
          ]}
          data={[data?.tableGridData?.data || {}]}
        />
      </div>
    );
  };

export default FinalSortingHLUnitProcessLogTable;
