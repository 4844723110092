interface ProgressbarProps {
  progress?: string | number;
  color?: string;
}

const Progressbar: React.FC<ProgressbarProps> = ({ progress, color }) => {
  return (
    <div className="relative w-full rounded-full overflow-hidden h-2.5 bg-grey-light">
      <div
        style={{ width: `${progress}%`, background: color }}
        className="absolute top-0 left-0 h-full bg-blue"
      ></div>
    </div>
  );
};

export default Progressbar;
