import classNames from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useState } from 'react';
import { IconClose, IconLaunch } from 'assets';
import { ColdStorageColorMapping } from 'colors';
import { WorkingTimeDistLegend } from 'components';

interface OperatorMachinesProps {
  className?: string;
}

const options: Highcharts.Options = {
  title: {
    text: '',
  },
  yAxis: {
    gridLineWidth: 0,
    title: {
      text: 'Hours',
    },
  },
  series: [
    {
      data: [
        {
          name: 'Machine 1',
          color: ColdStorageColorMapping[1],
          y: 6,
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: 'Machine 2',
          color: ColdStorageColorMapping[2],
          y: 3.5,
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: 'Machine 3',
          color: ColdStorageColorMapping[3],
          y: 1.5,
          dataLabels: {
            enabled: false,
          },
        },
      ],
      name: 'Operator 1',
      type: 'pie',
      innerSize: '80%',
      size: '75%',
    },
  ],
  legend: {
    enabled: false,
  },
  tooltip: {
    valueSuffix: ' Hours',
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 60,
        },
      },
    ],
  },
};

const WorkingTimeDistribution: React.FC<OperatorMachinesProps> = ({
  className = '',
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [reRender, setReRender] = useState<boolean>(true);

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
    // Hack the chart so it can fill entire screen during fullscreen state
    setReRender(false);
    setTimeout(() => {
      setReRender(true);
    }, 0);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        [className]: className,
      })}
    >
      <button onClick={handleFullScreen} className="absolute right-5 top-5">
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">
          Working Time Distribution of Operators
        </h4>
        <div className="grid grid-cols-3 gap-3 items-center">
          <div className="col-span-2">
            {reRender && (
              <HighchartsReact options={options} highcharts={Highcharts} />
            )}
          </div>
          <div>
            <WorkingTimeDistLegend
              title="Operator 1"
              percentage="60"
              color={ColdStorageColorMapping[1]}
              hours="6 Hours"
              className="mb-4"
            />
            <WorkingTimeDistLegend
              title="Operator 2"
              percentage="35"
              color={ColdStorageColorMapping[2]}
              hours="3 Hours 30 Minutes"
              className="mb-4"
            />
            <WorkingTimeDistLegend
              title="Operator 1"
              percentage="15"
              color={ColdStorageColorMapping[3]}
              hours="1 Hours 15 Minutes"
            />
          </div>
        </div>
        <p className="text-caption-1 font-bold text-grey pl-10 mt-3">
          Total Working Time &nbsp;
          <span className="text-blue-2">11 Hours 11 Minutes</span>
        </p>
      </div>
    </div>
  );
};

export default WorkingTimeDistribution;
