import { useMemo, useState } from 'react';
import {
  checkPosition,
  comparePoint,
  findCenter,
  findClosestSnap,
  findSnapPoint,
  whichSnapped,
} from 'utils/pathFinder';

const unitsLayout: UnitLayout[] = [
  {
    key: 'start',
    x: 35,
    y: 10,
    height: 5,
    width: 5,
  },
  {
    key: 'receiving',
    x: 45,
    y: 10,
    height: 5,
    width: 5,
    title: 'Receiving',
  },
  {
    key: 'sorting-machine',
    x: 55,
    y: 10,
    height: 5,
    width: 5,
    title: 'Sorting Machine',
  },
  {
    key: 'peeling',
    x: 65,
    y: 10,
    height: 5,
    width: 5,
    title: 'Peeling',
  },
  {
    key: 'final-sorting-hl',
    x: 15,
    y: 30,
    height: 5,
    width: 5,
    title: 'Final Sorting HL',
  },
  {
    key: 'final-sorting-peeled',
    x: 25,
    y: 30,
    height: 5,
    width: 5,
    title: 'Final Sorting Peeled',
  },
  {
    key: 'cooking',
    x: 35,
    y: 30,
    height: 5,
    width: 5,
    title: 'Cooking',
  },
  {
    key: 'nobashi',
    x: 45,
    y: 30,
    height: 5,
    width: 5,
    title: 'Nobashi',
  },
  {
    key: 'soaking',
    x: 55,
    y: 30,
    height: 5,
    width: 5,
    title: 'Soaking',
  },
  {
    key: 'layering',
    x: 65,
    y: 30,
    height: 5,
    width: 5,
    title: 'Layering',
  },
  {
    key: 'freezing',
    x: 75,
    y: 30,
    height: 5,
    width: 5,
    title: 'Freezing',
  },
  {
    key: 'iqf',
    x: 85,
    y: 30,
    height: 5,
    width: 5,
    title: 'IQF',
  },
  {
    key: 'freezing-packing',
    x: 45,
    y: 50,
    height: 5,
    width: 5,
    title: 'Freezing Packing',
  },
  {
    key: 'iqf-packing',
    x: 55,
    y: 50,
    height: 5,
    width: 5,
    title: 'IQF Packing',
  },
  {
    key: 'cold-storage',
    x: 65,
    y: 50,
    height: 5,
    width: 5,
    title: 'Cold Storage',
  },
  {
    key: 'end',
    x: 75,
    y: 50,
    height: 5,
    width: 5,
  },
];

const TraceabilityPage = () => {
  const [arrows, setArrows] = useState<string[]>([]);

  const mapObject = useMemo(
    () => [
      {
        start: 'start',
        end: 'receiving',
      },
      {
        start: 'receiving',
        end: 'sorting-machine',
      },
      {
        start: 'sorting-machine',
        end: 'final-sorting-hl',
      },
      {
        start: 'peeling',
        end: 'cooking',
      },
      {
        start: 'peeling',
        end: 'final-sorting-hl',
      },
      {
        start: 'final-sorting-hl',
        end: 'final-sorting-peeled',
      },
      {
        start: 'final-sorting-peeled',
        end: 'peeling',
      },
      {
        start: 'cooking',
        end: 'nobashi',
      },
      {
        start: 'nobashi',
        end: 'soaking',
      },
      {
        start: 'soaking',
        end: 'layering',
      },
      {
        start: 'layering',
        end: 'freezing',
      },
      {
        start: 'freezing',
        end: 'iqf',
      },
      {
        start: 'iqf',
        end: 'freezing-packing',
      },
      {
        start: 'freezing-packing',
        end: 'iqf-packing',
      },
      {
        start: 'iqf-packing',
        end: 'cold-storage',
      },
      {
        start: 'cold-storage',
        end: 'end',
      },
      {
        start: 'final-sorting-hl',
        end: 'soaking',
      },
      {
        start: 'soaking',
        end: 'final-sorting-hl',
      },
      {
        start: 'cooking',
        end: 'soaking',
      },
      {
        start: 'freezing',
        end: 'soaking',
      },
      {
        start: 'receiving',
        end: 'iqf',
      },
      {
        start: 'start',
        end: 'iqf',
      },
      {
        start: 'iqf',
        end: 'sorting-machine',
      },
    ],
    []
  );

  const generatePath = () => {
    const startSnappedLists: Point[] = [];
    const endSnappedLists: Point[] = [];
    const startSnappedMorethanOnce: Point[] = [];
    const endSnappedMorethanOnce: Point[] = [];

    const getStartEndingPoint = (
      startUnit: UnitLayout,
      endUnit: UnitLayout
    ) => {
      const startSnapPoints = findSnapPoint(startUnit);
      const endSnapPoints = findSnapPoint(endUnit);

      const tempPoint = findClosestSnap(endSnapPoints, findCenter(endUnit));
      let startingPoint = findClosestSnap(startSnapPoints, tempPoint);
      let endingPoint = findClosestSnap(endSnapPoints, startingPoint);

      const { posV } = checkPosition(startingPoint, endingPoint);

      // prefer use bottom snap point at the start
      if (posV === 'bottom' && startingPoint[1] !== endingPoint[1]) {
        startingPoint = startSnapPoints[3];
        endingPoint = endSnapPoints[1];
      }
      // prefer use top snap point at the start
      else if (posV === 'top' && startingPoint[1] !== endingPoint[1]) {
        startingPoint = startSnapPoints[1];
        endingPoint = endSnapPoints[3];
      }
      // prefer use bottom snap at the start if differences at x direction is more than 10 and the y direction is already the same
      else if (
        startingPoint[1] === endingPoint[1] &&
        Math.abs(endingPoint[0] - startingPoint[0]) > 10
      ) {
        startingPoint = startSnapPoints[3];
        endingPoint = endSnapPoints[3];
      }

      return { endingPoint, startingPoint, startSnapPoints, endSnapPoints };
    };

    // predicting snapped point
    mapObject.forEach(({ start, end }) => {
      const startUnit = unitsLayout.find((unit) => unit.key === start);
      const endUnit = unitsLayout.find((unit) => unit.key === end);

      if (startUnit && endUnit) {
        const { endingPoint, startingPoint } = getStartEndingPoint(
          startUnit,
          endUnit
        );

        let isSnappedMorethanOnce = startSnappedLists.find(
          (point) =>
            point[0] === startingPoint[0] && point[1] === startingPoint[1]
        );

        if (isSnappedMorethanOnce) {
          startSnappedMorethanOnce.push(startingPoint);
        }

        startSnappedLists.push(startingPoint);

        isSnappedMorethanOnce = endSnappedLists.find(
          (point) => point[0] === endingPoint[0] && point[1] === endingPoint[1]
        );

        if (isSnappedMorethanOnce) {
          endSnappedMorethanOnce.push(endingPoint);
        }

        endSnappedLists.push(endingPoint);
      }
    });

    const paths: string[] = [];
    const snappedData: Record<
      string,
      Record<string, Record<string, number>>
    > = {};

    mapObject.forEach(({ end }) => {
      snappedData[end] = {
        top: {
          right: 0,
          left: 0,
        },
        bottom: {
          right: 0,
          left: 0,
        },
        left: {
          right: 0,
          left: 0,
        },
        right: {
          right: 0,
          left: 0,
        },
      };
    });

    mapObject.forEach(({ start, end }) => {
      const startUnit = unitsLayout.find((unit) => unit.key === start);
      const endUnit = unitsLayout.find((unit) => unit.key === end);

      if (startUnit && endUnit) {
        const { endingPoint, startingPoint } = getStartEndingPoint(
          startUnit,
          endUnit
        );

        const path: string[] = [];
        let latestPath: Point = [...startingPoint];

        while (true) {
          if (
            latestPath[0] === endingPoint[0] &&
            latestPath[1] === endingPoint[1]
          ) {
            break;
          }
          const { posV, posH } = checkPosition(latestPath, endingPoint);

          if (
            latestPath[1] === endingPoint[1] &&
            Math.abs(endingPoint[0] - startingPoint[0]) < 10
          ) {
            latestPath[0] = endingPoint[0];
            path.push(`L${latestPath[0]} ${latestPath[1]}`);
            break;
          }

          if (latestPath[0] === endingPoint[0]) {
            latestPath[1] = endingPoint[1];
            path.push(`L${latestPath[0]} ${latestPath[1]}`);
            break;
          }

          if (latestPath[0] !== endingPoint[0]) {
            // if latest path still at starting point
            if (latestPath[1] === startingPoint[1]) {
              // if snap at bottom or top
              if (posV === 'bottom') {
                if (posH === 'right')
                  latestPath[1] += (100 - startingPoint[0]) / 8;
                else if (posH === 'left') latestPath[1] += startingPoint[0] / 8;
              } else if (posV === 'top') {
                if (posH === 'right')
                  latestPath[1] -= (100 - startingPoint[0]) / 8;
                else if (posH === 'left') latestPath[1] -= startingPoint[0] / 8;
              }

              path.push(`L${latestPath[0]} ${latestPath[1]}`);
            }

            // check if the point is already has a path snapped to that
            const isEndSnapped = endSnappedLists.find((point) =>
              comparePoint(endingPoint, point)
            );

            const which = whichSnapped(endUnit, endingPoint);

            // check if the ending point also become a starting point too
            const isStartSnapped = startSnappedLists.find((point) =>
              comparePoint(endingPoint, point)
            );

            if (isStartSnapped && isEndSnapped) {
              if (posH === 'right') {
                snappedData[end][which].right++;
                latestPath[0] =
                  endingPoint[0] - 0.8 * snappedData[end][which].right;
                path.push(`L${latestPath[0]} ${latestPath[1]}`);
              } else if (posH === 'left') {
                snappedData[end][which].left++;
                latestPath[0] =
                  endingPoint[0] + 0.8 * snappedData[end][which].left;
                path.push(`L${latestPath[0]} ${latestPath[1]}`);
              }
            } else if (isEndSnapped && !isStartSnapped) {
              if (posH === 'right') {
                latestPath[0] =
                  endingPoint[0] - 0.8 * snappedData[end][which].right;
                path.push(`L${latestPath[0]} ${latestPath[1]}`);
                snappedData[end][which].right++;
              } else if (posH === 'left') {
                latestPath[0] =
                  endingPoint[0] + 0.8 * snappedData[end][which].left;
                path.push(`L${latestPath[0]} ${latestPath[1]}`);
                snappedData[end][which].left++;
              }
            } else {
              // equalize the x value
              latestPath[0] = endingPoint[0];
              path.push(`L${latestPath[0]} ${latestPath[1]}`);
            }

            // equalize the y value
            latestPath[1] = endingPoint[1];
            path.push(`L${latestPath[0]} ${latestPath[1]}`);

            break;
          }
        }

        const thePath =
          `M${startingPoint[0]} ${startingPoint[1]} ` + path.join(' ');

        paths.push(thePath);
      }
    });

    setArrows(paths);
  };

  return (
    <>
      <button onClick={generatePath}>generate path</button>
      <div>
        <svg viewBox="0 0 100 70">
          {unitsLayout.map((props) => (
            <UnitBox {...props} />
          ))}
          <defs>
            <marker
              id="head"
              orient="auto"
              markerWidth="3"
              markerHeight="6"
              refX="3"
              refY="3"
            >
              <path d="M0,0 V6 L3,3 Z" fill="#0067BE" />
            </marker>
          </defs>
          {/* <Path start="start" end="receiving"></Path> */}
          {arrows.map((arrow) => (
            <path
              key={arrow}
              markerEnd="url(#head)"
              strokeWidth="0.1"
              stroke="#0067BE"
              fill="none"
              d={arrow}
            ></path>
          ))}
        </svg>
      </div>
    </>
  );
};

export default TraceabilityPage;

interface UnitBoxProps {
  title?: string;
  Icon?: React.FC<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  height?: number;
  width?: number;
  x?: number;
  y?: number;
}

const UnitBox: React.FC<UnitBoxProps> = ({
  height = 0,
  width = 0,
  x = 0,
  y = 0,
  Icon,
  title,
}) => {
  const iconHeight = height * (3 / 5);
  const iconWidth = width * (3 / 5);

  const iconX = x + width - width / 2 - iconWidth / 2;
  const iconY = y + height - height / 2 - iconHeight / 2;

  return (
    <g>
      <rect
        height={height}
        width={width}
        x={x}
        y={y}
        fill="white"
        stroke="#0067BE"
        strokeWidth="0.1"
        rx="0.2"
        style={{
          filter: 'drop-shadow(0px 1px 0.3px rgba(0, 0, 0, 0.15))',
        }}
      ></rect>
      {Icon && (
        <Icon height={iconHeight} width={iconWidth} x={iconX} y={iconY} />
      )}
      <text
        fontSize="0.9"
        textAnchor="middle"
        x={x + width / 2}
        y={height + y + 2}
        fill="#5F6368"
        fontWeight="700"
      >
        {title}
      </text>
    </g>
  );
};
