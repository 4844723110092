import { IconClose, IconLaunch } from 'assets';
import classNames from 'classnames';
import { MultiSelectDropdown, TableGrid } from 'components';
import { useState } from 'react';

interface ManagementCostAnalysisTableProps extends IBaseTable {
  className?: string;
}

const ManagementCostAnalysisTable: React.FC<ManagementCostAnalysisTableProps> =
  ({ className }) => {
    const [fullScreen, setFullScreen] = useState<boolean>(false);

    const handleFullScreen = () => {
      setFullScreen(!fullScreen);
    };

    return (
      <div
        style={{ zIndex: fullScreen ? 1200 : 0 }}
        className={classNames('flex justify-center items-center', className, {
          relative: !fullScreen,
          'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        })}
      >
        <button onClick={handleFullScreen} className="absolute right-5 top-5">
          {!fullScreen ? <IconLaunch /> : <IconClose />}
        </button>
        <div className="w-full h-full border rounded-lg border-grey-light p-6">
          <h4 className="text-footnote font-bold mb-8">
            Cost Material and Labor Table
          </h4>
          <div className="grid grid-cols-2 gap-x-2 w-80 ml-auto mb-6">
            <MultiSelectDropdown placeholder="Species"></MultiSelectDropdown>
            <MultiSelectDropdown placeholder="Size"></MultiSelectDropdown>
          </div>
          <TableGrid
            columns={[
              {
                field: 'product',
                name: 'Product',
              },
              {
                field: 'cost_material',
                name: 'Cost Material',
              },
              {
                field: 'cost_labor',
                name: 'Cost Labor',
              },
              {
                field: 'total_cost',
                name: 'Total Cost',
              },
              {
                field: 'quantity',
                name: 'Quantity',
              },
              {
                field: 'cost_material_per_kg',
                name: 'Cost Material / kg',
              },
              {
                field: 'cost_labor_per_kg',
                name: 'Cost Labor / kg',
              },
              {
                field: 'cogs',
                name: 'Cogs',
              },
            ]}
            data={[
              {
                product: 'Product 1',
                cost_material: '8.000k IDR',
                cost_labor: '8.000k IDR',
                total_cost: '16.000k IDR',
                quantity: '700 kg',
                cost_material_per_kg: '220k IDR / kg',
                cost_labor_per_kg: '300k IDR / kg',
                cogs: '120k IDR / kg',
              },
              {
                product: 'Product 1',
                cost_material: '8.000k IDR',
                cost_labor: '8.000k IDR',
                total_cost: '16.000k IDR',
                quantity: '700 kg',
                cost_material_per_kg: '220k IDR / kg',
                cost_labor_per_kg: '300k IDR / kg',
                cogs: '120k IDR / kg',
              },
              {
                product: 'Product 1',
                cost_material: '8.000k IDR',
                cost_labor: '8.000k IDR',
                total_cost: '16.000k IDR',
                quantity: '700 kg',
                cost_material_per_kg: '220k IDR / kg',
                cost_labor_per_kg: '300k IDR / kg',
                cogs: '120k IDR / kg',
              },
              {
                product: 'Product 1',
                cost_material: '8.000k IDR',
                cost_labor: '8.000k IDR',
                total_cost: '16.000k IDR',
                quantity: '700 kg',
                cost_material_per_kg: '220k IDR / kg',
                cost_labor_per_kg: '300k IDR / kg',
                cogs: '120k IDR / kg',
              },
            ]}
          />
        </div>
      </div>
    );
  };

export default ManagementCostAnalysisTable;
