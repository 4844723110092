import classNames from 'classnames';

interface CardUnitProps {
  className?: string;
  color?: 'green' | 'purple' | 'blue' | 'yellow';
  title?: string;
  value?: string;
}

const CardUnit: React.FC<CardUnitProps> = ({
  className,
  color = 'green',
  title,
  value,
}) => {
  let background = '';
  if (color === 'green') background = '#cddbc2';
  if (color === 'purple') background = '#E9E0E6';
  if (color === 'blue') background = '#DCE7E8';
  if (color === 'yellow') background = '#EDE7D7';

  return (
    <div
      style={{ background }}
      className={classNames(
        'rounded-lg flex-col text-caption-1 border-2 h-20 flex items-center justify-center text-center',
        className,
        {
          'border-colorful-green': color === 'green',
          'border-colorful-yellow': color === 'yellow',
          'border-colorful-purple': color === 'purple',
          'border-colorful-blue': color === 'blue',
        }
      )}
    >
      <span className="font-semibold text-black mb-2">{title}</span>
      <span
        className={classNames('font-extrabold', {
          'text-colorful-green': color === 'green',
          'text-colorful-yellow': color === 'yellow',
          'text-colorful-purple': color === 'purple',
          'text-colorful-blue': color === 'blue',
        })}
      >
        {value}
      </span>
    </div>
  );
};

export default CardUnit;
