import { useState } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  CostByOperator,
  DatePicker,
  OperatorInCharge,
  OperatorMachines,
  OperatorPerformance,
  WorkingTimeDistribution,
} from 'components';

const OperatorPerformancePage = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  return (
    <div className="user-management">
      <h1 className="text-heading font-bold mb-3">
        Dashboard Operator Performance Time by Operator
      </h1>
      <Breadcrumb className="mb-5">
        <BreadcrumbItem href="/mechanic/overview">Home</BreadcrumbItem>
        <BreadcrumbItem href="/mechanic/operator-performance">
          Operator Performance
        </BreadcrumbItem>
        <BreadcrumbItem isActive>Dashboard</BreadcrumbItem>
      </Breadcrumb>
      <div className="flex justify-between items-center mb-8">
        <div></div>
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <DatePicker
            selected={startDate}
            // @ts-ignore
            onChange={(date) => setStartDate(date)}
            dateFormat="E, d MMM yyyy"
            containerClassName="mr-3.5"
          />
          <DatePicker
            selected={endDate}
            // @ts-ignore
            onChange={(date) => setEndDate(date)}
            dateFormat="E, d MMM yyyy"
            popperPlacement="bottom-end"
          />
        </div>
      </div>
      <div className="grid-cols-4 grid gap-6">
        <OperatorPerformance className="col-span-2" />
        <OperatorInCharge />
        <CostByOperator />
        <OperatorMachines className="col-span-2" />
        <WorkingTimeDistribution className="col-span-2" />
      </div>
    </div>
  );
};

export default OperatorPerformancePage;
