const IQFUnitProcessLogTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">Detailed IQF Input and Output</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9 overflow-auto">
        <div
          style={{
            gridTemplateColumns: 'repeat(17, 1fr)',
            minWidth: 2000,
          }}
          className="grid font-semibold grid-rows-3 items-center text-caption-1 gap-y-2 gap-x-4 px-2 py-2.5"
        >
          <div className="col-span-2 text-center border-b border-grey-placeholder pb-2">
            Input
          </div>
          <div
            className="text-center border-b border-grey-placeholder pb-2"
            style={{ gridColumn: '3 / -1' }}
          >
            Output
          </div>
          <div className="row-span-2">Container ID</div>
          <div className="row-span-2">Weight</div>
          <div className="row-span-2">Product</div>
          <div className="row-span-2">Species</div>
          <div className="row-span-2">Size</div>
          <div className="row-span-2">Date</div>
          <div className="row-span-2">Timestamp</div>
          <div className="col-span-10 text-center border-b border-grey-placeholder pb-2">
            MC
          </div>
          <div>MCO Code</div>
          <div>Supplier</div>
          <div>Product</div>
          <div>Species</div>
          <div>Size</div>
          <div>Date</div>
          <div>Weight</div>
          <div>MC Code</div>
          <div>MC Weight</div>
          <div>Total Weight</div>
        </div>
        {/* Put the children here later */}
      </div>

      <h6 className="font-bold mb-2">Detail Packing Freezing Metal Detector</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <div className="grid grid-cols-8 items-center font-semibold text-caption-1 gap-y-2 gap-x-4 px-2 py-2.5">
          <div className="col-span-2">MC Code</div>
          <div>Supplier</div>
          <div>Product</div>
          <div>Species</div>
          <div>Size</div>
          <div>Date</div>
          <div>Timestamp</div>
        </div>
        {/* Put the children here later */}
      </div>

      <h6 className="font-bold mb-2">Input Breakdown</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <div className="grid grid-cols-3 items-center font-semibold text-caption-1 gap-y-2 gap-x-4 px-2 py-2.5">
          <div>Container ID</div>
          <div>Basket Weight</div>
          <div>Total Weight</div>
        </div>
        {/* Put the children here later */}
      </div>
    </>
  );
};

export default IQFUnitProcessLogTable;
