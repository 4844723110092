import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconExcel } from 'assets';
import {
  AvgFrozenTimeByOperatorTable,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ContactFreezerPerformanceTable,
  DatePicker,
} from 'components';
import { useQuery } from 'utils/useQuery';

const ContactFreezerPerformanceReportPage = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const report = useQuery().get('report') || 'performance';
  const history = useHistory();

  return (
    <div className="user-management">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-heading font-bold mb-3">
            Report Contact Freezer Perfromance
          </h1>
          <Breadcrumb className="mb-5">
            <BreadcrumbItem href="/mechanic/overview">Home</BreadcrumbItem>
            <BreadcrumbItem href="/mechanic/contact-freezer">
              Contact Freezer Performance
            </BreadcrumbItem>
            <BreadcrumbItem isActive>Report</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="flex items-center">
          <Button
            className="mr-5 w-44"
            color={report === 'performance' ? 'default' : 'grey'}
            onClick={() => history.push({ search: 'report=performance' })}
          >
            Contact Freezer Performance
          </Button>
          <Button
            className="w-44"
            color={report === 'operator' ? 'default' : 'grey'}
            onClick={() => history.push({ search: 'report=operator' })}
          >
            Avg Frozen Time by Operator & CF
          </Button>
        </div>
      </div>
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <DatePicker
            selected={startDate}
            // @ts-ignore
            onChange={(date) => setStartDate(date)}
            dateFormat="E, d MMM yyyy"
            containerClassName="mr-3.5"
          />
          <DatePicker
            selected={endDate}
            // @ts-ignore
            onChange={(date) => setEndDate(date)}
            dateFormat="E, d MMM yyyy"
            popperPlacement="bottom-end"
          />
        </div>
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Export
          </span>
          <button className="p-2 rounded-md border border-grey-1">
            <IconExcel />
          </button>
        </div>
      </div>
      {report === 'performance' && <ContactFreezerPerformanceTable />}
      {report === 'operator' && <AvgFrozenTimeByOperatorTable />}
    </div>
  );
};

export default ContactFreezerPerformanceReportPage;
