import classNames from 'classnames';
import { useState } from 'react';
import { IconClose, IconLaunch } from 'assets';

const CostByOperator = () => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
      })}
    >
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <div className="flex items-start justify-between mb-12">
          <h4 className="text-footnote font-bold">
            Average CF Electricity Cost per batch by Operator
          </h4>
          <button onClick={handleFullScreen}>
            {!fullScreen ? <IconLaunch /> : <IconClose />}
          </button>
        </div>
        <div>
          <div className="grid grid-cols-2 text-caption-2 text-grey font-bold mb-2.5">
            <div>Operator Name</div>
            <div>Avg. Electricity Cost</div>
          </div>
          <div className="grid grid-cols-2 text-caption-1 font-bold mb-2.5">
            <div>Operator 1</div>
            <div>99.000/hour</div>
          </div>
          <div className="grid grid-cols-2 text-caption-1 font-bold mb-2.5">
            <div>Operator 2</div>
            <div>99.000/hour</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostByOperator;
