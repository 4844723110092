const ContactFreezerPerformanceTable = () => {
  return (
    <div className="rounded-lg px-8 py-7 border border-grey-1">
      <div className="grid grid-cols-7 font-semibold text-caption-1 py-2.5 gap-2.5">
        <div>CF Name</div>
        <div>Avg Frozen Time</div>
        <div>Batch Usage</div>
        <div>Running Time</div>
        <div>Productivity Rate</div>
        <div>Production</div>
        <div>Unfrozen</div>
      </div>
      {/* Put the children here later */}
      <div className="grid grid-cols-7 text-caption-1 py-2.5 mt-11 gap-2.5">
        <div></div>
        <div></div>
        <div className="font-semibold">Total Batch Usage</div>
        <div className="font-semibold">Total Running Time</div>
        <div className="font-semibold">Avg Productivity Rate</div>
        <div className="font-semibold">Total Production</div>
        <div className="font-semibold">Total Unfrozen</div>
      </div>
      <div className="grid grid-cols-7 text-caption-1 py-2.5 gap-2.5">
        <div></div>
        <div></div>
        <div>300 Times</div>
        <div>62 Hours 30 Minutes</div>
        <div>11 inner pan/hr</div>
        <div>605 inner pan/hr</div>
        <div>22 inner pan</div>
      </div>
    </div>
  );
};

export default ContactFreezerPerformanceTable;
