import classNames from 'classnames';

interface ChartLegendProps {
  className?: string;
  percentage?: string | number;
  title?: string;
  color?: string;
}

const ChartLegend: React.FC<ChartLegendProps> = ({
  className = '',
  percentage,
  title,
  color,
}) => {
  return (
    <div
      className={classNames(
        'flex items-center text-caption-1 text-grey font-bold',
        { [className]: className }
      )}
    >
      <div
        style={{ background: color }}
        className={classNames('h-1 rounded-full', {
          'w-11': percentage,
          'w-6': !percentage,
        })}
      ></div>
      {percentage && <span className="block ml-2.5">{percentage}%</span>}
      <span className="ml-2.5">{title}</span>
    </div>
  );
};

export default ChartLegend;
