import { TableGrid } from 'components';

const FreezingUnitReportSummaryTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">Inner Pan Freezed</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              field: 'no',
              name: 'No.',
              width: '40px',
            },
            {
              field: 'contactFreezer',
              name: 'Contact Freezer',
            },
            {
              field: 'innerSuccess',
              name: 'Inner Success',
            },
            {
              field: 'innerFail',
              name: 'Inner Fail',
            },
          ]}
          data={[
            {
              no: '1',
              contactFreezer: 'F-001',
              innerSuccess: '80',
              innerFail: '80',
            },
            {
              no: '2',
              contactFreezer: 'F-001',
              innerSuccess: '80',
              innerFail: '80',
            },
          ]}
        />
      </div>

      <div className="flex items-start mb-8">
        <div className="w-96 mr-20">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Inner Success</div>
            <div>50</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Total Inner Fail</div>
            <div>200</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Total Pending Inner</div>
            <div>200</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Total Pending Frozen Inner</div>
            <div>200</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Average Success Rate</div>
            <div>100 %</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Headcount</div>
            <div>9 people</div>
          </div>
        </div>
        <div className="w-96">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Working Time</div>
            <div>10 man-hours</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Machine Time</div>
            <div>50 hours</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Time</div>
            <div>2 hours</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Labor Productivity</div>
            <div>50 inner/hour</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Machine Productivity</div>
            <div>20 inner/hour</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Unit Productivity</div>
            <div>200 Kg/hour</div>
          </div>
        </div>
      </div>
      <div className="w-80">
        <div className="flex justify-between mb-3 text-caption-1">
          <div className="font-semibold">Labor Cost</div>
          <div>Rp 750.000</div>
        </div>
        <div className="flex justify-between mb-3 text-caption-1">
          <div className="font-semibold">Labor Cost / Inner</div>
          <div>Rp 75.000</div>
        </div>
      </div>
    </>
  );
};

export default FreezingUnitReportSummaryTable;
