import { LoadingAnimation, Overlay } from '../../atoms';

const Loading = () => {
  return (
    <>
      <Overlay />
      <div
        style={{ zIndex: 999 }}
        className="px-6 py-4 w-80 h-48 rounded-lg bg-white flex flex-col items-center justify-center fixed top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4"
      >
        <LoadingAnimation size="md" />
        <span className="text-footnote mt-4">
          Processing your request, Please wait...
        </span>
      </div>
    </>
  );
};

export default Loading;
