import dayjs from 'dayjs';
import humanizeDuration from 'humanize-duration';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import { urlAPIMechanic } from 'utils';

interface ColdStorageConditionTableProps {
  startDate?: string;
  endDate?: string;
}

type ColdStorageConditionTableData = {
  [key: string]: any;
  id: number;
  nama_node: string;
  avgTemperature: number;
  avgTemperature1: number;
  avgTemperature2: number;
  doorOpenFreq: number;
  doorOpenDuration: number;
  criticalTimeFreq: number;
  durationInCriticalTime: number;
};

type mapManipulator = Record<
  keyof Omit<ColdStorageConditionTableData, 'id' | 'nama_node'> | string,
  string[]
>;

const ColdStorageConditionTable: React.FC<ColdStorageConditionTableProps> = ({
  startDate = dayjs().subtract(6, 'days').format('YYYY-MM-DD'),
  endDate = dayjs().format('YYYY-MM-DD'),
}) => {
  const { data } = useQuery<ColdStorageConditionTableData[]>(
    ['coldStorageConditionTable', startDate, endDate],
    () =>
      fetch(
        urlAPIMechanic +
          `/mechanic/cold-storage/report/condition?start=${startDate}&end=${endDate}`
      )
        .then((res) => res.json())
        .then((data) => data.data)
  );

  let mapManipultar: mapManipulator = {
    avgTemperature: ['Avg Temp', 'C'],
    avgTemperature1: ['Avg Temp 1', 'C'],
    avgTemperature2: ['Avg Temp 2', 'C'],
    durationInCriticalTime: ['Duration in Critical Temp', 'seconds'],
    doorOpenDuration: ['Duration Door Open', 'seconds'],
    criticalTimeFreq: ['Frequency in Critical Temp', 'times'],
    doorOpenFreq: ['Frequency Door Open', 'times'],
  };

  return (
    <div className="rounded-lg px-8 py-7 border border-grey-1">
      <div
        style={{
          gridTemplateColumns: `repeat(${(data?.length || 0) + 1}, 1fr)`,
        }}
        className="grid font-semibold text-caption-1 px-2 py-2.5"
      >
        <div>Cold Storage Name</div>
        {data?.map((val) => (
          <div>{val.nama_node}</div>
        ))}
      </div>
      {/* Put the children here later */}
      {Object.keys(mapManipultar).map((key: string, i) => {
        return (
          <div
            style={{
              gridTemplateColumns: `repeat(${(data?.length || 0) + 1}, 1fr)`,
            }}
            className={classNames('grid text-caption-1 px-2 py-2.5', {
              'bg-white-snow': i % 2,
            })}
          >
            <div>{mapManipultar[key][0]}</div>
            {data?.map((val) => {
              let cellValue = `${val[key]}  ${mapManipultar[key][1]}`;
              if (mapManipultar[key][1] === 'seconds') {
                cellValue = humanizeDuration(val[key] * 1000, { round: true });
              }

              return <div>{cellValue}</div>;
            })}
          </div>
        );
      })}
    </div>
  );
};

export default ColdStorageConditionTable;
