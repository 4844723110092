import classNames from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useState } from 'react';
import { IconClose, IconLaunch } from 'assets';
import { ContactFreezerColorMapping } from 'colors';
import { ChartLabel } from 'components';

interface OperatorMachinesProps {
  className?: string;
}

const options: Highcharts.Options = {
  title: {
    text: '',
  },
  chart: {
    type: 'column',
  },
  xAxis: {
    type: 'category',
    gridLineWidth: 1,
  },
  yAxis: {
    gridLineWidth: 0,
    title: {
      text: 'Hours',
    },
  },
  series: [
    {
      data: [
        {
          name: 'Machine 1',
          color: ContactFreezerColorMapping[1],
          y: 8,
        },
        {
          name: 'Machine 2',
          color: ContactFreezerColorMapping[1],
          y: 5,
        },
        {
          name: 'Machine 3',
          color: ContactFreezerColorMapping[1],
          y: 7,
        },
        {
          name: 'Machine 4',
          color: ContactFreezerColorMapping[1],
          y: 7,
        },
        {
          name: 'Machine 5',
          color: ContactFreezerColorMapping[1],
          y: 7,
        },
      ],
      name: 'Operator 1',
      type: 'column',
      borderRadius: 5,
    },
    {
      data: [
        {
          name: 'Machine 1',
          color: ContactFreezerColorMapping[2],
          y: 6,
        },
        {
          name: 'Machine 2',
          color: ContactFreezerColorMapping[2],
          y: 3,
        },
        {
          name: 'Machine 3',
          color: ContactFreezerColorMapping[2],
          y: 5,
        },
        {
          name: 'Machine 4',
          color: ContactFreezerColorMapping[2],
          y: 9,
        },
        {
          name: 'Machine 5',
          color: ContactFreezerColorMapping[2],
          y: 14,
        },
      ],
      name: 'Operator 2',
      type: 'column',
      borderRadius: 5,
    },
    {
      data: [
        {
          name: 'Machine 1',
          color: ContactFreezerColorMapping[3],
          y: 6,
        },
        {
          name: 'Machine 2',
          color: ContactFreezerColorMapping[3],
          y: 7,
        },
        {
          name: 'Machine 3',
          color: ContactFreezerColorMapping[3],
          y: 8,
        },
        {
          name: 'Machine 4',
          color: ContactFreezerColorMapping[3],
          y: 4,
        },
        {
          name: 'Machine 5',
          color: ContactFreezerColorMapping[3],
          y: 10,
        },
      ],
      name: 'Operator 3',
      type: 'column',
      borderRadius: 5,
    },
  ],
  legend: {
    enabled: false,
  },
  tooltip: {
    valueSuffix: ' Hours',
  },
};

const OperatorMachines: React.FC<OperatorMachinesProps> = ({
  className = '',
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [reRender, setReRender] = useState<boolean>(true);

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
    // Hack the chart so it can fill entire screen during fullscreen state
    setReRender(false);
    setTimeout(() => {
      setReRender(true);
    }, 0);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        [className]: className,
      })}
    >
      <button onClick={handleFullScreen} className="absolute right-5 top-5">
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">
          Average CF Frozen Time By Machine
        </h4>
        {reRender && (
          <HighchartsReact options={options} highcharts={Highcharts} />
        )}

        <p className="text-caption-1 font-bold text-grey pl-10 mt-3">
          Best Average Time &nbsp;
          <span className="text-blue-2">Operator 1</span>
        </p>
        <div className="flex items-center flex-wrap pl-10 mt-3">
          <ChartLabel
            title="Operator 1"
            bgColor={ContactFreezerColorMapping[1]}
            className="mr-3"
          />
          <ChartLabel
            title="Operator 2"
            bgColor={ContactFreezerColorMapping[2]}
            className="mr-3"
          />
          <ChartLabel
            title="Operator 3"
            bgColor={ContactFreezerColorMapping[3]}
          />
        </div>
      </div>
    </div>
  );
};

export default OperatorMachines;
