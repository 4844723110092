import classNames from 'classnames';

interface MachineConditionRealtimeProps {
  className?: string;
  title?: string;
  condition?: 'good' | 'bad' | 'poor' | 'critical';
}

const conditionMap = {
  good: 'Good',
  poor: 'Not so good',
  bad: 'Bad',
  critical: 'Need immidiate repair',
};

const MachineConditionRealtime: React.FC<MachineConditionRealtimeProps> = ({
  className = '',
  title,
  condition = 'good',
}) => {
  return (
    <div className={classNames({ [className]: className })}>
      <h5 className="font-bold text-caption-1 mb-2">{title}</h5>
      <div className="flex items-center">
        <div
          className={classNames('w-5 h-1 rounded-full mr-1', {
            'bg-colorful-green': condition === 'good',
            'bg-colorful-yellow': condition === 'poor',
            'bg-red-error': condition === 'bad',
            'bg-red': condition === 'critical',
          })}
        ></div>
        <span
          className={classNames('text-caption-1', {
            'text-colorful-green': condition === 'good',
            'text-colorful-yellow': condition === 'poor',
            'text-red-error': condition === 'bad',
            'text-red': condition === 'critical',
          })}
        >
          {conditionMap[condition]}
        </span>
      </div>
    </div>
  );
};

export default MachineConditionRealtime;
