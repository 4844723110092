type H = {
  [key: number]: any;
};

export const ColdStorageColorMapping: H = {
  1: '#0089ED',
  2: '#EDAB00',
  3: '#6ACE7C',
  4: '#E94646',
  5: '#FF40C9',
  6: '#28B446',
  7: '#BB64FF',
  8: '#5A4FF3',
};

export const ContactFreezerColorMapping: H = {
  1: '#00A3FF',
  2: '#FF40C9',
  3: '#28B446',
  4: '#EDAB00',
  5: '#BB64FF',
  6: '#5A4FF3',
  7: '#ED455D',
};
