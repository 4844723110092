const SoakingUnitSoakingAnalysisTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">Material to Treatment Comparison</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <div className="grid grid-rows-2 grid-cols-4 items-center font-semibold text-caption-1 gap-y-2 gap-x-4 px-2 py-2.5">
          <div className="col-span-2 border-b border-grey-placeholder pb-2 text-center">
            Material
          </div>
          <div className="col-span-2 border-b border-grey-placeholder pb-2 text-center">
            Treatment
          </div>
          <div className="text-center">Type</div>
          <div className="text-center">Quantity</div>
          <div className="text-center">Type</div>
          <div className="text-center">Goods Quantity</div>
        </div>
        {/* Put the children here later */}
      </div>
      <h6 className="font-bold mb-2">
        Unit Performance by Treatment, Product, Grade, Size, and Species
      </h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <div
          style={{ gridTemplateColumns: '40px repeat(9, 1fr)' }}
          className="grid font-semibold text-caption-1 py-2.5"
        >
          <div>No</div>
          <div>Treatment</div>
          <div>Product</div>
          <div>Grade</div>
          <div>Size</div>
          <div>Species</div>
          <div>Avg Stir Time</div>
          <div>Avg Soaking Time</div>
          <div>Yield</div>
          <div>Productivity</div>
        </div>
        {/* Put the children here later */}
      </div>
    </>
  );
};

export default SoakingUnitSoakingAnalysisTable;
