import { IconCircleCheck, IconClose } from 'assets';
import './index.css';

interface ProceedConfirmationProps {
  onClose?: () => void;
  message?: string;
  Icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement & { title?: string | undefined }>
  >;
}

const ProceedConfirmation: React.FC<ProceedConfirmationProps> = ({
  onClose,
  message,
  Icon = () => (
    <IconCircleCheck
      height="123"
      width="123"
      className="mb-6 ic-proceed-confirmation"
    />
  ),
}) => {
  return (
    <div className="bg-white fixed shadow-lg flex items-center flex-col z-20 rounded-lg px-11 py-16 top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4">
      <button onClick={onClose} className="absolute top-4 right-4">
        <IconClose />
      </button>
      <Icon height="123" width="123" />
      <span className="text-subtitle text-black font-medium text-center">
        {message}
      </span>
    </div>
  );
};

export default ProceedConfirmation;
