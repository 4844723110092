import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Breadcrumb, BreadcrumbItem, Button, FormSchedule } from 'components';
import { useState } from 'react';

const ScheduleManagementPage = () => {
  const [addSchedule, setAddSchedule] = useState<boolean>(false);

  return (
    <>
      {addSchedule && <FormSchedule onCancel={() => setAddSchedule(false)} />}
      <div className="user-management">
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-heading font-bold mb-3">Schedule Management</h1>
            <Breadcrumb className="mb-5">
              <BreadcrumbItem href="/mechanic/overview">Home</BreadcrumbItem>
              <BreadcrumbItem isActive>Schedule Management</BreadcrumbItem>
            </Breadcrumb>
          </div>
          <div className="flex items-center">
            <Button onClick={() => setAddSchedule(true)}>
              Create New Schedule
            </Button>
          </div>
        </div>
        <FullCalendar
          selectable={true}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={[
            {
              title: 'Coba',
              date: new Date(),
              end: new Date(Date.now() + 2 * 60 * 60 * 1000),
            },
            {
              title: 'Coba',
              date: new Date(Date.now() + 24 * 60 * 60 * 1000),
            },
            {
              title: 'Coba',
              date: new Date(),
              end: new Date(Date.now() + 24 * 60 * 60 * 1000),
            },
            {
              title: 'Coba',
              date: new Date(),
              end: new Date(Date.now() + 24 * 60 * 60 * 1000),
            },
            {
              title: 'Coba',
              date: new Date(),
              end: new Date(Date.now() + 24 * 60 * 60 * 1000),
            },
            {
              title: 'Coba',
              date: new Date(),
              end: new Date(Date.now() + 24 * 60 * 60 * 1000),
            },
          ]}
          dayMaxEventRows={4}
          eventClick={() => alert('halo')}
          dateClick={(arg) => console.log(arg.view)}
        />
      </div>
    </>
  );
};

export default ScheduleManagementPage;
