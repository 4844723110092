import { useState } from 'react';
import { IconClose } from 'assets';
import { Button, DatePicker, Overlay, TextField } from 'components';

interface FormScheduleProps {
  onCancel?: () => void;
  onSubmit?: () => void;
}

const FormSchedule: React.FC<FormScheduleProps> = ({ onCancel, onSubmit }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleSubmit = () => {
    if (onSubmit) onSubmit();
  };

  return (
    <>
      <div
        style={{ zIndex: 999 }}
        className="bg-white rounded-2xl px-11 py-14 fixed top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4"
      >
        <div className="flex items-center justify-between">
          <h2 className="text-heading font-bold">Add New Schedule</h2>
          <button onClick={onCancel}>
            <IconClose />
          </button>
        </div>
        <div className="mt-11">
          <TextField
            labelText="Schedule Title"
            placeholder="Schedule Title"
            width={589}
            className="mb-4"
          />
          <TextField
            labelText="Operator Name"
            placeholder="Operator Name"
            width={589}
            className="mb-4"
          />
          <div className="mb-12">
            <h6 className="text-caption-1 text-blue-light font-bold mb-3">
              Work Time
            </h6>
            <div className="flex items-center">
              <DatePicker
                selected={startDate}
                // @ts-ignore
                onChange={(date) => setStartDate(date)}
                dateFormat="E, d MMM yyyy"
                containerClassName="mr-3.5"
                popperPlacement="bottom-end"
              />
              <span className="text-grey font-bold mr-3.5">-</span>
              <DatePicker
                selected={endDate}
                // @ts-ignore
                onChange={(date) => setEndDate(date)}
                dateFormat="E, d MMM yyyy"
                popperPlacement="bottom-end"
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <Button onClick={onCancel} className="w-40" color="grey">
              Cancel
            </Button>
            <Button onClick={handleSubmit} className="w-52">
              Save
            </Button>
          </div>
        </div>
      </div>
      <Overlay />
    </>
  );
};

export default FormSchedule;
