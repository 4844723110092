const ReceivingUnitSupplierPerfTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">Granular Shrinkage and Size Accuracy</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9 overflow-auto">
        <div
          style={{
            gridTemplateColumns: '40px repeat(13, 1fr)',
            minWidth: 1800,
          }}
          className="grid grid-rows-2 gap-3 font-semibold text-caption-1 py-2.5 gap-y-2 gap-x-4 px-2 items-center"
        >
          <div className="row-span-2">No</div>
          <div className="row-span-2">Supplier Code</div>
          <div className="row-span-2">Supplier Name</div>
          <div className="row-span-2">Species</div>
          <div className="row-span-2">Purchasing Size</div>
          <div className="row-span-2">Purchasing Weight</div>
          <div className="col-span-2 text-center border-b border-grey-placeholder pb-2">
            Size Distribution
          </div>
          <div className="row-span-2">Recorded Weight</div>
          <div className="row-span-2">Size Mean</div>
          <div className="col-span-2 text-center border-b border-grey-placeholder pb-2">
            Size Accuracy
          </div>
          <div className="row-span-2">Shrinkage</div>
          <div className="row-span-2">Cost per Kg</div>
          <div className="text-center">Actual Size</div>
          <div className="text-center">Percentage</div>
          <div className="text-center">Difference</div>
          <div className="text-center">STD</div>
        </div>
        {/* Put the children here later */}
      </div>
      <h6 className="font-bold mb-2">Shrinkage Supplier</h6>
      <div
        style={{ maxWidth: 1124 }}
        className="rounded-lg px-8 py-7 border border-grey-1 mb-9"
      >
        <div
          style={{ gridTemplateColumns: '40px repeat(7, 1fr)' }}
          className="grid grid-rows-2 items-center font-semibold text-caption-1 gap-y-2 gap-x-4 px-2 py-2.5"
        >
          <div className="row-span-2">No</div>
          <div className="row-span-2">Supplier Code</div>
          <div className="row-span-2">Supplier Name</div>
          <div className="row-span-2">Purchasing Weight</div>
          <div className="col-span-2 text-center border-b border-grey-placeholder pb-2">
            Size Accuracy
          </div>
          <div className="row-span-2">Shrinkage</div>
          <div className="row-span-2">Cost per Kg</div>
          <div className="text-center">Avg. Difference</div>
          <div className="text-center">Avg STD</div>
        </div>
        {/* Put the children here later */}
      </div>
    </>
  );
};

export default ReceivingUnitSupplierPerfTable;
