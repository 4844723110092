import { useState } from 'react';
import { urlAPI } from '../../../utils/urlApi';
import { useUser } from '../../../utils/useUser';
import { Button, Checkbox, DropdownItem, TextField } from 'components';
import { Dropdown } from 'components';

interface AddEditEmployeeProps {
  onCancel?: () => void;
  onSubmit?: (state: AddEditEmployeeState) => void;
  state?: AddEditEmployeeState;
  type?: 'add' | 'edit';
}

export type AddEditEmployeeState = {
  [key: string]: any;
  company_branch?: string;
  Factory_id?: number;
  id?: string | number;
  User_Role_id: number | undefined;
  nama_employee?: string;
  role?: Role;
  phone?: string | number;
  initial_password?: string;
  is_superadmin?: 0 | 1;
  registered_date?: string;
  avatar?: string | File | undefined;
  photo?: string;
  email?: string;
};

export type Branch = {
  branch_name: string;
  id: number;
};

export type Role = {
  Role_name: string;
  id: number;
};

type ErrorState = {
  [key: string]: any;
  Factory_id?: boolean;
  id?: boolean;
  User_Role_id?: boolean;
  nama_employee?: boolean;
  phone?: boolean;
  initial_password?: boolean;
  registered_date?: boolean;
  email?: boolean;
};

const AddEditEmployee: React.FC<AddEditEmployeeProps> = ({
  onCancel,
  onSubmit,
  state = {
    company_branch: '',
    id: '',
    nama_employee: '',
    initial_password: '',
    phone: '',
    role: undefined,
    is_superadmin: 0,
    registered_date: '',
    avatar: undefined,
    email: '',
    User_Role_id: undefined,
    Factory_id: undefined,
    photo: '',
  },
  type = 'add',
}) => {
  const [formState, setFormState] = useState<AddEditEmployeeState>(state);
  const [branches, setBranches] = useState<Branch[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [error, setError] = useState<ErrorState>({});

  useUser(async (user, err) => {
    if (err) console.log(err);
    else {
      const res = await fetch(urlAPI + '/employee/filter', {
        headers: {
          Authorization: 'Bearer ' + user?.token,
        },
      });

      if (res.status === 200) {
        const data = await res.json();

        setBranches(data.factories || []);
        setRoles(data.userRoles || []);
      } else console.log('Error');
    }
  });

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    let err: ErrorState = {};

    Object.keys(formState).forEach((val) => {
      if (val === 'photo' || val === 'avatar') return;
      if (!formState[val]) err[val] = true;
    });

    if (Object.keys(err).length > 0) setError(err);
    else {
      if (onSubmit) onSubmit(formState);
    }
  };

  const handleChangeTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, files } = e.target;

    if (name === 'is_superadmin')
      setFormState({ ...formState, [name]: checked ? 1 : 0 });
    else if (name === 'avatar') {
      if (files && files?.length > 0)
        setFormState({
          ...formState,
          [name]: files[0],
        });
    } else {
      setFormState({
        ...formState,
        [name]: value,
      });

      setError({
        ...error,
        [name]: false,
      });
    }
  };

  const handleChangeDropdown = (val: string, name: string) => {
    if (name === 'role') {
      const findRole = roles.find(
        (value) => value.id.toString() === val
      )?.Role_name;

      setFormState({
        ...formState,
        User_Role_id: Number(val),
        role: {
          id: Number(val),
          Role_name: findRole || '',
        },
      });

      setError({
        ...error,
        User_Role_id: false,
      });
    } else if (name === 'company_branch') {
      const findBranch = branches.find(
        (value) => value.id.toString() === val
      )?.branch_name;

      setFormState({
        ...formState,
        Factory_id: Number(val),
        company_branch: findBranch,
      });

      setError({
        ...error,
        Factory_id: false,
      });
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full overflow-auto z-20">
      <div
        style={{ minWidth: 862 }}
        className="bg-white flex flex-col z-10 items-center rounded-lg px-16 absolute top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4 shadow-lg pt-20 pb-11"
      >
        <h2 className="text-title-2 font-extrabold mb-8">
          {type === 'edit' ? 'Edit' : 'Add New'} Employee
        </h2>
        <div className="relative flex justify-center flex-col items-center">
          {/* eslint-disable-next-line */}
          <img
            className="rounded-full h-32 w-32 object-cover object-center text-center bg-blue-lighter"
            src={
              typeof formState.avatar === 'object'
                ? URL.createObjectURL(formState.avatar)
                : formState.photo
            }
          />
          <label
            className="text-footnote text-center cursor-pointer underline text-blue mt-2"
            htmlFor="avatar"
          >
            {formState.photo || formState.avatar
              ? 'Change Photo'
              : 'Upload Photo'}
          </label>
          <input
            className="absolute top-0 left-0 w-0 h-0"
            type="file"
            id="avatar"
            onChange={handleChangeTextField}
            name="avatar"
            accept=".png,.jpg,.jpeg"
          />
        </div>
        <form
          onSubmit={(e) => e.preventDefault()}
          className="grid mt-3 grid-cols-2 gap-x-12 gap-y-5 mb-9"
        >
          <Dropdown
            value={formState.company_branch}
            name="company_branch"
            onChange={handleChangeDropdown}
            labelText="Branch"
            width={340}
            placeholder="Pilih branch company"
            errorMessage="This field is required"
            isError={error.Factory_id}
          >
            {branches.map((val) => (
              <DropdownItem key={val.id.toString()} value={val.id.toString()}>
                {val.branch_name}
              </DropdownItem>
            ))}
          </Dropdown>
          <TextField
            onChange={handleChangeTextField}
            value={formState.id}
            name="id"
            fullWidth
            labelText="Employee ID"
            placeholder="Masukan Employee ID"
            disabled={type === 'edit'}
            errorMessage="This field is required"
            isError={error.id}
          />
          <TextField
            onChange={handleChangeTextField}
            value={formState.nama_employee}
            name="nama_employee"
            fullWidth
            labelText="Employee Name"
            placeholder="Masukan nama employee"
            maxLength={45}
            errorMessage="This field is required"
            isError={error.nama_employee}
          />
          <Dropdown
            value={formState.role?.Role_name}
            name="role"
            onChange={handleChangeDropdown}
            labelText="User Role"
            width={340}
            placeholder="Pilih user role"
            errorMessage="This field is required"
            isError={error.User_Role_id}
          >
            {roles.map((val) => (
              <DropdownItem key={val.id.toString()} value={val.id.toString()}>
                {val.Role_name}
              </DropdownItem>
            ))}
          </Dropdown>
          <TextField
            onChange={handleChangeTextField}
            value={formState.phone}
            name="phone"
            fullWidth
            labelText="Phone Number"
            placeholder="Masukan phone number"
            maxLength={255}
            errorMessage="This field is required"
            isError={error.phone}
          />
          <TextField
            name="registered_date"
            onChange={handleChangeTextField}
            value={formState.registered_date}
            fullWidth
            labelText="Registered Date"
            type="date"
            disabled={type === 'edit'}
            errorMessage="This field is required"
            isError={error.registered_date}
          />
          <TextField
            onChange={handleChangeTextField}
            value={formState.email}
            name="email"
            fullWidth
            labelText="Email"
            placeholder="Masukan email"
            type="email"
            disabled={type === 'edit'}
            maxLength={255}
            errorMessage="This field is required"
            isError={error.email}
          />
          <TextField
            onChange={handleChangeTextField}
            value={formState.initial_password}
            name="initial_password"
            fullWidth
            labelText="Initial Password"
            placeholder="Masukan initial password"
            type="password"
            disabled={type === 'edit'}
            defaultValue={type === 'edit' ? 'randompassword' : ''}
            errorMessage="This field is required"
            isError={error.initial_password}
          />
          <div className="flex items-center pt-4">
            <Checkbox
              checked={Boolean(formState.is_superadmin)}
              name="is_superadmin"
              onChange={handleChangeTextField}
            />
            <span className="font-bold text-caption-1 text-blue ml-2">
              Register as Superadmin
            </span>
          </div>
        </form>
        <div className="flex items-center">
          <Button onClick={onCancel} className="mr-4" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmit} className="w-20">
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddEditEmployee;
