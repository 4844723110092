import { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { IconError, LogoEmifly } from '../../assets';
import {
  Button,
  Loading,
  ProceedConfirmation,
  TextField,
} from '../../components';
import { urlAPI } from '../../utils/urlApi';

type LoginState = {
  id: string;
  password: string;
};

type LoginErrorState = {
  id: boolean;
  password: boolean;
};

const LoginBGs = [
  '/assets/images/bg-login-1.png',
  '/assets/images/bg-login-2.png',
  '/assets/images/bg-login-3.png',
  '/assets/images/bg-login-4.png',
  '/assets/images/bg-login-5.png',
  '/assets/images/bg-login-6.png',
  '/assets/images/bg-login-7.png',
];

const LoginPage = () => {
  const [state, setState] = useState<LoginState>({
    id: '',
    password: '',
  });

  const [errorState, setErrorState] = useState<LoginErrorState>({
    id: false,
    password: false,
  });

  const [background, setBackground] = useState<string>(LoginBGs[0]);

  const backgroundIndex = useRef<number>(1);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    const interval = 10 * 1000;

    const changeBackground = setInterval(() => {
      setBackground(LoginBGs[backgroundIndex.current % 7]);
      backgroundIndex.current++;
    }, interval);

    return () => {
      clearInterval(changeBackground);
    };
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setState({
      ...state,
      [name]: value,
    });

    setErrorState({
      ...errorState,
      [name]: false,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let errorMessages: LoginErrorState = { id: false, password: false };

    if (state.id.length === 0) errorMessages.id = true;

    if (state.password.length === 0) errorMessages.password = true;

    if (Object.values(errorMessages).some((val) => val))
      setErrorState(errorMessages);
    else {
      // fetch API here
      setLoading(true);
      try {
        const res = await fetch(urlAPI + '/auth/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(state),
        });

        const data = await res.json();
        setLoading(false);
        if (res.status === 400) throw data;
        else {
          localStorage.setItem('user', JSON.stringify(data));
          history.replace('/');
        }
      } catch (e) {
        console.log(e);
        console.log(e.message);
        setError(true);
      }
    }
  };

  return (
    <>
      {loading && <Loading />}
      {error && (
        <ProceedConfirmation
          Icon={(props) => <IconError {...props} className="ic-red mb-6" />}
          message="Employee ID or Password might be wrong, please try again!"
          onClose={() => setError(false)}
        />
      )}
      <div
        style={{ minWidth: 1200 }}
        className="grid grid-cols-5 justify-items-center items-center h-full"
      >
        <div className="col-span-2">
          <form
            onSubmit={handleSubmit}
            className="w-80 flex flex-col justify-center items-center"
          >
            <img className="h-16 mb-28" src={LogoEmifly} alt="Logo Emifly" />
            <TextField
              fullWidth
              className="mb-5"
              labelText="Employee ID"
              errorMessage="This field is required"
              placeholder="Employee ID"
              isError={errorState.id}
              name="id"
              onChange={handleChange}
            />
            <TextField
              fullWidth
              className="mb-5"
              labelText="Password"
              errorMessage="This field is required"
              placeholder="Password"
              isError={errorState.password}
              type="password"
              name="password"
              onChange={handleChange}
            />
            <Link
              to="/forgot-password"
              className="text-blue text-caption-1 self-start mb-8 cursor-pointer select-none"
            >
              Forgot Password?
            </Link>
            <Button className="w-full">Login</Button>
          </form>
        </div>
        <div className="col-span-3 h-screen w-full relative">
          {LoginBGs.map((val) => {
            return (
              <img
                key={val}
                className={classNames(
                  'h-full transform duration-1000 w-full object-center object-cover opacity-0 absolute top-0 left-0 z-0',
                  {
                    'opacity-100': val === background,
                  }
                )}
                src={val}
                alt="background login"
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default LoginPage;
