import classNames from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more.js';
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import React, { useState } from 'react';
import { IconArrowBreadcrumb, IconClose, IconLaunch } from '../../../../assets';
import { Dropdown } from 'components';

highchartsMore(Highcharts);
HCSoldGauge(Highcharts);

interface ColdStorageUnitProcessingSpeedProps {
  className?: string;
}

const CostperKgOptions: Highcharts.Options = {
  chart: {
    type: 'gauge',
    plotBackgroundColor: undefined,
    plotBackgroundImage: undefined,
    plotBorderWidth: 0,
    plotShadow: false,
  },

  title: {
    text: '',
  },

  pane: {
    startAngle: -90,
    endAngle: 90,
    background: [
      {
        shape: 'arc',
        innerRadius: '60%',
        outerRadius: '100%',
      },
    ],
  },

  // the value axis
  yAxis: [
    {
      min: 0,
      max: 200,
      minorTickWidth: 0,
      tickPixelInterval: 50,
      tickWidth: 1,
      tickPosition: 'outside',
      tickColor: '#666',
      labels: {
        enabled: false,
      },
      title: {
        text: '',
      },
      plotBands: [
        {
          from: 0,
          to: 50,
          color: '#E94646', // red
          innerRadius: '60%',
          outerRadius: '100%',
        },
        {
          from: 50,
          to: 100,
          color: '#EDAB00', // yellow
          innerRadius: '60%',
          outerRadius: '100%',
        },
        {
          from: 100,
          to: 150,
          color: '#00A3FF', // blue
          innerRadius: '60%',
          outerRadius: '100%',
        },
        {
          from: 150,
          to: 200,
          color: '#6ACE7C', // green
          innerRadius: '60%',
          outerRadius: '100%',
        },
      ],
    },
  ],

  series: [
    {
      type: 'gauge',
      name: 'Speed',
      data: [80],
      tooltip: {
        valueSuffix: ' mc/hour',
      },
      dataLabels: {},
    },
  ],

  plotOptions: {
    gauge: {
      dial: {
        backgroundColor: '#616264',
      },
      dataLabels: {
        y: 5,
        borderWidth: 0,
        useHTML: true,
        format:
          '<div style="text-align:center; color: #616264; font-weight: 700;">' +
          '<span style="font-size:12px;">{y}</span> ' +
          '<span style="font-size:12px;">mc/hour</span>' +
          '</div>',
      },
    },
  },
};

const ColdStorageUnitProcessingSpeed: React.FC<ColdStorageUnitProcessingSpeedProps> =
  ({ className }) => {
    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const [reRender, setReRender] = useState<boolean>(true);
    const [outputDistroUnit, setOutputDistroUnit] = useState<'kg' | 'mc'>('kg');

    const handleFullScreen = () => {
      setFullScreen(!fullScreen);
      // Hack the chart so it can fill entire screen during fullscreen state
      setReRender(false);
      setTimeout(() => {
        setReRender(true);
      }, 0);
    };

    return (
      <div
        style={{ zIndex: fullScreen ? 1200 : 0 }}
        className={classNames('flex justify-center items-center', className, {
          relative: !fullScreen,
          'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        })}
      >
        <button onClick={handleFullScreen} className="absolute right-5 top-5">
          {!fullScreen ? <IconLaunch /> : <IconClose />}
        </button>
        <div className="w-full h-full border rounded-lg border-grey-light p-6">
          <h4 className="text-footnote font-bold mb-8">Processing Speed</h4>
          <div className="flex items-center justify-between mb-5">
            <button
              onClick={() => {
                if (outputDistroUnit === 'kg') setOutputDistroUnit('mc');
                else setOutputDistroUnit('kg');
              }}
              className="text-caption-1 text-blue flex items-center font-bold my-3 focus:outline-none"
            >
              {outputDistroUnit === 'kg' ? (
                <>
                  <span className="mr-0.5">kg/hour</span>
                  <IconArrowBreadcrumb
                    height={18}
                    width={18}
                    className="transform rotate-180 ic-blue"
                  />
                </>
              ) : (
                <>
                  <span className="mr-0.5">MC/hour</span>
                  <IconArrowBreadcrumb
                    height={18}
                    width={18}
                    className="ic-blue"
                  />
                </>
              )}
            </button>
            <Dropdown
              width={162}
              height={35}
              top="3rem"
              textSize="caption-1"
              fullWidth
              placeholder="Takeout ID"
            />
          </div>

          {reRender && (
            <>
              <div>
                <HighchartsReact
                  options={CostperKgOptions}
                  highcharts={Highcharts}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

export default ColdStorageUnitProcessingSpeed;
