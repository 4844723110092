import classNames from 'classnames';
import { IconCheck } from 'assets';
import './index.css';

interface FilterChoiceProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const FilterChoice: React.FC<FilterChoiceProps> = ({
  children,
  className = '',
  ...otherProps
}) => {
  return (
    <label
      className={classNames(
        'filter-choice relative px-4 py-1 flex items-center justify-center rounded-full cursor-pointer select-none',
        { [className]: className?.length > 0 }
      )}
    >
      <input
        {...otherProps}
        className="absolute w-0 h-0 left-0 top-0"
        type="checkbox"
      />
      <IconCheck className="mr-1 icon relative z-10" />
      <span className="text-black text-caption-1 relative z-10">
        {children}
      </span>
      <div className="z-0 background absolute top-0 left-0 rounded-full w-full h-full bg-grey-light"></div>
    </label>
  );
};

export default FilterChoice;
