import { forwardRef, useEffect, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  ListChildComponentProps,
  VariableSizeList as List,
  VariableSizeList,
} from 'react-window';
import { AddEditEmployee, AddEditEmployeeState, Branch } from '..';
import { IconEdit, IconPersonDisabled, IconSort } from '../../../assets';
import { IUser } from '../../../utils/types';
import { urlAPI } from '../../../utils/urlApi';
import { useUser } from '../../../utils/useUser';
import { LoadingAnimation, Switch } from 'components';
import { Loading } from 'components';
import { ProceedConfirmation, Dialog } from 'components';
import './index.css';

type RowHeights = {
  [key: string]: number;
};

interface UserManagamentTableProps {
  searchQuery?: string;
  filterQuery?: string;
}

const UserManagementTable: React.FC<UserManagamentTableProps> = ({
  searchQuery = '',
  filterQuery = '',
}) => {
  const listRef = useRef<VariableSizeList>(null);
  const rowHeights = useRef<RowHeights>({});

  function setRowHeight(index: number, size: number) {
    listRef.current?.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  function getRowHeight(index: number) {
    const height = rowHeights.current[Number(index)] || 60;

    return height < 60 ? 60 : height;
  }

  // Row Element for home table
  const Row = forwardRef<any, ListChildComponentProps>(
    ({ data, index, style }, _) => {
      const rowRef = useRef<HTMLDivElement>(null);

      useEffect(() => {
        if (rowRef.current) setRowHeight(index, rowRef.current.offsetHeight);
      }, [rowRef, index]);

      const handleEdit = async () => {
        let employee: AddEditEmployeeState = data[index];

        const findBranch = branches.find(
          (value) => value.id.toString() === employee.Factory_id?.toString()
        )?.branch_name;

        employee.company_branch = findBranch;
        employee.registered_date = new Date(
          employee.registered_date!
        ).toLocaleDateString('en-CA');

        setState(employee);
        setEditEmployee(true);
      };

      const handleEditStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;

        setState(data[index]);
        setStatus(checked);
        setEditStatus(true);
      };

      return (
        <>
          <div style={style} className="user-management-table-row px-4">
            <div className="text-footnote text-black font-bold">
              {index + 1}
            </div>
            <div ref={rowRef} className="text-footnote text-black font-bold">
              <span className="inline-block">{data[index].id}</span>
            </div>
            <div className="text-footnote text-black font-bold">
              <span>{data[index].nama_employee}</span>
            </div>
            <div className="text-footnote text-black font-bold">
              <span>{data[index].role.Role_name}</span>
            </div>
            <div className="text-footnote text-black font-bold">
              <span>
                {new Date(data[index].registered_date).toLocaleDateString(
                  'id-ID'
                )}
              </span>
            </div>
            <div className="text-footnote text-black font-bold">
              <span>{data[index].phone}</span>
            </div>
            <div className="text-footnote text-black font-bold">
              <Switch
                onChange={handleEditStatus}
                checked={Boolean(data[index].active_status)}
              />
            </div>
            <button
              onClick={handleEdit}
              className="text-footnote border-0 bg-transparent text-black font-bold cursor-pointer justify-self-center"
            >
              <IconEdit height="24" width="24" />
            </button>
          </div>
        </>
      );
    }
  );

  // State for returned home table element
  const [users, setUsers] = useState<AddEditEmployeeState[]>([]);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(true);
  const [editEmployee, setEditEmployee] = useState<boolean>(false);
  const [state, setState] = useState<AddEditEmployeeState>();
  const [user, setUser] = useState<IUser>();
  const [branches, setBranches] = useState<Branch[]>([]);
  const [editDialog, setEditDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [successEdit, setSuccessEdit] = useState<boolean>(false);
  const [editStatus, setEditStatus] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>();
  const [successEditStatus, setSuccessEditStatus] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>('');
  const [isAscending, setAscending] = useState<boolean>(true);

  useUser(async (userFromLocal, err) => {
    if (err) console.log(err);
    else {
      setUser(userFromLocal);

      const res = await fetch(urlAPI + '/employee', {
        headers: {
          Authorization: 'Bearer ' + userFromLocal?.token,
        },
      });

      const data = await res.json();

      const resBranch = await fetch(urlAPI + '/employee/filter', {
        headers: {
          Authorization: 'Bearer ' + userFromLocal?.token,
        },
      });

      const dataBranches = (await resBranch.json()).factories || [];

      setBranches(dataBranches);

      setUsers(data.data || []);
      setLoadingUsers(false);
    }
  });

  // Effect for handling filtering, search query and sort
  useEffect(() => {
    if (user) {
      let s = '';
      let name = '';
      let asc = '';
      let type = '';

      if (sortBy.length > 0) s = `&sortBy=${sortBy}`;
      if (searchQuery?.length > 0) name = `&name=${searchQuery}`;
      if (filterQuery.length > 0) type = filterQuery;
      isAscending ? (asc = '&sort=ASC') : (asc = '&sort=DESC');

      const fetchData = async function () {
        const res = await fetch(
          urlAPI + `/employee?limit=999999${s + asc + name + type}`,
          {
            headers: {
              Authorization: 'Bearer ' + user?.token,
            },
          }
        );

        const data = await res.json();
        setUsers(data.data || []);
      };

      fetchData();
    }
  }, [sortBy, isAscending, searchQuery, user, filterQuery]);

  const handleSort = (sort: string, asc: boolean) => {
    if (sort !== sortBy || sortBy.length === 0) {
      setAscending(true);
    } else {
      setAscending(asc);
    }

    setSortBy(sort);
  };

  const handleEditEmployee = (val: AddEditEmployeeState) => {
    setState(val);
    setEditEmployee(false);
    setEditDialog(true);
  };

  const handleProceedDialog = async () => {
    // Fetch API to add employee here
    setLoading(true);
    const body = new FormData();
    body.append('nama_employee', state?.nama_employee!);
    if (state?.avatar) body.append('avatar', state?.avatar);
    body.append('is_superadmin', state?.is_superadmin?.toString()!);
    body.append('User_Role_id', JSON.stringify(state?.User_Role_id));
    body.append('Factory_id', JSON.stringify(state?.User_Role_id));
    body.append('phone', state?.phone?.toString()!);

    const res = await fetch(urlAPI + `/employee/${state?.id}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
      body,
    });

    if (res.status === 200) {
      setLoading(false);
      setEditDialog(false);
      setSuccessEdit(true);

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  };

  const handleCancelDialog = () => {
    setEditDialog(false);
    setEditEmployee(true);
  };

  const handleProceedStatus = async () => {
    // Fetch API to add employee here
    setLoading(true);
    const body = {
      active_status: status ? 1 : 0,
    };

    const res = await fetch(urlAPI + `/employee/${state?.id}/change-status`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user?.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (res.status === 200) {
      setLoading(false);
      setEditStatus(false);
      setSuccessEditStatus(true);

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  };

  return (
    <>
      {editEmployee && (
        <AddEditEmployee
          onCancel={() => setEditEmployee(false)}
          state={state}
          type="edit"
          onSubmit={handleEditEmployee}
        />
      )}
      {editDialog && (
        <Dialog
          onSubmit={handleProceedDialog}
          message={`Do you really want to update the ${state?.nama_employee} information?`}
          heading="Update Employee Information"
          onCancel={handleCancelDialog}
        />
      )}
      {loading && <Loading />}
      {successEdit && (
        <ProceedConfirmation
          message={`${state?.nama_employee} information has been changed`}
          onClose={() => setSuccessEdit(false)}
        />
      )}
      {editStatus && (
        <Dialog
          onSubmit={handleProceedStatus}
          message={`Are you sure you want to ${status ? 'enable' : 'disable'} ${
            state?.nama_employee
          } account?`}
          heading={`${status ? 'Enable' : 'Disable'} Employee`}
          reverseButton
          submitText={`${status ? 'Enable' : 'Disable'}`}
          onCancel={() => setEditStatus(false)}
        />
      )}
      {successEditStatus && (
        <ProceedConfirmation
          Icon={
            !status ? () => <IconPersonDisabled className="mb-4" /> : undefined
          }
          message={`${state?.nama_employee} account has been ${
            status ? 'enabled' : 'disabled'
          }`}
          onClose={() => setSuccessEditStatus(false)}
        />
      )}
      <div className="user-management-table bg-white rounded-lg px-8 py-10 border rounded-lg border-grey-light">
        <div className="user-management-table-row py-4 px-4">
          <div className="text-body text-grey font-bold flex items-center">
            <span className="block mr-2">NO.</span>
          </div>
          <button
            onClick={() => handleSort('id', !isAscending)}
            className="text-body text-grey font-bold flex items-center cursor-pointer"
          >
            <span className="block mr-2">Employee ID</span>
            <IconSort />
          </button>
          <button
            onClick={() => handleSort('nama_employee', !isAscending)}
            className="text-body text-grey font-bold flex items-center cursor-pointer"
          >
            <span className="block mr-2">Employee Name</span>
            <IconSort />
          </button>
          <button
            onClick={() => handleSort('User_Role_id', !isAscending)}
            className="text-body text-grey font-bold flex items-center cursor-pointer"
          >
            <span className="block mr-2">User Role</span>
            <IconSort />
          </button>
          <button
            onClick={() => handleSort('registered_date', !isAscending)}
            className="text-body text-grey font-bold flex items-center cursor-pointer"
          >
            <span className="block mr-2">Registered Date</span>
            <IconSort />
          </button>
          <button
            onClick={() => handleSort('phone', !isAscending)}
            className="text-body text-grey font-bold flex items-center cursor-pointer"
          >
            <span className="block mr-2">Phone</span>
            <IconSort />
          </button>
          <button
            onClick={() => handleSort('active_status', !isAscending)}
            className="text-body text-grey font-bold flex items-center cursor-pointer"
          >
            <span className="block mr-2">Status</span>
            <IconSort />
          </button>
          <div className="text-body text-grey font-bold flex items-center cursor-pointer justify-self-center">
            Action
          </div>
          <div></div>
        </div>
        {loadingUsers ? (
          <div className="flex justify-center">
            <LoadingAnimation className="mt-6" size="md" />
          </div>
        ) : (
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height - 80}
                itemData={users}
                itemCount={users.length}
                itemSize={getRowHeight}
                width={width + 5}
                ref={listRef}
                className="list"
              >
                {({ ...props }) => {
                  return <Row {...props} />;
                }}
              </List>
            )}
          </AutoSizer>
        )}
      </div>
    </>
  );
};

export default UserManagementTable;
