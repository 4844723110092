import classNames from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useState } from 'react';
import { IconClose, IconLaunch } from '../../../../assets';
import { ColdStorageColorMapping } from '../../../../colors';
import { ChartLegend } from 'components';

interface NobashiUnitSoakingMaterialUsageProps {
  className?: string;
}

const BrokenAndRejectedDistributionOptions: Highcharts.Options = {
  title: {
    text: '',
  },
  yAxis: {
    gridLineWidth: 0,
    title: {
      text: 'kg',
    },
  },
  series: [
    {
      type: 'pie',
      data: [
        {
          name: 'White',
          y: 4,
          color: ColdStorageColorMapping[1],
          dataLabels: { enabled: false },
        },
        {
          name: 'Black Pink',
          y: 2,
          color: ColdStorageColorMapping[2],
          dataLabels: { enabled: false },
        },
        {
          name: 'Black Pink',
          y: 2,
          color: ColdStorageColorMapping[4],
          dataLabels: { enabled: false },
        },
      ],
      innerSize: '80%',
      size: '75%',
      name: 'Incoming Distribution',
    },
  ],
  legend: {
    enabled: false,
  },
  tooltip: {
    valueSuffix: ' kg',
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 60,
        },
      },
    ],
  },
};

const NobashiUnitSoakingMaterialUsage: React.FC<NobashiUnitSoakingMaterialUsageProps> =
  ({ className }) => {
    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const [reRender, setReRender] = useState<boolean>(true);

    const handleFullScreen = () => {
      setFullScreen(!fullScreen);
      // Hack the chart so it can fill entire screen during fullscreen state
      setReRender(false);
      setTimeout(() => {
        setReRender(true);
      }, 0);
    };

    return (
      <div
        style={{ zIndex: fullScreen ? 1200 : 0 }}
        className={classNames('flex justify-center items-center', className, {
          relative: !fullScreen,
          'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        })}
      >
        <button onClick={handleFullScreen} className="absolute right-5 top-5">
          {!fullScreen ? <IconLaunch /> : <IconClose />}
        </button>
        <div className="w-full h-full border rounded-lg border-grey-light p-6">
          <h4 className="text-footnote font-bold mb-8">
            Soaking Material Usage
          </h4>

          {reRender && (
            <>
              <div className="grid grid-cols-3 items-center">
                <div className="col-span-2">
                  <HighchartsReact
                    options={BrokenAndRejectedDistributionOptions}
                    highcharts={Highcharts}
                  />
                </div>
                <div>
                  <ChartLegend
                    title="4 kg"
                    percentage="50"
                    className="mb-4"
                    color={ColdStorageColorMapping[1]}
                  />
                  <ChartLegend
                    title="4 kg"
                    percentage="50"
                    color={ColdStorageColorMapping[2]}
                  />
                </div>
              </div>
              <div className="flex items-center flex-wrap pl-10 mt-3">
                <ChartLegend
                  className="m-3"
                  color={ColdStorageColorMapping[1]}
                  title="White"
                />
                <ChartLegend
                  className="m-3"
                  color={ColdStorageColorMapping[2]}
                  title="Black Pink"
                />
              </div>
            </>
          )}
          <p className="text-caption-1 text-grey font-bold my-4">
            Total Quantity Material : <span className="text-blue-3">500kg</span>
          </p>
        </div>
      </div>
    );
  };

export default NobashiUnitSoakingMaterialUsage;
