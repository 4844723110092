import dayjs from 'dayjs';
import { useState } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  DatePicker,
  LabInspectionCard,
} from 'components';

const LabsInspectionUnitReportPage = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  return (
    <div style={{ minWidth: 1200 }}>
      <h1 className="text-heading font-bold mb-3">
        Lab Inspection Unit Report
      </h1>
      <Breadcrumb className="mb-5">
        <BreadcrumbItem href="/unit/">Home</BreadcrumbItem>
        <BreadcrumbItem href="/unit/cold-storage-unit">
          Lab Inspection Unit
        </BreadcrumbItem>
        <BreadcrumbItem isActive>Report</BreadcrumbItem>
      </Breadcrumb>
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <DatePicker
            selected={startDate}
            // @ts-ignore
            onChange={(date) => setStartDate(date)}
            dateFormat="E, d MMM yyyy"
            containerClassName="mr-3.5"
          />
          <DatePicker
            selected={endDate}
            // @ts-ignore
            onChange={(date) => setEndDate(date)}
            dateFormat="E, d MMM yyyy"
            popperPlacement="bottom-end"
          />
        </div>
      </div>
      <div className="grid grid-cols-2 border border-grey-light rounded-lg py-8">
        <div className="px-10 border-r border-grey-light pb-8">
          <div className="text-caption-1 mb-5">
            {dayjs(startDate).format('dddd, D MMM YYYY')} -{' '}
            {dayjs(endDate).format('dddd, D MMM YYYY')}
          </div>
          <div className="grid auto-grid-auto gap-1.5">
            <LabInspectionCard
              timeMode="06/12/2020"
              href="/lab-inspection-unit/report/06-12-2020"
              color="blue"
            />
            <LabInspectionCard
              timeMode="06/12/2020"
              href="/lab-inspection-unit/report/06-12-2020"
              color="green"
              approved
            />
            <LabInspectionCard
              timeMode="06/12/2020"
              href="/lab-inspection-unit/report/06-12-2020"
              color="yellow"
              approved
            />
            <LabInspectionCard
              timeMode="06/12/2020"
              href="/lab-inspection-unit/report/06-12-2020"
              color="purple"
              approved
            />
            <LabInspectionCard
              timeMode="06/12/2020"
              href="/lab-inspection-unit/report/06-12-2020"
              color="red"
            />
          </div>
        </div>
        <div className="px-10">
          <div className="text-caption-1 mb-5">Today</div>
          <LabInspectionCard
            timeMode="06/12/2020"
            href="/lab-inspection-unit/report/06-12-2020"
            color="blue"
          />
        </div>
      </div>
    </div>
  );
};

export default LabsInspectionUnitReportPage;
