import classNames from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState } from 'react';
import { IconClose, IconLaunch } from '../../../../assets';
import { ContactFreezerColorMapping } from '../../../../colors';
import { ChartLegend, MultiSelectDropdown } from 'components';

interface FreezingUnitProductionProps {
  className?: string;
  realtime?: boolean;
}

const lineChartIncomingOutputOptions: Highcharts.Options = {
  title: {
    text: '',
  },
  xAxis: {
    type: 'datetime',
    title: {
      text: 'Hours',
    },
    dateTimeLabelFormats: {
      hour: '%H:%M',
    },
    gridLineWidth: 1,
  },
  yAxis: {
    gridLineWidth: 0,
    title: {
      text: null,
    },
    labels: {
      format: '{value} ton',
    },
  },
  series: [
    {
      type: 'spline',
      name: 'Incoming',
      data: [2, 3, 4, 5, 6, 34, 3, 2],
      marker: {
        enabled: false,
      },
      color: ContactFreezerColorMapping[1],
    },
    {
      type: 'spline',
      name: 'Output',
      data: [1, 3, 9, 15, 2, 14, 9, 4],
      marker: {
        enabled: false,
      },
      color: ContactFreezerColorMapping[2],
    },
  ],
  plotOptions: {
    line: {
      lineWidth: 4,
      states: {
        hover: {
          lineWidth: 5,
        },
      },
      pointInterval: 60 * 60 * 1000,
      marker: {
        enabled: false,
      },
    },
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    valueSuffix: ' Ton',
  },
};

const FreezingUnitProduction: React.FC<FreezingUnitProductionProps> = ({
  className,
  realtime,
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [reRender, setReRender] = useState<boolean>(true);

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
    // Hack the chart so it can fill entire screen during fullscreen state
    setReRender(false);
    setTimeout(() => {
      setReRender(true);
    }, 0);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', className, {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
      })}
    >
      <button onClick={handleFullScreen} className="absolute right-5 top-5">
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">Production</h4>
        <div className="grid grid-cols-4 gap-x-2 mb-5 relative z-20 ml-4">
          <div></div>
          <div></div>
          <div></div>
          <MultiSelectDropdown placeholder="Contact Freezer" />
        </div>
        {reRender && (
          <div>
            <HighchartsReact
              options={lineChartIncomingOutputOptions}
              highcharts={Highcharts}
            />
            <div className="flex items-center justify-center mt-4">
              <ChartLegend
                title="Success"
                color={ContactFreezerColorMapping[1]}
                className="mr-8"
              />
              {realtime && (
                <ChartLegend
                  title="Currently Processed"
                  color={ContactFreezerColorMapping[3]}
                  className="mr-8"
                />
              )}
              <ChartLegend title="Fail" color={ContactFreezerColorMapping[2]} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FreezingUnitProduction;
