import { TableGrid } from 'components';
import { useQuery } from 'react-query';
import { urlAPIUnit } from 'utils';
import { TableAdapter, TableData,  transformHeaderToColumn } from 'utils/table-adapter';

const ProcessLogTable: React.FC<ProcessLogProperty> = ({start_date, end_date, unit}) => {

    // const transformWeight = (weight: any) => {
    //   return (weight !== '' ? `${weight} kg` : '-');
    // }mport InfiniteScroll from 'react-infinite-scroll-component';
  
    const { data: datas } = useQuery<Array<ITableGridPackageExtended>>(['sorting-machine-production-log', start_date, end_date], async () => {
      console.log('run');
      const targetUrl = urlAPIUnit + `/general/production/${unit}?start_date=${start_date}&end_date=${end_date}`;
      console.log(`Fetching ${targetUrl}`)
      const result = await fetch(targetUrl);
      const data = await result.json();
      let finalResult: ITableGridPackageExtended[] = [];
      if(data?.data?.length) {
        for (let index = 0; index < data.data.length; index++) {
            const extendedTableData = data?.data[index];
            const header: ITableGridMainHeader = extendedTableData.header;
            const cols: string[] = transformHeaderToColumn(header);
            const title: string = typeof (extendedTableData?.title) == "string" ? extendedTableData?.title : "";
            const tableData: TableData = new TableData(extendedTableData.tableData);
            const tableAdapter = new TableAdapter(tableData, cols);
            const tableGridData = tableAdapter.getTableGridData();
            console.log('row size', tableData.getRowSize());
            console.log('table grid data', JSON.stringify(tableGridData));
            const tableGridDataExtended:ITableGridPackageExtended = {
                title: title,
                header: header,
                cols: cols,
                tableGridData: tableGridData.tableGridData,
                rowSize: tableGridData.rowSize,
            };
            finalResult = [
                ...finalResult,
                tableGridDataExtended
            ]
        }
      }
      return finalResult;
    });

  
    return (
      <div  className="rounded-lg px-5 py-4 border border-grey-1 mb-9 max-w-none">
        {datas?.map?.(
            (data, index) => {
                console.log(data.header);
                return <TableGrid key ={index}
                // className="mx-5"
                // fixWidth="6vw"
                columns={[
                    {
                    Component: (
                        <div
                        key="process-log-main-column"
                        style={{gridTemplateColumns: `repeat(${data.header.reduce((a,b)=> typeof b === 'object' ? (a + b.sub.length) : a + 1, 0)}, 1fr)`}}
                        className="grid grid-rows-2 items-center grid-flow-col"
                        >
                        {
                            data.header.map((item, i) => (
                                typeof item === 'object' ?
                                (
                                    <div key={i} className={`h-full row-span-6 col-span-${item.sub.length} border-r ${!i ? 'border-l' : ''}`}>
                                    <div className={`row-span-3 col-span-${item.sub.length} border-b`}>{item.main}</div>
                                    <div className={`row-span-3 col-span-${item.sub.length} grid grid-cols-${item.sub.length} grid-rows-1`}>
                                        {
                                            item.sub.map((subitem, j) => (
                                                <div key={j} className={`row-span-3 col-span-1 ${j === item.sub.length-1 ? '' : 'border-r'}`}>{subitem}</div>
                                            ))
                                        }
                                    </div>
                                    </div>
                                )
                                :
                                <div key={i} className={`table h-full row-span-6 col-span-1 border-r ${!i ? 'border-l' : ''}`}>
                                    <span className='table-cell align-middle'>{item}</span>
                                </div>
                            ))
                        }
                        {/* <div className="row-span-6 col-span-1">Date</div>
                        <div className="row-span-6 col-span-7 border-r border-l">
                            <div className="row-span-3 col-span-7 border-b">Input</div>
                            <div className="row-span-3 col-span-7 grid grid-cols-7 grid-rows-1">
                            <div className="row-span-3 col-span-1">Container</div>
                            <div className="row-span-3 col-span-1">Supplier</div>
                            <div className="row-span-3 col-span-1">Product</div>
                            <div className="row-span-3 col-span-1">Species</div>
                            <div className="row-span-3 col-span-1">Size</div>
                            <div className="row-span-3 col-span-1">Weight</div>
                            <div className="row-span-3 col-span-1">Source</div>
                            </div>
                        </div>
                        <div className="row-span-6 col-span-6 border-r">
                        <div className="row-span-3 col-span-6 border-b">Output</div>
                            <div className="row-span-3 col-span-6 grid grid-cols-6 grid-rows-1">
                            <div className="row-span-3 col-span-1">Container</div>
                            <div className="row-span-3 col-span-1">Supplier</div>
                            <div className="row-span-3 col-span-1">Product</div>
                            <div className="row-span-3 col-span-1">Species</div>
                            <div className="row-span-3 col-span-1">Size</div>
                            <div className="row-span-3 col-span-1">Weight</div>
                            </div>
                        </div> */}
                        </div>
                    ),
                    cols: data?.cols,
                    width: '14fr',
                    rows: data?.rowSize,
                    merge: data?.tableGridData?.merge,
                    },
                ]}
                data={[data?.tableGridData?.data || {}]}
                />;
            }
        )}
        
      </div>
    );
};

export default ProcessLogTable;