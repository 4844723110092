import classNames from 'classnames';
import { useState } from 'react';
import { DummyAvatar, IconClose, IconLaunch } from 'assets';

interface OperatorInChargeProps {
  className?: string;
}

const OperatorInCharge: React.FC<OperatorInChargeProps> = ({
  className = '',
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        [className]: className,
      })}
    >
      <button
        onClick={handleFullScreen}
        className="absolute right-5 top-5 z-10"
      >
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6 relative">
        <h4 className="text-footnote font-bold mb-8 absolute top-6 left-6">
          Operator in Charge
        </h4>
        <div className="flex flex-col items-center justify-center h-full text-center">
          <img
            src={DummyAvatar}
            alt="Avatar"
            className="h-32 w-32 rounded-full object-center object-cover mb-4"
          />
          <span className="font-bold mb-1">Operator in Charge</span>
          <span className="text-footnote">07:00 - 18:00 WITA</span>
        </div>
      </div>
    </div>
  );
};

export default OperatorInCharge;
