import dayjs from 'dayjs';
import classNames from 'classnames';
import humanizeDuration from 'humanize-duration';
import { useQuery } from 'react-query';
import { urlAPIMechanic } from 'utils';

interface DoorOpenedTableProps {
  startDate?: string;
  endDate?: string;
}

type DoorOpenedTableData = {
  id: number;
  nama_node: string;
  durationTotal: number;
  durationDoorOpen: number;
  durationDoorClose: number;
  startTime: string;
  endTime: string;
};

const DoorOpenedTable: React.FC<DoorOpenedTableProps> = ({
  startDate = dayjs().subtract(6, 'days').format('YYYY-MM-DD'),
  endDate = dayjs().format('YYYY-MM-DD'),
}) => {
  const { data } = useQuery<DoorOpenedTableData[]>(
    ['coldStorageDoorOpen', startDate, endDate],
    () =>
      fetch(
        urlAPIMechanic +
          `/mechanic/cold-storage/report/door-open-occurrences?start=${startDate}&end=${endDate}`
      )
        .then((res) => res.json())
        .then((data) => data.data)
  );

  return (
    <div className="rounded-lg px-8 py-7 border border-grey-1">
      <div
        style={{ gridTemplateColumns: '60px repeat(6, 1fr)' }}
        className="grid  font-semibold text-caption-1 px-2 py-2.5"
      >
        <div>No</div>
        <div>Start Time</div>
        <div>End Time</div>
        <div>Cold Storage Duration</div>
        <div>Total Duration</div>
        <div>Door Opened</div>
        <div>Door Closed</div>
      </div>
      {/* Put the children here later */}
      {data?.map((val, i) => (
        <div
          key={`door-opened-table-${val.id}`}
          style={{ gridTemplateColumns: '60px repeat(6, 1fr)' }}
          className={classNames('grid text-caption-1 px-2 py-2.5', {
            'bg-white-snow': i % 2,
          })}
        >
          <div>{i + 1}</div>
          <div>{dayjs(val.startTime).format('DD MMM YYYY HH:MM')}</div>
          <div>{dayjs(val.endTime).format('DD MMM YYYY HH:MM')}</div>
          <div>{val.nama_node}</div>
          <div>
            {humanizeDuration(val.durationTotal * 1000, { round: true })}
          </div>
          <div>
            {humanizeDuration(val.durationDoorOpen * 1000, {
              round: true,
            })}
          </div>
          <div>
            {humanizeDuration(val.durationDoorClose * 1000, {
              round: true,
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DoorOpenedTable;
