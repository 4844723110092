import classNames from 'classnames';
import { Children, cloneElement, useRef, useState } from 'react';
import { IconDropdown } from 'assets';
import { useOutside } from 'utils/useOutside';

interface MultiSelectDropdownProps {
  className?: string;
  placeholder?: string;
  disabled?: boolean;
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  className,
  placeholder,
  children,
  disabled,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [state, setState] = useState<Record<string, boolean | string>>({});
  const selectedLength = Object.keys(state).filter((k) => state[k]).length;

  const boxRef = useRef(null);
  const triggerRef = useRef(null);

  useOutside(boxRef, triggerRef, () => {
    setShow(false);
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value, name, type } = e.target;

    if (type === 'radio') {
      setState({
        ...state,
        [name]: value,
      });
    } else {
      setState({
        ...state,
        [value]: checked,
      });
    }
  };

  const newChildren = Children.map(children, (child: any) =>
    cloneElement(child, { state, handleChange })
  );

  return (
    <div className={classNames('relative z-50', className)}>
      {show && (
        <div
          ref={boxRef}
          style={{ top: '2.5rem' }}
          className="absolute w-full left-0 bg-white shadow-lg px-3.5 py-3"
        >
          <div>{newChildren}</div>
          <div className="flex justify-end">
            <button
              onClick={() => setState({})}
              className="mt-5 text-blue text-caption-2"
            >
              Reset
            </button>
          </div>
        </div>
      )}
      <button
        disabled={disabled}
        ref={triggerRef}
        onClick={() => setShow(!show)}
        style={{ height: 35 }}
        className={classNames(
          'flex items-center justify-between border border-grey-1 text-footnote pl-4 pr-2 rounded-lg focus:outline-none w-full',
          { 'bg-grey-3': disabled }
        )}
      >
        <div className="flex items-center text-grey-placeholder">
          <span className="mr-1 text-caption-1 line-clamp-1">
            {placeholder}
          </span>
          {selectedLength > 0 && (
            <div
              style={{ fontSize: 9 }}
              className="rounded-full h-4 w-4 bg-blue text-white flex justify-center items-center"
            >
              {selectedLength}
            </div>
          )}
        </div>
        <IconDropdown />
      </button>
    </div>
  );
};

export default MultiSelectDropdown;
