import classNames from 'classnames';
import './index.css';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'outlined' | 'default';
  color?: 'red' | 'default' | 'purple' | 'blue-light' | 'grey' | 'dark-blue';
  fullWidth?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'default',
  className = '',
  color = 'default',
  fullWidth,
  ...otherProps
}) => {
  return (
    <button
      {...otherProps}
      className={classNames(
        'btn text-center flex items-center justify-center px-4 rounded-md text-caption-1 font-semibold  focus:outline-none',
        {
          [className]: className.length > 0,
          'text-white': variant === 'default',
          'bg-transparent border': variant === 'outlined',
          'bg-red': color === 'red',
          'bg-blue-2': color === 'default' && variant === 'default',
          'text-blue-2 border-blue-2':
            color === 'default' && variant === 'outlined',
          'text-colorful-purple border-colorful-purple':
            color === 'purple' && variant === 'outlined',
          'border-blue-light': color === 'blue-light' && variant === 'outlined',
          'w-full': fullWidth,
          'btn-grey': color === 'grey',
          'btn-grey btn-grey--outlined':
            color === 'grey' && variant === 'outlined',
          'bg-blue': color === 'dark-blue' && variant === 'default',
          'text-blue border-blue':
            color === 'dark-blue' && variant === 'outlined',
        }
      )}
    >
      {children}
    </button>
  );
};

export default Button;
