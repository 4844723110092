import { MouseEvent } from "react";
import axios from "axios";
import { urlAPITablet, urlAPIUnit } from "./urlApi";
import dayjs from "dayjs";

export const handleDownloadExcelUnit = (e:MouseEvent<HTMLButtonElement>, startDate:string, endDate:string, unit:number, name:string) => {
    axios({
      url: urlAPIUnit + `/general/excel/${unit}?start_date=${dayjs(startDate).format('YYYY-MM-DD')}&end_date=${dayjs(endDate).format('YYYY-MM-DD')}`,
      method: 'GET',
      responseType: 'blob', 
    }).then((response) => {
       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', `${name}_${dayjs(startDate).format('DD-MM-YYYY')}_${dayjs(endDate).format('DD-MM-YYYY')}.xlsx`); 
       document.body.appendChild(link);
       link.click();
       e.preventDefault();
    }).catch(err => {
      throw err;
    });
  }

export const handleDownloadExcelUnitSortingMachine = (e:MouseEvent<HTMLButtonElement>, startDate:string, endDate:string, name:string) => {
  axios({
    url: urlAPITablet + `/sorting-machine/report/production?start_date=${dayjs(startDate).format('YYYY-MM-DD')}&end_date=${dayjs(endDate).format('YYYY-MM-DD')}`,
    method: 'GET',
    responseType: 'blob', 
  }).then((response) => {
     const url = window.URL.createObjectURL(new Blob([response.data]));
     const link = document.createElement('a');
     link.href = url;
     link.setAttribute('download', `${name}_${dayjs(startDate).format('DD-MM-YYYY')}_${dayjs(endDate).format('DD-MM-YYYY')}.xlsx`); 
     document.body.appendChild(link);
     link.click();
     e.preventDefault();
  }).catch(err => {
    throw err;
  });
}

export const handleDownloadExcelUnitReceivingStation = (e:MouseEvent<HTMLButtonElement>, startDate:string, endDate:string, name:string) => {
  axios({
    url: urlAPITablet + `/receiving-station/report/production?start_date=${dayjs(startDate).format('YYYY-MM-DD')}&end_date=${dayjs(endDate).format('YYYY-MM-DD')}`,
    method: 'GET',
    responseType: 'blob', 
  }).then((response) => {
     const url = window.URL.createObjectURL(new Blob([response.data]));
     const link = document.createElement('a');
     link.href = url;
     link.setAttribute('download', `${name}_${dayjs(startDate).format('DD-MM-YYYY')}_${dayjs(endDate).format('DD-MM-YYYY')}.xlsx`); 
     document.body.appendChild(link);
     link.click();
     e.preventDefault();
  }).catch(err => {
    throw err;
  });
}