import { TableGrid } from 'components';

const PeelingUnitProcessLogTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">Detailed Data Output</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9 overflow-auto">
        <TableGrid
          config={{ minWidth: '2200px' }}
          columns={[
            {
              field: 'process',
              name: 'Process.',
              width: '80px',
            },
            {
              field: 'startTimeStamp',
              name: 'Start Time Stamp',
            },
            {
              field: 'finishTimeStamp',
              name: 'Finish Time Stamp',
            },
            {
              name: 'Input',
              Component: (
                <div className="grid grid-cols-5 gap-y-4">
                  <div className="col-span-5 text-center pb-2 border-b border-grey-placeholder">
                    Input
                  </div>
                  <div className="text-center">Product</div>
                  <div className="text-center">Species</div>
                  <div className="text-center">Size</div>
                  <div className="text-center">Weight</div>
                  <div className="text-center">Container ID</div>
                </div>
              ),
              width: '5fr',
              cols: [
                'inputProduct',
                'inputSpecies',
                'inputSize',
                'weight',
                'containerID',
              ],
              rows: 3,
            },
            {
              name: 'Output',
              Component: (
                <div className="grid grid-cols-8 gap-y-4">
                  <div className="col-span-8 text-center pb-2 border-b border-grey-placeholder">
                    Output
                  </div>
                  <div className="text-center">Product</div>
                  <div className="text-center">Species</div>
                  <div className="text-center">Size</div>
                  <div className="text-center">Count</div>
                  <div className="text-center col-span-3">After Peeled</div>
                  <div className="text-center">After Peeled Total</div>
                </div>
              ),
              width: '8fr',
              cols: [
                'outputProduct',
                'outputSpecies',
                'outputSize',
                'count',
                'afterPeeled1',
                'afterPeeled2',
                'afterPeeled3',
                'afterPeeledTotal',
              ],
              rows: 6,
            },
            {
              name: 'After Cooked',
              Component: (
                <div className="grid grid-cols-3 gap-y-4">
                  <div className="col-span-3 text-center">After Cooked</div>
                </div>
              ),
              width: '3fr',
              cols: ['afterCooked1', 'afterCooked2', 'afterCooked3'],
              rows: 12,
              merge: [
                {
                  fieldToMerge: ['afterCooked3.12'],
                  mergeInto: 'totalValue',
                  style: {
                    textAlign: 'center',
                  },
                },
                {
                  fieldToMerge: ['afterCooked1.12', 'afterCooked2.12'],
                  mergeInto: 'total',
                  style: {
                    textAlign: 'center',
                  },
                },
              ],
            },
          ]}
          data={[
            {
              process: '1',
              startTimeStamp: '21 Juli 2020',
              finishTimeStamp: '22 Juli 2020',
              'inputProduct.1': 'PUD 1st',
              'inputProduct.2': 'PUD 1st',
              'inputProduct.3': 'PUD 2nd',
              'inputSpecies.1': 'Black Tiger',
              'inputSpecies.2': 'Black Tiger',
              'inputSpecies.3': 'Black Tiger',
              'inputSize.1': '21 - 25',
              'inputSize.2': '21 - 25',
              'inputSize.3': '21 - 25',
              'weight.1': '240',
              'weight.2': '240',
              'weight.3': '240',
              'containerID.1': 'P-001',
              'containerID.2': 'P-001',
              'containerID.3': 'P-001',
              'outputProduct.1': 'Cooked 1st 800g',
              'outputProduct.2': 'Cooked 1st 800g',
              'outputProduct.3': 'Cooked 1st 800g',
              'outputProduct.4': 'Cooked 2nd 800g',
              'outputProduct.5': 'Cooked Broken 800g',
              'outputProduct.6': 'Cooked 2nd 800g',
              'outputSpecies.1': 'Black Tiger',
              'outputSpecies.2': 'Black Tiger',
              'outputSpecies.3': 'Black Tiger',
              'outputSpecies.4': 'Black Tiger',
              'outputSpecies.5': 'Black Tiger',
              'outputSpecies.6': 'Black Tiger',
              'outputSize.1': '100 - 200',
              'outputSize.2': '200 - 300',
              'outputSize.3': '50 - 90',
              'outputSize.4': '100 - 200',
              'outputSize.5': '',
              'outputSize.6': '',
              'count.1': '200',
              'count.2': '200',
              'count.3': '200',
              'count.4': '200',
              'count.5': '200',
              'count.6': '',
              'afterPeeled1.1': ['200', '200'],
              'afterPeeled1.2': ['200', '200'],
              'afterPeeled1.3': ['200', '200'],
              'afterPeeled1.4': ['200', '200'],
              'afterPeeled1.5': ['200', '200'],
              'afterPeeled1.6': ['200', '200'],
              'afterPeeled2.1': ['200', '200'],
              'afterPeeled2.2': ['200', '200'],
              'afterPeeled2.3': ['200', '200'],
              'afterPeeled2.4': ['200', '200'],
              'afterPeeled2.5': ['200', '200'],
              'afterPeeled2.6': ['200', '200'],
              'afterPeeled3.1': ['200', '200'],
              'afterPeeled3.2': ['200', '200'],
              'afterPeeled3.3': ['200', '200'],
              'afterPeeled3.4': ['200', '200'],
              'afterPeeled3.5': ['200', '200'],
              'afterPeeled3.6': ['200', '200'],
              'afterPeeledTotal.1': '2400',
              'afterPeeledTotal.2': '2400',
              'afterPeeledTotal.3': '2400',
              'afterPeeledTotal.4': '2400',
              'afterPeeledTotal.5': '2400',
              'afterPeeledTotal.6': '2400',
              'afterCooked1.1': '20',
              'afterCooked1.2': '20',
              'afterCooked1.3': '20',
              'afterCooked1.4': '20',
              'afterCooked1.5': '20',
              'afterCooked1.6': '20',
              'afterCooked1.7': '20',
              'afterCooked1.8': '20',
              'afterCooked1.9': '20',
              'afterCooked1.10': '20',
              'afterCooked1.11': '20',
              'afterCooked1.12': '',
              'afterCooked2.1': '20',
              'afterCooked2.2': '20',
              'afterCooked2.3': '20',
              'afterCooked2.4': '20',
              'afterCooked2.5': '20',
              'afterCooked2.6': '20',
              'afterCooked2.7': '20',
              'afterCooked2.8': '20',
              'afterCooked2.9': '20',
              'afterCooked2.10': '20',
              'afterCooked2.11': '20',
              'afterCooked2.12': '',
              'afterCooked3.1': '20',
              'afterCooked3.2': '20',
              'afterCooked3.3': '20',
              'afterCooked3.4': '20',
              'afterCooked3.5': '20',
              'afterCooked3.6': '20',
              'afterCooked3.7': '20',
              'afterCooked3.8': '20',
              'afterCooked3.9': '20',
              'afterCooked3.10': '20',
              'afterCooked3.11': '20',
              'afterCooked3.12': '',
              totalValue: '200',
              total: 'Total',
            },
            {
              process: '2',
              startTimeStamp: '21 Juli 2020',
              finishTimeStamp: '22 Juli 2020',
              'inputProduct.1': 'PUD 1st',
              'inputProduct.2': 'PUD 1st',
              'inputProduct.3': 'PUD 2nd',
              'inputSpecies.1': 'Black Tiger',
              'inputSpecies.2': 'Black Tiger',
              'inputSpecies.3': 'Black Tiger',
              'inputSize.1': '21 - 25',
              'inputSize.2': '21 - 25',
              'inputSize.3': '21 - 25',
              'weight.1': '240',
              'weight.2': '240',
              'weight.3': '240',
              'containerID.1': 'P-001',
              'containerID.2': 'P-001',
              'containerID.3': 'P-001',
              'outputProduct.1': 'Cooked 1st 800g',
              'outputProduct.2': 'Cooked 1st 800g',
              'outputProduct.3': 'Cooked 1st 800g',
              'outputProduct.4': 'Cooked 2nd 800g',
              'outputProduct.5': 'Cooked Broken 800g',
              'outputProduct.6': 'Cooked 2nd 800g',
              'outputSpecies.1': 'Black Tiger',
              'outputSpecies.2': 'Black Tiger',
              'outputSpecies.3': 'Black Tiger',
              'outputSpecies.4': 'Black Tiger',
              'outputSpecies.5': 'Black Tiger',
              'outputSpecies.6': 'Black Tiger',
              'outputSize.1': '100 - 200',
              'outputSize.2': '200 - 300',
              'outputSize.3': '50 - 90',
              'outputSize.4': '100 - 200',
              'outputSize.5': '',
              'outputSize.6': '',
              'count.1': '200',
              'count.2': '200',
              'count.3': '200',
              'count.4': '200',
              'count.5': '200',
              'count.6': '',
              'afterPeeled1.1': ['200', '200'],
              'afterPeeled1.2': ['200', '200'],
              'afterPeeled1.3': ['200', '200'],
              'afterPeeled1.4': ['200', '200'],
              'afterPeeled1.5': ['200', '200'],
              'afterPeeled1.6': ['200', '200'],
              'afterPeeled2.1': ['200', '200'],
              'afterPeeled2.2': ['200', '200'],
              'afterPeeled2.3': ['200', '200'],
              'afterPeeled2.4': ['200', '200'],
              'afterPeeled2.5': ['200', '200'],
              'afterPeeled2.6': ['200', '200'],
              'afterPeeled3.1': ['200', '200'],
              'afterPeeled3.2': ['200', '200'],
              'afterPeeled3.3': ['200', '200'],
              'afterPeeled3.4': ['200', '200'],
              'afterPeeled3.5': ['200', '200'],
              'afterPeeled3.6': ['200', '200'],
              'afterPeeledTotal.1': '2400',
              'afterPeeledTotal.2': '2400',
              'afterPeeledTotal.3': '2400',
              'afterPeeledTotal.4': '2400',
              'afterPeeledTotal.5': '2400',
              'afterPeeledTotal.6': '2400',
              'afterCooked1.1': '20',
              'afterCooked1.2': '20',
              'afterCooked1.3': '20',
              'afterCooked1.4': '20',
              'afterCooked1.5': '20',
              'afterCooked1.6': '20',
              'afterCooked1.7': '20',
              'afterCooked1.8': '20',
              'afterCooked1.9': '20',
              'afterCooked1.10': '20',
              'afterCooked1.11': '20',
              'afterCooked1.12': '',
              'afterCooked2.1': '20',
              'afterCooked2.2': '20',
              'afterCooked2.3': '20',
              'afterCooked2.4': '20',
              'afterCooked2.5': '20',
              'afterCooked2.6': '20',
              'afterCooked2.7': '20',
              'afterCooked2.8': '20',
              'afterCooked2.9': '20',
              'afterCooked2.10': '20',
              'afterCooked2.11': '20',
              'afterCooked2.12': '',
              'afterCooked3.1': '20',
              'afterCooked3.2': '20',
              'afterCooked3.3': '20',
              'afterCooked3.4': '20',
              'afterCooked3.5': '20',
              'afterCooked3.6': '20',
              'afterCooked3.7': '20',
              'afterCooked3.8': '20',
              'afterCooked3.9': '20',
              'afterCooked3.10': '20',
              'afterCooked3.11': '',
              'afterCooked3.12': '',
              totalValue: '200',
              total: 'Total',
            },
          ]}
          onRenderField={(_field, value, { key }) => {
            return (
              <div key={key} className="text-center">
                {value}
              </div>
            );
          }}
        />
      </div>
    </>
  );
};

export default PeelingUnitProcessLogTable;
