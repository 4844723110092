import { IconArrowBreadcrumb, IconClose, IconLaunch } from 'assets';
import classNames from 'classnames';
import { ContactFreezerColorMapping } from 'colors';
import { Button } from 'components';
import { ChartLegend, MultiSelectDropdown } from 'components';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useMemo, useState } from 'react';

interface ManagementCostMaterialAndLaborChartProps extends IBaseChart {
  className?: string;
  queryKey?: string;
}

const ManagementCostMaterialAndLaborChart: React.FC<ManagementCostMaterialAndLaborChartProps> =
  ({ className }) => {
    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const [reRender, setReRender] = useState<boolean>(true);
    const [highest, setHeight] = useState<number>(0);
    const [cost, setCost] = useState<'IDR' | 'IDR / kg'>('IDR');

    const barChartOptions = useMemo<Highcharts.Options>(
      () => ({
        title: {
          text: '',
        },
        chart: {
          type: 'column',
        },
        xAxis: {
          gridLineWidth: 1,
          categories: [
            'Product 1',
            'Product 2',
            'Product 3',
            'Product 4',
            'Product 5',
          ],
        },
        yAxis: {
          gridLineWidth: 0,
          title: {
            text: `Cost (${cost})`,
            rotation: -90,
          },
        },

        series: [
          {
            data: [
              {
                color: ContactFreezerColorMapping[1],
                y: 30,
              },
              {
                color: ContactFreezerColorMapping[1],
                y: 25,
              },
              {
                color: ContactFreezerColorMapping[1],
                y: 25,
              },
              {
                color: ContactFreezerColorMapping[1],
                y: 25,
              },
              {
                color: ContactFreezerColorMapping[1],
                y: 25,
              },
            ],
            name: 'Cost Material',
            type: 'column',
            stack: 'duration',
          },
          {
            data: [
              {
                color: ContactFreezerColorMapping[2],
                y: 30,
              },
              {
                color: ContactFreezerColorMapping[2],
                y: 25,
              },
              {
                color: ContactFreezerColorMapping[2],
                y: 25,
              },
              {
                color: ContactFreezerColorMapping[2],
                y: 25,
              },
              {
                color: ContactFreezerColorMapping[2],
                y: 25,
              },
            ],
            name: 'Cost Labor',
            type: 'column',
            stack: 'duration',
          },
        ],
        legend: {
          enabled: false,
        },
        plotOptions: {
          column: {
            stacking: 'normal',
          },
        },
      }),
      [cost]
    );

    const handleFullScreen = () => {
      setFullScreen(!fullScreen);
      // Hack the chart so it can fill entire screen during fullscreen state
      setReRender(false);
      setTimeout(() => {
        setReRender(true);
      }, 0);
    };

    return (
      <div
        style={{ zIndex: fullScreen ? 1200 : 0 }}
        className={classNames('flex justify-center items-center', className, {
          relative: !fullScreen,
          'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        })}
      >
        <button onClick={handleFullScreen} className="absolute right-5 top-5">
          {!fullScreen ? <IconLaunch /> : <IconClose />}
        </button>
        <div className="w-full h-full border rounded-lg border-grey-light p-6">
          <h4 className="text-footnote font-bold mb-8">
            Cost Material and Labor Chart
          </h4>
          <div className="grid grid-cols-4 gap-x-2 mb-5 relative z-20">
            <div></div>
            <div></div>
            <MultiSelectDropdown placeholder="Species" />
            <MultiSelectDropdown placeholder="Size" />
          </div>
          <div className="flex justify-end">
            <button
              onClick={() => setHeight((highest + 1) % 3)}
              className="flex items-center focus:outline-none"
            >
              <span
                style={{ marginTop: 2 }}
                className="text-caption-1 font-bold text-blue"
              >
                Highest
              </span>
              {(highest === 0 || highest === 1) && (
                <IconArrowBreadcrumb
                  height={18}
                  width={18}
                  className="transform rotate-180 ic-blue"
                />
              )}
              {(highest === 0 || highest === 2) && (
                <IconArrowBreadcrumb
                  height={18}
                  width={18}
                  className={classNames('ic-blue', { '-ml-2': highest === 0 })}
                />
              )}
            </button>
          </div>
          <div className="flex items-center justify-end my-4">
            <Button
              onClick={() => setCost('IDR')}
              color={cost === 'IDR' ? 'default' : 'grey'}
              className="mr-2 w-32"
            >
              Cost
            </Button>
            <Button
              onClick={() => setCost('IDR / kg')}
              color={cost === 'IDR / kg' ? 'default' : 'grey'}
              className="w-32"
            >
              Cost / kg
            </Button>
          </div>

          {reRender && (
            <div>
              <HighchartsReact
                options={barChartOptions}
                highcharts={Highcharts}
              />
              <div className="grid auto-cols-max w-max mx-auto grid-flow-col gap-3 items-center mt-6">
                <ChartLegend
                  color={ContactFreezerColorMapping[1]}
                  title="Cost Material"
                />
                <ChartLegend
                  color={ContactFreezerColorMapping[2]}
                  title="Cost Labor"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

export default ManagementCostMaterialAndLaborChart;
