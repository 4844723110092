import {
  FilterDateReducerActionTypes,
  FilterDateState,
} from './filterDate.reducer';

export const SetStartDate = (key: keyof FilterDateState, value: string) => {
  return {
    type: FilterDateReducerActionTypes.SET_START_DATE,
    payload: {
      key,
      value,
    },
  };
};

export const SetEndDate = (key: keyof FilterDateState, value: string) => {
  return {
    type: FilterDateReducerActionTypes.SET_END_DATE,
    payload: {
      key,
      value,
    },
  };
};
