import classNames from 'classnames';
import { useState } from 'react';
import { IconClose, IconLaunch } from '../../../../assets';

interface FreezingUnitQuantityPerFreezerProps {
  className?: string;
}

const FreezingUnitQuantityPerFreezer: React.FC<FreezingUnitQuantityPerFreezerProps> =
  ({ className }) => {
    const [fullScreen, setFullScreen] = useState<boolean>(false);

    const handleFullScreen = () => {
      setFullScreen(!fullScreen);
    };

    return (
      <div
        style={{ zIndex: fullScreen ? 1200 : 0 }}
        className={classNames('flex justify-center items-center', className, {
          relative: !fullScreen,
          'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        })}
      >
        <button
          onClick={handleFullScreen}
          className="absolute right-5 top-5 z-10"
        >
          {!fullScreen ? <IconLaunch /> : <IconClose />}
        </button>
        <div className="w-full h-full border rounded-lg border-grey-light p-6">
          <h4 className="text-footnote font-bold mb-8">Quantity per Freezer</h4>
          <div>
            <div className="grid grid-cols-3 text-footnote text-grey font-bold mb-5">
              <div>Contact Freezer</div>
              <div>Inner Success</div>
              <div className="text-right">Inner Fail</div>
            </div>
            <div className="grid grid-cols-3 text-caption-1 font-bold py-2 border-b border-grey-1">
              <div>F-001</div>
              <div>100</div>
              <div className="text-right">200</div>
            </div>
            <div className="grid grid-cols-3 text-caption-1 font-bold py-2 border-b border-grey-1">
              <div>F-002</div>
              <div>100</div>
              <div className="text-right">200</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default FreezingUnitQuantityPerFreezer;
