import { Breadcrumb, BreadcrumbItem, Switch } from 'components';
import { useState } from 'react';
import ReactDropdown from 'react-dropdown';

const ManagementInspectionPage = () => {
  const [realtime, setRealtime] = useState<boolean>(false);

  const handleChangeRealtime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    setRealtime(checked);
  };

  const rowClassName =
    'grid grid-cols-3 text-caption-1 items-center mb-3 last:mb-0';

  return (
    <div style={{ minWidth: 1200 }}>
      <h1 className="text-heading font-bold mb-3">Inspection</h1>
      <Breadcrumb className="mb-5">
        <BreadcrumbItem href="/management">Home</BreadcrumbItem>
        <BreadcrumbItem isActive>Inspection</BreadcrumbItem>
      </Breadcrumb>
      <div className="border border-grey-1 rounded-lg py-5 px-8">
        <div className="flex items-center mb-8">
          <Switch
            onChange={handleChangeRealtime}
            checked={realtime}
            withoutMessage
            className="mr-3"
          />
          <span className="text-caption-1 font-semibold text-grey">
            Inspection Mode
          </span>
        </div>
        <div className="px-12">
          <div className="grid grid-cols-3 text-caption-1 mb-4 items-center text-black">
            <div className="font-bold">Species</div>
            <div className="col-span-2 font-bold">Supplier</div>
          </div>
          <div className={rowClassName}>
            <div>White Tiger</div>
            <ReactDropdown
              controlClassName="border-grey-placeholder border "
              placeholder="Choose the Supplier...."
              className="text-black text-footnote"
              options={[
                {
                  label: 'Supplier 1',
                  value: 'shipment',
                },
                {
                  label: 'Supplier 2',
                  value: 'shipment2',
                },
              ]}
            />
          </div>
          <div className={rowClassName}>
            <div>Black Tiger</div>
            <ReactDropdown
              controlClassName="border-grey-placeholder border "
              placeholder="Choose the Supplier...."
              className="text-black text-footnote"
              options={[
                {
                  label: 'Supplier 1',
                  value: 'shipment',
                },
                {
                  label: 'Supplier 2',
                  value: 'shipment2',
                },
              ]}
            />
          </div>
          <div className={rowClassName}>
            <div>Black Pink</div>
            <ReactDropdown
              controlClassName="border-grey-placeholder border "
              placeholder="Choose the Supplier...."
              className="text-black text-footnote"
              options={[
                {
                  label: 'Supplier 1',
                  value: 'shipment',
                },
                {
                  label: 'Supplier 2',
                  value: 'shipment2',
                },
              ]}
            />
          </div>
          <div className={rowClassName}>
            <div>Pink Tiger</div>
            <ReactDropdown
              controlClassName="border-grey-placeholder border "
              placeholder="Choose the Supplier...."
              className="text-black text-footnote"
              options={[
                {
                  label: 'Supplier 1',
                  value: 'shipment',
                },
                {
                  label: 'Supplier 2',
                  value: 'shipment2',
                },
              ]}
            />
          </div>
          <div className={rowClassName}>
            <div>White Black</div>
            <ReactDropdown
              controlClassName="border-grey-placeholder border "
              placeholder="Choose the Supplier...."
              className="text-black text-footnote"
              options={[
                {
                  label: 'Supplier 1',
                  value: 'shipment',
                },
                {
                  label: 'Supplier 2',
                  value: 'shipment2',
                },
              ]}
            />
          </div>
          <div className={rowClassName}>
            <div>Black White</div>
            <ReactDropdown
              controlClassName="border-grey-placeholder border "
              placeholder="Choose the Supplier...."
              className="text-black text-footnote"
              options={[
                {
                  label: 'Supplier 1',
                  value: 'shipment',
                },
                {
                  label: 'Supplier 2',
                  value: 'shipment2',
                },
              ]}
            />
          </div>
          <div className={rowClassName}>
            <div>Pink Black</div>
            <ReactDropdown
              controlClassName="border-grey-placeholder border "
              placeholder="Choose the Supplier...."
              className="text-black text-footnote"
              options={[
                {
                  label: 'Supplier 1',
                  value: 'shipment',
                },
                {
                  label: 'Supplier 2',
                  value: 'shipment2',
                },
              ]}
            />
          </div>
          <div className={rowClassName}>
            <div>Pink White</div>
            <ReactDropdown
              controlClassName="border-grey-placeholder border "
              placeholder="Choose the Supplier...."
              className="text-black text-footnote"
              options={[
                {
                  label: 'Supplier 1',
                  value: 'shipment',
                },
                {
                  label: 'Supplier 2',
                  value: 'shipment2',
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagementInspectionPage;
