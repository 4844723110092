import classNames from 'classnames';
import './index.css';

interface LoadingAnimationProps {
  size?: 'sm' | 'md';
  className?: string;
}

const LoadingAnimation: React.FC<LoadingAnimationProps> = ({
  size,
  className = '',
}) => {
  return (
    <div
      className={classNames('lds-dual-ring', {
        sm: size === 'sm',
        md: size === 'md',
        [className]: className.length > 0,
      })}
    ></div>
  );
};

export default LoadingAnimation;
