const OverviewOperatorTable = () => {
  return (
    <div className="rounded-lg px-8 py-7 border border-grey-1">
      <div className="grid grid-cols-3 font-semibold text-caption-1 py-2.5">
        <div>Name</div>
        <div>Avg Frozen Time</div>
        <div>Total Working Time</div>
      </div>
      {/* Put the children here later */}
    </div>
  );
};

export default OverviewOperatorTable;
