import classNames from 'classnames';
import { useState } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  CardUnit,
  CookingUnitHeadcountPerformance,
  CookingUnitKotoranAndBlackSpot,
  CookingUnitProduction,
  CookingUnitQuantityPerProduct,
  CookingUnitWorkers,
  DatePicker,
  Switch,
} from 'components';

const CookingUnitDashboardPage = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [realtime, setRealtime] = useState<boolean>(false);

  const handleChangeRealtime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    setRealtime(checked);
  };

  return (
    <div style={{ minWidth: 1200 }}>
      <h1 className="text-heading font-bold mb-3">
        Final Sorting HL Unit Dashboard
      </h1>
      <Breadcrumb className="mb-5">
        <BreadcrumbItem href="/unit/">Home</BreadcrumbItem>
        <BreadcrumbItem href="/unit/final-sorting-hl-unit">
          Final Sorting HL Unit
        </BreadcrumbItem>
        <BreadcrumbItem isActive>Dashboard</BreadcrumbItem>
      </Breadcrumb>
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <Switch
            onChange={handleChangeRealtime}
            checked={realtime}
            withoutMessage
            className="mr-3"
          />
          <span className="text-caption-1 font-semibold text-grey">
            Realtime
          </span>
        </div>
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <DatePicker
            disabled={realtime}
            selected={startDate}
            // @ts-ignore
            onChange={(date) => setStartDate(date)}
            dateFormat="E, d MMM yyyy"
            containerClassName="mr-3.5"
          />
          <DatePicker
            disabled={realtime}
            selected={endDate}
            // @ts-ignore
            onChange={(date) => setEndDate(date)}
            dateFormat="E, d MMM yyyy"
            popperPlacement="bottom-end"
          />
        </div>
      </div>
      <div className={classNames('grid gap-x-4 mb-8', 'grid-cols-6')}>
        {!realtime ? (
          <>
            <CardUnit color="blue" title="Total Incoming:" value="1.5 ton" />
            <CardUnit color="green" title="Total Output:" value="1.5 ton" />
            <CardUnit color="purple" title="Latest Pending:" value="1.5 ton" />
            <CardUnit color="yellow" title="Yield After Cooked:" value="1%" />
            <CardUnit color="blue" title="Yield After Peeled:" value="1%" />
            <CardUnit color="green" title="Overall Yield:" value="1.5%" />
          </>
        ) : (
          <>
            <CardUnit color="blue" title="Today's Incoming:" value="1.5 ton" />
            <CardUnit color="green" title="Today's Output:" value="1.5 ton" />
            <CardUnit color="purple" title="Current Pending:" value="1.5 ton" />
            <CardUnit color="yellow" title="Currently Processed:" value="1%" />
            <CardUnit color="blue" title="Yield After Cooked:" value="1%" />
            <CardUnit color="green" title="Yield After Period:" value="1.5%" />
          </>
        )}
      </div>
      <div className="grid grid-cols-2 gap-6">
        <CookingUnitQuantityPerProduct />
        <CookingUnitKotoranAndBlackSpot />
        <CookingUnitWorkers />
        <CookingUnitProduction />
        {!realtime && <CookingUnitHeadcountPerformance />}
      </div>
    </div>
  );
};

export default CookingUnitDashboardPage;
