import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  DatePicker,
  ManagementProductivityChart,
  MultiSelectDropdown,
  TableGrid,
} from 'components';
import { useState } from 'react';

const ManagementProductivityPage = () => {
  const [active, setActive] = useState<'worker' | 'unit'>('worker');

  return (
    <div>
      <h1 className="text-heading font-bold mb-3">Productivity</h1>
      <Breadcrumb className="mb-5">
        <BreadcrumbItem href="/management/wage-productivity">
          Wage & Productivity
        </BreadcrumbItem>
        <BreadcrumbItem isActive>Productivity</BreadcrumbItem>
      </Breadcrumb>
      <div className="flex items-center text-caption-1 justify-end">
        <div style={{ width: 566 }} className="grid grid-cols-2 gap-x-4">
          <Button
            color={active === 'worker' ? 'default' : 'grey'}
            onClick={() => setActive('worker')}
          >
            Workers Productivity
          </Button>
          <Button
            color={active === 'unit' ? 'default' : 'grey'}
            onClick={() => setActive('unit')}
          >
            Unit Productivity
          </Button>
        </div>
      </div>
      {active === 'worker' && <WorkerProductivity />}
      {active === 'unit' && <UnitProductivity />}
    </div>
  );
};

export default ManagementProductivityPage;

const WorkerProductivity = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  return (
    <div className="border border-grey-1 rounded-lg py-5 px-8">
      <h2 className="text-footnote font-bold mb-3">Workers Productivity</h2>
      <div className="flex justify-between items-center mb-10">
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <DatePicker
            selected={startDate}
            onChange={(date: Date) => setStartDate(date)}
            dateFormat="E, d MMM yyyy"
            containerClassName="mr-3.5"
          />
          <DatePicker
            selected={endDate}
            onChange={(date: Date) => setEndDate(date)}
            dateFormat="E, d MMM yyyy"
            popperPlacement="bottom-end"
          />
        </div>
        <div style={{ width: 524 }} className="grid grid-cols-4 gap-x-2">
          <MultiSelectDropdown placeholder="Job Type..." />
          <MultiSelectDropdown placeholder="Unit..." />
          <MultiSelectDropdown placeholder="Species..." />
          <MultiSelectDropdown placeholder="Size..." />
        </div>
      </div>
      <div className="px-56">
        <TableGrid
          className="border border-grey-1 rounded-lg mb-10"
          columns={[
            {
              name: 'Name',
              field: 'name',
              textAlign: 'center',
            },
            {
              name: 'Working Time',
              field: 'working_time',
              textAlign: 'center',
            },
            {
              name: 'Headcount Performance',
              field: 'headcount_performance',
              textAlign: 'center',
            },
          ]}
          data={[
            {
              name: 'Budi Kragaan',
              working_time: '12 Hours',
              headcount_performance: '100 kg/hour',
            },
            {
              name: 'Budi Soul',
              working_time: '12 Hours',
              headcount_performance: '100 kg/hour',
            },
            {
              name: 'Budi Somebody',
              working_time: '12 Hours',
              headcount_performance: '100 kg/hour',
            },
          ]}
          onRenderField={(_, value, { key }) => {
            return (
              <div key={key} className="text-center">
                {value}
              </div>
            );
          }}
        />
        <div className="grid grid-cols-2 gap-x-6">
          <ManagementProductivityChart
            title="Headcount Performance"
            unit="Kg / Hour"
          />
          <ManagementProductivityChart title="Working Time" unit="Hours" />
        </div>
      </div>
    </div>
  );
};

const UnitProductivity = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  return (
    <div className="border border-grey-1 rounded-lg py-5 px-8">
      <h2 className="text-footnote font-bold mb-3">Unit Productivity</h2>
      <div className="flex justify-between items-center mb-10">
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <DatePicker
            selected={startDate}
            onChange={(date: Date) => setStartDate(date)}
            dateFormat="E, d MMM yyyy"
            containerClassName="mr-3.5"
          />
          <DatePicker
            selected={endDate}
            onChange={(date: Date) => setEndDate(date)}
            dateFormat="E, d MMM yyyy"
            popperPlacement="bottom-end"
          />
        </div>
        <div style={{ width: 524 }} className="grid grid-cols-2 gap-x-2">
          <MultiSelectDropdown placeholder="Species..." />
          <MultiSelectDropdown placeholder="Size..." />
        </div>
      </div>
      <div className="px-56">
        <TableGrid
          className="border border-grey-1 rounded-lg mb-10"
          columns={[
            {
              name: 'Unit',
              field: 'unit',
              textAlign: 'center',
            },
            {
              name: 'Process Time',
              field: 'process_time',
              textAlign: 'center',
            },
            {
              name: 'Working Time',
              field: 'working_time',
              textAlign: 'center',
            },
            {
              name: 'Unit Productivity',
              field: 'unit_productivity',
              textAlign: 'center',
            },
            {
              name: 'Unit Productivity Rate',
              field: 'unit_productivity_rate',
              textAlign: 'center',
            },
          ]}
          data={[
            {
              unit: 'Receiving',
              process_time: '15 Hours',
              working_time: '12 Hours',
              unit_productivity: '100 kg/hour',
              unit_productivity_rate: '100 kg/man-hour',
            },
            {
              unit: 'Receiving',
              process_time: '15 Hours',
              working_time: '12 Hours',
              unit_productivity: '100 kg/hour',
              unit_productivity_rate: '100 kg/man-hour',
            },
            {
              unit: 'Receiving',
              process_time: '15 Hours',
              working_time: '12 Hours',
              unit_productivity: '100 kg/hour',
              unit_productivity_rate: '100 kg/man-hour',
            },
          ]}
          onRenderField={(_, value, { key }) => {
            return (
              <div key={key} className="text-center">
                {value}
              </div>
            );
          }}
        />
        <div className="grid grid-cols-2 gap-x-6 gap-y-6">
          <ManagementProductivityChart title="Process Time" unit="Hours" />
          <ManagementProductivityChart title="Working Time" unit="Hours" />
          <ManagementProductivityChart
            title="Unit Productivity"
            unit="Kg / Hour"
          />
          <ManagementProductivityChart
            title="Unit Productivity Rate"
            unit="Kg / man-Hour"
          />
        </div>
      </div>
    </div>
  );
};
