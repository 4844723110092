import dayjs from 'dayjs';
import humanizeDuration from 'humanize-duration';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import { urlAPIMechanic } from 'utils';

interface MachineConditionTableProps {
  startDate?: string;
  endDate?: string;
}

type MachineConditionTableData = {
  id: number;
  nama_node: string;
  avgVibratiion: number;
  machineRunningTime: number;
};

const MachineConditionTable: React.FC<MachineConditionTableProps> = ({
  startDate = dayjs().subtract(3, 'days').format('YYYY-MM-DD'),
  endDate = dayjs().format('YYYY-MM-DD'),
}) => {
  const { data } = useQuery<MachineConditionTableData[]>(
    'machineConditionTable',
    () =>
      fetch(
        urlAPIMechanic +
          `/mechanic/machine/report/condition?start=${startDate}&end=${endDate}`
      )
        .then((res) => res.json())
        .then((data) => data.data)
  );

  return (
    <div className="rounded-lg px-8 py-7 border border-grey-1">
      <div className="grid grid-cols-7 grid-rows-2 font-semibold text-caption-1 items-center py-2.5 px-2 gap-2.5">
        <div className="row-span-2">Machine Name</div>
        <div className="col-span-4 text-center border-b border-blue-lighter pb-2">
          Duration in this condition
        </div>
        <div className="row-span-2">Machine Running Time</div>
        <div className="row-span-2">Avg Vibration Machine</div>
        <div>Very Good</div>
        <div>Good</div>
        <div>Bad</div>
        <div>Need Repair</div>
      </div>
      {/* Put the children here later */}
      {data?.map((val, i) => (
        <div
          key={`machine-condition-table-${val.id}`}
          className={classNames(
            'grid grid-cols-7 text-caption-1 py-2.5 px-2 gap-2.5',
            { 'bg-white-snow': i % 2 }
          )}
        >
          <div>{val.nama_node}</div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div>
            {humanizeDuration(val.machineRunningTime * 3600 * 1000, {
              round: true,
              units: ['h', 'm', 's'],
            })}
          </div>
          <div>{val.avgVibratiion.toFixed(1)} m/s</div>
        </div>
      ))}
      <div className="grid grid-cols-7 text-caption-1 py-2.5 px-2 mt-11 gap-2.5">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div className="font-semibold">Total Machine Running Time</div>
      </div>
      <div className="grid grid-cols-7 text-caption-1 py-2.5 px-2 gap-2.5">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div>
          {humanizeDuration(
            (data?.reduce(
              (acc, currentVal) => acc + currentVal.machineRunningTime,
              0
            ) || 0) *
              3600 *
              1000,
            { round: true, units: ['h', 'm', 's'] }
          )}{' '}
        </div>
      </div>
    </div>
  );
};

export default MachineConditionTable;
