import dayjs from 'dayjs';
import classNames from 'classnames';
import humanizeDuration from 'humanize-duration';
import { useQuery } from 'react-query';
import { urlAPIMechanic } from 'utils';

interface OverviewColdStorageProps {
  startDate?: string;
  endDate?: string;
}

type OverviewColdStorageData = {
  id: number;
  nama_node: string;
  avgTemperature: number;
  doorOpenFreq: number;
  doorOpenDuration: number;
  durationInCriticalTime: number;
};

const OverviewColdStorageTable: React.FC<OverviewColdStorageProps> = ({
  startDate = dayjs().subtract(6, 'days').format('YYYY-MM-DD'),
  endDate = dayjs().format('YYYY-MM-DD'),
}) => {
  const { data } = useQuery<OverviewColdStorageData[]>(
    ['overviewColdStorageReport', startDate, endDate],
    () =>
      fetch(
        urlAPIMechanic +
          `/mechanic/overview/cold-storage?start=${startDate}&end=${endDate}`
      )
        .then((res) => res.json())
        .then((data) => data.data)
  );

  return (
    <div className="rounded-lg px-8 py-7 border border-grey-1">
      <div className="grid grid-cols-5 font-semibold text-caption-1 px-2 py-2.5">
        <div>Name</div>
        <div>Avg Temperature</div>
        <div>Duration in Critical Temp</div>
        <div>Door Open Freq</div>
        <div>Door Open Duration</div>
      </div>
      {/* Put the children here later */}
      {data?.map((val, i) => (
        <div
          key={`overview-cold-storage-report-${val.id}`}
          className={classNames('grid grid-cols-5 text-caption-1 px-2 py-2.5', {
            'bg-white-snow': i % 2,
          })}
        >
          <div>{val.nama_node}</div>
          <div>{val.avgTemperature.toFixed(2)} C</div>
          <div>
            {humanizeDuration(val.durationInCriticalTime * 3600 * 1000, {
              round: true,
            })}
          </div>
          <div>{val.doorOpenFreq} times</div>
          <div>
            {humanizeDuration(val.doorOpenDuration * 3600 * 1000, {
              round: true,
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default OverviewColdStorageTable;
