import classNames from 'classnames';
// import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import './App.css';
import { Dialog, Navbar, Sidebar } from './components';
import {
  ChangePasswordPage,
  ColdStoragePerformancePage,
  ColdStoragePerformanceReportPage,
  ColdStorageUnitDashboardPage,
  ColdStorageUnitReportPage,
  ConstantaManagementPage,
  ContactFreezerPerformancePage,
  ContactFreezerPerformanceReportPage,
  CookingUnitDashboardPage,
  CookingUnitReportPage,
  DailyReportApproval,
  DeviceManagementPage,
  FinalSortingHLDashboardUnitPage,
  FinalSortingHLUnitReportPage,
  ForgotPassPage,
  FreezingPackingUnitReportPage,
  FreezingUnitDashboardPage,
  FreezingUnitReportPage,
  FrezingPackingUnitDashboardPage,
  HistoryOfEventPage,
  HomeManagementPage,
  HomePage,
  HomeUnitPage,
  IQFUnitReportPage,
  LabInspectionUnitReportDetail,
  LabsInspectionUnitDashboardPage,
  // LabsInspectionUnitReportPage,
  LayeringUnitDashboardPage,
  LayeringUnitReportPage,
  LogHistoryPage,
  LoginPage,
  MachinePerformancePage,
  MachinePerformanceReportPage,
  ManagementCostAnalysisPage,
  ManagementInspectionPage,
  ManagementLeavePermissionPage,
  ManagementProductivityPage,
  ManagementStockQuantityPage,
  ManagementStockTransferPage,
  ManagementSupplierAnalysisPage,
  ManagementTakeoutOrderCommandPage,
  ManagementWagePage,
  NobashiUnitDashboardPage,
  NobashiUnitReportPage,
  OperatorPerformancePage,
  OperatorPerformanceReportPage,
  OverviewPage,
  OverviewReportPage,
  PeelingUnitDashboardPage,
  PeelingUnitReportPage,
  ProductPriorityPage,
  ReceivingUnitDashboardPage,
  ReceivingUnitReportPage,
  ResetPassManagementPage,
  RoleManagementPage,
  ScheduleManagementPage,
  ShortLeavePermissionPage,
  SoakingUnitDashboardPage,
  SoakingUnitReportPage,
  SortingMachineUnitDashboardPage,
  SortingMachineUnitReportPage,
  TraceabilityPage,
  UserManagementPage,
  FinalSortingPeeledUnitDashboardPage,
  FinalSortingPeeledUnitReportPage,
  IQFUnitDashboardPage,
  LabInspectionReportGeneral,
  FinalSortingFiveStarUnitDashboardPage,
  FinalSortingFiveStarUnitReportPage,
  DistributionUnitReportPage,
  DistributionUnitDashboardPage
} from './pages';
import { AuthErrorClose } from './redux/errors/error.action';
import { RootReducerState } from './redux/root-reducer';
// import { ColdStorageList } from './utils/types';
// import { urlAPI } from './utils/urlApi';
import 'react-dropdown/style.css';
import { useState } from 'react';

Modal.setAppElement('#root');
Modal.defaultStyles = {
  overlay: {
    ...Modal.defaultStyles.overlay,
    background: 'rgba(0,0,0, .35)',
    overflow: 'auto',
  },
  content: {
    ...Modal.defaultStyles.content,
    margin: 'auto',
    height: 'max-content',
    padding: '40px 52px',
    borderRadius: '10px',
  },
};

function App() {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const error = useSelector((state: RootReducerState) => state.error);

  // useQuery<ColdStorageList[]>('coldStorageList', () =>
  //   fetch(urlAPI + '/mechanic/cold-storage/dashboard/list')
  //     .then((res) => res.json())
  //     .then((data) => data.data)
  // );

  const handleSubmitDialog = () => {
    dispatch(AuthErrorClose());
    history.replace('/login');
  };

  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleToggleMenu = () => {
    setMenuOpen(!isMenuOpen)
  }

  const role = 'management'; // diganti nanti pake data dari backend

  return (
    <div className="App">
      {error.authError &&
        pathname !== '/login' &&
        pathname !== '/forgot-password' && (
          <Dialog
            heading="You are not logged in"
            message="You don't have any permission to access this site, Please log in or use superadmin account to access this site"
            singleButton
            submitText="OK"
            width={480}
            onSubmit={handleSubmitDialog}
          />
        )}
      {pathname !== '/login' && pathname !== '/forgot-password' && (
        <>
          <Navbar />
          <Sidebar
            type={
              pathname.startsWith('/admin')
                ? 'admin'
                : pathname.startsWith('/management') || role === 'management'
                ? 'management'
                : pathname.startsWith('/mechanic')
                ? 'mechanic'
                : 'unit'
            }
            isOpen={isMenuOpen}
            onMenuToggle={handleToggleMenu}
          />
        </>
      )}
      <div
        className={`${classNames({
          'wrapper p-6':
            pathname !== '/login' && pathname !== '/forgot-password',
        })} ${isMenuOpen === true ? ' open' : ''}`}
      >
        <Switch>
          <Route exact path="/admin/" component={HomePage} />
          <Route exact path="/login" component={LoginPage} />
          <Route
            exact
            path="/admin/user-management"
            component={UserManagementPage}
          />
          <Route
            exact
            path="/admin/user-management/role"
            component={RoleManagementPage}
          />
          <Route
            exact
            path="/admin/device-management"
            component={DeviceManagementPage}
          />
          <Route
            exact
            path="/admin/constant-management"
            component={ConstantaManagementPage}
          />
          <Route
            exact
            path="/admin/user-management/reset-password-management"
            component={ResetPassManagementPage}
          />
          <Route exact path="/forgot-password" component={ForgotPassPage} />
          <Route exact path="/change-password" component={ChangePasswordPage} />
          <Route
            exact
            path="/admin/change-password"
            component={ChangePasswordPage}
          />
          <Route exact path="/admin/log-history" component={LogHistoryPage} />
          <Route exact path="/mechanic/overview" component={OverviewPage} />
          <Route
            exact
            path="/mechanic/overview/report"
            component={OverviewReportPage}
          />
          <Route
            exact
            path="/mechanic/operator-performance"
            component={OperatorPerformancePage}
          />
          <Route
            exact
            path="/mechanic/operator-performance/report"
            component={OperatorPerformanceReportPage}
          />
          <Route
            exact
            path="/mechanic/machine-performance"
            component={MachinePerformancePage}
          />
          <Route
            exact
            path="/mechanic/machine-performance/report"
            component={MachinePerformanceReportPage}
          />
          <Route
            exact
            path="/mechanic/cold-storage"
            component={ColdStoragePerformancePage}
          />
          <Route
            exact
            path="/mechanic/cold-storage/report"
            component={ColdStoragePerformanceReportPage}
          />
          <Route
            exact
            path="/mechanic/contact-freezer"
            component={ContactFreezerPerformancePage}
          />
          <Route
            exact
            path="/mechanic/contact-freezer/report"
            component={ContactFreezerPerformanceReportPage}
          />
          <Route
            exact
            path="/mechanic/history-events"
            component={HistoryOfEventPage}
          />
          <Route exact path="/history-events" component={HistoryOfEventPage} />
          <Route
            exact
            path="/mechanic/schedule-management"
            component={ScheduleManagementPage}
          />
          <Route exact path="/unit/home" component={HomeUnitPage} />
          <Route
            exact
            path="/unit/short-leave-permission"
            component={ShortLeavePermissionPage}
          />
          <Route
            exact
            path="/unit/receiving-unit/report"
            component={ReceivingUnitReportPage}
          />
          <Route
            exact
            path="/unit/receiving-unit/"
            component={ReceivingUnitDashboardPage}
          />
          <Route
            exact
            path="/unit/layering-unit/"
            component={LayeringUnitDashboardPage}
          />
          <Route
            exact
            path="/unit/layering-unit/report"
            component={LayeringUnitReportPage}
          />
          <Route
            exact
            path="/unit/sorting-machine-unit/report"
            component={SortingMachineUnitReportPage}
          />
          <Route
            exact
            path="/unit/soaking-unit/report"
            component={SoakingUnitReportPage}
          />
          <Route
            exact
            path="/unit/soaking-unit/"
            component={SoakingUnitDashboardPage}
          />
          <Route
            exact
            path="/unit/freezing-unit/"
            component={FreezingUnitDashboardPage}
          />
          <Route
            exact
            path="/unit/freezing-unit/report"
            component={FreezingUnitReportPage}
          />
          <Route
            exact
            path="/unit/iqf-unit/report"
            component={IQFUnitReportPage}
          />
          <Route
            exact
            path="/unit/freezing-packing-unit/report"
            component={FreezingPackingUnitReportPage}
          />
          <Route
            exact
            path="/unit/freezing-packing-unit/"
            component={FrezingPackingUnitDashboardPage}
          />
          <Route
            exact
            path="/unit/iqf-unit/"
            component={IQFUnitDashboardPage}
          />
          <Route
            exact
            path="/unit/sorting-machine-unit/"
            component={SortingMachineUnitDashboardPage}
          />
          <Route
            exact
            path="/unit/cold-storage-unit/"
            component={ColdStorageUnitDashboardPage}
          />
          <Route
            exact
            path="/unit/cold-storage-unit/report"
            component={ColdStorageUnitReportPage}
          />
          <Route
            exact
            path="/unit/final-sorting-hl-unit/"
            component={FinalSortingHLDashboardUnitPage}
          />
          <Route
            exact
            path="/unit/final-sorting-hl-unit/report"
            component={FinalSortingHLUnitReportPage}
          />
          <Route
            exact
            path="/unit/final-sorting-five-star-unit/"
            component={FinalSortingFiveStarUnitDashboardPage}
          />
          <Route
            exact
            path="/unit/final-sorting-five-star-unit/report"
            component={FinalSortingFiveStarUnitReportPage}
          />
          <Route
            exact
            path="/unit/cooking-unit"
            component={CookingUnitDashboardPage}
          />
          <Route
            exact
            path="/unit/cooking-unit/report"
            component={CookingUnitReportPage}
          />
          <Route
            exact
            path="/unit/peeling-unit"
            component={PeelingUnitDashboardPage}
          />
          <Route
            exact
            path="/unit/peeling-unit/report"
            component={PeelingUnitReportPage}
          />
          <Route
            exact
            path="/unit/final-sorting-peeled-unit"
            component={FinalSortingPeeledUnitDashboardPage}
          />
          <Route
            exact
            path="/unit/final-sorting-peeled-unit/report"
            component={FinalSortingPeeledUnitReportPage}
          />
          <Route
            exact
            path="/unit/nobashi-unit"
            component={NobashiUnitDashboardPage}
          />
          <Route
            exact
            path="/unit/nobashi-unit/report"
            component={NobashiUnitReportPage}
          />
           <Route
            exact
            path="/unit/distribution-unit"
            component={DistributionUnitDashboardPage}
          />
          <Route
            exact
            path="/unit/distribution-unit/report"
            component={DistributionUnitReportPage}
          />
          <Route
            exact
            path="/unit/lab-inspection-unit"
            component={LabsInspectionUnitDashboardPage}
          />
          <Route
            exact
            path="/unit/lab-inspection-unit/report"
            component={LabInspectionReportGeneral}
          />
          <Route
            exact
            path="/unit/lab-inspection-unit/report/:id"
            component={LabInspectionUnitReportDetail}
          />
          <Route
            exact
            path="/unit/daily-report-approval"
            component={DailyReportApproval}
          />
          <Route
            exact
            path="/management/short-leave-permission"
            component={ManagementLeavePermissionPage}
          />
          <Route
            exact
            path="/management/takeout-order-command"
            component={ManagementTakeoutOrderCommandPage}
          />
          <Route
            exact
            path="/management/inspection"
            component={ManagementInspectionPage}
          />
          <Route
            exact
            path="/management/wage-productivity/"
            component={ManagementWagePage}
          />
          <Route
            exact
            path="/management/wage-productivity/productivity"
            component={ManagementProductivityPage}
          />
          <Route
            exact
            path="/management/stock"
            component={ManagementStockQuantityPage}
          />
          <Route
            exact
            path="/management/stock/transfer"
            component={ManagementStockTransferPage}
          />
          <Route
            exact
            path="/management/supplier-analysis"
            component={ManagementSupplierAnalysisPage}
          />
          <Route
            exact
            path="/management/cost-analysis"
            component={ManagementCostAnalysisPage}
          />
          <Route
            exact
            path="/management/product-priority"
            component={ProductPriorityPage}
          />
          <Route
            exact
            path="/management/traceability"
            component={TraceabilityPage}
          />
          <Route exact path="/management/" component={HomeManagementPage} />
        </Switch>
      </div>
    </div>
  );
}

export default App;
