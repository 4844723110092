import { TableGrid } from 'components';
import { useQuery } from 'react-query';
import { urlAPIUnit, idReceiving } from 'utils';
import { TableAdapter, TableData } from 'utils/table-adapter';

const ReceivingUnitWorkersWageTable: React.FC<StringDate> = ({start_date, end_date}) => {
    const cols: Array<string> = [
      'no',
      'name',
      'nik',
      'jobname',
      'groupSize',
      'matchProductName',
      'matchProductPrice',
      'value',
      'total',
    ];
  
    const { data } = useQuery<ITableGridPackage>(['receiving-wage', start_date, end_date], async () => {
      console.log('run');
      const result = await fetch(urlAPIUnit + `/general/wage/${idReceiving}?start_date=${start_date}&end_date=${end_date}`);
      const data = await result.json();
      const tableData: TableData = new TableData(data?.data?.productWage);
      console.log(JSON.stringify(tableData.plain));
      const tableAdapter = new TableAdapter(tableData, cols);
      const tableGridData = tableAdapter.getTableGridData();
      console.log('table grid data', JSON.stringify(tableGridData));
      return tableGridData;
    });
  
    return (
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              Component: (
                <div
                  key="workers-wage-main-column"
                  className="grid grid-cols-9 grid-rows-2 items-center"
                >
                  <div className="row-span-3">No</div>
                  <div className="row-span-3">Name</div>
                  <div className="row-span-3">Workers NIK</div>
                  <div className="row-span-3">Login Job Name</div>
                  <div className="row-span-3">Group Count</div>
                  <div className="row-span-3">Tipe Gaji</div>
                  <div className="row-span-3">Price</div>
                  <div className="row-span-3">Quantity</div>
                  <div className="row-span-3">Total</div>
                </div>
              ),
              cols: cols,
              width: '9fr',
              rows: data?.rowSize,
              merge: data?.tableGridData?.merge,
            },
          ]}
          data={[data?.tableGridData?.data || {}]}
        />
      </div>
    );      
  }


export default ReceivingUnitWorkersWageTable;
