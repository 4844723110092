import OverviewMachineTable from './machine';
import OverviewContactFreezerTable from './contact-freezer';
import OverviewColdStorageTable from './cold-storage';
import OverviewOperatorTable from './operator';

export {
  OverviewMachineTable,
  OverviewContactFreezerTable,
  OverviewColdStorageTable,
  OverviewOperatorTable,
};
