import { Button } from '../../atoms';

interface DialogProps {
  heading?: string;
  message?: string;
  onCancel?(): void;
  onSubmit?(): void;
  cancelText?: string;
  submitText?: string;
  width?: string | number;
  reverseButton?: boolean;
  singleButton?: boolean;
}

const Dialog: React.FC<DialogProps> = ({
  heading,
  message,
  onCancel,
  onSubmit,
  cancelText = 'Cancel',
  submitText = 'Proceed',
  width,
  reverseButton,
  singleButton,
}) => {
  return (
    <div
      style={{ width }}
      className="bg-white flex flex-col items-center rounded-lg px-16 py-10 fixed top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4 shadow-lg z-20"
    >
      <h1 className="text-heading font-extrabold mb-9 text-center">
        {heading}
      </h1>
      <p className="text-subheading text-black mb-9 text-center">{message}</p>
      <div className="flex items-center">
        {!singleButton && (
          <Button
            onClick={onCancel}
            className="mr-4"
            variant={!reverseButton ? 'outlined' : 'default'}
          >
            {cancelText}
          </Button>
        )}
        <Button
          variant={reverseButton ? 'outlined' : 'default'}
          onClick={onSubmit}
        >
          {submitText}
        </Button>
      </div>
    </div>
  );
};

export default Dialog;
