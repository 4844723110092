import classNames from 'classnames';
import { Progressbar } from '../../atoms';

interface ContactFreezerProgressProps {
  title?: string;
  progress?: string | number;
  temperature?: string | number;
  runtime?: string;
  className?: string;
  color?: string;
}

const ContactFreezerProgress: React.FC<ContactFreezerProgressProps> = ({
  progress,
  runtime,
  temperature,
  title,
  className = '',
  color,
}) => {
  return (
    <div className={classNames({ [className]: className })}>
      <div className="flex items-center justify-between mb-2">
        <span className="text-caption-1 font-bold text-grey">{title}</span>
        <span className="text-caption-1 font-bold text-grey-placeholder">
          {runtime}
        </span>
      </div>
      <Progressbar progress={progress} color={color} />
      <div className="flex items-center justify-between mt-2">
        <span className="text-caption-1 text-grey">Progress {progress}%</span>
        <span className="text-caption-1 font-bold text-grey">
          {temperature} <sup>o</sup>C
        </span>
      </div>
    </div>
  );
};

export default ContactFreezerProgress;
