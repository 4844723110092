import { TableGrid } from 'components';

const PeelingUnitReportSummaryTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">Incoming</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              field: 'no',
              name: 'No.',
              width: '40px',
            },
            {
              field: 'product',
              name: 'Product',
            },
            {
              field: 'grade',
              name: 'Grade',
            },
            {
              field: 'size',
              name: 'Size',
              width: '0.6fr',
            },
            {
              field: 'species',
              name: 'Species',
              width: '0.4fr',
            },
            {
              field: 'quantity',
              name: 'Quantity',
            },
          ]}
        />
      </div>
      <h6 className="font-bold mb-2">Output</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              field: 'no',
              name: 'No.',
              width: '40px',
            },
            {
              field: 'product',
              name: 'Product',
            },
            {
              field: 'grade',
              name: 'Grade',
            },
            {
              field: 'size',
              name: 'Size',
              width: '0.4fr',
            },
            {
              field: 'species',
              name: 'Species',
              width: '0.6fr',
            },
            {
              field: 'quantity',
              name: 'Quantity',
            },
          ]}
          data={[
            {
              no: '1',
              product: 'HL Treatment',
              grade: '1st',
              size: ['4-8', '8-12'],
              species: ['Black Pink', 'Black Tiger'],
              quantity: ['1 ton', '1 ton'],
            },
            {
              no: '2',
              product: 'HL Treatment',
              grade: '2nd',
              size: '4-8',
              species: 'Black Pink',
              quantity: '1 ton',
            },
          ]}
        />
      </div>
      <h6 className="font-bold mb-2">Blackspot dan Kotoran</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              field: 'no',
              name: 'No.',
              width: '40px',
            },
            {
              field: 'product',
              name: 'Product',
            },
            {
              field: 'grade',
              name: 'Grade',
            },
            {
              field: 'size',
              name: 'Size',
              width: '0.4fr',
            },
            {
              field: 'species',
              name: 'Species',
              width: '0.6fr',
            },
            {
              field: 'quantity',
              name: 'Quantity',
              Component: (
                <div className="grid grid-cols-2 gap-y-4">
                  <div className="col-span-2 text-center pb-2 border-b border-grey-placeholder">
                    Quantity
                  </div>
                  <div className="text-center">Blackspot</div>
                  <div className="text-center">Kotoran</div>
                </div>
              ),
              cols: ['quantity1', 'quantity2'],
              rows: 3,
            },
          ]}
          data={[
            {
              no: '1',
              product: 'HL Treatment',
              grade: '1st',
              size: ['4-8', '8-12', '12-14'],
              species: ['Black Pink', 'Black Tiger', 'Black Mamba'],
              'quantity1.1': '100 Ton',
              'quantity2.1': '200 Ton',
              'quantity1.2': '500 Ton',
              'quantity2.2': '300 Ton',
              'quantity1.3': '500 Ton',
              'quantity2.3': '300 Ton',
            },
            {
              no: '2',
              product: 'HL Treatment',
              grade: '2nd',
              size: ['4-8', '8-12'],
              species: ['Black Pink', 'Black Tiger'],
              'quantity1.1': '100 Ton',
              'quantity2.1': '200 Ton',
              'quantity1.2': '',
              'quantity2.2': '300 Ton',
            },
          ]}
          onRenderField={(field, value, { key }) => {
            if (field.startsWith('quantity'))
              return (
                <div key={key} className="text-center">
                  {value}
                </div>
              );
          }}
        />
      </div>

      <div className="flex items-start mb-8">
        <div className="w-96 mr-20">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Incoming</div>
            <div>5 ton</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Output</div>
            <div>4.9 ton</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Kotoran</div>
            <div>4.9 ton</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Yield After Soaking</div>
            <div>-1 %</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Yield After Cooked</div>
            <div>-1 %</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Yield After Peeled</div>
            <div>-1 %</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Overall Yield</div>
            <div>-1 %</div>
          </div>
        </div>
        <div className="w-96">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Product</div>
            <div>2</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Product and Grade</div>
            <div>4</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total 1st</div>
            <div>3 ton</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total 2nd</div>
            <div>3 ton</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Total Broken</div>
            <div>200 Kg</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Latest Pending Material</div>
            <div>200 Kg</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Total Blackspot</div>
            <div>200 Kg</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PeelingUnitReportSummaryTable;
