const FreezingPackingUnitProcessLogTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">
        Detailed Packing Freezing Input and Output
      </h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9 overflow-auto">
        <div
          style={{
            gridTemplateColumns: 'repeat(13, 1fr)',
          }}
          className="grid font-semibold grid-rows-2 items-center text-caption-1 gap-y-2 gap-x-4 px-2 py-2.5"
        >
          <div className="row-span-2">MC Code</div>
          <div className="row-span-2">Supplier</div>
          <div className="row-span-2">Product</div>
          <div className="row-span-2">Species</div>
          <div className="row-span-2">Size</div>
          <div className="row-span-2">Date</div>
          <div className="row-span-2">Timestamp</div>
          <div className="col-span-6 pb-2 border-b border-grey-placeholder text-center">
            MCO
          </div>
          <div>MCO Code</div>
          <div>Supplier</div>
          <div>Product</div>
          <div>Species</div>
          <div>Size</div>
          <div>Date</div>
        </div>
        {/* Put the children here later */}
      </div>

      <h6 className="font-bold mb-2">
        Detailed Packing Freezing Metal Detector
      </h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <div className="grid grid-cols-8 items-center font-semibold text-caption-1 gap-y-2 gap-x-4 px-2 py-2.5">
          <div className="col-span-2">MCO Code</div>
          <div>Supplier</div>
          <div>Product</div>
          <div>Species</div>
          <div>Size</div>
          <div>Date</div>
          <div>Timestamp</div>
        </div>
        {/* Put the children here later */}
      </div>
    </>
  );
};

export default FreezingPackingUnitProcessLogTable;
