import { useState } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  DatePicker,
  HistoryOfEventsTable,
} from 'components';

const LogHistoryPage = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  return (
    <div className="user-management">
      <h1 className="text-heading font-bold mb-3">History of Events</h1>
      <Breadcrumb className="mb-5">
        <BreadcrumbItem href="/mechanic/overview">Home</BreadcrumbItem>
        <BreadcrumbItem isActive>History of Events</BreadcrumbItem>
      </Breadcrumb>
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <DatePicker
            selected={startDate}
            // @ts-ignore
            onChange={(date) => setStartDate(date)}
            dateFormat="E, d MMM yyyy"
            containerClassName="mr-3.5"
          />
          <DatePicker
            selected={endDate}
            // @ts-ignore
            onChange={(date) => setEndDate(date)}
            dateFormat="E, d MMM yyyy"
            popperPlacement="bottom-end"
          />
        </div>
      </div>
      <HistoryOfEventsTable />
    </div>
  );
};

export default LogHistoryPage;
