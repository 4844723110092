import classNames from 'classnames';
import { useState } from 'react';
import { IconClose, IconLaunch } from '../../../../assets';
import { MultiSelectDropdownItem } from 'components';
import { MultiSelectDropdown } from 'components';

interface SoakingQuantityPerProductProps {
  className?: string;
}

const SoakingQuantityPerProduct: React.FC<SoakingQuantityPerProductProps> = ({
  className,
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', className, {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
      })}
    >
      <button
        onClick={handleFullScreen}
        className="absolute right-5 top-5 z-10"
      >
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">Quantity per Product</h4>
        <div className="grid w-11/12 ml-auto grid-cols-4 mb-5 gap-x-1">
          <MultiSelectDropdown placeholder="Treatment" />
          <MultiSelectDropdown placeholder="Species">
            <MultiSelectDropdownItem value="supplier-1" id="supplier-1">
              Supplier 1
            </MultiSelectDropdownItem>
            <MultiSelectDropdownItem value="supplier-2" id="supplier-2">
              Supplier 2
            </MultiSelectDropdownItem>
          </MultiSelectDropdown>
          <MultiSelectDropdown placeholder="Size" />
          <MultiSelectDropdown placeholder="Grade" />
        </div>
        <div>
          <div className="grid grid-cols-4 text-footnote text-grey font-bold mb-5">
            <div>Product</div>
            <div>Input</div>
            <div>Output</div>
            <div className="text-right">Last Tampung</div>
          </div>
          <div className="grid grid-cols-4 text-caption-1 font-bold py-2 border-b border-grey-1">
            <div>HL Treatment</div>
            <div>100 kg</div>
            <div>100 kg</div>
            <div className="text-right">200 kg</div>
          </div>
          <div className="grid grid-cols-4 text-caption-1 font-bold py-2 border-b border-grey-1">
            <div>PDD Treatment</div>
            <div>100 kg</div>
            <div>100 kg</div>
            <div className="text-right">200 kg</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoakingQuantityPerProduct;
