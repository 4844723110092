import classNames from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState } from 'react';
import { IconClose, IconLaunch } from '../../../../assets';
import { ContactFreezerColorMapping } from '../../../../colors';
import { ChartLegend, MultiSelectDropdown } from 'components';

interface SoakingUnitProducitivityAnalysisProps {
  className?: string;
}

const CostperKgOptions: Highcharts.Options = {
  title: {
    text: '',
  },
  chart: {
    type: 'column',
  },
  xAxis: {
    gridLineWidth: 1,
    categories: [
      'Product 1',
      'Product 2',
      'Product 3',
      'Product 4',
      'Product 5',
    ],
  },
  yAxis: [
    {
      gridLineWidth: 0,
      title: {
        text: 'Average Duration (s)',
        rotation: -90,
      },
    },
    {
      gridLineWidth: 0,
      title: {
        text: 'Yield Percentage (%)',
      },
      opposite: true,
    },
  ],
  series: [
    {
      data: [
        {
          color: ContactFreezerColorMapping[1],
          y: 8,
        },
        {
          color: ContactFreezerColorMapping[1],
          y: 5,
        },
        {
          color: ContactFreezerColorMapping[1],
          y: 7,
        },
        {
          color: ContactFreezerColorMapping[1],
          y: 7,
        },
        {
          color: ContactFreezerColorMapping[1],
          y: 7,
        },
      ],
      name: 'Stirring Duration',
      type: 'column',
      stack: 'duration',
      tooltip: {
        valueSuffix: ' Seconds',
      },
    },
    {
      data: [
        {
          color: ContactFreezerColorMapping[2],
          y: 8,
        },
        {
          color: ContactFreezerColorMapping[2],
          y: 5,
        },
        {
          color: ContactFreezerColorMapping[2],
          y: 7,
        },
        {
          color: ContactFreezerColorMapping[2],
          y: 7,
        },
        {
          color: ContactFreezerColorMapping[2],
          y: 7,
        },
      ],
      name: 'Soaking Duration',
      type: 'column',
      stack: 'duration',
      tooltip: {
        valueSuffix: ' Seconds',
      },
    },
    {
      data: [
        {
          color: ContactFreezerColorMapping[3],
          y: 8,
        },
        {
          color: ContactFreezerColorMapping[3],
          y: 5,
        },
        {
          color: ContactFreezerColorMapping[3],
          y: 9,
        },
        {
          color: ContactFreezerColorMapping[3],
          y: 5,
        },
        {
          color: ContactFreezerColorMapping[3],
          y: 3,
        },
      ],
      name: 'Yield Percentage',
      type: 'column',
      stack: 'yield',
      yAxis: 1,
      tooltip: {
        valueSuffix: ' %',
      },
    },
  ],
  legend: {
    enabled: false,
  },
  plotOptions: {
    column: {
      stacking: 'normal',
    },
  },
};

const SoakingUnitProducitivityAnalysis: React.FC<SoakingUnitProducitivityAnalysisProps> =
  ({ className }) => {
    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const [reRender, setReRender] = useState<boolean>(true);

    const handleFullScreen = () => {
      setFullScreen(!fullScreen);
      // Hack the chart so it can fill entire screen during fullscreen state
      setReRender(false);
      setTimeout(() => {
        setReRender(true);
      }, 0);
    };

    return (
      <div
        style={{ zIndex: fullScreen ? 1200 : 0 }}
        className={classNames('flex justify-center items-center', className, {
          relative: !fullScreen,
          'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        })}
      >
        <button onClick={handleFullScreen} className="absolute right-5 top-5">
          {!fullScreen ? <IconLaunch /> : <IconClose />}
        </button>
        <div className="w-full h-full border rounded-lg border-grey-light p-6">
          <h4 className="text-footnote font-bold mb-8">
            Soaking Productivity Analysis
          </h4>
          <div className="grid grid-cols-4 gap-x-2 mb-5 relative z-20 ml-4">
            <MultiSelectDropdown placeholder="Treatment" />
            <MultiSelectDropdown placeholder="Grade" />
            <MultiSelectDropdown placeholder="Size" />
            <MultiSelectDropdown placeholder="Species" />
          </div>
          {reRender && (
            <>
              <div>
                <HighchartsReact
                  options={CostperKgOptions}
                  highcharts={Highcharts}
                />
                <div className="flex items-center justify-center mt-6">
                  <ChartLegend
                    color={ContactFreezerColorMapping[1]}
                    title="Stirring Duration"
                    className="mr-3"
                  />
                  <ChartLegend
                    color={ContactFreezerColorMapping[2]}
                    title="Soaking Duration"
                    className="mr-3"
                  />
                  <ChartLegend
                    color={ContactFreezerColorMapping[3]}
                    title="Yield Percentage"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

export default SoakingUnitProducitivityAnalysis;
