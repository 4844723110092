import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IconExcel } from 'assets';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ColdStorageConditionTable,
  CriticalTempTable,
  DatePicker,
  DoorOpenedTable,
} from 'components';
import { SetEndDate, SetStartDate } from 'redux/filterDates/filterDate.action';
import { RootReducerState } from 'redux/root-reducer';
import { useQuery } from 'utils/useQuery';

const ColdStoragePerformanceReport = () => {
  const startDate = useSelector(
    (state: RootReducerState) => state.filterDate.coldStorageReport.startDate
  );
  const endDate = useSelector(
    (state: RootReducerState) => state.filterDate.coldStorageReport.endDate
  );

  const dispatch = useDispatch();
  const report = useQuery().get('report') || 'condition';
  const history = useHistory();

  return (
    <div className="user-management">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-heading font-bold mb-3">
            Report Operator Performance
          </h1>
          <Breadcrumb className="mb-5">
            <BreadcrumbItem href="/mechanic/overview">Home</BreadcrumbItem>
            <BreadcrumbItem href="/mechanic/cold-storage">
              Cold Storage Performance
            </BreadcrumbItem>
            <BreadcrumbItem isActive>Report</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="flex items-center">
          <Button
            className="mr-5 w-44"
            color={report === 'condition' ? 'default' : 'grey'}
            onClick={() => history.push({ search: 'report=condition' })}
          >
            Cold Storage Condition
          </Button>
          <Button
            className="w-44 mr-5"
            color={report === 'critical-temp' ? 'default' : 'grey'}
            onClick={() => history.push({ search: 'report=critical-temp' })}
          >
            Critical Temperature Occurrences
          </Button>
          <Button
            className="w-44"
            color={report === 'door-opened' ? 'default' : 'grey'}
            onClick={() => history.push({ search: 'report=door-opened' })}
          >
            Door Opened Occurrences
          </Button>
        </div>
      </div>
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <DatePicker
            selected={new Date(startDate)}
            onChange={(date: Date) =>
              dispatch(
                SetStartDate(
                  'coldStorageReport',
                  dayjs(date).format('YYYY-MM-DD')
                )
              )
            }
            dateFormat="E, d MMM yyyy"
            containerClassName="mr-3.5"
          />
          <DatePicker
            selected={new Date(endDate)}
            onChange={(date: Date) =>
              dispatch(
                SetEndDate(
                  'coldStorageReport',
                  dayjs(date).format('YYYY-MM-DD')
                )
              )
            }
            dateFormat="E, d MMM yyyy"
            popperPlacement="bottom-end"
          />
        </div>
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Export
          </span>
          <button className="p-2 rounded-md border border-grey-1">
            <IconExcel />
          </button>
        </div>
      </div>
      {report === 'condition' && (
        <ColdStorageConditionTable startDate={startDate} endDate={endDate} />
      )}
      {report === 'critical-temp' && (
        <CriticalTempTable startDate={startDate} endDate={endDate} />
      )}
      {report === 'door-opened' && (
        <DoorOpenedTable startDate={startDate} endDate={endDate} />
      )}
    </div>
  );
};

export default ColdStoragePerformanceReport;
