import { IconArrowBreadcrumb, IconClose, IconLaunch } from 'assets';
import classNames from 'classnames';
import { ContactFreezerColorMapping } from 'colors';
import { ChartLegend, MultiSelectDropdown } from 'components';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useMemo, useState } from 'react';

interface StackProps extends IBaseChart {
  className?: string;
  queryKey?: string;
  chartTitle?: string;
  yAxisTitle?: string;
  withHighest?: boolean;
}

const Stack: React.FC<StackProps> = ({
  className,
  chartTitle,
  yAxisTitle,
  withHighest = true,
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [reRender, setReRender] = useState<boolean>(true);
  const [highest, setHeight] = useState<number>(0);

  const barChartOptions = useMemo<Highcharts.Options>(
    () => ({
      title: {
        text: '',
      },
      chart: {
        type: 'column',
      },
      xAxis: {
        gridLineWidth: 1,
        categories: [
          'Product 1',
          'Product 2',
          'Product 3',
          'Product 4',
          'Product 5',
        ],
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: yAxisTitle,
          rotation: -90,
        },
      },

      series: [
        {
          data: [
            {
              color: '#BBC8D4',
              y: 25,
            },
            {
              color: '#BBC8D4',
              y: 20,
            },
            {
              color: '#BBC8D4',
              y: 20,
            },
            {
              color: '#BBC8D4',
              y: 20,
            },
            {
              color: '#BBC8D4',
              y: 20,
            },
          ],
          name: 'Broken',
          type: 'column',
          stack: 'duration',
        },
        {
          data: [
            {
              color: ContactFreezerColorMapping[4],
              y: 15,
            },
            {
              color: ContactFreezerColorMapping[4],
              y: 30,
            },
            {
              color: ContactFreezerColorMapping[4],
              y: 30,
            },
            {
              color: ContactFreezerColorMapping[4],
              y: 30,
            },
            {
              color: ContactFreezerColorMapping[4],
              y: 30,
            },
          ],
          name: 'Grade 3rd',
          type: 'column',
          stack: 'duration',
        },
        {
          data: [
            {
              color: ContactFreezerColorMapping[2],
              y: 30,
            },
            {
              color: ContactFreezerColorMapping[2],
              y: 25,
            },
            {
              color: ContactFreezerColorMapping[2],
              y: 25,
            },
            {
              color: ContactFreezerColorMapping[2],
              y: 25,
            },
            {
              color: ContactFreezerColorMapping[2],
              y: 25,
            },
          ],
          name: 'Grade 2nd',
          type: 'column',
          stack: 'duration',
        },
        {
          data: [
            {
              color: ContactFreezerColorMapping[1],
              y: 30,
            },
            {
              color: ContactFreezerColorMapping[1],
              y: 25,
            },
            {
              color: ContactFreezerColorMapping[1],
              y: 25,
            },
            {
              color: ContactFreezerColorMapping[1],
              y: 25,
            },
            {
              color: ContactFreezerColorMapping[1],
              y: 25,
            },
          ],
          name: 'Grade 1st',
          type: 'column',
          stack: 'duration',
        },
      ],
      legend: {
        enabled: false,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
    }),
    [yAxisTitle]
  );

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
    // Hack the chart so it can fill entire screen during fullscreen state
    setReRender(false);
    setTimeout(() => {
      setReRender(true);
    }, 0);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', className, {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
      })}
    >
      <button onClick={handleFullScreen} className="absolute right-5 top-5">
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">{chartTitle}</h4>
        <div className="grid grid-cols-4 gap-x-2 mb-5 relative z-20">
          <div></div>
          <div></div>
          <MultiSelectDropdown placeholder="Species" />
          <MultiSelectDropdown placeholder="Size" />
        </div>
        {withHighest && (
          <div className="flex justify-end">
            <button
              onClick={() => setHeight((highest + 1) % 3)}
              className="flex items-center focus:outline-none"
            >
              <span
                style={{ marginTop: 2 }}
                className="text-caption-1 font-bold text-blue"
              >
                Highest
              </span>
              {(highest === 0 || highest === 1) && (
                <IconArrowBreadcrumb
                  height={18}
                  width={18}
                  className="transform rotate-180 ic-blue"
                />
              )}
              {(highest === 0 || highest === 2) && (
                <IconArrowBreadcrumb
                  height={18}
                  width={18}
                  className={classNames('ic-blue', { '-ml-2': highest === 0 })}
                />
              )}
            </button>
          </div>
        )}

        {reRender && (
          <div>
            <HighchartsReact
              options={barChartOptions}
              highcharts={Highcharts}
            />
            <div className="grid auto-cols-max w-max mx-auto grid-flow-col gap-3 items-center mt-6">
              <ChartLegend
                color={ContactFreezerColorMapping[1]}
                title="Grade 1st"
              />
              <ChartLegend
                color={ContactFreezerColorMapping[2]}
                title="Grade 2nd"
              />
              <ChartLegend
                color={ContactFreezerColorMapping[4]}
                title="Grade 3rd"
              />
              <ChartLegend color="#BBC8D4" title="Broken" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Stack;
