import { createStore, applyMiddleware, Middleware } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { rootReducer } from './root-reducer';

const middleware: Middleware[] = [thunk];

const logger = createLogger();

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

export const store = createStore(
  rootReducer,
  undefined,
  applyMiddleware(...middleware)
);
