import { useState } from 'react';
import { IconAdd } from 'assets';
import {
  AddEditConstant,
  AddEditConstantState,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ConstantManagementTable,
  Dialog,
  FilterButton,
  KonstantaType,
  Loading,
  ProceedConfirmation,
  TextField,
  Filter,
} from 'components';
import { IUser } from 'utils/types';
import { urlAPI } from 'utils/urlApi';
import { useUser } from 'utils/useUser';

const ConstantaManagementPage = () => {
  const [addConstant, setAddConstant] = useState<boolean>(false);
  const [state, setState] = useState<AddEditConstantState>();
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [dialog, setDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [filterChoices, setFilterChoices] = useState<KonstantaType[]>([]);
  const [filterState, setFilterState] = useState<{ [key: string]: any }>({});
  const [filterQuery, setFilterQuery] = useState<string>('');

  useUser((userFromLocal, err) => {
    if (err) console.log(err);
    else {
      setUser(userFromLocal);
    }
  });

  useUser(async (userFromLocal) => {
    const res = await fetch(urlAPI + '/konstanta/filter', {
      headers: {
        Authorization: 'Bearer ' + userFromLocal?.token,
      },
    });

    const data = await res.json();

    setFilterChoices(data.konstantaTypes || []);
  });

  const handleSubmitFilter = (val: { [key: string]: any }) => {
    setFilterState(val);
    setShowFilter(false);

    let filterQuery = Object.keys(val)
      .filter((value) => val[value])
      .join('@!@');

    if (Object.values(val).some((val) => val))
      setFilterQuery(`&type=${filterQuery}`);
    else setFilterQuery('');
  };

  const handleAddConstant = (val: AddEditConstantState) => {
    setAddConstant(false);
    setDialog(true);

    // Set state to be sent to API below here
    setState(val);
  };

  const handleProceedDialog = async () => {
    // Fetch API to add Constant here
    setLoading(true);
    const res = await fetch(urlAPI + '/konstanta', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + user?.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(state),
    });

    if (res.status === 200) {
      setLoading(false);
      setDialog(false);
      setConfirmation(true);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else console.log('Error');
  };

  const handleCancelDialog = () => {
    setDialog(false);
    setAddConstant(true);
  };

  const handleCancelAddEditConstant = () => {
    setAddConstant(false);
    setState(undefined);
  };

  const handleCancelProceedDialog = () => {
    setConfirmation(false);
    setState(undefined);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  return (
    <>
      {addConstant && (
        <AddEditConstant
          onSubmit={handleAddConstant}
          onCancel={handleCancelAddEditConstant}
          state={state}
        />
      )}
      {dialog && (
        <Dialog
          onSubmit={handleProceedDialog}
          onCancel={handleCancelDialog}
          heading="Confirm New Employee"
          message={`Do you really want to add ${state?.id} Constant?`}
          width={600}
        />
      )}
      {confirmation && (
        <ProceedConfirmation
          onClose={handleCancelProceedDialog}
          message={`The new Constant ${state?.nama_konstanta} has been added`}
        />
      )}
      {loading && <Loading />}
      {showFilter && (
        <Filter
          filterState={filterState}
          filterChoices={filterChoices}
          onSubmit={handleSubmitFilter}
          filterChoiceValueKey="konstanta_type"
          filterChoiceLabelKey="konstanta_type"
          onCancel={() => setShowFilter(false)}
        />
      )}
      <div className="user-management">
        <h1 className="text-heading font-bold mb-3">Konstanta Management</h1>
        <Breadcrumb className="mb-5">
          <BreadcrumbItem>Home</BreadcrumbItem>
          <BreadcrumbItem isActive>Konstanta Management</BreadcrumbItem>
        </Breadcrumb>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center">
            <h2 className="text-heading font-extrabold mr-8">Konstanta List</h2>
            <TextField
              value={searchQuery}
              onChange={handleChange}
              placeholder="Cari nama konstanta"
              variant="search"
            />
            <FilterButton
              onClick={() => setShowFilter(true)}
              className="ml-4"
            />
          </div>
          <div className="flex items-center">
            <Button onClick={() => setAddConstant(true)}>
              <IconAdd className="mr-2" />
              <span>Add New Konstanta</span>
            </Button>
          </div>
        </div>
        <ConstantManagementTable
          filterQuery={filterQuery}
          searchQuery={searchQuery}
        />
      </div>
    </>
  );
};

export default ConstantaManagementPage;
