import { forwardRef, useEffect, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  ListChildComponentProps,
  VariableSizeList as List,
  VariableSizeList,
} from 'react-window';
import { IconClose, IconEdit, IconSort, IconTrash } from '../../../assets';
import { IUser } from '../../../utils/types';
import { urlAPI } from '../../../utils/urlApi';
import { useUser } from '../../../utils/useUser';
import { LoadingAnimation } from 'components';
import { Loading } from 'components';
import AddEditRole, { AddEditRoleState } from '../add-edit-role';
import { Dialog, ProceedConfirmation } from 'components';
import './index.css';

type RowHeights = {
  [key: string]: number;
};

interface RoleManagementTableProps {
  searchQuery?: string;
}

const RoleManagementTable: React.FC<RoleManagementTableProps> = ({
  searchQuery = '',
}) => {
  const listRef = useRef<VariableSizeList>(null);
  const rowHeights = useRef<RowHeights>({});

  function setRowHeight(index: number, size: number) {
    listRef.current?.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  function getRowHeight(index: number) {
    const height = rowHeights.current[Number(index)] || 60;

    return height < 60 ? 60 : height;
  }

  // Row Element for home table
  const Row = forwardRef<any, ListChildComponentProps>(
    ({ data, index, style }, _) => {
      const rowRef = useRef<HTMLDivElement>(null);

      useEffect(() => {
        if (rowRef.current) setRowHeight(index, rowRef.current.offsetHeight);
      }, [rowRef, index]);

      const handleEdit = () => {
        setState(data[index]);
        setEditRole(true);
      };

      const handleDelete = () => {
        setState(data[index]);
        setDeleteRole(true);
      };

      return (
        <>
          <div style={style} className="role-management-table-row px-4">
            <div className="text-footnote text-black font-bold">
              {index + 1}
            </div>
            <div ref={rowRef} className="text-footnote text-black font-bold">
              <span className="inline-block">{data[index].id}</span>
            </div>
            <div className="text-footnote text-black font-bold">
              <span>{data[index].Role_name}</span>
            </div>
            <div className="flex items-center justify-self-center">
              <button
                onClick={handleEdit}
                className="mr-4 border-0 bg-transparent "
              >
                <IconEdit height="24" width="24" />
              </button>
              <button
                onClick={handleDelete}
                className=" border-0 bg-transparent "
              >
                <IconClose height="24" width="24" />
              </button>
            </div>
          </div>
        </>
      );
    }
  );

  // State for returned device management table element
  const [state, setState] = useState<AddEditRoleState>();
  const [roles, setRoles] = useState<AddEditRoleState[]>([]);
  const [user, setUser] = useState<IUser>();
  const [editRole, setEditRole] = useState<boolean>(false);
  const [dialogEdit, setDialogEdit] = useState<boolean>(false);
  const [deleteRole, setDeleteRole] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [successDelete, setSuccessDelete] = useState<boolean>(false);
  const [loadingRole, setLoadingRole] = useState(true);

  const attempt = useRef(0);

  useUser(async (userFromLocal) => {
    setUser(userFromLocal);
    try {
      const res = await fetch(urlAPI + '/user-role', {
        headers: {
          Authorization: 'Bearer ' + userFromLocal?.token,
        },
      });

      const data = await res.json();
      setLoadingRole(false);
      setRoles(data.data || []);
    } catch (e) {
      console.error(e);
    }
  });

  useEffect(() => {
    if (attempt.current > 1) {
      const fetchData = async function () {
        let s = '';
        if (searchQuery) s = `&name=${searchQuery}`;

        const res = await fetch(urlAPI + `/user-role?limit=99999${s}`, {
          headers: {
            Authorization: 'Bearer ' + user?.token,
          },
        });

        const data = await res.json();

        setRoles(data.data || []);
      };

      fetchData();
    } else attempt.current++;
  }, [user, searchQuery]);

  const handleEditRole = (val: AddEditRoleState) => {
    setState(val);
    setDialogEdit(true);
    setEditRole(false);
  };

  const handleProceedEdit = async () => {
    setLoading(true);
    setDialogEdit(false);

    // fetch API to edit role here
    const res = await fetch(urlAPI + `/user-role/${state?.id}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user?.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Role_name: state?.Role_name,
      }),
    });

    setLoading(false);
    if (res.status === 200) {
      setSuccess(true);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  };

  const handleCancelDialog = () => {
    setDialogEdit(false);
    setEditRole(true);
  };

  const handleDeleteRole = async () => {
    setLoading(true);
    setDeleteRole(false);

    // fetch API to delete role here
    const res = await fetch(urlAPI + `/user-role/${state?.id}`, {
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
      method: 'DELETE',
    });

    setLoading(false);
    if (res.status === 200) {
      setSuccessDelete(true);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  };

  return (
    <>
      {editRole && (
        <AddEditRole
          onSubmit={handleEditRole}
          onCancel={() => setEditRole(false)}
          state={state}
        />
      )}
      {dialogEdit && (
        <Dialog
          onSubmit={handleProceedEdit}
          onCancel={handleCancelDialog}
          heading="Update Role Information"
          message={`Do you really want to update the ${state?.id} information?`}
        />
      )}
      {deleteRole && (
        <Dialog
          onSubmit={handleDeleteRole}
          onCancel={() => setDeleteRole(false)}
          heading="Delete Role"
          message={`Are you sure you want to delete this ${state?.id} role?`}
          submitText="Delete for good"
          reverseButton
        />
      )}
      {loading && <Loading />}
      {success && (
        <ProceedConfirmation
          onClose={() => setSuccess(false)}
          message={`The ${state?.id} information has been changed`}
        />
      )}
      {successDelete && (
        <ProceedConfirmation
          onClose={() => setSuccessDelete(false)}
          Icon={(props) => <IconTrash {...props} className="mb-6 ic-red" />}
          message={`The ${state?.id} information has been deleted`}
        />
      )}
      <div className="role-management-table bg-white rounded-lg px-8 py-10 border rounded-lg border-grey-light">
        <div className="role-management-table-row py-4 px-4">
          <div className="text-body text-grey font-bold flex items-center cursor-pointer">
            <span className="block mr-2">NO.</span>
            <IconSort />
          </div>
          <div className="text-body text-grey font-bold flex items-center cursor-pointer">
            <span className="block mr-2">Role ID</span>
            <IconSort />
          </div>
          <div className="text-body text-grey font-bold flex items-center cursor-pointer">
            <span className="block mr-2">Role Name</span>
            <IconSort />
          </div>
          <div className="text-body text-grey font-bold flex items-center cursor-pointer justify-self-center">
            Action
          </div>
        </div>
        {loadingRole ? (
          <div className="flex justify-center">
            <LoadingAnimation size="md" className="mt-6" />
          </div>
        ) : (
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height - 80}
                itemData={roles}
                itemCount={roles.length}
                itemSize={getRowHeight}
                width={width + 5}
                ref={listRef}
                className="list"
              >
                {({ ...props }) => {
                  return <Row {...props} />;
                }}
              </List>
            )}
          </AutoSizer>
        )}
      </div>
    </>
  );
};

export default RoleManagementTable;
