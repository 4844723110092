import { useState } from 'react';
import classNames from 'classnames';
import {
  Breadcrumb,
  BreadcrumbItem,
  ColdStorageTemp,
  DatePicker,
  Switch,
  ContactFreezerTemp,
  MachinePowerConsumption,
  MachineCondition,
  OperatorPerformance,
  OperatorInCharge,
} from 'components';

const OverviewPage = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [realtime, setRealtime] = useState<boolean>(false);

  const handleChangeRealtime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    setRealtime(checked);
  };

  return (
    <div className="user-management">
      <h1 className="text-heading font-bold mb-3">Dashboard Overview</h1>
      <Breadcrumb className="mb-5">
        <BreadcrumbItem href="/mechanic/overview">Home</BreadcrumbItem>
        <BreadcrumbItem href="/mechanic/overview">Overview</BreadcrumbItem>
        <BreadcrumbItem isActive>Dashboard</BreadcrumbItem>
      </Breadcrumb>
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <Switch
            onChange={handleChangeRealtime}
            checked={realtime}
            withoutMessage
            className="mr-3"
          />
          <span className="text-caption-1 font-semibold text-grey">
            Realtime
          </span>
        </div>
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <DatePicker
            disabled={realtime}
            selected={startDate}
            // @ts-ignore
            onChange={(date) => setStartDate(date)}
            dateFormat="E, d MMM yyyy"
            containerClassName="mr-3.5"
          />
          <DatePicker
            disabled={realtime}
            selected={endDate}
            // @ts-ignore
            onChange={(date) => setEndDate(date)}
            dateFormat="E, d MMM yyyy"
            popperPlacement="bottom-end"
          />
        </div>
      </div>
      <div
        className={classNames('grid-cols-2 grid gap-6 mt-6', {
          'grid-cols-4': realtime,
        })}
      >
        <ColdStorageTemp
          className={classNames({ 'col-span-2': realtime })}
          mode={realtime ? 'realtime' : 'default'}
        />
        <ContactFreezerTemp
          className={classNames({ 'col-span-2': realtime })}
          mode={realtime ? 'realtime' : 'default'}
        />
        {realtime && <OperatorInCharge />}
        <MachinePowerConsumption
          className={classNames({ 'col-span-2': realtime })}
        />
        <MachineCondition mode={realtime ? 'realtime' : 'default'} />
        {!realtime && <OperatorPerformance />}
      </div>
    </div>
  );
};

export default OverviewPage;
