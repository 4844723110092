import classNames from 'classnames';
import { useState } from 'react';
import { IconClose, IconLaunch } from '../../../../assets';
import {
  Button,
  MultiSelectDropdownItem,
  MultiSelectDropdown,
} from 'components';

interface ColdStorageUnitProductQuantityProps {
  className?: string;
}

const ColdStorageUnitProductQuantity: React.FC<ColdStorageUnitProductQuantityProps> =
  ({ className }) => {
    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const [active, setActive] = useState<'mc' | 'mco' | 'order'>('mc');

    const handleFullScreen = () => {
      setFullScreen(!fullScreen);
    };

    return (
      <div
        style={{ zIndex: fullScreen ? 1200 : 0 }}
        className={classNames('flex justify-center items-center', className, {
          relative: !fullScreen,
          'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        })}
      >
        <button
          onClick={handleFullScreen}
          className="absolute right-5 top-5 z-10"
        >
          {!fullScreen ? <IconLaunch /> : <IconClose />}
        </button>
        <div className="w-full h-full border rounded-lg border-grey-light p-6 pb-12">
          <h4 className="text-footnote font-bold mb-8">Product Quantity</h4>
          <div className="flex items-center">
            <label className="flex-items-center text-caption-1 font-bold text-grey mr-3">
              <input
                value="weight"
                name="quantity"
                className="mr-2"
                type="radio"
              />
              <span>Weight</span>
            </label>
            <label className="flex-items-center text-caption-1 font-bold text-grey mr-3">
              <input value="mc" name="quantity" className="mr-2" type="radio" />
              <span>MC</span>
            </label>
          </div>
          <div className="flex items-center justify-end mb-4">
            <Button
              color={active === 'mc' ? 'default' : 'grey'}
              onClick={() => setActive('mc')}
              className="mr-3"
            >
              MC Quantity
            </Button>
            <Button
              color={active === 'mco' ? 'default' : 'grey'}
              onClick={() => setActive('mco')}
              className="mr-3"
            >
              MCO Quantity
            </Button>
            <Button
              color={active === 'order' ? 'default' : 'grey'}
              onClick={() => setActive('order')}
            >
              Takeout Order
            </Button>
          </div>
          {active === 'mc' && (
            <div>
              <h4 className="text-footnote font-bold mb-8">Quantity per MC</h4>
              <div className="grid ml-auto grid-cols-6 mb-5 gap-x-1">
                <MultiSelectDropdown placeholder="Grade" />
                <MultiSelectDropdown placeholder="Size" />

                <MultiSelectDropdown placeholder="Species">
                  <MultiSelectDropdownItem value="supplier-1" id="supplier-1">
                    Supplier 1
                  </MultiSelectDropdownItem>
                  <MultiSelectDropdownItem value="supplier-2" id="supplier-2">
                    Supplier 2
                  </MultiSelectDropdownItem>
                </MultiSelectDropdown>
                <MultiSelectDropdown placeholder="Supplier" />
                <MultiSelectDropdown placeholder="Brand" />
                <MultiSelectDropdown placeholder="Production Date" />
              </div>
              <div>
                <div className="grid grid-cols-4 text-footnote text-grey font-bold mb-5">
                  <div>MC Code</div>
                  <div>Input</div>
                  <div>Output</div>
                  <div className="text-right">Latest Stock</div>
                </div>
                <div className="grid grid-cols-4 text-caption-1 font-bold py-2 border-b border-grey-1">
                  <div>MC-4124709</div>
                  <div>100 kg</div>
                  <div>100 kg</div>
                  <div className="text-right">200 kg</div>
                </div>
                <div className="grid grid-cols-4 text-caption-1 font-bold py-2 border-b border-grey-1">
                  <div>MC-1928471</div>
                  <div>100 kg</div>
                  <div>100 kg</div>
                  <div className="text-right">200 kg</div>
                </div>
              </div>
            </div>
          )}
          {active === 'mco' && (
            <div>
              <h4 className="text-footnote font-bold mb-8">
                Quantity per Product
              </h4>
              <div className="grid ml-auto grid-cols-5 mb-5 gap-x-1">
                <MultiSelectDropdown placeholder="Grade" />
                <MultiSelectDropdown placeholder="Size" />
                <MultiSelectDropdown placeholder="Species">
                  <MultiSelectDropdownItem value="supplier-1" id="supplier-1">
                    Supplier 1
                  </MultiSelectDropdownItem>
                  <MultiSelectDropdownItem value="supplier-2" id="supplier-2">
                    Supplier 2
                  </MultiSelectDropdownItem>
                </MultiSelectDropdown>
                <MultiSelectDropdown placeholder="Supplier" />
                <MultiSelectDropdown placeholder="Production Date" />
              </div>
              <div>
                <div className="grid grid-cols-4 text-footnote text-grey font-bold mb-5">
                  <div>Product</div>
                  <div>Input</div>
                  <div>Output</div>
                  <div className="text-right">Latest Stock</div>
                </div>
                <div className="grid grid-cols-4 text-caption-1 font-bold py-2 border-b border-grey-1">
                  <div>HL Treatment</div>
                  <div>100 kg</div>
                  <div>100 kg</div>
                  <div className="text-right">200 kg</div>
                </div>
                <div className="grid grid-cols-4 text-caption-1 font-bold py-2 border-b border-grey-1">
                  <div>PDD Treatment</div>
                  <div>100 kg</div>
                  <div>100 kg</div>
                  <div className="text-right">200 kg</div>
                </div>
              </div>
            </div>
          )}
          {active === 'order' && (
            <div>
              <h4 className="text-footnote font-bold mb-8">Takeout Order</h4>
              <div className="grid ml-auto grid-cols-7 mb-5 gap-x-1">
                <MultiSelectDropdown placeholder="Grade" />
                <MultiSelectDropdown placeholder="Size" />
                <MultiSelectDropdown placeholder="Species">
                  <MultiSelectDropdownItem value="supplier-1" id="supplier-1">
                    Supplier 1
                  </MultiSelectDropdownItem>
                  <MultiSelectDropdownItem value="supplier-2" id="supplier-2">
                    Supplier 2
                  </MultiSelectDropdownItem>
                </MultiSelectDropdown>
                <MultiSelectDropdown placeholder="Supplier" />
                <MultiSelectDropdown placeholder="Sales" />
                <MultiSelectDropdown placeholder="Brand" />
                <MultiSelectDropdown placeholder="Production Date" />
              </div>
              <div>
                <div className="grid grid-cols-3 text-footnote text-grey font-bold mb-5">
                  <div>Product</div>
                  <div className="text-center">Finished</div>
                  <div className="text-right">Ongoing</div>
                </div>
                <div className="grid grid-cols-3 text-caption-1 font-bold py-2 border-b border-grey-1">
                  <div>HL Treatment</div>
                  <div className="text-center">100 kg</div>
                  <div className="text-right">200 kg</div>
                </div>
                <div className="grid grid-cols-3 text-caption-1 font-bold py-2 border-b border-grey-1">
                  <div>PDTO</div>
                  <div className="text-center">100 kg</div>
                  <div className="text-right">200 kg</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

export default ColdStorageUnitProductQuantity;
