const IQFUnitReportSummaryTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">Branded Package</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <div className="grid grid-cols-8 items-center font-semibold text-caption-1 gap-y-2 gap-x-4 px-2 py-2.5">
          <div className="col-span-2">MC Code</div>
          <div>Product</div>
          <div className="col-span-2 text-center">Input</div>
          <div className="col-span-2 text-center">Output</div>
          <div>Yield</div>
        </div>
        {/* Put the children here later */}
      </div>
      <h6 className="font-bold mb-2">No Branded Package</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <div className="grid grid-cols-8 items-center font-semibold text-caption-1 gap-y-2 gap-x-4 px-2 py-2.5">
          <div className="col-span-2">MC Code</div>
          <div>Product</div>
          <div className="col-span-2 text-center">Input</div>
          <div className="col-span-2 text-center">Output</div>
          <div>Yield</div>
        </div>
        {/* Put the children here later */}
      </div>

      <div className="flex items-start mb-8">
        <div className="w-96 mr-20">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Headcount</div>
            <div>8 People</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Working Time</div>
            <div>50 Man-Hours</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Time</div>
            <div>+5.5 Hours</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Product Type</div>
            <div>8</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Unit Productivity Rate</div>
            <div>50kg/Man-Hours</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Unit Productivity</div>
            <div>500kg/Hour</div>
          </div>
          <div className="flex justify-between text-caption-1">
            <div className="font-semibold">Yield</div>
            <div>1%</div>
          </div>
        </div>
        <div className="w-96">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total MC</div>
            <div>5500 MC</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total kg</div>
            <div>11 ton</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">MC Branded Package</div>
            <div>5500 MC</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">kg Branded Package</div>
            <div>11 ton</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">MC No Branded Package</div>
            <div>5500 MC</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">kg No Branded Package</div>
            <div>11 ton</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Frequency Metal Detected</div>
            <div>11 times</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IQFUnitReportSummaryTable;
