import { TableGrid } from 'components';

const PeelingUnitProcessLogTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">Detailed Data</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9 overflow-auto">
        <div className="mb-5">
          <div className="flex items-center text-caption-1 mb-2">
            <span className="font-semibold mr-6">Process ID</span>
            <span>1</span>
          </div>
          <div className="flex items-center text-caption-1 mb-2">
            <span className="font-semibold mr-6">Start Date</span>
            <span>11 Februari 2020</span>
          </div>
          <div className="flex items-center text-caption-1 mb-4">
            <span className="font-semibold mr-6">End Date</span>
            <span>12 Februrari 2020</span>
          </div>
        </div>
        <h6 className="font-bold px-2 mb-4">Input</h6>
        <TableGrid
          className="mb-6"
          columns={[
            {
              field: 'product',
              name: 'Product',
            },
            {
              field: 'species',
              name: 'Species',
            },
            {
              field: 'size',
              name: 'Size',
            },
            {
              field: 'weight',
              name: 'Weight',
            },
            {
              field: 'containerID',
              name: 'Container ID',
            },
          ]}
          data={[
            {
              product: 'HL Treatment',
              species: 'Black Tiger',
              size: '8 - 12',
              weight: '200 kg',
              containerID: 'KL-001',
            },
            {
              product: 'HL Treatment',
              species: 'Black Tiger',
              size: '8 - 12',
              weight: '200 kg',
              containerID: 'KL-001',
            },
          ]}
        />
        <h6 className="font-bold px-2 mb-4">Peeling</h6>
        <TableGrid
          className="mb-6"
          columns={[
            {
              field: 'product',
              name: 'Product',
            },
            {
              field: 'species',
              name: 'Species',
            },
            {
              field: 'size',
              name: 'Size',
            },
            {
              field: 'weight',
              name: 'Weight',
            },
            {
              name: 'Tally After Peeled',
              cols: ['afterPeeled1', 'afterPeeled2', 'afterPeeled3'],
              rows: 4,
              textAlign: 'center',
            },
          ]}
          data={[
            {
              product: 'HL Treatment',
              species: 'Black Tiger',
              size: '8 - 12',
              weight: '200 kg',
              'afterPeeled1.1': '20',
              'afterPeeled1.2': '20',
              'afterPeeled1.3': '20',
              'afterPeeled1.4': '20',
              'afterPeeled2.1': '20',
              'afterPeeled2.2': '20',
              'afterPeeled2.3': '20',
              'afterPeeled2.4': '20',
              'afterPeeled3.1': '20',
              'afterPeeled3.2': '20',
              'afterPeeled3.3': '20',
              'afterPeeled3.4': '20',
            },
            {
              product: 'HL Treatment',
              species: 'Black Tiger',
              size: '8 - 12',
              weight: '200 kg',
              'afterPeeled1.1': '20',
              'afterPeeled1.2': '20',
              'afterPeeled2.1': '20',
              'afterPeeled2.2': '20',
              'afterPeeled3.1': '20',
              'afterPeeled3.2': '20',
            },
          ]}
          onRenderField={(field, value, { key }) => {
            if (field.startsWith('afterPeeled'))
              return (
                <div key={key} className="text-center">
                  {value}
                </div>
              );
          }}
        />
        <h6 className="font-bold px-2 mb-4">Soaking</h6>
        <TableGrid
          className="mb-6"
          columns={[
            {
              field: 'product',
              name: 'Product',
            },
            {
              field: 'species',
              name: 'Species',
            },
            {
              field: 'size',
              name: 'Size',
              width: '0.5fr',
            },
            {
              field: 'treatment',
              name: 'Treatment',
              width: '0.5fr',
            },
            {
              field: 'weight',
              name: 'Weight',
            },
            {
              name: 'Tally After Soaking',
              cols: ['afterSoaking1', 'afterSoaking2', 'afterSoaking3'],
              rows: 4,
              textAlign: 'center',
            },
          ]}
          data={[
            {
              product: 'HL Treatment',
              species: 'Black Tiger',
              size: '8 - 12',
              weight: '200 kg',
              treatment: 'Treatment A',
              'afterSoaking1.1': '20',
              'afterSoaking1.2': '20',
              'afterSoaking1.3': '20',
              'afterSoaking1.4': '20',
              'afterSoaking2.1': '20',
              'afterSoaking2.2': '20',
              'afterSoaking2.3': '20',
              'afterSoaking2.4': '20',
              'afterSoaking3.1': '20',
              'afterSoaking3.2': '20',
              'afterSoaking3.3': '20',
              'afterSoaking3.4': '20',
            },
            {
              product: 'HL Treatment',
              species: 'Black Tiger',
              size: '8 - 12',
              weight: '200 kg',
              treatment: 'Treatment B',
              'afterSoaking1.1': '20',
              'afterSoaking1.2': '20',
              'afterSoaking2.1': '20',
              'afterSoaking2.2': '20',
              'afterSoaking3.1': '20',
              'afterSoaking3.2': '20',
            },
          ]}
          onRenderField={(field, value, { key }) => {
            if (field.startsWith('afterSoaking'))
              return (
                <div key={key} className="text-center">
                  {value}
                </div>
              );
          }}
        />
        <h6 className="font-bold px-2 mb-4">Output</h6>
        <TableGrid
          className="mb-6"
          columns={[
            {
              field: 'product',
              name: 'Product',
            },
            {
              field: 'species',
              name: 'Species',
            },
            {
              field: 'size',
              name: 'Size',
            },
            {
              field: 'weight',
              name: 'Weight',
            },
            {
              field: 'tallyCount',
              name: 'Tally Count',
            },
          ]}
          data={[
            {
              product: 'HL Treatment',
              species: 'Black Tiger',
              size: '8 - 12',
              weight: '200 kg',
              tallyCount: '23',
            },
            {
              product: 'HL Treatment',
              species: 'Black Tiger',
              size: '8 - 12',
              weight: '200 kg',
              tallyCount: '12',
            },
          ]}
        />
      </div>
      <h6 className="font-bold mb-2">Detailed Material Soaking Usage</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              field: 'no',
              name: 'No.',
              width: '40px',
            },
            {
              field: 'material',
              name: 'Material',
            },
            {
              field: 'quantity',
              name: 'Quantity',
            },
            {
              field: 'time',
              name: 'Time',
            },
          ]}
          data={[
            {
              no: '1',
              material: 'Mat A',
              quantity: '200 kg',
              time: '28 Februari 2020',
            },
            {
              no: '2',
              material: 'Mat A',
              quantity: '200 kg',
              time: '28 Februari 2020',
            },
          ]}
        />
      </div>
    </>
  );
};

export default PeelingUnitProcessLogTable;
