import { useState } from 'react';
import { Breadcrumb, BreadcrumbItem, Button } from 'components';
import ReportGeneral from './report-general';
import ReportPTO from './report-pto';

const LabInspectionUnitReportDetail = () => {
  const [active, setActive] = useState<'general' | 'pto'>('general');

  return (
    <div style={{ minWidth: 1200 }}>
      <div className="flex items-end justify-between mb-4">
        <div>
          <h1 className="text-heading font-bold mb-3">
            Lab Inspection Unit Report{' '}
            {active === 'general' ? 'General' : 'PTO Strech'}
          </h1>
          <Breadcrumb className="mb-5">
            <BreadcrumbItem href="/unit/">Home</BreadcrumbItem>
            <BreadcrumbItem href="/unit/lab-inspection-unit">
              Lab Inspection Unit
            </BreadcrumbItem>
            <BreadcrumbItem href="/lab-inspection-unit/report">
              Report
            </BreadcrumbItem>
            <BreadcrumbItem isActive>06-12-2020</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="grid auto-grid-max grid-flow-col gap-4">
          <Button
            color={active === 'general' ? 'default' : 'grey'}
            onClick={() => setActive('general')}
            className="w-40"
          >
            General
          </Button>
          <Button
            color={active === 'pto' ? 'default' : 'grey'}
            onClick={() => setActive('pto')}
            className="w-40"
          >
            PTO
          </Button>
        </div>
      </div>
      {active === 'general' && <ReportGeneral />}
      {active === 'pto' && <ReportPTO />}
    </div>
  );
};

export default LabInspectionUnitReportDetail;
