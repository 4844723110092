import { TableGrid } from 'components';

const FreezingUnitProcessLogTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">Detailed Freezing Input and Output</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9 overflow-auto">
        <TableGrid
          className="mb-6"
          columns={[
            {
              field: 'no',
              name: 'No',
              width: '40px',
            },
            {
              field: 'contactFreezer',
              name: 'Contact Freezer',
            },
            {
              field: 'inputInner',
              name: 'Input Inner',
            },
            {
              field: 'inputTimestamp',
              name: 'Input Timestamp',
            },
            {
              field: 'output',
              name: 'output',
              cols: ['innerSuccess', 'innerFail'],
              Component: (
                <div
                  className="grid grid-cols-2 gap-y-4"
                  key="output-freezing-unit "
                >
                  <div className="col-span-2 text-center pb-2 border-b border-grey-placeholder">
                    Output
                  </div>
                  <div className="text-center">Inner Success</div>
                  <div className="text-center">Inner Fail</div>
                </div>
              ),
              width: '2fr',
            },
            {
              field: 'outputTimestamp',
              name: 'Output Timestamp',
            },
          ]}
          data={[
            {
              no: '1',
              contactFreezer: 'F-001',
              inputInner: '100',
              inputTimestamp: '28 Februari 2020',
              innerSuccess: '80',
              innerFail: '20',
              outputTimestamp: '29 Februari 2020',
            },
            {
              no: '2',
              contactFreezer: 'F-001',
              inputInner: '100',
              inputTimestamp: '28 Februari 2020',
              innerSuccess: '80',
              innerFail: '20',
              outputTimestamp: '29 Februari 2020',
            },
          ]}
          onRenderField={(field, value, { key }) => {
            if (field.startsWith('inner'))
              return (
                <div className="text-center" key={key}>
                  {value}
                </div>
              );
          }}
        />
      </div>

      <h6 className="font-bold mb-2">Detailed Freezing Rekeeping</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              field: 'no',
              name: 'No.',
              width: '40px',
            },
            {
              field: 'containerID',
              name: 'Container ID',
            },
            {
              field: 'inner',
              name: 'Inner',
            },
            {
              field: 'timestamp',
              name: 'Timestamp',
            },
          ]}
        />
      </div>
    </>
  );
};

export default FreezingUnitProcessLogTable;
