import { IconClose, IconLaunch } from 'assets';
import classNames from 'classnames';
import {
  Breadcrumb,
  BreadcrumbItem,
  DatePicker,
  ManagementCostAnalysisTable,
  ManagementCostDistributionChart,
  ManagementCostMaterialAndLaborChart,
} from 'components';
import { useState } from 'react';

const ManagementCostAnalysisPage = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  return (
    <div className="user-management">
      <h1 className="text-heading font-bold mb-3">Cost Analysis</h1>
      <Breadcrumb className="mb-5">
        <BreadcrumbItem href="/management/">Home</BreadcrumbItem>
        <BreadcrumbItem isActive>Cost Analysis</BreadcrumbItem>
      </Breadcrumb>
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <DatePicker
            selected={startDate}
            // @ts-ignore
            onChange={(date) => setStartDate(date)}
            dateFormat="E, d MMM yyyy"
            containerClassName="mr-3.5"
          />
          <DatePicker
            selected={endDate}
            // @ts-ignore
            onChange={(date) => setEndDate(date)}
            dateFormat="E, d MMM yyyy"
            popperPlacement="bottom-end"
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-6 mb-10">
        <ManagementCostDistributionChart />
        <ManagementCostMaterialAndLaborChart />
      </div>
      <CostElectricity className="mb-6" />
      <ManagementCostAnalysisTable />
    </div>
  );
};

export default ManagementCostAnalysisPage;

interface CostElectricityProps extends IBaseTable {
  className?: string;
}

const CostElectricity: React.FC<CostElectricityProps> = ({ className }) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', className, {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
      })}
    >
      <button onClick={handleFullScreen} className="absolute right-5 top-5">
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">Cost Electricity</h4>
        <div className="grid grid-cols-3 justify-center items-center text-footnote">
          <div className="border-r border-grey-placeholder text-center">
            <div className="text-blue font-bold">Total Cost Electricity:</div>
            <div className="text-grey">Rp 10 juta</div>
          </div>
          <div className="border-r border-grey-placeholder text-center">
            <div className="text-blue font-bold">
              Total Freezing Finished Good:
            </div>
            <div className="text-grey">1 ton</div>
          </div>
          <div className="text-center">
            <div className="text-blue font-bold">
              Cost Freezing Electricity:
            </div>
            <div className="text-grey">Rp 10 rb / kg</div>
          </div>
        </div>
      </div>
    </div>
  );
};
