import classNames from 'classnames';

interface DropdownItemProps {
  onClick?(): void;
  active?: boolean;
  disabled?: boolean;
  className?: string;
  setHidden?: React.Dispatch<React.SetStateAction<boolean>>;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  value?: string;
  textSize?: 'footnote' | 'caption-1';
}

const DropdownItem: React.FC<DropdownItemProps> = ({
  children,
  onClick,
  className = '',
  setHidden,
  setValue,
  value = '',
  textSize = 'footnote',
}) => {
  const handleClick = () => {
    if (onClick) onClick();
    if (setValue) setValue(value);
    if (setHidden) setHidden(true);
  };

  return (
    <div
      className={classNames(
        `px-4 text-${textSize} py-2 cursor-pointer font-bold hover:bg-blue-lighter`,
        {
          [className]: className.length > 0,
        }
      )}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};

export default DropdownItem;
