import { Action, Reducer } from 'redux';

export enum FilterDateReducerActionTypes {
  SET_MODAL_OPEN = 'SET_MODAL_OPEN',
  SET_MODAL_CLOSE = 'SET_MODAL_CLOSE',
}

export interface FilterDateAction extends Action<any> {
  payload: {
    id?: string;
    type: 'create' | 'edit';
  };
}

export interface TakeoutOrderState {
  isModalOpen: boolean;
  type: 'create' | 'edit';
  id: string;
}

export const takeoutOrderReducer: Reducer<TakeoutOrderState, FilterDateAction> =
  (
    state = {
      isModalOpen: false,
      type: 'create',
      id: '',
    },
    action
  ) => {
    switch (action.type) {
      case FilterDateReducerActionTypes.SET_MODAL_OPEN:
        return {
          isModalOpen: true,
          type: action.payload.type,
          id: action.payload.id ?? '',
        };
      case FilterDateReducerActionTypes.SET_MODAL_CLOSE:
        return {
          ...state,
          id: '',
          isModalOpen: false,
        };
      default:
        return state;
    }
  };
