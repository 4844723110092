import classNames from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState } from 'react';
import { IconClose, IconLaunch } from '../../../../assets';
import { ColdStorageColorMapping } from '../../../../colors';
import { Button, DropdownItem } from 'components';
import {
  ChartLegend,
  Dropdown,
  MultiSelectDropdown,
  SortThreeState,
} from 'components';

interface FreezingPackingUnitProductionProps {
  className?: string;
}

const packedProductOptions: Highcharts.Options = {
  title: {
    text: '',
  },
  xAxis: {
    type: 'datetime',
    title: {
      text: 'Hours',
    },
    dateTimeLabelFormats: {
      hour: '%H:%M',
    },
    gridLineWidth: 1,
  },
  yAxis: {
    gridLineWidth: 0,
    title: {
      text: null,
    },
    labels: {
      format: '{value} ton',
    },
  },
  series: [
    {
      type: 'spline',
      name: 'Incoming',
      data: [2, 3, 4, 5, 6, 34, 3, 2],
      marker: {
        enabled: false,
      },
      color: ColdStorageColorMapping[1],
    },
  ],
  plotOptions: {
    line: {
      lineWidth: 4,
      states: {
        hover: {
          lineWidth: 5,
        },
      },
      pointInterval: 60 * 60 * 1000,
      marker: {
        enabled: false,
      },
    },
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    valueSuffix: ' Ton',
  },
};

const outputDistributionOptions: Highcharts.Options = {
  title: {
    text: '',
  },
  yAxis: {
    gridLineWidth: 0,
    title: {
      text: 'kg',
    },
  },
  series: [
    {
      type: 'pie',
      data: [
        {
          name: 'White',
          y: 4,
          color: ColdStorageColorMapping[1],
          dataLabels: { enabled: false },
        },
        {
          name: 'Black Pink',
          y: 4,
          color: ColdStorageColorMapping[2],
          dataLabels: { enabled: false },
        },
      ],
      innerSize: '80%',
      size: '75%',
      name: 'Output Distribution',
    },
  ],
  legend: {
    enabled: false,
  },
  tooltip: {
    valueSuffix: ' kg',
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 60,
        },
      },
    ],
  },
};

type FreezingPackingUnitProductionActive =
  | 'Packed-Product'
  | 'Output-Distribution';

const FreezingPackingUnitProduction: React.FC<FreezingPackingUnitProductionProps> =
  ({ className }) => {
    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const [reRender, setReRender] = useState<boolean>(true);
    const [active, setActive] =
      useState<FreezingPackingUnitProductionActive>('Packed-Product');
    const [kilogram, setKilogram] = useState<number>(0);

    const handleFullScreen = () => {
      setFullScreen(!fullScreen);
      // Hack the chart so it can fill entire screen during fullscreen state
      setReRender(false);
      setTimeout(() => {
        setReRender(true);
      }, 0);
    };

    const handleActive = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      const { name } = e.currentTarget;

      setActive(name as FreezingPackingUnitProductionActive);
    };

    return (
      <div
        style={{ zIndex: fullScreen ? 1200 : 0 }}
        className={classNames('flex justify-center items-center', className, {
          relative: !fullScreen,
          'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        })}
      >
        <button onClick={handleFullScreen} className="absolute right-5 top-5">
          {!fullScreen ? <IconLaunch /> : <IconClose />}
        </button>
        <div className="w-full h-full border rounded-lg border-grey-light p-6">
          <h4 className="text-footnote font-bold mb-8">Production</h4>
          <div className="grid grid-cols-5 gap-x-2 mb-5 relative z-20 ml-4">
            <MultiSelectDropdown placeholder="MC Code" />
            <MultiSelectDropdown placeholder="Product" />
            <MultiSelectDropdown placeholder="Prod Date" />
            <MultiSelectDropdown placeholder="Size" />
            <MultiSelectDropdown placeholder="Species" />
          </div>
          <div className="flex justify-end items-center mb-3.5">
            <Button
              onClick={handleActive}
              name="Packed-Product"
              color={active === 'Packed-Product' ? 'default' : 'grey'}
              className="mr-1.5"
            >
              Packed Product
            </Button>
            <Button
              onClick={handleActive}
              name="Output-Distribution"
              color={active === 'Output-Distribution' ? 'default' : 'grey'}
            >
              Output Distribution
            </Button>
          </div>

          <h4 className="text-footnote font-bold mb-8">
            {active === 'Output-Distribution'
              ? 'Output Distribution by [Distribution by]'
              : active.split('-').join(' ')}
          </h4>
          {reRender && (
            <>
              {active === 'Packed-Product' && (
                <div>
                  <div className="ml-4 mb-4">
                    <SortThreeState
                      value={kilogram}
                      onChange={(s) => setKilogram(s)}
                      text="Kilogram"
                    />
                  </div>
                  <HighchartsReact
                    options={packedProductOptions}
                    highcharts={Highcharts}
                  />
                  <div className="flex items-center justify-center mt-4">
                    <ChartLegend
                      title="Incoming"
                      color={ColdStorageColorMapping[1]}
                      className="mr-8"
                    />
                  </div>
                </div>
              )}
              {active === 'Output-Distribution' && (
                <div>
                  <div className="ml-4 mb-4">
                    <SortThreeState text="MC" />
                  </div>
                  <div className="grid grid-cols-3 items-center">
                    <div className="col-span-2">
                      <HighchartsReact
                        options={outputDistributionOptions}
                        highcharts={Highcharts}
                      />
                    </div>
                    <div>
                      <ChartLegend
                        title="4 kg"
                        percentage="50"
                        className="mb-4"
                        color={ColdStorageColorMapping[1]}
                      />
                      <ChartLegend
                        title="4 kg"
                        percentage="50"
                        color={ColdStorageColorMapping[2]}
                      />
                    </div>
                  </div>
                  <div className="flex items-center flex-wrap pl-10 mt-3">
                    <ChartLegend
                      className="m-3"
                      color={ColdStorageColorMapping[1]}
                      title="White"
                    />
                    <ChartLegend
                      className="m-3"
                      color={ColdStorageColorMapping[2]}
                      title="Black Pink"
                    />
                  </div>
                  <div className="flex justify-end">
                    <Dropdown
                      height={35}
                      top="3rem"
                      placeholder="Distribution by"
                      textSize="caption-1"
                    >
                      <DropdownItem>Species</DropdownItem>
                    </Dropdown>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

export default FreezingPackingUnitProduction;
