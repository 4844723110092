import classNames from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState } from 'react';
import { IconArrowBreadcrumb, IconClose, IconLaunch } from '../../../../assets';
import {
  ColdStorageColorMapping,
  ContactFreezerColorMapping,
} from '../../../../colors';
import { Button } from 'components';
import { ChartLegend, MultiSelectDropdown } from 'components';

interface SortingPeeledUnitMaterialCostOfProductProps {
  className?: string;
}

const CostperKgOptions: Highcharts.Options = {
  title: {
    text: '',
  },
  chart: {
    type: 'column',
  },
  xAxis: {
    type: 'category',
    gridLineWidth: 1,
  },
  yAxis: {
    gridLineWidth: 0,
    title: {
      text: 'IDR / kg',
      rotation: -90,
    },
  },
  series: [
    {
      data: [
        {
          name: 'Product 1',
          color: ContactFreezerColorMapping[1],
          y: 8,
        },
        {
          name: 'Product 2',
          color: ContactFreezerColorMapping[2],
          y: 5,
        },
        {
          name: 'Product 3',
          color: ContactFreezerColorMapping[3],
          y: 7,
        },
        {
          name: 'Product 4',
          color: ContactFreezerColorMapping[4],
          y: 7,
        },
        {
          name: 'Product 5',
          color: ContactFreezerColorMapping[5],
          y: 7,
        },
        {
          name: 'Product 6',
          color: ContactFreezerColorMapping[6],
          y: 7,
        },
      ],
      name: 'Cost / kg',
      type: 'column',
      borderRadius: 30,
    },
  ],
  legend: {
    enabled: false,
  },
};

const TotalCostOptions: Highcharts.Options = {
  title: {
    text: '',
  },
  yAxis: {
    gridLineWidth: 0,
    title: {
      text: 'kg',
    },
  },
  series: [
    {
      type: 'pie',
      data: [
        {
          name: 'Product 1',
          y: 4,
          color: ColdStorageColorMapping[1],
          dataLabels: { enabled: false },
        },
        {
          name: 'Product 2',
          y: 4,
          color: ColdStorageColorMapping[2],
          dataLabels: { enabled: false },
        },
      ],
      innerSize: '80%',
      size: '75%',
      name: 'Total Cost',
    },
  ],
  legend: {
    enabled: false,
  },
  tooltip: {
    valueSuffix: ' kg',
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 60,
        },
      },
    ],
  },
};

type SortingPeeledUnitMaterialCostOfProductActive = 'Cost / kg' | 'Total Cost';

const SortingPeeledUnitMaterialCostOfProduct: React.FC<SortingPeeledUnitMaterialCostOfProductProps> =
  ({ className }) => {
    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const [reRender, setReRender] = useState<boolean>(true);
    const [highest, setHeight] = useState<number>(0);
    const [active, setActive] =
      useState<SortingPeeledUnitMaterialCostOfProductActive>('Cost / kg');

    const handleFullScreen = () => {
      setFullScreen(!fullScreen);
      // Hack the chart so it can fill entire screen during fullscreen state
      setReRender(false);
      setTimeout(() => {
        setReRender(true);
      }, 0);
    };

    const handleActive = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      const { name } = e.currentTarget;

      setActive(name as SortingPeeledUnitMaterialCostOfProductActive);
    };

    return (
      <div
        style={{ zIndex: fullScreen ? 1200 : 0 }}
        className={classNames('flex justify-center items-center', className, {
          relative: !fullScreen,
          'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        })}
      >
        <button onClick={handleFullScreen} className="absolute right-5 top-5">
          {!fullScreen ? <IconLaunch /> : <IconClose />}
        </button>
        <div className="w-full h-full border rounded-lg border-grey-light p-6">
          <h4 className="text-footnote font-bold mb-8">
            Material Cost of Product
          </h4>
          <div className="grid grid-cols-4 gap-x-2 mb-5 relative z-20 ml-4">
            <div></div>
            <MultiSelectDropdown placeholder="Grade" />
            <MultiSelectDropdown placeholder="Size" />
            <MultiSelectDropdown placeholder="Species" />
          </div>
          <div className="flex justify-end items-center mb-3.5">
            <Button
              onClick={handleActive}
              name="Cost / kg"
              color={active === 'Cost / kg' ? 'default' : 'grey'}
              className="mr-1.5"
            >
              Cost / kg
            </Button>
            <Button
              onClick={handleActive}
              name="Total Cost"
              color={active === 'Total Cost' ? 'default' : 'grey'}
            >
              Total Cost
            </Button>
          </div>
          {active === 'Cost / kg' && (
            <div className="flex justify-end">
              <button
                onClick={() => setHeight((highest + 1) % 3)}
                className="flex items-center focus:outline-none"
              >
                <span
                  style={{ marginTop: 2 }}
                  className="text-caption-1 font-bold text-blue"
                >
                  Highest
                </span>
                {(highest === 0 || highest === 1) && (
                  <IconArrowBreadcrumb
                    height={18}
                    width={18}
                    className="transform rotate-180 ic-blue"
                  />
                )}
                {(highest === 0 || highest === 2) && (
                  <IconArrowBreadcrumb
                    height={18}
                    width={18}
                    className={classNames('ic-blue', {
                      '-ml-2': highest === 0,
                    })}
                  />
                )}
              </button>
            </div>
          )}
          <h4 className="text-footnote font-bold mb-8">
            {active.split('-').join(' ')}
          </h4>
          {reRender && (
            <>
              {active === 'Cost / kg' && (
                <div>
                  <HighchartsReact
                    options={CostperKgOptions}
                    highcharts={Highcharts}
                  />
                  <p className="text-caption-1 text-grey font-bold my-4">
                    Overall Cost / kg:{' '}
                    <span className="text-blue-3">500kg/hour</span>
                  </p>
                </div>
              )}

              {active === 'Total Cost' && (
                <div>
                  <div className="grid grid-cols-3 items-center">
                    <div className="col-span-2">
                      <HighchartsReact
                        options={TotalCostOptions}
                        highcharts={Highcharts}
                      />
                    </div>
                    <div>
                      <ChartLegend
                        title="4 kg"
                        percentage="50"
                        className="mb-4"
                        color={ColdStorageColorMapping[1]}
                      />
                      <ChartLegend
                        title="4 kg"
                        percentage="50"
                        color={ColdStorageColorMapping[2]}
                      />
                    </div>
                  </div>
                  <div className="flex items-center flex-wrap pl-10 mt-3">
                    <ChartLegend
                      className="m-3"
                      color={ColdStorageColorMapping[1]}
                      title="Product 1"
                    />
                    <ChartLegend
                      className="m-3"
                      color={ColdStorageColorMapping[2]}
                      title="Product 2"
                    />
                  </div>
                  <p className="text-caption-1 text-grey font-bold my-4">
                    Total Material Cost:{' '}
                    <span className="text-blue-3">500kg/hour</span>
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

export default SortingPeeledUnitMaterialCostOfProduct;
