import { IconExcel } from 'assets';
import classNames from 'classnames';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  DatePicker,
  MultiSelectDropdown,
  Switch,
  TableGrid,
} from 'components';
import dayjs from 'dayjs';
import React, { useState } from 'react';

const ManagementStockQuantityPage = () => {
  const [date, setDate] = useState(new Date());
  const [realtime, setRealtime] = useState<boolean>(false);
  const [active, setActive] = useState<
    'global' | 'tarakan' | 'balikpapan' | 'surabaya'
  >('global');

  const handleChangeRealtime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    setRealtime(checked);
  };

  return (
    <div style={{ minWidth: 1200 }}>
      <h1 className="text-heading font-bold mb-3">Stock Quantity</h1>
      <Breadcrumb className="mb-5">
        <BreadcrumbItem href="/management/stock">Stock</BreadcrumbItem>

        <BreadcrumbItem isActive>Stock Quatity</BreadcrumbItem>
      </Breadcrumb>
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <Switch
            onChange={handleChangeRealtime}
            checked={realtime}
            withoutMessage
            className="mr-3"
          />
          <span className="text-caption-1 font-semibold text-grey">
            Realtime
          </span>
        </div>
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <div className="grid grid-cols-5 gap-x-2">
            <DatePicker
              disabled={realtime}
              selected={date}
              onChange={(date: Date) => setDate(date)}
              dateFormat="E, d MMM yyyy"
            />
            <MultiSelectDropdown placeholder="Product" />
            <MultiSelectDropdown placeholder="Species" />
            <MultiSelectDropdown placeholder="Brand" />
            <MultiSelectDropdown placeholder="Size" />
          </div>
        </div>
      </div>
      <Card
        className="grid grid-cols-2 items-center justify-center h-20 mb-3"
        color="yellow"
      >
        <div className="font-bold text-center">
          <div className="text-black mb-2">Total MC :</div>
          47.538
        </div>
        <div className="font-bold text-center">
          <div className="text-black mb-2">Total kg :</div>
          47.538
        </div>
      </Card>
      <div className="grid grid-cols-3 gap-x-3 mb-12">
        <Card className="font-bold py-5">
          <div className="text-black text-center mb-3">Tarakan Stock</div>
          <div className="text-center mb-1">Total MC : 2.900</div>
          <div className="text-center">Total kg : 123.000</div>
        </Card>
        <Card color="purple" className="font-bold py-5">
          <div className="text-black text-center mb-3">Balikpapan Stock</div>
          <div className="text-center mb-1">Total MC : 2.900</div>
          <div className="text-center">Total kg : 123.000</div>
        </Card>
        <Card color="blue" className="font-bold py-5">
          <div className="text-black text-center mb-3">Surabaya Stock</div>
          <div className="text-center mb-1">Total MC : 2.900</div>
          <div className="text-center">Total kg : 123.000</div>
        </Card>
      </div>
      <div
        style={{ width: 800 }}
        className="grid grid-cols-4 gap-x-2 grid-flow-col ml-auto"
      >
        <Button
          onClick={() => setActive('global')}
          color={active === 'global' ? 'default' : 'grey'}
        >
          Global
        </Button>
        <Button
          onClick={() => setActive('tarakan')}
          color={active === 'tarakan' ? 'default' : 'grey'}
        >
          Tarakan
        </Button>
        <Button
          onClick={() => setActive('balikpapan')}
          color={active === 'balikpapan' ? 'default' : 'grey'}
        >
          Balikpapan
        </Button>
        <Button
          onClick={() => setActive('surabaya')}
          color={active === 'surabaya' ? 'default' : 'grey'}
        >
          Surabaya
        </Button>
      </div>
      {active === 'global' && (
        <Report date={date} queryKey="stock-quantity-global" />
      )}
      {active === 'balikpapan' && (
        <Report date={date} queryKey="stock-quantity-balikpapan" />
      )}
      {active === 'tarakan' && (
        <Report date={date} queryKey="stock-quantity-tarakan" />
      )}
      {active === 'surabaya' && (
        <Report date={date} queryKey="stock-quantity-surabaya" />
      )}
    </div>
  );
};

export default ManagementStockQuantityPage;

interface CardProps {
  className?: string;
  color?: 'green' | 'purple' | 'blue' | 'yellow';
}

const Card: React.FC<CardProps> = ({
  color = 'green',
  className,
  children,
}) => {
  let background = '';
  if (color === 'green') background = '#cddbc2';
  if (color === 'purple') background = '#E9E0E6';
  if (color === 'blue') background = '#DCE7E8';

  return (
    <div
      style={{ background }}
      className={classNames(
        'rounded-lg border-2 text-caption-1',
        `text-colorful-${color}`,
        className,
        {
          'border-colorful-green': color === 'green',
          'border-colorful-yellow bg-colorful-yellow bg-opacity-10':
            color === 'yellow',
          'border-colorful-purple': color === 'purple',
          'border-colorful-blue': color === 'blue',
        }
      )}
    >
      {children}
    </div>
  );
};

interface ReportProps {
  queryKey?: string;
  date?: Date;
}

const getLastWord = (string: string, separator?: string) => {
  const strings = string.split(separator ?? ' ');

  return strings[strings.length - 1];
};

const Report: React.FC<ReportProps> = ({ queryKey, date }) => {
  return (
    <div className="border border-grey-1 rounded-lg py-5 px-8 overflow-auto">
      <div className="flex items-center text-blue text-caption-1 font-bold">
        <span className="mr-2">Downloads :</span>
        <button>
          <IconExcel />
        </button>
      </div>
      <div className="text-center font-bold underline uppercase">
        LAPORAN STOCK {dayjs(date).format('DD MMMM YYYY')}{' '}
        {getLastWord(queryKey ?? '', '-')}
      </div>
      <div className="flex flex-col items-start mt-4 text-caption-1 mb-6">
        <div
          style={{ gridTemplateColumns: '72px 1fr', width: 320 }}
          className="grid mb-3 mx-auto"
        >
          <div className="font-bold">Product</div>
          <div>: Headless PDTO</div>
        </div>

        <div
          style={{ gridTemplateColumns: '72px 1fr', width: 320 }}
          className="grid mb-3 mx-auto"
        >
          <div className="font-bold">Species</div>
          <div>: All Species</div>
        </div>

        <div
          style={{ gridTemplateColumns: '72px 1fr', width: 320 }}
          className="grid mb-3 mx-auto"
        >
          <div className="font-bold">Brand</div>
          <div>: All Brand</div>
        </div>

        <div
          style={{ gridTemplateColumns: '72px 1fr', width: 320 }}
          className="grid mb-6 mx-auto"
        >
          <div className="font-bold">Size</div>
          <div>: All Size</div>
        </div>
        <div
          style={{ gridTemplateColumns: '72px 1fr', width: 320 }}
          className="grid mb-3 mx-auto"
        >
          <div className="font-bold">Total MC</div>
          <div>: 1771</div>
        </div>
        <div
          style={{ gridTemplateColumns: '72px 1fr', width: 320 }}
          className="grid mx-auto"
        >
          <div className="font-bold">Total kg</div>
          <div>: 17.500.810</div>
        </div>
      </div>
      <div className="px-64">
        <TableGrid
          className="border border-grey-1 rounded-lg mb-10"
          config={{
            minWidth: '837px',
          }}
          columns={[
            {
              name: 'size',
              field: 'size',
              width: '2fr',
              Component: (
                <div className="grid grid-cols-2 grid-rows-4 font-bold text-caption-1 text-blue items-center">
                  <div className="row-span-4 justify-self-center">Size</div>
                  <div className="py-3 even:bg-white bg-white-snow px-2">
                    Product
                  </div>
                  <div className="py-3 even:bg-white bg-white-snow px-2">
                    Species
                  </div>
                  <div className="py-3 even:bg-white bg-white-snow px-2">
                    Brand
                  </div>
                  <div className="py-3 even:bg-white bg-white-snow px-2">
                    Weight/MC (kg)
                  </div>
                </div>
              ),
            },
            {
              name: 'headless',
              field: 'headless',
              width: '4fr',
              cols: ['headless1', 'headless2', 'headless3', 'headless4'],
              Component: (
                <div className="grid grid-cols-4 grid-rows-4 items-center">
                  <div className="col-span-4 text-center py-3">Headless</div>
                  <div className="py-3 px-2 bg-white-snow">BT 1st</div>
                  <div className="py-3 px-2 bg-white-snow">ST 1st</div>
                  <div className="py-3 px-2 bg-white-snow">BT 1st</div>
                  <div className="py-3 px-2 bg-white-snow">BT 2nd</div>
                  <div className="py-3 px-2">G. HILL</div>
                  <div className="py-3 px-2">G. HILL</div>
                  <div className="py-3 px-2">G. SC</div>
                  <div className="py-3 px-2">G. HILL</div>
                  <div className="py-3 px-2 bg-white-snow">10.8</div>
                  <div className="py-3 px-2 bg-white-snow">10.8</div>
                  <div className="py-3 px-2 bg-white-snow">10.8</div>
                  <div className="py-3 px-2 bg-white-snow">10.8</div>
                </div>
              ),
            },
          ]}
          data={[
            {
              size: '4-6',
            },
            {
              size: '6-8',
            },
            {
              size: '8-12',
            },
            {
              size: '13-15',
            },
            {
              size: '16-20',
            },
          ]}
          onRenderField={(field, value, { key }) => {
            if (field === 'size')
              return (
                <div key={key} className="text-center font-bold">
                  {value}
                </div>
              );

            return (
              <div key={key} className="text-center">
                {value}
              </div>
            );
          }}
        />
        <TableGrid
          className="border border-grey-1 rounded-lg mb-10"
          config={{
            minWidth: '682px',
          }}
          columns={[
            {
              name: 'size',
              field: 'size',
              width: '2fr',
              Component: (
                <div className="grid grid-cols-2 grid-rows-4 font-bold text-caption-1 text-blue items-center">
                  <div className="row-span-4 justify-self-center">Size</div>
                  <div className="py-3 even:bg-white bg-white-snow px-2">
                    Product
                  </div>
                  <div className="py-3 even:bg-white bg-white-snow px-2">
                    Species
                  </div>
                  <div className="py-3 even:bg-white bg-white-snow px-2">
                    Brand
                  </div>
                  <div className="py-3 even:bg-white bg-white-snow px-2">
                    Weight/MC (kg)
                  </div>
                </div>
              ),
            },
            {
              name: 'headless',
              field: 'headless',
              width: '3fr',
              cols: ['headless1', 'headless2', 'headless3'],
              Component: (
                <div className="grid grid-cols-3 grid-rows-4 items-center">
                  <div className="col-span-3 text-center py-3">Headless</div>
                  <div className="py-3 px-2 bg-white-snow">W 1st</div>
                  <div className="py-3 px-2 bg-white-snow">Grey 1st</div>
                  <div className="py-3 px-2 bg-white-snow">W 2nd</div>
                  <div className="py-3 px-2">G. HILL</div>
                  <div className="py-3 px-2">G. HILL</div>
                  <div className="py-3 px-2">SC</div>
                  <div className="py-3 px-2 bg-white-snow">10.8</div>
                  <div className="py-3 px-2 bg-white-snow">10.8</div>
                  <div className="py-3 px-2 bg-white-snow">10.8</div>
                </div>
              ),
            },
          ]}
          data={[
            {
              size: '18-12',
            },
            {
              size: '13-15',
            },
            {
              size: '16 / LL / 36',
            },
            {
              size: '21 / L',
            },
            {
              size: '26 / M',
            },
          ]}
          onRenderField={(field, value, { key }) => {
            if (field === 'size')
              return (
                <div key={key} className="text-center font-bold">
                  {value}
                </div>
              );

            return (
              <div key={key} className="text-center">
                {value}
              </div>
            );
          }}
        />
        <div style={{ minWidth: 1400 }} className="pr-48">
          <TableGrid
            className="border border-grey-1 rounded-lg mb-10 mr-48"
            config={{
              minWidth: '1354px',
            }}
            columns={[
              {
                name: 'size',
                field: 'size',
                width: '2fr',
                Component: (
                  <div className="grid grid-cols-2 grid-rows-4 font-bold text-caption-1 text-blue items-center">
                    <div className="row-span-4 justify-self-center">Size</div>
                    <div className="py-3 even:bg-white bg-white-snow px-2">
                      Product
                    </div>
                    <div className="py-3 even:bg-white bg-white-snow px-2">
                      Species
                    </div>
                    <div className="py-3 even:bg-white bg-white-snow px-2">
                      Brand
                    </div>
                    <div className="py-3 even:bg-white bg-white-snow px-2">
                      Weight/MC (kg)
                    </div>
                  </div>
                ),
              },
              {
                name: 'headless',
                field: 'headless',
                width: '8fr',
                cols: [
                  'headless1',
                  'headless2',
                  'headless3',
                  'headless4',
                  'headless5',
                  'headless6',
                  'headless7',
                  'headless8',
                ],
                Component: (
                  <div className="grid grid-cols-8 grid-rows-4 items-center">
                    <div className="col-span-8 text-center py-3">
                      PDTO (T) IQF
                    </div>
                    <div className="py-3 px-2 bg-white-snow">BT</div>
                    <div className="py-3 px-2 bg-white-snow">BT</div>
                    <div className="py-3 px-2 bg-white-snow">BT</div>
                    <div className="py-3 px-2 bg-white-snow">BT</div>
                    <div className="py-3 px-2 bg-white-snow">BT</div>
                    <div className="py-3 px-2 bg-white-snow">BT</div>
                    <div className="py-3 px-2 bg-white-snow">BT</div>
                    <div className="py-3 px-2 bg-white-snow">BT</div>
                    <div className="py-3 px-2">PS</div>
                    <div className="py-3 px-2">PS</div>
                    <div className="py-3 px-2">PS</div>
                    <div className="py-3 px-2">CENSEA</div>
                    <div className="py-3 px-2">F. ONE</div>
                    <div className="py-3 px-2">F. ONE</div>
                    <div className="py-3 px-2">NEPT</div>
                    <div className="py-3 px-2">F. ONE</div>
                    <div className="py-3 px-2 bg-white-snow">9.08</div>
                    <div className="py-3 px-2 bg-white-snow">9.08</div>
                    <div className="py-3 px-2 bg-white-snow">9.08</div>
                    <div className="py-3 px-2 bg-white-snow">4.54</div>
                    <div className="py-3 px-2 bg-white-snow">4.54</div>
                    <div className="py-3 px-2 bg-white-snow">4.54</div>
                    <div className="py-3 px-2 bg-white-snow">9.08</div>
                    <div className="py-3 px-2 bg-white-snow">4.54</div>
                  </div>
                ),
              },
            ]}
            data={[
              {
                size: '18-12',
              },
              {
                size: '13-15',
              },
              {
                size: '16 / LL / 36',
              },
              {
                size: '21 / L',
              },
              {
                size: '26 / M',
              },
            ]}
            onRenderField={(field, value, { key }) => {
              if (field === 'size')
                return (
                  <div key={key} className="text-center font-bold">
                    {value}
                  </div>
                );

              return (
                <div key={key} className="text-center">
                  {value}
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};
