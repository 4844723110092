import { useState } from 'react';
import { IconAdd } from 'assets';
import {
  AddEditRole,
  AddEditRoleState,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Dialog,
  Loading,
  ProceedConfirmation,
  RoleManagementTable,
  TextField,
} from 'components';
import { IUser } from 'utils/types';
import { urlAPI } from 'utils/urlApi';
import { useUser } from 'utils/useUser';

const RoleManagementPage = () => {
  const [addRole, setAddRole] = useState<boolean>(false);
  const [state, setState] = useState<AddEditRoleState>();
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [dialog, setDialog] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  useUser((userFromLocal) => {
    setUser(userFromLocal);
  });

  const handleAddRole = (val: AddEditRoleState) => {
    setAddRole(false);
    setDialog(true);

    // Set state to be sent to API below here
    setState(val);
  };

  const handleProceedDialog = async () => {
    // Fetch API to add Role here
    setDialog(false);
    setLoading(true);
    const res = await fetch(urlAPI + '/user-role', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + user?.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(state),
    });
    setLoading(false);
    if (res.status === 200) {
      setConfirmation(true);

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  };

  const handleCancelDialog = () => {
    setDialog(false);
    setAddRole(true);
  };

  const handleCancelAddEditRole = () => {
    setAddRole(false);
    setState(undefined);
  };

  const handleCancelProceedDialog = () => {
    setConfirmation(false);
    setState(undefined);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setSearchQuery(value);
  };

  return (
    <>
      {addRole && (
        <AddEditRole
          onSubmit={handleAddRole}
          onCancel={handleCancelAddEditRole}
          state={state}
        />
      )}
      {dialog && (
        <Dialog
          onSubmit={handleProceedDialog}
          onCancel={handleCancelDialog}
          heading="Confirm New Employee"
          message={`Do you really want to add ${state?.id} Role?`}
          width={600}
        />
      )}
      {confirmation && (
        <ProceedConfirmation
          onClose={handleCancelProceedDialog}
          message={`The new Role ${state?.Role_name} has been added`}
        />
      )}
      {loading && <Loading />}
      <div className="user-management">
        <h1 className="text-heading font-bold mb-3">User Management</h1>
        <Breadcrumb className="mb-5">
          <BreadcrumbItem>Home</BreadcrumbItem>
          <BreadcrumbItem href="/user-management">
            User Management
          </BreadcrumbItem>
          <BreadcrumbItem isActive>Role Management</BreadcrumbItem>
        </Breadcrumb>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center">
            <h2 className="text-heading font-extrabold mr-8">Role List</h2>
            <TextField
              onChange={handleChange}
              value={searchQuery}
              placeholder="Cari nama employee"
              variant="search"
            />
          </div>
          <div className="flex items-center">
            <Button onClick={() => setAddRole(true)}>
              <IconAdd className="mr-2" />
              <span>Add New Role</span>
            </Button>
          </div>
        </div>
        <RoleManagementTable searchQuery={searchQuery} />
      </div>
    </>
  );
};

export default RoleManagementPage;
