import classNames from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState } from 'react';
import { IconClose, IconLaunch } from '../../../../assets';
import { ColdStorageColorMapping } from '../../../../colors';
import { Button } from 'components';
import { ChartLegend, MultiSelectDropdown } from 'components';

interface SoakingUnitProductionProps {
  className?: string;
}

const lineChartIncomingOutputOptions: Highcharts.Options = {
  title: {
    text: '',
  },
  xAxis: {
    type: 'datetime',
    title: {
      text: 'Hours',
    },
    dateTimeLabelFormats: {
      hour: '%H:%M',
    },
    gridLineWidth: 1,
  },
  yAxis: {
    gridLineWidth: 0,
    title: {
      text: null,
    },
    labels: {
      format: '{value} ton',
    },
  },
  series: [
    {
      type: 'spline',
      name: 'Incoming',
      data: [2, 3, 4, 5, 6, 34, 3, 2],
      marker: {
        enabled: false,
      },
      color: ColdStorageColorMapping[1],
    },
    {
      type: 'spline',
      name: 'Output',
      data: [1, 3, 9, 15, 2, 14, 9, 4],
      marker: {
        enabled: false,
      },
      color: ColdStorageColorMapping[2],
    },
  ],
  plotOptions: {
    line: {
      lineWidth: 4,
      states: {
        hover: {
          lineWidth: 5,
        },
      },
      pointInterval: 60 * 60 * 1000,
      marker: {
        enabled: false,
      },
    },
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    valueSuffix: ' Ton',
  },
};

const incomingDistributionOptions: Highcharts.Options = {
  title: {
    text: '',
  },
  yAxis: {
    gridLineWidth: 0,
    title: {
      text: 'kg',
    },
  },
  series: [
    {
      type: 'pie',
      data: [
        {
          name: 'White',
          y: 4,
          color: ColdStorageColorMapping[1],
          dataLabels: { enabled: false },
        },
        {
          name: 'Black Pink',
          y: 4,
          color: ColdStorageColorMapping[2],
          dataLabels: { enabled: false },
        },
      ],
      innerSize: '80%',
      size: '75%',
      name: 'Incoming Distribution',
    },
  ],
  legend: {
    enabled: false,
  },
  tooltip: {
    valueSuffix: ' kg',
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 60,
        },
      },
    ],
  },
};

type SoakingUnitProductionActive =
  | 'Line-Chart-Incoming--Output'
  | 'Incoming-Distribution'
  | 'Output-Distribution';

const SoakingUnitProduction: React.FC<SoakingUnitProductionProps> = ({
  className,
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [reRender, setReRender] = useState<boolean>(true);
  const [active, setActive] = useState<SoakingUnitProductionActive>(
    'Line-Chart-Incoming--Output'
  );

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
    // Hack the chart so it can fill entire screen during fullscreen state
    setReRender(false);
    setTimeout(() => {
      setReRender(true);
    }, 0);
  };

  const handleActive = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { name } = e.currentTarget;

    setActive(name as SoakingUnitProductionActive);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', className, {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
      })}
    >
      <button onClick={handleFullScreen} className="absolute right-5 top-5">
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">Production</h4>
        <div className="grid grid-cols-5 gap-x-2 mb-5 relative z-20 ml-4">
          <MultiSelectDropdown placeholder="Treatment" />
          <MultiSelectDropdown placeholder="Product" />
          <MultiSelectDropdown placeholder="Grade" />
          <MultiSelectDropdown placeholder="Size" />
          <MultiSelectDropdown placeholder="Species" />
        </div>
        <div className="flex justify-end items-center mb-3.5">
          <Button
            onClick={handleActive}
            name="Line-Chart-Incoming--Output"
            color={
              active === 'Line-Chart-Incoming--Output' ? 'default' : 'grey'
            }
            className="mr-1.5"
          >
            Accumulated Incoming-Output
          </Button>
          <Button
            onClick={handleActive}
            name="Incoming-Distribution"
            color={active === 'Incoming-Distribution' ? 'default' : 'grey'}
            className="mr-1.5"
          >
            Incoming Distribution
          </Button>
          <Button
            onClick={handleActive}
            name="Output-Distribution"
            color={active === 'Output-Distribution' ? 'default' : 'grey'}
          >
            Output Distribution
          </Button>
        </div>

        <h4 className="text-footnote font-bold mb-8">
          {active === 'Line-Chart-Incoming--Output'
            ? 'Line Chart Accumulated Incoming-Output'
            : active.split('-').join(' ')}
        </h4>
        {reRender && (
          <>
            {active === 'Line-Chart-Incoming--Output' && (
              <div>
                <HighchartsReact
                  options={lineChartIncomingOutputOptions}
                  highcharts={Highcharts}
                />
                <div className="flex items-center justify-center mt-4">
                  <ChartLegend
                    title="Incoming"
                    color={ColdStorageColorMapping[1]}
                    className="mr-8"
                  />
                  <ChartLegend
                    title="Output"
                    color={ColdStorageColorMapping[2]}
                  />
                </div>
              </div>
            )}
            {active === 'Incoming-Distribution' && (
              <div>
                <div
                  style={{
                    gridTemplateColumns:
                      'repeat(auto-fit, minmax(0, max-content))',
                  }}
                  className="grid gap-3"
                >
                  <div className="flex items-center text-caption-1 text-grey font-bold">
                    <input
                      name="incoming-distribution"
                      id="treatment"
                      type="radio"
                    />
                    <label className=" ml-2" htmlFor="treatment">
                      Treatment
                    </label>
                  </div>
                  <div className="flex items-center text-caption-1 text-grey font-bold">
                    <input
                      name="incoming-distribution"
                      id="species"
                      type="radio"
                    />
                    <label className=" ml-2" htmlFor="species">
                      Species
                    </label>
                  </div>
                  <div className="flex items-center text-caption-1 text-grey font-bold">
                    <input
                      name="incoming-distribution"
                      id="supplier"
                      type="radio"
                    />
                    <label className=" ml-2" htmlFor="supplier">
                      Supplier
                    </label>
                  </div>
                  <div className="flex items-center text-caption-1 text-grey font-bold">
                    <input
                      name="incoming-distribution"
                      id="product"
                      type="radio"
                    />
                    <label className=" ml-2" htmlFor="product">
                      Product
                    </label>
                  </div>
                  <div className="flex items-center text-caption-1 text-grey font-bold">
                    <input
                      name="incoming-distribution"
                      id="grade"
                      type="radio"
                    />
                    <label className=" ml-2" htmlFor="grade">
                      Grade
                    </label>
                  </div>
                </div>
                <div className="grid grid-cols-3 items-center">
                  <div className="col-span-2">
                    <HighchartsReact
                      options={incomingDistributionOptions}
                      highcharts={Highcharts}
                    />
                  </div>
                  <div>
                    <ChartLegend
                      title="4 kg"
                      percentage="50"
                      className="mb-4"
                      color={ColdStorageColorMapping[1]}
                    />
                    <ChartLegend
                      title="4 kg"
                      percentage="50"
                      color={ColdStorageColorMapping[2]}
                    />
                  </div>
                </div>
                <div className="flex items-center flex-wrap pl-10 mt-3">
                  <ChartLegend
                    className="m-3"
                    color={ColdStorageColorMapping[1]}
                    title="White"
                  />
                  <ChartLegend
                    className="m-3"
                    color={ColdStorageColorMapping[2]}
                    title="Black Pink"
                  />
                </div>
              </div>
            )}
            {active === 'Output-Distribution' && (
              <div>
                <div
                  style={{
                    gridTemplateColumns:
                      'repeat(auto-fit, minmax(0, max-content))',
                  }}
                  className="grid gap-3"
                >
                  <div className="flex items-center text-caption-1 text-grey font-bold">
                    <input
                      name="incoming-distribution"
                      id="treatment"
                      type="radio"
                    />
                    <label className=" ml-2" htmlFor="treatment">
                      Treatment
                    </label>
                  </div>
                  <div className="flex items-center text-caption-1 text-grey font-bold">
                    <input
                      name="incoming-distribution"
                      id="species"
                      type="radio"
                    />
                    <label className=" ml-2" htmlFor="species">
                      Species
                    </label>
                  </div>
                  <div className="flex items-center text-caption-1 text-grey font-bold">
                    <input
                      name="incoming-distribution"
                      id="supplier"
                      type="radio"
                    />
                    <label className=" ml-2" htmlFor="supplier">
                      Supplier
                    </label>
                  </div>
                  <div className="flex items-center text-caption-1 text-grey font-bold">
                    <input
                      name="incoming-distribution"
                      id="product"
                      type="radio"
                    />
                    <label className=" ml-2" htmlFor="product">
                      Product
                    </label>
                  </div>
                  <div className="flex items-center text-caption-1 text-grey font-bold">
                    <input
                      name="incoming-distribution"
                      id="grade"
                      type="radio"
                    />
                    <label className=" ml-2" htmlFor="grade">
                      Grade
                    </label>
                  </div>
                  <div className="flex items-center text-caption-1 text-grey font-bold">
                    <input
                      name="incoming-distribution"
                      id="next-unit"
                      type="radio"
                    />
                    <label className=" ml-2" htmlFor="next-unit">
                      Next Unit
                    </label>
                  </div>
                </div>
                <div className="grid grid-cols-3 items-center">
                  <div className="col-span-2">
                    <HighchartsReact
                      options={incomingDistributionOptions}
                      highcharts={Highcharts}
                    />
                  </div>
                  <div>
                    <ChartLegend
                      title="4 kg"
                      percentage="50"
                      className="mb-4"
                      color={ColdStorageColorMapping[1]}
                    />
                    <ChartLegend
                      title="4 kg"
                      percentage="50"
                      color={ColdStorageColorMapping[2]}
                    />
                  </div>
                </div>
                <div className="flex items-center flex-wrap pl-10 mt-3">
                  <ChartLegend
                    className="m-3"
                    color={ColdStorageColorMapping[1]}
                    title="White"
                  />
                  <ChartLegend
                    className="m-3"
                    color={ColdStorageColorMapping[2]}
                    title="Black Pink"
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SoakingUnitProduction;
