import classNames from 'classnames';
import { useHistory } from 'react-router';
import { IconArrowBack, LogoSKA } from 'assets';
import styles from './report-general.module.css';

const ReportPTOTable = [
  {
    label: 'Production Code',
  },
  {
    label: 'Production Date',
  },
  {
    label: 'Expiry Date',
  },
  {
    label: 'Species',
  },
  {
    label: 'Size',
  },
  {
    label: 'Frozen Weight',
  },
  {
    label: 'Defrosted Weight',
  },
  {
    label: 'Length',
    align: 'center',
    divided: true,
  },
  {
    label: '1',
    align: 'center',
    divided: true,
  },
  {
    label: '2',
    align: 'center',
    divided: true,
  },
  {
    label: '3',
    align: 'center',
    divided: true,
  },
  {
    label: 'Discoloration',
    align: 'center',
    divided: true,
  },
  {
    label: 'Broken Meat',
    align: 'center',
    divided: true,
  },
  {
    label: 'Dehydration',
    align: 'center',
    divided: true,
  },
  {
    label: 'Broken Tail',
    align: 'center',
    divided: true,
  },
  {
    label: 'Vein',
    align: 'center',
    divided: true,
  },
  {
    label: 'COMMENT',
    align: 'center',
    divided: true,
  },
];

const ReportPTO = () => {
  const history = useHistory();

  return (
    <div className="border border-grey-light rounded-lg px-9 py-6">
      <button
        onClick={() => history.goBack()}
        className="flex items-center focus:outline-none mb-12"
      >
        <IconArrowBack className="mr-2" />
        <div className="text-grey underline">Lab Inspection Unit Report</div>
      </button>
      <div className="pl-12">
        <div className="flex items-center justify-between mb-7">
          <img src={LogoSKA} alt="logo SKA" />
          <div className="text-caption-1">
            <div className="font-bold mb-1">PT Sumber Kalimantan Abadi</div>
            <div>
              Tanjung Pasir - Mamburungan <br />
              Kalimantan Utara, Tarakan, Indonesia
            </div>
          </div>
        </div>
        <div className="text-sub-heading font-bold text-center underline mb-2">
          INSPECTION REPORT
        </div>
        <div className="font-bold text-footnote text-center mb-5">
          PTO STRETCH
        </div>
        <div
          style={{ gridTemplateColumns: '85px 1fr', maxWidth: 420 }}
          className="grid items-center text-caption-1 mx-auto mb-3 text-black"
        >
          <div className="font-bold">Date:</div>
          <div className="border border-blue-light rounded-lg px-3 py-2">
            26 Januari 2020
          </div>
        </div>
        <div
          style={{ gridTemplateColumns: '85px 1fr', maxWidth: 420 }}
          className="grid items-center text-caption-1 mx-auto mb-3"
        >
          <div className="font-bold">R/M Code:</div>
          <div className="border border-blue-light rounded-lg px-3 py-2">
            Form 17
          </div>
        </div>
        <div className="border border-grey-light rounded-lg mt-6 text-caption-1 mb-8">
          <div className={styles['table-head-row']}>
            <div className={styles['table-head-cell']}>BRAND</div>
            <div className={styles['table-head-cell']}></div>
            <div className={styles['table-head-cell']}></div>
            <div className={styles['table-head-cell']}></div>
            <div className={styles['table-head-cell']}></div>
            <div className={styles['table-head-cell']}></div>
            <div className={styles['table-head-cell']}></div>
          </div>
          {ReportPTOTable.map(({ label, align = 'left', divided }, i) => {
            if (divided)
              return (
                <div key={`${label}-${i}`} className={styles['table-row']}>
                  <div
                    className={classNames(
                      styles['table-cell'],
                      `text-${align}`
                    )}
                  >
                    {label}
                  </div>
                  <div className="grid grid-cols-2 border-r border-grey-light last:border-r-0">
                    <div className={styles['table-cell']}>
                      {label === 'Length' && (
                        <div className="text-center">Cm</div>
                      )}
                    </div>
                    <div className={styles['table-cell']}>
                      {label === 'Length' && (
                        <div className="text-center">Gr</div>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 border-r border-grey-light last:border-r-0">
                    <div className={styles['table-cell']}>
                      {label === 'Length' && (
                        <div className="text-center">Cm</div>
                      )}
                    </div>
                    <div className={styles['table-cell']}>
                      {label === 'Length' && (
                        <div className="text-center">Gr</div>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 border-r border-grey-light last:border-r-0">
                    <div className={styles['table-cell']}>
                      {label === 'Length' && (
                        <div className="text-center">Cm</div>
                      )}
                    </div>
                    <div className={styles['table-cell']}>
                      {label === 'Length' && (
                        <div className="text-center">Gr</div>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 border-r border-grey-light last:border-r-0">
                    <div className={styles['table-cell']}>
                      {label === 'Length' && (
                        <div className="text-center">Cm</div>
                      )}
                    </div>
                    <div className={styles['table-cell']}>
                      {label === 'Length' && (
                        <div className="text-center">Gr</div>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 border-r border-grey-light last:border-r-0">
                    <div className={styles['table-cell']}>
                      {label === 'Length' && (
                        <div className="text-center">Cm</div>
                      )}
                    </div>
                    <div className={styles['table-cell']}>
                      {label === 'Length' && (
                        <div className="text-center">Gr</div>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 border-r border-grey-light last:border-r-0">
                    <div className={styles['table-cell']}>
                      {label === 'Length' && (
                        <div className="text-center">Cm</div>
                      )}
                    </div>
                    <div className={styles['table-cell']}>
                      {label === 'Length' && (
                        <div className="text-center">Gr</div>
                      )}
                    </div>
                  </div>
                </div>
              );

            return (
              <div key={`${label}-${i}`} className={styles['table-row']}>
                <div
                  className={classNames(styles['table-cell'], `text-${align}`)}
                >
                  {label}
                </div>
                <div className={styles['table-cell']}></div>
                <div className={styles['table-cell']}></div>
                <div className={styles['table-cell']}></div>
                <div className={styles['table-cell']}></div>
                <div className={styles['table-cell']}></div>
                <div className={styles['table-cell']}></div>
              </div>
            );
          })}
        </div>
        <div className="grid grid-cols-3 border-b border-grey-light text-caption-1 text-black pb-2 mb-2">
          <div className="col-span-2">Inspected by:</div>
          <div>(Signature) :</div>
        </div>
        <div className="grid grid-cols-3 border-b border-grey-light text-caption-1 text-black pb-2 mb-2">
          <div className="col-span-2">Reviewed by:</div>
          <div>(Signature) :</div>
        </div>
        <div className="grid grid-cols-3 border-b border-grey-light text-caption-1 text-black pb-2 mb-2">
          <div className="col-span-2">Date by:</div>
        </div>
        <div className="my-8 text-caption-1">
          <div className="text-right mb-3">Report by</div>
          <div className="text-right font-bold mb-1">Head of Machine</div>
          <div className="text-right font-bold">Budi Raharjo</div>
        </div>
      </div>
    </div>
  );
};

export default ReportPTO;
