import { forwardRef, useEffect, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  ListChildComponentProps,
  VariableSizeList as List,
  VariableSizeList,
} from 'react-window';
import {
  IconCheck,
  IconClose,
  IconPersonDisabled,
  IconSort,
} from '../../../assets';
import { IEmployee, IUser } from '../../../utils/types';
import { urlAPI } from '../../../utils/urlApi';
import { useUser } from '../../../utils/useUser';
import { LoadingAnimation } from 'components';
import { Loading } from 'components';
import { Dialog, ProceedConfirmation } from 'components';
import './index.css';

type RowHeights = {
  [key: string]: number;
};

interface ResetPassTableProps {
  searchQuery?: string;
}

const ResetPassTable: React.FC<ResetPassTableProps> = ({
  searchQuery = '',
}) => {
  const listRef = useRef<VariableSizeList>(null);
  const rowHeights = useRef<RowHeights>({});

  function setRowHeight(index: number, size: number) {
    listRef.current?.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  function getRowHeight(index: number) {
    const height = rowHeights.current[Number(index)] || 60;

    return height < 60 ? 60 : height;
  }

  // Row Element for home table
  const Row = forwardRef<any, ListChildComponentProps>(
    ({ data, index, style }, _) => {
      const rowRef = useRef<HTMLDivElement>(null);

      const { id, nama_employee, role, phone, status_reset_password } = data[
        index
      ] as IEmployee;

      useEffect(() => {
        if (rowRef.current) setRowHeight(index, rowRef.current.offsetHeight);
      }, [rowRef, index]);

      const handleAccept = () => {
        setState({ id });

        setDialogAccept(true);
      };

      const handleReject = () => {
        setState({ id });

        setReject(true);
      };

      return (
        <>
          <div style={style} className="reset-pass-table-row px-4">
            <div className="text-footnote text-black font-bold">
              {index + 1}
            </div>
            <div ref={rowRef} className="text-footnote text-black font-bold">
              <span className="inline-block">{id}</span>
            </div>
            <div className="text-footnote text-black font-bold">
              <span>{nama_employee}</span>
            </div>
            <div className="text-footnote text-black font-bold">
              <span>{role.Role_name}</span>
            </div>
            <div className="text-footnote text-black font-bold">
              <span>{phone}</span>
            </div>
            <div className="text-footnote">
              {status_reset_password === 0 ? (
                <span className="text-red"></span>
              ) : status_reset_password === 1 ? (
                <span className="text-colorful-yellow">PENDING</span>
              ) : (
                <span className="text-red">REJECTED</span>
              )}
            </div>
            {status_reset_password === 1 && (
              <div className="flex items-center justify-self-center">
                <button
                  onClick={handleAccept}
                  className="mr-4 border-0 bg-transparent "
                >
                  <IconCheck className="ic-green" height="24" width="24" />
                </button>
                <button
                  onClick={handleReject}
                  className=" border-0 bg-transparent "
                >
                  <IconClose className="ic-red" height="24" width="24" />
                </button>
              </div>
            )}
          </div>
        </>
      );
    }
  );

  // State for returned device management table element
  const [state, setState] = useState<{ id: number }>();
  const [dialogAccept, setDialogAccept] = useState<boolean>(false);
  const [reject, setReject] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [successDelete, setSuccessDelete] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>();
  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [loadingEmployees, setLoadingEmployees] = useState<boolean>(true);
  const [sortBy, setSortBy] = useState<string>('');
  const [isAscending, setAscending] = useState<boolean>(true);

  useUser(async (userFromLocal) => {
    setUser(userFromLocal);

    const res = await fetch(urlAPI + '/employee?limit=999999', {
      headers: {
        Authorization: 'Bearer ' + userFromLocal?.token,
      },
    });

    const data = await res.json();
    setLoadingEmployees(false);
    setEmployees(data.data || []);
  });

  useEffect(() => {
    if (user) {
      const fetchData = async function () {
        let s = '';
        let name = '';
        let asc = '';

        if (searchQuery.length > 0) name = `&name=${searchQuery}`;
        if (sortBy.length > 0) s = `&sortBy = ${sortBy}`;
        isAscending ? (asc = '&sort=ASC') : (asc = '&sort=DESC');

        const res = await fetch(
          urlAPI + `/employee?limit=999999${name + s + asc}`,
          {
            headers: {
              Authorization: 'Bearer ' + user?.token,
            },
          }
        );

        const data = await res.json();
        setEmployees(data.data || []);
      };

      fetchData();
    }
  }, [user, searchQuery, sortBy, isAscending]);

  const handleSort = (sort: string, asc: boolean) => {
    if (sort !== sortBy || sortBy.length === 0) {
      setAscending(true);
    } else {
      setAscending(asc);
    }

    setSortBy(sort);
  };

  const handleProceedAccept = async () => {
    setLoading(true);
    setDialogAccept(false);

    // fetch API to accept request password request here
    await fetch(urlAPI + '/auth/verif-reset-password', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + user?.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: state?.id,
        is_accept: 1,
      }),
    });

    setLoading(false);
    setSuccess(true);
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  const handleCancelDialog = () => {
    setDialogAccept(false);
  };

  const handlereject = async () => {
    setLoading(true);
    setReject(false);

    // fetch API to edit role here
    await fetch(urlAPI + '/auth/verif-reset-password', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + user?.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: state?.id,
        is_accept: 0,
      }),
    });

    setLoading(false);
    setSuccessDelete(true);

    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  return (
    <>
      {dialogAccept && (
        <Dialog
          onSubmit={handleProceedAccept}
          onCancel={handleCancelDialog}
          heading="Accept Reset Password Request"
          message={`Do you really want to accept employee ${state?.id} reset password request?`}
        />
      )}
      {reject && (
        <Dialog
          onSubmit={handlereject}
          onCancel={() => setReject(false)}
          heading="Reject Reset Password Request"
          message={`Are you sure you want to reject employee ${state?.id} reset password request?`}
          submitText="Reject for good"
          reverseButton
        />
      )}
      {loading && <Loading />}
      {success && (
        <ProceedConfirmation
          onClose={() => setSuccess(false)}
          message={`Employee ${state?.id} reset password request has been accepted`}
        />
      )}
      {successDelete && (
        <ProceedConfirmation
          onClose={() => setSuccessDelete(false)}
          Icon={(props) => (
            <IconPersonDisabled {...props} className="mb-6 ic-red" />
          )}
          message={`Employee ${state?.id} reset password request has been rejected`}
        />
      )}
      <div className="reset-pass-table bg-white rounded-lg px-8 py-10 border rounded-lg border-grey-light">
        <div className="reset-pass-table-row py-4 px-4">
          <div className="text-body text-grey font-bold flex items-center cursor-pointer">
            <span className="block mr-2">NO.</span>
          </div>
          <button
            onClick={() => handleSort('id', !isAscending)}
            className="text-body text-grey font-bold flex items-center cursor-pointer"
          >
            <span className="block mr-2">Employee ID</span>
            <IconSort />
          </button>
          <button
            onClick={() => handleSort('nama_employee', !isAscending)}
            className="text-body text-grey font-bold flex items-center cursor-pointer"
          >
            <span className="block mr-2">Employee Name</span>
            <IconSort />
          </button>
          <button
            onClick={() => handleSort('User_Role_id', !isAscending)}
            className="text-body text-grey font-bold flex items-center cursor-pointer"
          >
            <span className="block mr-2">User Role</span>
            <IconSort />
          </button>
          <button
            onClick={() => handleSort('phone', !isAscending)}
            className="text-body text-grey font-bold flex items-center cursor-pointer"
          >
            <span className="block mr-2">Phone</span>
            <IconSort />
          </button>
          <button
            onClick={() => handleSort('active_status', !isAscending)}
            className="text-body text-grey font-bold flex items-center cursor-pointer"
          >
            <span className="block mr-2">Status</span>
            <IconSort />
          </button>
          <div className="text-body text-grey font-bold flex items-center cursor-pointer justify-self-center">
            Action
          </div>
        </div>
        {loadingEmployees ? (
          <div className="flex justify-center">
            <LoadingAnimation size="md" className="mt-6" />
          </div>
        ) : (
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height - 80}
                itemData={employees}
                itemCount={employees.length}
                itemSize={getRowHeight}
                width={width + 5}
                ref={listRef}
                className="list"
              >
                {({ ...props }) => {
                  return <Row {...props} />;
                }}
              </List>
            )}
          </AutoSizer>
        )}
      </div>
    </>
  );
};

export default ResetPassTable;
