import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { IconArrowBreadcrumb } from 'assets';

interface SortThreeStateProps {
  onChange?: (state: number) => void;
  text?: string;
  value?: number;
}

const SortThreeState: React.FC<SortThreeStateProps> = ({
  onChange,
  text = 'Highest',
  value,
}) => {
  const [highest, setHighest] = useState<number>(0);

  useEffect(() => {
    if (value !== undefined) setHighest(value);
  }, [value]);

  const handleClick = () => {
    const val = (highest + 1) % 3;
    if (value === undefined) setHighest(val);
    if (onChange) onChange(val);
  };

  return (
    <button
      onClick={handleClick}
      className="flex items-center focus:outline-none"
    >
      <span
        style={{ marginTop: 2 }}
        className="text-caption-1 font-bold text-blue"
      >
        {text}
      </span>
      {(highest === 0 || highest === 1) && (
        <IconArrowBreadcrumb
          height={18}
          width={18}
          className="transform rotate-180 ic-blue"
        />
      )}
      {(highest === 0 || highest === 2) && (
        <IconArrowBreadcrumb
          height={18}
          width={18}
          className={classNames('ic-blue', { '-ml-2': highest === 0 })}
        />
      )}
    </button>
  );
};

export default SortThreeState;
