import classNames from 'classnames';
import dayjs from 'dayjs';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { IconClose, IconLaunch } from 'assets';
import { ColdStorageColorMapping } from 'colors';
import { MapAny } from 'utils/types';
import { urlAPIMechanic } from 'utils';
import { ChartLabel } from 'components';

interface ColdStorageDoorStatusProps {
  className?: string;
  mode?: 'default' | 'realtime';
  startDate?: string;
  endDate?: string;
}

type ColdStorageDoorStatusData = {
  id: number;
  nama_node: string;
  data: Array<{
    id: number;
    data3: number;
    createdAt: string;
  }>;
};

const ColdStorageDoorStatus: React.FC<ColdStorageDoorStatusProps> = ({
  className = '',
  startDate = dayjs().subtract(6, 'days').format('YYYY-MM-DD'),
  endDate = dayjs().format('YYYY-MM-DD'),
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [reRender, setReRender] = useState<boolean>(true);
  const [selected, setSelected] = useState<MapAny>({});
  const [immutableSeries, setImmutableSeries] = useState<
    Highcharts.SeriesLineOptions[]
  >([]);
  const [options, setOptions] = useState<Highcharts.Options>({
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Hours',
      },
      dateTimeLabelFormats: {
        hour: '%H:%M',
      },
    },
    yAxis: {
      gridLineDashStyle: 'LongDash',
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
      max: 2,
      min: 0,
      plotBands: [
        {
          from: 0,
          to: 1,
          color: 'rgba(255, 206, 206, 0.5)',
          label: {
            text: 'Open',
            y: -10,
          },
        },
        {
          from: 1,
          to: 2,
          color: 'transparent',
          label: {
            text: 'Close',
            y: -10,
          },
        },
      ],
    },
    tooltip: {
      formatter: function () {
        return `${dayjs(this.x - 7 * 3600 * 1000).format(
          'dddd, D MMM YYYY, hh:mm:ss'
        )}<br>
        ${this.series.name} - <b>${this.y === 1.5 ? 'Close' : 'Open'}</b>`;
      },
    },
    series: [],
    plotOptions: {
      line: {
        lineWidth: 4,
        states: {
          hover: {
            lineWidth: 5,
          },
        },
        pointInterval: 60 * 60 * 1000,
        marker: {
          enabled: false,
        },
      },
    },
    legend: {
      enabled: false,
    },
  });

  const { data } = useQuery<ColdStorageDoorStatusData[]>(
    ['coldStorageDoorStatus', startDate, endDate],
    () =>
      fetch(
        urlAPIMechanic +
          `/mechanic/cold-storage/dashboard/door-status?start=${startDate}&end=${endDate}`
      )
        .then((res) => res.json())
        .then((data) => data.data),
    {
      onSuccess: (data) => {
        let selected: MapAny = {};
        let series: Highcharts.SeriesLineOptions[] = data.map((val, i) => {
          selected[val.id] = true;
          const { data: d } = val;
          const colorIndex =
            (i % Object.keys(ColdStorageColorMapping).length) + 1;
          let seriesData: Highcharts.PointOptionsObject[] = [];

          let p1 = 0;
          let p2 = 1;

          while (p2 < d.length) {
            const yp1 = d[p1].data3;
            if (d[p1].data3 !== d[p2].data3) {
              const yp2 = d[p2].data3;
              seriesData.push({
                x: dayjs(d[p2].createdAt).valueOf(),
                y: yp1 ? yp1 - 0.5 : yp1 + 1.5,
              });
              seriesData.push({
                x: dayjs(d[p2].createdAt).valueOf(),
                y: yp2 ? yp2 - 0.5 : yp2 + 1.5,
              });
            } else {
              seriesData.push({
                x: dayjs(d[p2].createdAt).valueOf(),
                y: yp1 ? yp1 - 0.5 : yp1 + 1.5,
              });
            }

            p1++;
            p2++;
          }

          return {
            type: 'line',
            name: val.nama_node,
            id: val.id.toString(),
            data: seriesData,
            color: ColdStorageColorMapping[colorIndex],
          };
        });

        setSelected(selected);
        // deep copy
        setImmutableSeries(JSON.parse(JSON.stringify(series)));

        setOptions({
          ...options,
          series,
        });
      },
    }
  );

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
    // Hack the chart so it can fill entire screen during fullscreen state
    setReRender(false);
    setTimeout(() => {
      setReRender(true);
    }, 0);
  };

  const handleChangeLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;

    let newSelected = { ...selected, [name]: checked };

    setSelected(newSelected);

    let selectedKeys = Object.keys(selected).filter((k) => newSelected[k]);
    const series: Highcharts.SeriesLineOptions[] = JSON.parse(
      JSON.stringify(immutableSeries)
    );

    const newSeries = series.map((val) => {
      let condition = selectedKeys.some((k) => val.id === k);

      if (!condition) return { ...val, data: [] };

      return val;
    });

    setOptions({
      ...options,
      series: newSeries,
    });
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        [className]: className,
      })}
    >
      <button onClick={handleFullScreen} className="absolute right-5 top-5">
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">Door Status</h4>

        {reRender && (
          <HighchartsReact options={options} highcharts={Highcharts} />
        )}
        <div className="flex items-center flex-wrap pl-10 mt-3">
          {data?.map((val, i) => (
            <ChartLabel
              key={`cold-storage-door-status-label-${val.id}`}
              bgColor={
                ColdStorageColorMapping[
                  (i % Object.keys(ColdStorageColorMapping).length) + 1
                ]
              }
              title={val.nama_node}
              className="mx-1.5 my-2"
              name={val.id.toString()}
              checked={selected[val.id.toString()]}
              onChange={handleChangeLabel}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ColdStorageDoorStatus;
