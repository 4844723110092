import classNames from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState } from 'react';
import { IconClose, IconLaunch, IconArrowBreadcrumb } from '../../../../assets';
import { ContactFreezerColorMapping } from '../../../../colors';
import { Button, DropdownItem } from 'components';
import { Dropdown, MultiSelectDropdown } from 'components';

interface ReceivingUnitSupplierPerformanceProps {
  className?: string;
}

const sizeDifferenceOptions: Highcharts.Options = {
  title: {
    text: '',
  },
  chart: {
    type: 'column',
  },
  xAxis: {
    type: 'category',
    gridLineWidth: 1,
  },
  yAxis: {
    gridLineWidth: 0,
    title: {
      text: 'Avr Differences',
      rotation: -90,
    },
  },
  series: [
    {
      data: [
        {
          name: 'Supplier 1',
          color: ContactFreezerColorMapping[1],
          y: 8,
        },
        {
          name: 'Supplier 2',
          color: ContactFreezerColorMapping[2],
          y: 5,
        },
        {
          name: 'Supplier 3',
          color: ContactFreezerColorMapping[3],
          y: 7,
        },
        {
          name: 'Supplier 4',
          color: ContactFreezerColorMapping[4],
          y: 7,
        },
        {
          name: 'Supplier 5',
          color: ContactFreezerColorMapping[5],
          y: 7,
        },
        {
          name: 'Supplier 6',
          color: ContactFreezerColorMapping[6],
          y: 7,
        },
      ],
      name: 'Contact Freezer Temperature',
      type: 'column',
      borderRadius: 30,
    },
  ],
  legend: {
    enabled: false,
  },
};

const costOfMaterialsOptions: Highcharts.Options = {
  title: {
    text: '',
  },
  chart: {
    type: 'column',
  },
  xAxis: {
    type: 'category',
    gridLineWidth: 1,
  },
  yAxis: {
    gridLineWidth: 0,
    title: {
      text: 'IDR /kg',
      rotation: -90,
    },
  },
  series: [
    {
      data: [
        {
          name: 'Supplier 1',
          color: ContactFreezerColorMapping[1],
          y: 8,
        },
        {
          name: 'Supplier 2',
          color: ContactFreezerColorMapping[2],
          y: 5,
        },
        {
          name: 'Supplier 3',
          color: ContactFreezerColorMapping[3],
          y: 7,
        },
        {
          name: 'Supplier 4',
          color: ContactFreezerColorMapping[4],
          y: 7,
        },
        {
          name: 'Supplier 5',
          color: ContactFreezerColorMapping[5],
          y: 7,
        },
        {
          name: 'Supplier 6',
          color: ContactFreezerColorMapping[6],
          y: 7,
        },
      ],
      name: 'Contact Freezer Temperature',
      type: 'column',
      borderRadius: 30,
    },
  ],
  legend: {
    enabled: false,
  },
};

const sizeDistributionOptions: Highcharts.Options = {
  title: {
    text: '',
  },
  chart: {
    type: 'column',
  },
  xAxis: {
    type: 'category',
    gridLineWidth: 1,
    title: {
      text: 'Purchasing Size',
    },
  },
  yAxis: {
    gridLineWidth: 0,
    title: {
      text: 'IDR /kg',
      rotation: -90,
    },
  },
  series: [
    {
      data: [
        {
          name: '21-25',
          color: ContactFreezerColorMapping[1],
          y: 3,
        },
        {
          name: '26-30',
          color: ContactFreezerColorMapping[2],
          y: 5,
        },
        {
          name: '31-35',
          color: ContactFreezerColorMapping[3],
          y: 7,
        },
        {
          name: '36-40',
          color: ContactFreezerColorMapping[4],
          y: 5,
        },
        {
          name: '41-45',
          color: ContactFreezerColorMapping[5],
          y: 2,
        },
      ],
      name: 'Purchasing Size',
      type: 'column',
      borderRadius: 30,
    },
    {
      name: 'Size Distribution',
      type: 'spline',
      data: [
        {
          name: '21-25',
          y: 2.5,
        },
        {
          name: '26-30',
          y: 4.7,
        },
        {
          name: '31-35',
          y: 6.5,
        },
        {
          name: '36-40',
          y: 4,
        },
        {
          name: '41-45',
          y: 2,
        },
      ],
      color: '#ca0000',
      marker: {
        enabled: false,
      },
    },
  ],
  legend: {
    enabled: false,
  },
};

type ReceivingUnitSupplierPerformanceActive =
  | 'Size-Difference'
  | 'Size-Distribution'
  | 'Shrinkage'
  | 'Cost-of-Materials';

const ReceivingUnitSupplierPerformance: React.FC<ReceivingUnitSupplierPerformanceProps> =
  ({ className }) => {
    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const [reRender, setReRender] = useState<boolean>(true);
    const [highest, setHeight] = useState<number>(0);
    const [active, setActive] =
      useState<ReceivingUnitSupplierPerformanceActive>('Size-Difference');

    const handleFullScreen = () => {
      setFullScreen(!fullScreen);
      // Hack the chart so it can fill entire screen during fullscreen state
      setReRender(false);
      setTimeout(() => {
        setReRender(true);
      }, 0);
    };

    const handleActive = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      const { name } = e.currentTarget;

      setActive(name as ReceivingUnitSupplierPerformanceActive);
    };

    return (
      <div
        style={{ zIndex: fullScreen ? 1200 : 0 }}
        className={classNames('flex justify-center items-center', className, {
          relative: !fullScreen,
          'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        })}
      >
        <button onClick={handleFullScreen} className="absolute right-5 top-5">
          {!fullScreen ? <IconLaunch /> : <IconClose />}
        </button>
        <div className="w-full h-full border rounded-lg border-grey-light p-6">
          <h4 className="text-footnote font-bold mb-8">Supplier Peformance</h4>
          <div className="grid grid-cols-4 gap-x-2 mb-5 relative z-20">
            <div></div>
            <div></div>
            <MultiSelectDropdown placeholder="Species" />
            <MultiSelectDropdown
              disabled={active === 'Size-Distribution'}
              placeholder="Size"
            />
          </div>
          <div className="flex justify-end items-center mb-3.5">
            <Button
              onClick={handleActive}
              name="Size-Difference"
              color={active === 'Size-Difference' ? 'default' : 'grey'}
              className="mr-1.5"
            >
              Size Difference
            </Button>
            <Button
              onClick={handleActive}
              name="Size-Distribution"
              color={active === 'Size-Distribution' ? 'default' : 'grey'}
              className="mr-1.5"
            >
              Size Distribution
            </Button>
            <Button
              onClick={handleActive}
              name="Shrinkage"
              color={active === 'Shrinkage' ? 'default' : 'grey'}
              className="mr-1.5"
            >
              Shrinkage
            </Button>
            <Button
              onClick={handleActive}
              name="Cost-of-Materials"
              color={active === 'Cost-of-Materials' ? 'default' : 'grey'}
            >
              Cost of Materials
            </Button>
          </div>
          {(active === 'Cost-of-Materials' || active === 'Size-Difference') && (
            <div className="flex justify-end">
              <button
                onClick={() => setHeight((highest + 1) % 3)}
                className="flex items-center focus:outline-none"
              >
                <span
                  style={{ marginTop: 2 }}
                  className="text-caption-1 font-bold text-blue"
                >
                  Highest
                </span>
                {(highest === 0 || highest === 1) && (
                  <IconArrowBreadcrumb
                    height={18}
                    width={18}
                    className="transform rotate-180 ic-blue"
                  />
                )}
                {(highest === 0 || highest === 2) && (
                  <IconArrowBreadcrumb
                    height={18}
                    width={18}
                    className={classNames('ic-blue', {
                      '-ml-2': highest === 0,
                    })}
                  />
                )}
              </button>
            </div>
          )}
          <h4 className="text-footnote font-bold mb-8">
            {active.split('-').join(' ')}
          </h4>
          {reRender && (
            <>
              {active === 'Size-Difference' && (
                <div>
                  <HighchartsReact
                    options={sizeDifferenceOptions}
                    highcharts={Highcharts}
                  />
                  <div className="flex items-center flex-wrap pl-10 mt-3">
                    <p className="text-caption-1 font-bold text-grey">
                      Overall Average Size Difference:{' '}
                      <span className="text-blue-2">+3</span>
                    </p>
                  </div>
                </div>
              )}
              {active === 'Cost-of-Materials' && (
                <div>
                  <HighchartsReact
                    options={costOfMaterialsOptions}
                    highcharts={Highcharts}
                  />
                  <div className="flex items-center flex-wrap pl-10 mt-3">
                    <p className="text-caption-1 font-bold text-grey">
                      Overall Cost of Materials per kg:{' '}
                      <span className="text-blue-2">+3</span>
                    </p>
                  </div>
                </div>
              )}
              {active === 'Size-Distribution' && (
                <div>
                  <HighchartsReact
                    options={sizeDistributionOptions}
                    highcharts={Highcharts}
                  />
                  <div className="flex items-center flex-wrap pl-10 mt-3">
                    <p className="text-caption-1 font-bold text-grey">
                      Average Standard Deviation :{' '}
                      <span className="text-blue-2">+3</span>
                    </p>
                  </div>
                  <div className="flex justify-end mt-5">
                    <Dropdown
                      textSize="caption-1"
                      height={35}
                      top="3rem"
                      placeholder="Supplier 1"
                    >
                      <DropdownItem>Supplier 1</DropdownItem>
                    </Dropdown>
                  </div>
                </div>
              )}
              {active === 'Shrinkage' && (
                <div>
                  <div className="grid grid-cols-4 text-footnote text-grey font-bold mb-5">
                    <div>Supplier</div>
                    <div>Incoming</div>
                    <div>Output</div>
                    <div className="text-right">Shrinkage</div>
                  </div>
                  <div className="grid grid-cols-4 text-caption-1 font-bold py-2 border-b border-grey-1">
                    <div>CF 1</div>
                    <div>20 kg</div>
                    <div>20 kg</div>
                    <div className="text-right">-5%</div>
                  </div>
                  <div className="grid grid-cols-4 text-caption-1 font-bold py-2 border-b border-grey-1">
                    <div>CF 2</div>
                    <div>20 kg</div>
                    <div>20 kg</div>
                    <div className="text-right">-5%</div>
                  </div>
                  <div className="grid grid-cols-4 text-caption-1 font-bold py-2 border-b border-grey-1">
                    <div>CF 3</div>
                    <div>20 kg</div>
                    <div>20 kg</div>
                    <div className="text-right">-5%</div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

export default ReceivingUnitSupplierPerformance;
