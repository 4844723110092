import { forwardRef, useEffect, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  ListChildComponentProps,
  VariableSizeList as List,
  VariableSizeList,
} from 'react-window';
import { Dialog, ProceedConfirmation } from 'components';
import { IconClose, IconEdit, IconSort, IconTrash } from '../../../assets';
import { IUser } from '../../../utils/types';
import { urlAPI } from '../../../utils/urlApi';
import { useUser } from '../../../utils/useUser';
import { LoadingAnimation } from 'components';
import { Loading } from 'components';
import AddEditDevice, { AddEditDeviceState } from '../add-edit-device';
import './index.css';

type RowHeights = {
  [key: string]: number;
};

interface DeviceManagementTableProps {
  searchQuery?: string;
  filterQuery?: string;
}

const DeviceManagementTable: React.FC<DeviceManagementTableProps> = ({
  searchQuery = '',
  filterQuery = '',
}) => {
  const listRef = useRef<VariableSizeList>(null);
  const rowHeights = useRef<RowHeights>({});

  function setRowHeight(index: number, size: number) {
    listRef.current?.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  function getRowHeight(index: number) {
    const height = rowHeights.current[Number(index)] || 60;

    return height < 60 ? 60 : height;
  }

  // Row Element for home table
  const Row = forwardRef<any, ListChildComponentProps>(
    ({ data, index, style }, _) => {
      const rowRef = useRef<HTMLDivElement>(null);

      useEffect(() => {
        if (rowRef.current) setRowHeight(index, rowRef.current.offsetHeight);
      }, [rowRef, index]);

      let selectedState: AddEditDeviceState = {
        id: data[index].id,
        Device_Type_id: data[index].Device_Type_id,
        nama_node: data[index].nama_node,
        device_name: data[index].type.type_name,
      };

      const handleEditDevice = () => {
        setState(selectedState);
        setEditDevice(true);
      };

      const handleDeleteDevice = () => {
        setState(selectedState);
        setDeleteDevice(true);
      };

      return (
        <>
          <div style={style} className="device-management-table-row px-4">
            <div className="text-footnote text-black font-bold">
              {index + 1}
            </div>
            <div ref={rowRef} className="text-footnote text-black font-bold">
              <span className="inline-block">{data[index].id}</span>
            </div>
            <div className="text-footnote text-black font-bold">
              <span>{data[index].nama_node}</span>
            </div>
            <div className="text-footnote text-black font-bold">
              <span>{data[index].type.type_name}</span>
            </div>
            <div className="flex items-center justify-self-center">
              <button
                onClick={handleEditDevice}
                className="mr-4 border-0 bg-transparent "
              >
                <IconEdit height="24" width="24" />
              </button>
              <button
                onClick={handleDeleteDevice}
                className=" border-0 bg-transparent "
              >
                <IconClose height="24" width="24" />
              </button>
            </div>
          </div>
        </>
      );
    }
  );

  // State for returned device management table element
  const [state, setState] = useState<AddEditDeviceState>();
  const [editDevice, setEditDevice] = useState<boolean>(false);
  const [deleteDevice, setDeleteDevice] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [successDelete, setSuccessDelete] = useState<boolean>(false);
  const [devices, setDevices] = useState<AddEditDeviceState[]>([]);
  const [user, setUser] = useState<IUser>();
  const [loadingDevices, setLoadingDevices] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>('');
  const [isAscending, setAscending] = useState<boolean>(true);

  const attempt = useRef<number>(0);

  useUser((userFromLocal, err) => {
    if (err) console.log(err);
    else {
      setUser(userFromLocal);
      const fetchData = async function () {
        const res = await fetch(urlAPI + '/device?limit=999999', {
          headers: {
            Authorization: 'Bearer ' + userFromLocal?.token,
          },
        });

        const data = await res.json();
        setDevices(data.data || []);
        setLoadingDevices(false);
      };

      fetchData();
      attempt.current = 1;
    }
  });

  // Effect for handling filtering, search query and sort
  useEffect(() => {
    if (attempt.current > 0) {
      let s = '';
      let name = '';
      let asc = '';
      let type = '';

      if (sortBy.length > 0) s = `&sortBy=${sortBy}`;
      if (searchQuery?.length > 0) name = `&name=${searchQuery}`;
      if (filterQuery.length > 0) type = filterQuery;
      isAscending ? (asc = '&sort=ASC') : (asc = '&sort=DESC');

      const fetchData = async function () {
        const res = await fetch(
          urlAPI + `/device?limit=999999${s + asc + name + type}`,
          {
            headers: {
              Authorization: 'Bearer ' + user?.token,
            },
          }
        );

        const data = await res.json();
        setDevices(data.data || []);
      };

      fetchData();
    }
  }, [sortBy, isAscending, searchQuery, user, filterQuery]);

  const handleSort = (sort: string, asc: boolean) => {
    if (sort !== sortBy || sortBy.length === 0) {
      setAscending(true);
    } else {
      setAscending(asc);
    }

    setSortBy(sort);
  };

  const handleEditDevice = (val: AddEditDeviceState) => {
    setState(val);
    setEditDevice(false);
    setConfirm(true);
  };

  const handleCancelDialog = () => {
    setConfirm(false);
    setEditDevice(true);
  };

  const handleProceed = async () => {
    // fetch API to edit device here
    setLoading(true);
    const res = await fetch(urlAPI + `/device/${state?.id}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user?.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(state),
    });
    setLoading(false);
    if (res.status === 200) {
      setConfirm(false);
      setSuccess(true);

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else console.log('Error');
  };

  const handleDeleteDevice = async () => {
    // fetch API to delete here
    setLoading(true);
    const res = await fetch(urlAPI + `/device/${state?.id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
    });
    setLoading(false);
    if (res.status === 200) {
      setDeleteDevice(false);
      setSuccessDelete(true);

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else console.log('Error');
  };

  return (
    <>
      {editDevice && (
        <AddEditDevice
          type="edit"
          onCancel={() => setEditDevice(false)}
          state={state}
          onSubmit={handleEditDevice}
        />
      )}
      {confirm && (
        <Dialog
          onSubmit={handleProceed}
          onCancel={handleCancelDialog}
          heading="Update Device Information"
          message={`Do you really want to update the ${state?.id} information?`}
        />
      )}
      {success && (
        <ProceedConfirmation
          onClose={() => setSuccess(false)}
          message={`The ${state?.id} information has been changed`}
        />
      )}
      {deleteDevice && (
        <Dialog
          onSubmit={handleDeleteDevice}
          onCancel={() => setDeleteDevice(false)}
          heading="Delete Device"
          message={`Are you sure you want to delete this ${state?.id} device?`}
          submitText="Delete for good"
          reverseButton
        />
      )}
      {successDelete && (
        <ProceedConfirmation
          onClose={() => setSuccessDelete(false)}
          Icon={(props) => <IconTrash {...props} className="mb-6 ic-red" />}
          message={`The ${state?.id} information has been deleted`}
        />
      )}
      {loading && <Loading />}
      <div className="device-management-table bg-white rounded-lg px-8 py-10 border rounded-lg border-grey-light">
        <div className="device-management-table-row py-4 px-4">
          <div className="text-body text-grey font-bold flex items-center">
            <span className="block mr-2">NO.</span>
          </div>
          <button
            onClick={() => handleSort('id', !isAscending)}
            className="text-body text-grey font-bold flex items-center cursor-pointer"
          >
            <span className="block mr-2">Device ID</span>
            <IconSort />
          </button>
          <button
            onClick={() => handleSort('nama_node', !isAscending)}
            className="text-body text-grey font-bold flex items-center cursor-pointer"
          >
            <span className="block mr-2">Device Name</span>
            <IconSort />
          </button>
          <button
            onClick={() => handleSort('type_node', !isAscending)}
            className="text-body text-grey font-bold flex items-center cursor-pointer"
          >
            <span className="block mr-2">Device Type</span>
            <IconSort />
          </button>
          <div className="text-body text-grey font-bold flex items-center cursor-pointer justify-self-center">
            Action
          </div>
        </div>
        {loadingDevices ? (
          <div className="flex justify-center">
            <LoadingAnimation size="md" className="mt-6" />
          </div>
        ) : (
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height - 80}
                itemData={devices}
                itemCount={devices?.length || 0}
                itemSize={getRowHeight}
                width={width + 5}
                ref={listRef}
                className="list"
              >
                {({ ...props }) => {
                  return <Row {...props} />;
                }}
              </List>
            )}
          </AutoSizer>
        )}
      </div>
    </>
  );
};

export default DeviceManagementTable;
