import { TableGrid } from 'components';
// import { useState } from 'react';
import { useQuery } from 'react-query';
import { urlAPITablet } from 'utils';
import { TableAdapter, TableData, transformTableData } from 'utils/table-adapter';
// import useWindowDimensions from 'utils/useWindowDimension';

const ReceivingUnitProcessLogTable: React.FC<StringDate> = ({start_date, end_date}) => {

  // const { height: windowHeight} = useWindowDimensions();

    const cols: Array<string> = [
      'date',
      'dealNumber',
      'supplier',
      'productType',
      'species',
      'size',
      'keterangan',
      'noPeti',
      'timbangSize',
      'weight',
      'sizeSampling',
      'weightSampling'
    ];

    const transformWeight = (weight: any) => {
      return (weight !== '' ? `${weight} kg` : '-');
    }

    // const [finalData, setFinalData] = useState<ITableGridPackage>({
    //   tableGridData: {
    //     data: {},
    //     merge:[]
    //   },
    //   rowSize: 0
    // })

    // const [lengthItems, setLengthItems] = useState<number>(0);
    // const [fullTable, setFullTable] = useState<TableData>();

  
    const { data } = useQuery<ITableGridPackage>(['receiving-production-log', start_date, end_date], async () => {
      console.log('run');
      const result = await fetch(urlAPITablet + `/receiving-station/report/production/json?start_date=${start_date}&end_date=${end_date}`);
      const data = await result.json();
      console.log('raw data', data);
      const tableData: TableData = new TableData(data?.data);
      // setLengthItems(tableData.children.length);
      // setFullTable(tableData);
      // console.log('tableData', tableData);
      // const rowsInterval = Math.floor(windowHeight/24);
      // let [i, sum] = [0, 0];
      // while (i < tableData.children.length) {
      //   sum += tableData.children[i][0].children.length;
      //   i++;
      //   if (sum > rowsInterval) {
      //     break;
      //   }
      // }
      // const temp = tableData.children.filter((e, index) => index < i);
      // tableData.children =  temp;
      const tableAdapter = new TableAdapter(tableData, cols);
      const tableGridData = tableAdapter.getTableGridData();
      const dataInput = await transformTableData(tableGridData?.tableGridData?.data, 'weight', transformWeight);
      tableGridData.tableGridData.data = await dataInput;
      // console.log('row size', tableData.getRowSize());
      // console.log('table grid data', JSON.stringify(tableGridData));
      return tableGridData;
    });

    // const loadItems = () => {
    //   // define number of rows interval for 2 x window height (24px height for 1 row)
    //   const rowsInterval = Math.floor(2*windowHeight/24);

    // }

  
    return (
      <div  className="rounded-lg px-5 py-4 border border-grey-1 mb-9 max-w-none">
        <TableGrid
        // className="mx-5"
        // fixWidth="6vw"
          columns={[
            {
              Component: (
                <div
                  key="process-log-main-column"
                  style={{gridTemplateColumns: 'repeat(12, 1fr)'}}
                  className="grid grid-rows-2 items-center grid-flow-col"
                >
                  {
                    ['Date', 'Deal Number', 'Supplier', 'Product', 'Species', 'Size', 'Keterangan', 'Container', 'Timbang Size', 'Weight',
                  {
                    main: "Sampling Size",
                    sub: ["Size", "Weight"]
                  }
                  ]
                    .map((item, i) => (
                      typeof item === 'object' ?
                      (
                          <div key={i} className={`h-full row-span-6 col-span-${item.sub.length} border-r ${!i ? 'border-l' : ''}`}>
                          <div className={`row-span-3 col-span-${item.sub.length} border-b`}>{item.main}</div>
                          <div className={`row-span-3 col-span-${item.sub.length} grid grid-cols-${item.sub.length} grid-rows-1`}>
                              {
                                  item.sub.map((subitem, j) => (
                                      <div key={j} className={`row-span-3 col-span-1 ${j === item.sub.length-1 ? '' : 'border-r'}`}>{subitem}</div>
                                  ))
                              }
                          </div>
                          </div>
                      )
                      :
                      <div key={i} className={`table h-full row-span-6 col-span-1 border-r ${!i ? 'border-l' : ''}`}>
                          <span className='table-cell align-middle'>{item}</span>
                      </div>
                    ))
                  }
{/*             
                  <div className="row-span-6 col-span-1">Deal Number</div>
                  <div className="row-span-6 col-span-1">Supplier</div>
                  <div className="row-span-6 col-span-1">Product</div>
                  <div className="row-span-6 col-span-1">Species</div>
                  <div className="row-span-6 col-span-1">Size</div>
                  <div className="row-span-6 col-span-1">Keterangan</div>
                  <div className="row-span-6 col-span-1">Container</div>
                  <div className="row-span-6 col-span-1">Timbang Size</div>
                  <div className="row-span-6 col-span-1">Weight</div>
                  <div className="row-span-6 col-span-2">
                    <div className="row-span-3 col-span-2 border-b">Sampling Size</div>
                    <div className="row-span-3 col-span-2 grid grid-cols-2 grid-rows-1">
                      <div className="row-span-3 col-span-1 border-r">Size</div>
                      <div className="row-span-3 col-span-1">Weight</div>
                    </div>
                  </div> */}
                </div>
              ),
              cols: cols,
              width: '12fr',
              rows: data?.rowSize,
              merge: data?.tableGridData?.merge,
            },
          ]}
          data={[data?.tableGridData?.data || {}]}
        />
      </div>
    );
  };
  

export default ReceivingUnitProcessLogTable;
