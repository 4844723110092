import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import classNames from 'classnames';
import { useState } from 'react';
import { IconClose, IconLaunch } from 'assets';
import { ChartLabel, MachineConditionRealtime } from 'components';
import { ColdStorageColorMapping } from 'colors';

interface MachineConditionProps {
  className?: string;
  mode?: 'default' | 'realtime';
}

const MachineCondition: React.FC<MachineConditionProps> = ({
  className = '',
  mode = 'default',
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [reRender, setReRender] = useState<boolean>(true);

  const options: Highcharts.Options = {
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Hours',
      },
      dateTimeLabelFormats: {
        hour: '%H:%M',
      },
    },
    yAxis: {
      gridLineDashStyle: 'LongDash',
      title: {
        text: null,
      },
      max: 20,
      min: 0,
      plotBands: [
        {
          from: 0,
          to: 5,
          color: 'rgba(255, 206, 206, 0.5)',
          label: {
            text: 'Need Repair',
          },
        },
        {
          from: 5,
          to: 10,
          color: 'transparent',
          label: {
            text: 'Bad',
          },
        },
        {
          from: 10,
          to: 15,
          color: 'transparent',
          label: {
            text: 'Good',
          },
        },
        {
          from: 15,
          to: 20,
          color: 'transparent',
          label: {
            text: 'Very Good',
          },
        },
      ],
    },
    series: [
      {
        data: [
          { x: 10_000, y: 13 },
          { x: 70_000, y: 13 },
          { x: 70_000, y: 8 },
          { x: 80_000, y: 8 },
          { x: 80_000, y: 17 },
          { x: 150_000, y: 17 },
        ],
        name: 'Machine 1',
        color: ColdStorageColorMapping[1],
        type: 'line',
      },
      {
        data: [
          { x: 10_000, y: 11 },
          { x: 50_000, y: 11 },
          { x: 50_000, y: 12 },
          { x: 100_000, y: 12 },
          { x: 100_000, y: 15 },
          { x: 150_000, y: 15 },
        ],
        name: 'Machine 2',
        color: ColdStorageColorMapping[2],
        type: 'line',
      },
    ],
    plotOptions: {
      line: {
        lineWidth: 4,
        states: {
          hover: {
            lineWidth: 5,
          },
        },
        pointInterval: 60 * 60 * 1000,
        marker: {
          enabled: false,
        },
      },
    },
    legend: {
      enabled: false,
    },
  };

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
    // Hack the chart so it can fill entire screen during fullscreen state
    setReRender(false);
    setTimeout(() => {
      setReRender(true);
    }, 0);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        [className]: className,
      })}
    >
      <button onClick={handleFullScreen} className="absolute right-5 top-5">
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">Machine Condition</h4>
        {mode === 'realtime' ? (
          <>
            <MachineConditionRealtime
              className="mb-2.5"
              condition="good"
              title="Machine 001"
            />
            <MachineConditionRealtime
              className="mb-2.5"
              condition="poor"
              title="Machine 002"
            />
            <MachineConditionRealtime
              className="mb-2.5"
              condition="bad"
              title="Machine 003"
            />
            <MachineConditionRealtime
              condition="critical"
              title="Machine 004"
            />
          </>
        ) : (
          <>
            {reRender && (
              <HighchartsReact options={options} highcharts={Highcharts} />
            )}
            <div className="flex items-center flex-wrap pl-10 mt-3">
              <ChartLabel
                bgColor={ColdStorageColorMapping[1]}
                title="Machine 1"
                className="mx-1.5 my-2"
              />
              <ChartLabel
                bgColor={ColdStorageColorMapping[2]}
                title="Machine 2"
                className="mx-1.5 my-2"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MachineCondition;
