import { TableGrid } from 'components';

const PeelingUnitProcessLogTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">Detailed Data Output</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9 overflow-auto">
        <TableGrid
          config={{ minWidth: '2000px' }}
          columns={[
            {
              field: 'no',
              name: 'No.',
              width: '80px',
            },
            {
              field: 'product',
              name: 'Product',
            },
            {
              field: 'species',
              name: 'Species',
            },
            {
              field: 'size',
              name: 'Size',
            },
            {
              field: 'weight',
              name: 'Weight',
            },
            {
              field: 'containerID',
              name: 'Container ID',
            },
            {
              field: 'timestamp',
              name: 'Timestamp',
            },
            {
              field: 'input_containerID',
              name: 'Input Container ID',
            },
            {
              field: 'container_product',
              name: 'Product',
            },
            {
              field: 'container_species',
              name: 'Species',
            },
            {
              field: 'container_size',
              name: 'Size',
            },
            {
              field: 'container_hpp',
              name: 'HPP',
            },
            {
              field: 'container_weight',
              name: 'Weight',
            },
            {
              field: 'total_weight_in_output',
              name: 'Total Weight in Output',
            },
            {
              field: 'tally',
              name: 'Tally',
            },
          ]}
        />
      </div>
    </>
  );
};

export default PeelingUnitProcessLogTable;
