import { useEffect } from 'react';

export function useOutside(ref: any, refTrigger: any, callback: any) {
  return useEffect(() => {
    const handleOutside = (e: any) => {
      if (
        ref.current &&
        !ref.current.contains(e.target) &&
        !refTrigger.current.contains(e.target)
      ) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleOutside);

    return () => {
      document.removeEventListener('mousedown', handleOutside);
    };
  });
}

export function useClickOutside(ref: any, refTrigger: any, callback: any) {
  return useEffect(() => {
    const handleOutside = (e: any) => {
      if (ref && !ref?.contains(e.target) && !refTrigger?.contains(e.target)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleOutside);

    return () => {
      document.removeEventListener('mousedown', handleOutside);
    };
  });
}
