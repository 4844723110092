import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './index.css';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  indeterminate?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  indeterminate,
  ...otherProps
}) => {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (ref.current && indeterminate)
      // @ts-ignore
      ReactDOM.findDOMNode(ref.current).indeterminate = 'indeterminate';
    // @ts-ignore
    else ReactDOM.findDOMNode(ref.current).indeterminate = undefined;
  }, [indeterminate]);

  return (
    <label className="container">
      <input {...otherProps} ref={ref} type="checkbox" />
      <span className="checkmark"></span>
    </label>
  );
};

export default Checkbox;
