const OperatorFrozenTimeTable = () => {
  return (
    <div className="rounded-lg px-8 py-7 border border-grey-1">
      <div className="grid grid-cols-6 font-semibold text-caption-1 py-2.5 gap-2.5">
        <div>Name</div>
        <div>Machine 1</div>
        <div>Machine 2</div>
        <div>Machine 3</div>
        <div>Machine 4</div>
        <div>Machine 5</div>
      </div>
      {/* Put the children here later */}
      <div className="grid grid-cols-6 text-caption-1 py-2.5 mt-11 gap-2.5">
        <div className="font-semibold">Best Performing Operator</div>
        <div>Operator 1</div>
      </div>
      <div className="grid grid-cols-6 text-caption-1 py-2.5 gap-2.5">
        <div className="font-semibold">Best Performing Machine</div>
        <div>Machine 2</div>
      </div>
    </div>
  );
};

export default OperatorFrozenTimeTable;
