import classNames from 'classnames';
import { TableGrid } from 'components';
import dayjs from 'dayjs';

const ManagementLeavePermissionTable: React.FC<IBaseTable> = ({
  endDate,
  startDate,
}) => {
  return (
    <div className="rounded-lg px-8 py-7 border border-grey-1">
      <div className="text-caption-1 mb-4">
        {dayjs(startDate).format('dddd, DD MMM YYYY')} -{' '}
        {dayjs(endDate).format('dddd, DD MMM YYYY')}
      </div>

      <TableGrid
        columns={[
          {
            field: 'name',
            name: 'Name',
          },
          {
            field: 'employeeID',
            name: 'Employee ID',
          },
          {
            field: 'created_at',
            name: 'Created at',
          },
          {
            field: 'start_date',
            name: 'Start Date',
          },
          {
            field: 'end_date',
            name: 'End Date',
          },
          {
            field: 'status',
            name: 'Status',
          },
          {
            field: 'action',
            name: '',
          },
        ]}
        data={[
          {
            name: 'Ilham Perdana',
            employeeID: '29093123',
            created_at: '30/01/2021',
            start_date: '30/01/2021',
            end_date: '12/02/2021',
            status: 'Rejected',
          },
          {
            name: 'Ilham Perdana',
            employeeID: '29093123',
            created_at: '30/01/2021',
            start_date: '30/01/2021',
            end_date: '12/02/2021',
            status: 'Pending',
          },
          {
            name: 'Ilham Perdana',
            employeeID: '29093123',
            created_at: '30/01/2021',
            start_date: '30/01/2021',
            end_date: '12/02/2021',
            status: 'Approved',
          },
        ]}
        onRenderField={(field, _value, { className, key, data }) => {
          if (field === 'action') {
            if (data.status === 'Pending')
              return (
                <div
                  className={classNames(
                    className,
                    'grid auto-cols-auto gap-x-2 grid-flow-col items-center'
                  )}
                  key={key}
                >
                  <Button>Approve</Button>
                  <Button color="red">Reject</Button>
                </div>
              );
          }
        }}
      />
    </div>
  );
};

export default ManagementLeavePermissionTable;

type ButtonProps = {
  color?: 'blue' | 'red';
  onClick?: () => void;
};

const Button: React.FC<ButtonProps> = ({ children, color = 'blue' }) => {
  return (
    <button
      className={classNames(
        'border py-1.5 px-4 rounded-full focus:outline-none font-semibold',
        {
          'text-blue border-blue': color === 'blue',
          'text-red-error border-red-error': color === 'red',
        }
      )}
    >
      {children}
    </button>
  );
};
