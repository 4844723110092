import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { IconBug } from 'assets';

interface HomeUnitIconProps {
  to?: string;
  className?: string;
  Icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement & { title?: string }>
  >;
  title?: string;
  color?: string;
}

const HomeUnitIcon: React.FC<HomeUnitIconProps> = ({
  className = '',
  to = '/',
  title,
  Icon = () => <IconBug className="ic-white" height={48} width={48} />,
  color,
}) => {
  return (
    <div
      className={classNames('flex flex-col items-start', {
        [className]: className,
      })}
    >
      <Link to={to}>
        <div
          style={{ background: color }}
          className="flex justify-center items-center w-20 h-20 rounded-lg cursor-pointer mb-2.5"
        >
          <Icon />
        </div>
      </Link>
      <span className="text-caption-1 text-left">{title}</span>
    </div>
  );
};

export default HomeUnitIcon;
