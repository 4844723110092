const SoakingUnitProcesLogTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">Process Log</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9 overflow-auto">
        <div
          style={{
            gridTemplateColumns: '40px repeat(20, 1fr)',
            minWidth: 2000,
          }}
          className="grid font-semibold grid-rows-2 items-center text-caption-1 gap-y-2 gap-x-4 px-2 py-2.5"
        >
          <div className="row-span-2">No</div>
          <div className="row-span-2">Batch ID</div>
          <div className="row-span-2">Species</div>
          <div className="row-span-2">Product</div>
          <div className="row-span-2">Size</div>
          <div className="row-span-2">Grade</div>
          <div className="row-span-2">Marker</div>
          <div className="row-span-2">Treatment</div>
          <div className="row-span-2">Batch Start</div>
          <div className="row-span-2">Stir Start</div>
          <div className="row-span-2">Soak Start</div>
          <div className="row-span-2">Finish Soak</div>
          <div className="col-span-7 text-center border-b border-grey-placeholder pb-2">
            Input
          </div>
          <div className="col-span-2 text-center border-b border-grey-placeholder pb-2">
            Output
          </div>
          <div>Container ID</div>
          <div>Supplier</div>
          <div>Species</div>
          <div>Purchasing Size</div>
          <div>HPP</div>
          <div>Weight</div>
          <div>Total Weight</div>
          <div>Container ID</div>
          <div>Weight</div>
        </div>
        {/* Put the children here later */}
      </div>
      <h6 className="font-bold mb-2">Detailed Soaking Material Usage</h6>
      <div
        style={{ width: 600 }}
        className="rounded-lg px-8 py-7 border border-grey-1 mb-9"
      >
        <div
          style={{ gridTemplateColumns: '40px repeat(3, 1fr)' }}
          className="grid font-semibold text-caption-1 gap-y-2 gap-x-4 px-2 py-2.5"
        >
          <div>No</div>
          <div>Material</div>
          <div>Quantity</div>
          <div>Time</div>
        </div>
        {/* Put the children here later */}
      </div>
    </>
  );
};

export default SoakingUnitProcesLogTable;
