const LayeringUnitPerformanceTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">Workers Performance</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <div
          style={{ gridTemplateColumns: '40px repeat(6, 1fr)' }}
          className="grid items-center font-semibold text-caption-1 gap-y-2 gap-x-4 px-2 py-2.5"
        >
          <div>No.</div>
          <div>Name</div>
          <div>Workers ID</div>
          <div>Working Time</div>
          <div>Headcount Performance</div>
          <div>Cost Labor</div>
          <div>Shrinkage</div>
        </div>
        {/* Put the children here later */}
      </div>
      <h6 className="font-bold mb-2">
        Unit Performance by Product, Grade, Size, and Species
      </h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <div
          style={{ gridTemplateColumns: '40px repeat(8, 1fr)' }}
          className="grid items-center font-semibold text-caption-1 gap-y-2 gap-x-4 px-2 py-2.5"
        >
          <div>No.</div>
          <div>Product</div>
          <div>Grade</div>
          <div>Size</div>
          <div>Species</div>
          <div>Working Time</div>
          <div>Productivity Rate</div>
          <div>Cost Labor</div>
          <div>Shrinkage</div>
        </div>
        {/* Put the children here later */}
      </div>

      <div className="flex items-start mb-8">
        <div className="w-96 mr-20">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Headcount</div>
            <div>8 People</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Working Time</div>
            <div>50 Man-Hours</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Time</div>
            <div>50 Hours</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Product</div>
            <div>2 Products</div>
          </div>
        </div>
        <div className="w-96">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Unit Productivity Rate</div>
            <div>50kg/Man-Hours</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total MCO</div>
            <div>200 kg</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Unit Productivity</div>
            <div>500kg/Hour</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Total Reject</div>
            <div>2 kg</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Total Shrinkage</div>
            <div>-10%</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LayeringUnitPerformanceTable;
