const OperatorPerformanceTable = () => {
  return (
    <div className="rounded-lg px-8 py-7 border border-grey-1">
      <div className="grid grid-cols-6 font-semibold text-caption-1 py-2.5 gap-2.5">
        <div>Name</div>
        <div>Avg Frozen Time</div>
        <div>Total Working Time</div>
        <div>Total Batch CF handled</div>
        <div>Total time CF handled</div>
        <div>Avg Electric Cost/ CF Batch</div>
      </div>
      {/* Put the children here later */}
      <div className="grid grid-cols-6 text-caption-1 py-2.5 mt-11 gap-2.5">
        <div className="font-semibold">Total Average Frozen Time</div>
        <div className="text-right">3 Hours</div>
      </div>
      <div className="grid grid-cols-6 text-caption-1 py-2.5 gap-2.5">
        <div className="font-semibold">Total Working Time</div>
        <div className="text-right">300 Hours</div>
      </div>
      <div className="grid grid-cols-6 text-caption-1 py-2.5 gap-2.5">
        <div className="font-semibold">Total Batch CF Handled</div>
        <div className="text-right">88 Times</div>
      </div>
      <div className="grid grid-cols-6 text-caption-1 py-2.5 gap-2.5">
        <div className="font-semibold">
          Total Avg Electricity Cost per CF batch
        </div>
        <div className="text-right">530.000 IDR</div>
      </div>
    </div>
  );
};

export default OperatorPerformanceTable;
