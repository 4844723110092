// export const urlAPI = 'http://36.94.223.75:10011/api';
export const urlAPI = 'http://trk.skasystem.com:9011/api';
// export const urlAPI = 'http://localhost:9011/api';
// export const urlAPIBPN = 'http://203.80.12.86:10011/api';
export const urlAPIBPN = 'http://203.80.12.86:9011/api';
export const urlAPIUnit = urlAPI + '/dashboard-unit';
export const urlAPIMechanic = urlAPI + '/dashboard-mechanic';
export const urlAPIManagement = urlAPI + '/dashboard-management';
export const urlAPIManagementBPN = urlAPIBPN + '/dashboard-management';
export const urlAPITablet = urlAPI + '/tablet';
export const urlAPITabletBPN = urlAPIBPN + '/tablet';
