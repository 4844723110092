import classNames from 'classnames';
import { useState } from 'react';
import { Breadcrumb, BreadcrumbItem, Button, DatePicker } from 'components';

const DailyReportApproval = () => {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <div style={{ minWidth: 1200 }}>
      <h1 className="text-heading font-bold mb-3">Daily Report Approval</h1>
      <Breadcrumb className="mb-5">
        <BreadcrumbItem href="/unit/">Home</BreadcrumbItem>
        <BreadcrumbItem isActive>Daily Report Approval</BreadcrumbItem>
      </Breadcrumb>
      <div className="flex items-center mb-5">
        <span className="text-caption-1 text-grey mr-3 font-semibold">
          Filter
        </span>
        <DatePicker
          selected={startDate}
          onChange={(date: Date) => setStartDate(date)}
          dateFormat="MMMM yyyy"
          containerClassName="mr-3.5"
          showMonthYearPicker
          showFullMonthYearPicker
        />
      </div>
      <div className="border border-grey-light rounded-lg px-5 py-6">
        <div className="grid grid-cols-4 gap-x-4 text-caption-1 font-bold p-3 items-center">
          <div>Time Received</div>
          <div>Unit</div>
          <div>Status</div>
          <div>Action</div>
        </div>
        <div>
          <div className="grid grid-cols-4 gap-x-4 text-caption-1 p-3 even:bg-white-snow items-center">
            <div>21 Januari 2021</div>
            <div>Receiving Unit</div>
            <div>
              <Status status="pending" />
            </div>
            <div>
              <Button color="grey">
                <span className="underline">View Detail</span>
              </Button>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-x-4 text-caption-1 p-3 even:bg-white-snow items-center">
            <div>21 Januari 2021</div>
            <div>Receiving Unit</div>
            <div>
              <Status status="approved" />
            </div>
            <div>
              <Button color="grey">
                <span className="underline">View Detail</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyReportApproval;

interface StatusProps {
  status?: 'pending' | 'approved';
}

const Status: React.FC<StatusProps> = ({ status = 'approved' }) => {
  return (
    <div
      className={classNames(
        'border bg-opacity-20 text-center rounded-full px-3 py-1.5 w-max ',
        {
          'border-colorful-green bg-colorful-green text-colorful-green':
            status === 'approved',
          'border-colorful-yellow bg-colorful-yellow text-colorful-yellow':
            status === 'pending',
        }
      )}
    >
      {status === 'approved' && 'Approved'}
      {status === 'pending' && 'Pending'}
    </div>
  );
};
