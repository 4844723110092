import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { AuthError } from '../redux/errors/error.thunk';
import { IUser } from './types';
import { urlAPI } from './urlApi';

export const useUser = (
  callback: (user: IUser | undefined, err: any) => void,
  onUnmounted?: () => void
) => {
  const cb = useRef((_user: IUser | undefined, _err: any) => {});
  const unMounted = useRef(() => {});
  const dispatch = useRef(useDispatch());
  dispatch.current = useDispatch();

  if (callback) cb.current = callback;
  if (onUnmounted) unMounted.current = onUnmounted;

  useEffect(() => {
    const item = localStorage.getItem('user');

    if (item) {
      const user: IUser = JSON.parse(item);
      const fetchData = async function () {
        try {
          const res = await fetch(urlAPI + '/auth/check-token', {
            headers: {
              Authorization: 'Bearer ' + user.token,
            },
          });

          if (res.status === 200) cb.current(user, undefined);
          else {
            dispatch.current(AuthError());
            cb.current(undefined, 'Not Logged in');
          }
        } catch (e) {
          console.log(e);
          alert('Internal Server Error');
        }
      };

      fetchData();
    } else {
      dispatch.current(AuthError());
      cb.current(undefined, 'Not Logged in');
    }
    if (unMounted.current) return unMounted.current;
  }, []);
};
