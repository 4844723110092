import { IconClose, IconLaunch } from 'assets';
import classNames from 'classnames';
import { MultiSelectDropdown, TableGrid } from 'components';
import { useState } from 'react';

interface ManagementSupplierAnalysisTableProps extends IBaseTable {
  className?: string;
}

const ManagementSupplierAnalysisTable: React.FC<ManagementSupplierAnalysisTableProps> =
  ({ className }) => {
    const [, reRender] = useState({});
    const [fullScreen, setFullScreen] = useState<boolean>(false);

    const handleFullScreen = () => {
      setFullScreen(!fullScreen);
      reRender({});
    };

    return (
      <div
        style={{ zIndex: fullScreen ? 1200 : 0 }}
        className={classNames('flex justify-center items-center', className, {
          relative: !fullScreen,
          'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        })}
      >
        <button onClick={handleFullScreen} className="absolute right-5 top-5">
          {!fullScreen ? <IconLaunch /> : <IconClose />}
        </button>
        <div className="w-full h-full border rounded-lg border-grey-light p-6">
          <h4 className="text-footnote font-bold mb-8">Supplier Analysis</h4>
          <div className="grid grid-cols-2 gap-x-2 w-80 ml-auto mb-6">
            <MultiSelectDropdown placeholder="Species"></MultiSelectDropdown>
            <MultiSelectDropdown placeholder="Size"></MultiSelectDropdown>
          </div>
          <TableGrid
            columns={[
              {
                field: 'supplier_name',
                name: 'Supplier Name',
              },
              {
                field: 'size_accuracy',
                name: 'Size Accuracy',
              },
              {
                field: 'yield',
                name: 'Yield',
              },
              {
                field: 'standard_deviation',
                name: 'Standard Deviation',
              },
              {
                field: 'material_cost',
                name: 'Material Cost',
              },
              {
                field: 'grade_distro',
                name: 'Grade Distribution',
                Component: (
                  <div key="grade-distro" className="grid grid-cols-4">
                    <div className="col-span-4 text-center mb-2">
                      Grade Distribution
                    </div>
                    <div>1st</div>
                    <div>2nd</div>
                    <div>3rd</div>
                    <div>broken</div>
                  </div>
                ),
                cols: ['1st', '2nd', '3rd', 'broken'],
                width: '4fr',
              },
            ]}
            data={[
              {
                supplier_name: 'Supplier 1',
                size_accuracy: '-5',
                yield: '3 %',
                standard_deviation: '4',
                material_cost: '75k IDR/kg',
                '1st': '35%',
                '2nd': '35%',
                '3rd': '15%',
                broken: '15%',
              },
              {
                supplier_name: 'Supplier 1',
                size_accuracy: '-5',
                yield: '3 %',
                standard_deviation: '4',
                material_cost: '75k IDR/kg',
                '1st': '35%',
                '2nd': '35%',
                '3rd': '15%',
                broken: '15%',
              },
              {
                supplier_name: 'Supplier 1',
                size_accuracy: '-5',
                yield: '3 %',
                standard_deviation: '4',
                material_cost: '75k IDR/kg',
                '1st': '35%',
                '2nd': '35%',
                '3rd': '15%',
                broken: '15%',
              },
              {
                supplier_name: 'Supplier 1',
                size_accuracy: '-5',
                yield: '3 %',
                standard_deviation: '4',
                material_cost: '75k IDR/kg',
                '1st': '35%',
                '2nd': '35%',
                '3rd': '15%',
                broken: '15%',
              },
            ]}
          />
        </div>
      </div>
    );
  };

export default ManagementSupplierAnalysisTable;
