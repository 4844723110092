import { IconApps, IconArrowBreadcrumb } from 'assets';
import classNames from 'classnames';
import { Breadcrumb, BreadcrumbItem, DatePicker } from 'components';
import React, { CSSProperties, forwardRef, useState, useCallback } from 'react';
import {
  Droppable,
  Draggable,
  DragDropContext,
  DropResult,
  DraggableLocation,
} from 'react-beautiful-dnd';
import { Collapse } from 'react-collapse';
import ReactDatePicker from 'react-datepicker';
import { usePopper } from 'react-popper';
import { useClickOutside } from 'utils/useOutside';

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (
  source: any[],
  destination: any[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: Record<string, any> = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const ProductPriorityPage = () => {
  const [backlog, setBacklog] = useState([{ id: '1asd' }, { id: '2fds' }]);
  const [priority, setPriority] = useState([{ id: 'adf' }, { id: 'dsx' }]);
  const [done, setDone] = useState([{ id: 'faf' }]);

  const getList = useCallback(
    (id: string) => {
      if (id === 'backlog') return backlog;
      if (id === 'priority') return priority;
      if (id === 'done') return done;

      return [];
    },
    [backlog, priority, done]
  );

  const setList = useCallback((id: string, items: any[]) => {
    if (id === 'backlog') setBacklog(items);
    if (id === 'priority') setPriority(items);
    if (id === 'done') setDone(items);
  }, []);

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination } = result;

      // dropped outside the list
      if (!destination) return;

      if (source.droppableId === destination.droppableId) {
        const items = reorder(
          getList(source.droppableId),
          source.index,
          destination.index
        );

        if (source.droppableId === 'backlog') setBacklog(items);
        else if (source.droppableId === 'priority') setPriority(items);
        else if (source.droppableId === 'done') setDone(items);
      } else {
        const result = move(
          getList(source.droppableId),
          getList(destination.droppableId),
          source,
          destination
        );

        Object.keys(result).forEach((key) => {
          setList(key, result[key]);
        });
      }
    },
    [getList, setList]
  );

  return (
    <div>
      <h1 className="text-heading font-bold mb-3">Product Priority</h1>
      <Breadcrumb className="mb-5">
        <BreadcrumbItem href="/management/">Home</BreadcrumbItem>
        <BreadcrumbItem isActive>Product Priority</BreadcrumbItem>
      </Breadcrumb>
      <div className="grid grid-cols-4 gap-3">
        <List color="blue" title="Finished Good Stacks">
          <div className="grid grid-cols-2 gap-3 mb-4">
            <DatePicker popperPlacement="bottom-start" onChange={() => {}} />
            <DatePicker popperPlacement="bottom-start" onChange={() => {}} />
          </div>
          <div className="text-caption-1 text-right mb-4">
            Friday, 25/01/2021 - Tuesday, 30/01/2021
          </div>
          <div className="grid gap-4">
            <CardStock />
            <CardStock />
          </div>
        </List>
        <DragDropContext onDragEnd={handleDragEnd}>
          <List color="yellow" title="Sales Order Backlog">
            <Droppable droppableId="backlog">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  className={classNames('grid gap-4 auto-rows-max h-full')}
                  style={{ minHeight: 225 }}
                >
                  {backlog.map(({ id }, idx) => (
                    <Draggable key={id} draggableId={id} index={idx}>
                      {(provided, snapshot) => {
                        return (
                          <Card
                            color="yellow"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={classNames({
                              'z-50': snapshot.isDragging,
                            })}
                            style={provided.draggableProps.style}
                            isDragging={snapshot.isDragging}
                          />
                        );
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </List>
          <List color="green" title="Sales Order Priority">
            <Droppable droppableId="priority">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  className={classNames('grid gap-4 auto-rows-max h-full')}
                  style={{ minHeight: 225 }}
                >
                  {priority.map(({ id }, idx) => (
                    <Draggable key={id} draggableId={id} index={idx}>
                      {(provided, snapshot) => {
                        return (
                          <Card
                            color="green"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={classNames({
                              'z-50': snapshot.isDragging,
                            })}
                            style={provided.draggableProps.style}
                            isDragging={snapshot.isDragging}
                          />
                        );
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </List>
          <List color="purple" title="Sales Order Done">
            <div className="grid grid-cols-2 gap-3 mb-4">
              <DatePicker popperPlacement="bottom-end" onChange={() => {}} />
              <DatePicker popperPlacement="bottom-end" onChange={() => {}} />
            </div>
            <div className="text-caption-1 text-right mb-4">
              Friday, 25/01/2021 - Tuesday, 30/01/2021
            </div>
            <Droppable droppableId="done">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  className={classNames('grid gap-4 auto-rows-max h-full')}
                  style={{ minHeight: 225 }}
                >
                  {done.map(({ id }, idx) => (
                    <Draggable key={id} draggableId={id} index={idx}>
                      {(provided, snapshot) => {
                        return (
                          <Card
                            color="purple"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={classNames({
                              'z-50': snapshot.isDragging,
                            })}
                            style={provided.draggableProps.style}
                            isDragging={snapshot.isDragging}
                          />
                        );
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </List>
        </DragDropContext>
      </div>
    </div>
  );
};

export default ProductPriorityPage;

interface ListProps {
  color?: 'blue' | 'yellow' | 'green' | 'purple';
  title?: string;
}

const List: React.FC<ListProps> = ({ children, color, title }) => {
  return (
    <div className="w-full border rounded-xl border-grey-light relative">
      <div
        className={classNames(
          `text-center py-5 text-white rounded-tl-xl rounded-tr-xl`,
          {
            [` bg-colorful-${color}`]: color !== 'blue',
            [`bg-${color}`]: color === 'blue',
          }
        )}
      >
        {title}
      </div>
      <div className="p-3">{children}</div>
    </div>
  );
};

interface CardProps {
  color?: 'blue' | 'yellow' | 'green' | 'purple';
  onDragStart?: React.DragEventHandler<any>;
  style?: CSSProperties;
  className?: string;
  isDragging?: boolean;
}

const Card = forwardRef<any, CardProps>(
  (
    { onDragStart, className, style, isDragging, color, ...otherProps },
    ref
  ) => {
    const [isCollapse, setCollapse] = useState<boolean>(false);

    return (
      <div
        ref={ref}
        {...otherProps}
        style={style}
        className={classNames('text-caption-1 shadow-md bg-white', className, {
          'relative w-full': !isDragging,
        })}
      >
        <button
          style={{
            cursor: isDragging ? 'grabbing' : 'grab',
          }}
          onDragStart={onDragStart}
          className="absolute right-2 top-3"
        >
          <IconApps />
        </button>
        <div
          className={classNames('w-full h-1', {
            [` bg-colorful-${color}`]: color !== 'blue',
            [`bg-${color}`]: color === 'blue',
          })}
        ></div>
        <div
          className={classNames('p-3', {
            [` text-colorful-${color}`]: color !== 'blue',
            [`text-${color}`]: color === 'blue',
          })}
        >
          <div className="grid grid-cols-3 font-bold">
            <div className="text-grey mb-2">Contact Number</div>
            <div className="col-span-2">: 089821237099</div>
          </div>
          <div className="grid grid-cols-3 font-bold">
            <div className="text-grey mb-2">Buyer</div>
            <div className="col-span-2">: Yakuza Cimahi</div>
          </div>
          <div className="grid grid-cols-3 font-bold">
            <div className="text-grey mb-2">Deadline Date</div>
            <div className="col-span-2">: 20 November 2021</div>
          </div>
          <div className="grid grid-cols-3 font-bold">
            <div className="text-grey mb-2">Product Items</div>
            <div className="col-span-2">: HL Treatment</div>
          </div>
          <Collapse
            initialStyle={{ height: '0px', overflow: 'hidden' }}
            isOpened={isCollapse}
          >
            <div className="font-bold mt-4">
              <div className="text-black mb-4">Detail Items</div>
              <div className="grid gap-4">
                <CardDetailItem />
              </div>
            </div>
          </Collapse>
          <div className="flex items-end justify-between">
            <button
              onClick={() => setCollapse(!isCollapse)}
              className="text-blue font-bold mt-2 flex items-center"
            >
              Expand{' '}
              <IconArrowBreadcrumb
                className={classNames('ic-blue transform', {
                  'rotate-90': !isCollapse,
                  '-rotate-90': isCollapse,
                })}
              />
            </button>
            {color === 'green' && (
              <div className="text-blue font-bold">Stock Sufficient</div>
            )}
            {color === 'purple' && (
              <div className="text-grey font-bold">Finished : 29/12/2020</div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

const CardDetailItem = () => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const [isDateOpen, setDateOpen] = useState<boolean>(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-start',
  });

  useClickOutside(referenceElement, popperElement, () => {
    setDateOpen(false);
  });

  const onChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <>
      {isDateOpen && (
        <div
          style={styles.popper}
          ref={setPopperElement}
          {...attributes.popper}
        >
          <ReactDatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
          />
        </div>
      )}
      <div className="border-b border-grey-light pb-3 last:border-0">
        <div className="grid grid-cols-3 font-bold">
          <div className="text-grey mb-2">Product</div>
          <div className="col-span-2">: HL Treatment</div>
        </div>
        <div className="grid grid-cols-3 font-bold text-grey">
          <div className="text-grey mb-2">Species</div>
          <div className="col-span-2">: White Tiger</div>
        </div>
        <div className="grid grid-cols-3 font-bold text-grey">
          <div className="text-grey mb-2">Size</div>
          <div className="col-span-2">: Large</div>
        </div>
        <div className="grid grid-cols-3 font-bold text-blue">
          <div className="text-grey mb-2">Production Date</div>
          <div className="col-span-2">
            :{' '}
            <button
              onClick={() => setDateOpen(!isDateOpen)}
              ref={setReferenceElement}
              className="underline font-bold"
            >
              Edit
            </button>
          </div>
        </div>
        <div className="grid grid-cols-3 font-bold text-grey">
          <div className="text-grey mb-2">Quantity</div>
          <div className="col-span-2">: 650 packs</div>
        </div>
      </div>
    </>
  );
};

const CardStock = () => {
  return (
    <div className="border border-blue rounded-xl p-3 text-caption-1 text-blue">
      <div className="grid grid-cols-3 font-bold">
        <div className="text-grey mb-2">Product</div>
        <div className="col-span-2">: HL Treatment</div>
      </div>
      <div className="grid grid-cols-3 font-bold ">
        <div className="text-grey mb-2">Species</div>
        <div className="col-span-2">: White Tiger</div>
      </div>
      <div className="grid grid-cols-3 font-bold ">
        <div className="text-grey mb-2">Size</div>
        <div className="col-span-2">: Large</div>
      </div>

      <div className="grid grid-cols-3 font-bold ">
        <div className="text-grey mb-2">Quantity</div>
        <div className="col-span-2">: 650 packs</div>
      </div>
      <div className="grid grid-cols-3 font-bold ">
        <div className="text-grey mb-2">Stock ID</div>
        <div className="col-span-2">: Tarakan, North Kalimantan</div>
      </div>
      <div className="text-right text-grey mt-1">Thu, 28 Jan 2021</div>
    </div>
  );
};
