import { TableGrid } from 'components';

const PeelingUnitReportSummaryTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">Incoming</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              field: 'no',
              name: 'No.',
              width: '40px',
            },
            {
              field: 'product',
              name: 'Product',
            },
            {
              field: 'species',
              name: 'Species',
            },
            {
              field: 'size',
              name: 'Size',
            },

            {
              field: 'weight',
              name: 'Weight',
            },
          ]}
          data={[
            {
              no: '1',
              product: 'Nobashi',
              species: 'Black Tiger',
              size: '21-25',
              weight: '200 kg',
            },
            {
              no: '2',
              product: 'Nobashi',
              species: 'Black Tiger',
              size: '21-25',
              weight: '200 kg',
            },
          ]}
        />
      </div>
      <h6 className="font-bold mb-2">Peeling and Soaking</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              field: 'no',
              name: 'No.',
              width: '40px',
            },
            {
              field: 'product',
              name: 'Product',
            },
            {
              field: 'species',
              name: 'Species',
            },
            {
              field: 'size',
              name: 'Size',
            },
            {
              name: 'Weight',
              Component: (
                <div
                  key="peeling-and-soaking-weight-column"
                  className="grid grid-cols-2 gap-y-4"
                >
                  <div className="text-center col-span-2 pb-2 border-b border-grey-placeholder">
                    Weight
                  </div>
                  <div className="text-center">Peeling</div>
                  <div className="text-center">Soaking</div>
                </div>
              ),
              cols: ['peeling', 'soaking'],
              width: '2fr',
            },
            {
              field: 'yield',
              name: 'Soaking Yield',
              Component: (
                <div key="peeling-soaking-yield-column" className="text-center">
                  Yield
                </div>
              ),
            },
          ]}
          data={[
            {
              no: '1',
              product: 'HL Treatment',
              grade: '1st',
              size: '4-8',
              species: 'Black Mamba',
              peeling: '150 kg',
              soaking: '150 kg',
              yield: '1%',
            },
            {
              no: '2',
              product: 'HL Treatment',
              grade: '2nd',
              size: '4-8',
              species: 'Black Pink',
              peeling: '150 kg',
              soaking: '150 kg',
              yield: '1%',
            },
          ]}
          onRenderField={(field, value, { key }) => {
            if (field === 'peeling' || field === 'soaking' || field === 'yield')
              return (
                <div className="text-center" key={key}>
                  {value}
                </div>
              );
          }}
        />
      </div>
      <h6 className="font-bold mb-2">Output</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              field: 'no',
              name: 'No.',
              width: '40px',
            },
            {
              field: 'product',
              name: 'Product',
            },
            {
              field: 'species',
              name: 'Species',
              width: '0.6fr',
            },
            {
              field: 'size',
              name: 'Size',
              width: '0.4fr',
            },
            {
              field: 'totalWeight',
              name: 'Total Weight',
            },
            {
              field: 'trayCount',
              name: 'Tray Count',
            },
          ]}
          data={[
            {
              no: '1',
              product: 'HL Treatment',
              species: 'Black Mamba',
              size: '4-8',
              totalWeight: '2 Ton',
              trayCount: '200',
            },
            {
              no: '2',
              product: 'HL Treatment',
              species: 'Black Mamba',
              size: '4-8',
              totalWeight: '2 Ton',
              trayCount: '200',
            },
          ]}
          onRenderField={(field, value, { key }) => {
            if (field.startsWith('quantity'))
              return (
                <div key={key} className="text-center">
                  {value}
                </div>
              );
          }}
        />
      </div>

      <h6 className="font-bold mb-2">Material Soaking Usage</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              field: 'no',
              name: 'No.',
              width: '40px',
            },
            {
              field: 'material',
              name: 'Material',
            },
            {
              field: 'quantity',
              name: 'Quantity',
            },
            {
              field: 'ghost',
            },
            {
              field: 'ghost1',
            },
          ]}
          data={[
            {
              no: '1',
              material: 'Mat A',
              quantity: '200 kg',
            },
            {
              no: '2',
              material: 'Mat A',
              quantity: '200 kg',
            },
          ]}
        />
      </div>

      <div className="flex items-start mb-8">
        <div className="w-96 mr-20">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Incoming</div>
            <div>5 ton</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Total After Peeled</div>
            <div>200 Kg</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Total Broken</div>
            <div>200 Kg</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Total Rejected</div>
            <div>200 Kg</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total After Soaking</div>
            <div>100 kg</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Output</div>
            <div>4.9 ton</div>
          </div>
        </div>
        <div className="w-96">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Soaking Yield</div>
            <div>-1 %</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Overall Yield</div>
            <div>-1 %</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Soaking Material</div>
            <div>2 Ton</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Broken Rate</div>
            <div>2 %</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Rejected Rate</div>
            <div>2 %</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Total Latest Pending</div>
            <div>200 Kg</div>
          </div>
        </div>
      </div>
      <div className="w-80">
        <div className="flex justify-between mb-3 text-caption-1">
          <div className="font-semibold">Total Tray Count</div>
          <div>200 Kg</div>
        </div>
      </div>
    </>
  );
};

export default PeelingUnitReportSummaryTable;
