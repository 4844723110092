import classNames from 'classnames';
// import './index.css';

interface RadioProps {
  className?: string;
  labelText?: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  checked?: boolean;
}

const Radio: React.FC<RadioProps> = ({
  className,
  labelText,
  ...otherProps
}) => {
  return (
    <label className={classNames('container', className)}>
      <span>{labelText}</span>
      <input {...otherProps} type="radio" />
      <span className="checkmark"></span>
    </label>
  );
};

export default Radio;
