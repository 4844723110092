import classNames from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState } from 'react';
import { IconArrowBreadcrumb, IconClose, IconLaunch } from '../../../../assets';
import { ContactFreezerColorMapping } from '../../../../colors';
import {
  Button,
  MultiSelectDropdownItem,
  MultiSelectDropdownSeparator,
} from 'components';
import { ChartLegend, MultiSelectDropdown } from 'components';

interface UnitProducitivityAnalysisProps {
  className?: string;
}

const UnitProductivityOptions: Highcharts.Options = {
  title: {
    text: '',
  },
  chart: {
    type: 'column',
  },
  xAxis: {
    gridLineWidth: 1,
    categories: ['CF 1', 'CF 2', 'CF 3', 'CF 4', 'CF 5'],
  },
  yAxis: [
    {
      gridLineWidth: 0,
      title: {
        text: 'Inner / hour',
        rotation: -90,
      },
    },
  ],
  series: [
    {
      data: [
        {
          color: ContactFreezerColorMapping[1],
          y: 3,
        },
        {
          color: ContactFreezerColorMapping[1],
          y: 2.5,
        },
        {
          color: ContactFreezerColorMapping[1],
          y: 4.5,
        },
        {
          color: ContactFreezerColorMapping[1],
          y: 3,
        },
        {
          color: ContactFreezerColorMapping[1],
          y: 2,
        },
      ],
      name: 'Machine Productivity',
      type: 'column',
    },
    {
      data: [
        {
          color: ContactFreezerColorMapping[2],
          y: 5,
        },
        {
          color: ContactFreezerColorMapping[2],
          y: 2.5,
        },
        {
          color: ContactFreezerColorMapping[2],
          y: 4.5,
        },
        {
          color: ContactFreezerColorMapping[2],
          y: 2,
        },
        {
          color: ContactFreezerColorMapping[2],
          y: 1,
        },
      ],
      name: 'Labor Productivity',
      type: 'column',
    },
    {
      data: [
        {
          color: ContactFreezerColorMapping[3],
          y: 8,
        },
        {
          color: ContactFreezerColorMapping[3],
          y: 5,
        },
        {
          color: ContactFreezerColorMapping[3],
          y: 9,
        },
        {
          color: ContactFreezerColorMapping[3],
          y: 5,
        },
        {
          color: ContactFreezerColorMapping[3],
          y: 3,
        },
      ],
      name: 'Overall Productivity',
      type: 'column',
    },
  ],
  legend: {
    enabled: false,
  },
};

const DurationPerBatchOptions: Highcharts.Options = {
  title: {
    text: '',
  },
  chart: {
    type: 'column',
  },
  xAxis: {
    gridLineWidth: 1,
    categories: ['CF 1', 'CF 2', 'CF 3', 'CF 4', 'CF 5'],
  },
  yAxis: [
    {
      gridLineWidth: 0,
      title: {
        text: 'hour',
        rotation: -90,
      },
    },
  ],
  series: [
    {
      data: [
        {
          color: ContactFreezerColorMapping[1],
          y: 3,
        },
        {
          color: ContactFreezerColorMapping[1],
          y: 2.5,
        },
        {
          color: ContactFreezerColorMapping[1],
          y: 4.5,
        },
        {
          color: ContactFreezerColorMapping[1],
          y: 3,
        },
        {
          color: ContactFreezerColorMapping[1],
          y: 2,
        },
      ],
      name: 'Machine Duration',
      type: 'column',
    },
    {
      data: [
        {
          color: ContactFreezerColorMapping[2],
          y: 5,
        },
        {
          color: ContactFreezerColorMapping[2],
          y: 2.5,
        },
        {
          color: ContactFreezerColorMapping[2],
          y: 4.5,
        },
        {
          color: ContactFreezerColorMapping[2],
          y: 2,
        },
        {
          color: ContactFreezerColorMapping[2],
          y: 1,
        },
      ],
      name: 'Labor Duration',
      type: 'column',
    },
  ],
  legend: {
    enabled: false,
  },
  plotOptions: {
    column: {
      stacking: 'normal',
    },
  },
  tooltip: {
    valueSuffix: ' Hours',
  },
};

const CapacityPerBatchOptions: Highcharts.Options = {
  title: {
    text: '',
  },
  chart: {
    type: 'column',
  },
  xAxis: {
    gridLineWidth: 1,
    categories: ['CF 1', 'CF 2', 'CF 3', 'CF 4', 'CF 5'],
  },
  yAxis: [
    {
      gridLineWidth: 0,
      title: {
        text: 'Inner',
        rotation: -90,
      },
    },
  ],
  series: [
    {
      data: [
        {
          color: ContactFreezerColorMapping[1],
          y: 3,
        },
        {
          color: ContactFreezerColorMapping[1],
          y: 2.5,
        },
        {
          color: ContactFreezerColorMapping[1],
          y: 4.5,
        },
        {
          color: ContactFreezerColorMapping[1],
          y: 3,
        },
        {
          color: ContactFreezerColorMapping[1],
          y: 2,
        },
      ],
      name: 'Inner Success',
      type: 'column',
    },
    {
      data: [
        {
          color: ContactFreezerColorMapping[2],
          y: 5,
        },
        {
          color: ContactFreezerColorMapping[2],
          y: 2.5,
        },
        {
          color: ContactFreezerColorMapping[2],
          y: 4.5,
        },
        {
          color: ContactFreezerColorMapping[2],
          y: 2,
        },
        {
          color: ContactFreezerColorMapping[2],
          y: 1,
        },
      ],
      name: 'Inner Fail',
      type: 'column',
    },
  ],
  legend: {
    enabled: false,
  },
};

type FreezingUnitProductivityAnalysisActive =
  | 'Unit Productivity'
  | 'Duration / Batch'
  | 'Capacity / Batch';

const UnitProducitivityAnalysis: React.FC<UnitProducitivityAnalysisProps> = ({
  className,
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [reRender, setReRender] = useState<boolean>(true);
  const [highest, setHeight] = useState<number>(0);
  const [active, setActive] =
    useState<FreezingUnitProductivityAnalysisActive>('Unit Productivity');

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
    // Hack the chart so it can fill entire screen during fullscreen state
    setReRender(false);
    setTimeout(() => {
      setReRender(true);
    }, 0);
  };

  const handleActive = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { name } = e.currentTarget;

    setActive(name as FreezingUnitProductivityAnalysisActive);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', className, {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
      })}
    >
      <button onClick={handleFullScreen} className="absolute right-5 top-5">
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">Productivity Analysis</h4>
        <div className="flex justify-end items-center mb-3.5">
          <Button
            onClick={handleActive}
            name="Unit Productivity"
            color={active === 'Unit Productivity' ? 'default' : 'grey'}
            className="mr-1.5"
          >
            Unit Productivity
          </Button>
          <Button
            onClick={handleActive}
            name="Duration / Batch"
            color={active === 'Duration / Batch' ? 'default' : 'grey'}
            className="mr-1.5"
          >
            Duration / Batch
          </Button>
          <Button
            onClick={handleActive}
            name="Capacity / Batch"
            color={active === 'Capacity / Batch' ? 'default' : 'grey'}
          >
            Capacity / Batch
          </Button>
        </div>
        <div className="flex justify-between items-center mb-4">
          <div>
            {active === 'Unit Productivity' && (
              <MultiSelectDropdown className="z-20 w-48" placeholder="Sort">
                <MultiSelectDropdownItem
                  name="machine"
                  type="radio"
                  id="machine-highest"
                  value="machine-highest"
                >
                  Machine Highest
                </MultiSelectDropdownItem>
                <MultiSelectDropdownItem
                  name="machine"
                  type="radio"
                  id="machine-lowest"
                  value="machine-lowest"
                >
                  Machine Lowest
                </MultiSelectDropdownItem>
                <MultiSelectDropdownSeparator />
                <MultiSelectDropdownItem
                  name="labor"
                  type="radio"
                  id="labor-highest"
                  value="labor-highest"
                >
                  Labor Highest
                </MultiSelectDropdownItem>
                <MultiSelectDropdownItem
                  name="labor"
                  type="radio"
                  id="labor-lowest"
                  value="labor-lowest"
                >
                  Labor Lowest
                </MultiSelectDropdownItem>
                <MultiSelectDropdownSeparator />
                <MultiSelectDropdownItem
                  name="overall"
                  type="radio"
                  id="overall-highest"
                  value="overall-highest"
                >
                  Overall Highest
                </MultiSelectDropdownItem>
                <MultiSelectDropdownItem
                  name="overall"
                  type="radio"
                  id="overall-lowest"
                  value="overall-lowest"
                >
                  Overall Lowest
                </MultiSelectDropdownItem>
                <MultiSelectDropdownSeparator />
              </MultiSelectDropdown>
            )}
            {active === 'Capacity / Batch' && (
              <MultiSelectDropdown className="z-20 w-48" placeholder="Sort">
                <MultiSelectDropdownItem
                  name="inner-success"
                  type="radio"
                  id="inner-success-highest"
                  value="inner-success-highest"
                >
                  Inner Success Highest
                </MultiSelectDropdownItem>
                <MultiSelectDropdownItem
                  name="inner-success"
                  type="radio"
                  id="inner-success-lowest"
                  value="inner-success-lowest"
                >
                  Inner Success Lowest
                </MultiSelectDropdownItem>
                <MultiSelectDropdownSeparator />
                <MultiSelectDropdownItem
                  name="inner-fail"
                  type="radio"
                  id="inner-fail-highest"
                  value="inner-fail-highest"
                >
                  Inner Fail Highest
                </MultiSelectDropdownItem>
                <MultiSelectDropdownItem
                  name="inner-fail"
                  type="radio"
                  id="inner-fail-lowest"
                  value="inner-fail-lowest"
                >
                  Inner Fail Lowest
                </MultiSelectDropdownItem>
                <MultiSelectDropdownSeparator />
              </MultiSelectDropdown>
            )}
          </div>
          <div className="flex">
            <button
              onClick={() => setHeight((highest + 1) % 3)}
              className="flex items-center focus:outline-none"
            >
              <span
                style={{ marginTop: 2 }}
                className="text-caption-1 font-bold text-blue"
              >
                Highest
              </span>
              {(highest === 0 || highest === 1) && (
                <IconArrowBreadcrumb
                  height={18}
                  width={18}
                  className="transform rotate-180 ic-blue"
                />
              )}
              {(highest === 0 || highest === 2) && (
                <IconArrowBreadcrumb
                  height={18}
                  width={18}
                  className={classNames('ic-blue', { '-ml-2': highest === 0 })}
                />
              )}
            </button>
          </div>
        </div>
        {reRender && (
          <>
            {active === 'Unit Productivity' && (
              <div>
                <HighchartsReact
                  options={UnitProductivityOptions}
                  highcharts={Highcharts}
                />
                <div className="flex items-center justify-center mt-6">
                  <ChartLegend
                    color={ContactFreezerColorMapping[1]}
                    title="Machine Productivity"
                    className="mr-3"
                  />
                  <ChartLegend
                    color={ContactFreezerColorMapping[2]}
                    title="Labor Productivity"
                    className="mr-3"
                  />
                  <ChartLegend
                    color={ContactFreezerColorMapping[3]}
                    title="Overall Productivity"
                  />
                </div>
              </div>
            )}
            {active === 'Duration / Batch' && (
              <div>
                <HighchartsReact
                  options={DurationPerBatchOptions}
                  highcharts={Highcharts}
                />
                <div className="flex items-center justify-center mt-6">
                  <ChartLegend
                    color={ContactFreezerColorMapping[1]}
                    title="Machine Duration"
                    className="mr-3"
                  />
                  <ChartLegend
                    color={ContactFreezerColorMapping[2]}
                    title="Labor Duration"
                  />
                </div>
              </div>
            )}
            {active === 'Capacity / Batch' && (
              <div>
                <HighchartsReact
                  options={CapacityPerBatchOptions}
                  highcharts={Highcharts}
                />
                <div className="flex items-center justify-center mt-6">
                  <ChartLegend
                    color={ContactFreezerColorMapping[1]}
                    title="Inner Success"
                    className="mr-3"
                  />
                  <ChartLegend
                    color={ContactFreezerColorMapping[2]}
                    title="Inner Fail"
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UnitProducitivityAnalysis;
