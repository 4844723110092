import SortingMachineUnitReportSummaryTable from './summary';
import SortingMachineUnitPerfTable from './unit-performance';
import SortingMachineUnitWorkersWageTable from './workers-wage';
import SortingMachineUnitWorkersLogTable from './workers-log';
import SortingMachineUnitProcessLogTable from './process-log';

export {
  SortingMachineUnitReportSummaryTable,
  SortingMachineUnitPerfTable,
  SortingMachineUnitWorkersWageTable,
  SortingMachineUnitWorkersLogTable,
  SortingMachineUnitProcessLogTable,
};
