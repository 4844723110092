import classNames from 'classnames';
import { useState } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  CardUnit,
  DatePicker,
  PeelingUnitHeadcountPerformance,
  PeelingUnitMaterialCostOfProduct,
  PeelingUnitProduction,
  PeelingUnitQuantityPerProduct,
  PeelingUnitWorkers,
  Switch,
} from 'components';

const PeelingUnitDashboardPage = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [realtime, setRealtime] = useState<boolean>(false);

  const handleChangeRealtime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    setRealtime(checked);
  };

  return (
    <div style={{ minWidth: 1200 }}>
      <h1 className="text-heading font-bold mb-3">Peeling Unit Dashboard</h1>
      <Breadcrumb className="mb-5">
        <BreadcrumbItem href="/unit/">Home</BreadcrumbItem>
        <BreadcrumbItem href="/unit/peeling-unit">Peeling Unit</BreadcrumbItem>
        <BreadcrumbItem isActive>Dashboard</BreadcrumbItem>
      </Breadcrumb>
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <Switch
            onChange={handleChangeRealtime}
            checked={realtime}
            withoutMessage
            className="mr-3"
          />
          <span className="text-caption-1 font-semibold text-grey">
            Realtime
          </span>
        </div>
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <DatePicker
            disabled={realtime}
            selected={startDate}
            // @ts-ignore
            onChange={(date) => setStartDate(date)}
            dateFormat="E, d MMM yyyy"
            containerClassName="mr-3.5"
          />
          <DatePicker
            disabled={realtime}
            selected={endDate}
            // @ts-ignore
            onChange={(date) => setEndDate(date)}
            dateFormat="E, d MMM yyyy"
            popperPlacement="bottom-end"
          />
        </div>
      </div>
      <div className={classNames('grid gap-x-4 mb-8', 'grid-cols-4')}>
        {!realtime ? (
          <>
            <CardUnit color="green" title="Total Incoming:" value="1.5 ton" />
            <CardUnit color="purple" title="Total Output:" value="1.5 ton" />
            <CardUnit color="blue" title="Shrinkage:" value="50 peti" />
            <CardUnit color="green" title="Latest Headcount:" value="1.5 ton" />
          </>
        ) : (
          <>
            <CardUnit color="green" title="Today's Incoming:" value="1.5 ton" />
            <CardUnit color="purple" title="Today's Output:" value="1.5 ton" />
            <CardUnit color="blue" title="Yield:" value="50 peti" />
            <CardUnit
              color="green"
              title="Current Headcount:"
              value="1.5 ton"
            />
          </>
        )}
      </div>
      <div className="grid grid-cols-2 gap-6">
        <PeelingUnitQuantityPerProduct />
        <PeelingUnitWorkers />
        <PeelingUnitProduction />
        {!realtime && <PeelingUnitHeadcountPerformance />}
        <PeelingUnitMaterialCostOfProduct />
      </div>
    </div>
  );
};

export default PeelingUnitDashboardPage;
