// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../assets/icons/ic-arrow-breadcrumb.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".breadcrumb-item::after {\n  content: '';\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  width: 1rem;\n  height: 1rem;\n  background-size: 1rem;\n  margin: 0 0.5rem;\n}\n\n.active.breadcrumb-item::after {\n  content: unset;\n}\n\n.active.breadcrumb-item {\n  color: #bbc8d4;\n  font-weight: 400;\n}\n", "",{"version":3,"sources":["webpack://src/components/universal/atoms/breadcrumb-item/index.css","webpack://node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mDAAmE;EACnE,WAAW;EACX,YAAY;EACZ,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;ECZhB,gBAAmB;ADcnB","sourcesContent":[".breadcrumb-item::after {\n  content: '';\n  background: url('../../../../assets/icons/ic-arrow-breadcrumb.svg');\n  width: 1rem;\n  height: 1rem;\n  background-size: 1rem;\n  margin: 0 0.5rem;\n}\n\n.active.breadcrumb-item::after {\n  content: unset;\n}\n\n.active.breadcrumb-item {\n  color: #bbc8d4;\n  @apply font-normal;\n}\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
