import classNames from 'classnames';
import { useState } from 'react';
import { IconClose, IconLaunch } from '../../../../assets';

interface FinalSortingHLUnitWorkersProps {
  className?: string;
}

const FinalSortingHLUnitWorkers: React.FC<FinalSortingHLUnitWorkersProps> = ({
  className,
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', className, {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
      })}
    >
      <button
        onClick={handleFullScreen}
        className="absolute right-5 top-5 z-10"
      >
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">Workers</h4>
        <div className="flex justify-end mb-5">
          <div className="flex items-center text-caption-1 text-grey font-bold mr-3">
            <input id="this-unit" name="worked" className="mr-2" type="radio" />
            <label htmlFor="this-unit">Worked at this unit</label>
          </div>
          <div className="flex items-center text-caption-1 text-grey font-bold">
            <input id="from-unit" name="worked" className="mr-2" type="radio" />
            <label htmlFor="from-unit">Worked from this unit</label>
          </div>
        </div>
        <div className="mb-5">
          <div className="grid grid-cols-3 text-footnote text-grey font-bold mb-5">
            <div>Name</div>
            <div>Total work hours</div>
            <div className="text-right">Total accumulated wage</div>
          </div>
          <div className="grid grid-cols-3 text-caption-1 font-bold py-2 border-b border-grey-1">
            <div>CF 1</div>
            <div>20 hours</div>
            <div className="text-right">Rp 3.000.0000</div>
          </div>
          <div className="grid grid-cols-3 text-caption-1 font-bold py-2 border-b border-grey-1">
            <div>CF 2</div>
            <div>20 hours</div>
            <div className="text-right">Rp 3.000.0000</div>
          </div>
          <div className="grid grid-cols-3 text-caption-1 font-bold py-2 border-b border-grey-1">
            <div>CF 3</div>
            <div>20 hours</div>
            <div className="text-right">Rp 3.000.0000</div>
          </div>
        </div>
        <p className="text-caption-1 text-grey font-bold mb-4">
          Unit Productivity: <span className="text-blue-3">500kg/hour</span>
        </p>
      </div>
    </div>
  );
};

export default FinalSortingHLUnitWorkers;
