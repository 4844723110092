export { default as ManagementLeavePermissionPage } from './leave-permission';
export { default as ManagementTakeoutOrderCommandPage } from './takeout-order-command';
export { default as ManagementInspectionPage } from './inspection';
export { default as ManagementWagePage } from './wage';
export { default as ManagementProductivityPage } from './productivity';
export { default as ManagementStockQuantityPage } from './stock/quantity';
export { default as ManagementStockTransferPage } from './stock/transfer';
export { default as ManagementSupplierAnalysisPage } from './supplier-analysis';
export { default as ManagementCostAnalysisPage } from './cost-analysis';
export { default as ProductPriorityPage } from './production-priority';
export { default as TraceabilityPage } from './traceability';
export { default as HomeManagementPage } from './home';
