import { MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  DatePicker,
  SoakingUnitPerformanceTable,
  ProcessLogTable,
  SoakingUnitSoakingAnalysisTable,
  SoakingUnitSummaryTable,
  WorkersLogTable,
  SoakingUnitWorkersWageTable,
} from 'components';
import { useQuery } from 'utils/useQuery';
import { IconExcel } from 'assets';
import dayjs from 'dayjs';
import { UnitIds, handleDownloadExcelUnit } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'redux/root-reducer';
import { SetEndDate, SetStartDate } from 'redux/filterDates/filterDate.action';

const SoakingUnitReportPage = () => {
  const report = useQuery().get('report') || 'process-log';
  const history = useHistory();

  const { startDate, endDate } = useSelector<RootReducerState, Dates>(
    (state) => state.filterDate.soakingUnitReport
  );
  const dispatch = useDispatch();


  const handleDownload = (e:MouseEvent<HTMLButtonElement>) => {
   handleDownloadExcelUnit(e, startDate, endDate, UnitIds.Soaking, 'Soaking');
  }

  return (
    <div style={{ minWidth: 1200 }}>
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-heading font-bold mb-3">Soaking Unit Report</h1>
          <Breadcrumb className="mb-5">
            <BreadcrumbItem href="/unit/">Home</BreadcrumbItem>
            <BreadcrumbItem href="/unit/soaking-unit">
              Soaking Unit
            </BreadcrumbItem>
            <BreadcrumbItem isActive>Report</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="flex items-center">
          {/* <Button
            className="mr-5 w-32"
            color={report === 'summary' ? 'default' : 'grey'}
            onClick={() => history.push({ search: 'report=summary' })}
          >
            Summary
          </Button>
          <Button
            className="mr-5 w-32"
            color={report === 'soaking-analysis' ? 'default' : 'grey'}
            onClick={() => history.push({ search: 'report=soaking-analysis' })}
          >
            Soaking Analysis
          </Button>
          <Button
            className="mr-5 w-32"
            color={report === 'unit-performance' ? 'default' : 'grey'}
            onClick={() => history.push({ search: 'report=unit-performance' })}
          >
            Unit Performance
          </Button> */}
          <Button
            className="mr-5 w-32"
            color={report === 'process-log' ? 'default' : 'grey'}
            onClick={() => history.push({ search: 'report=process-log' })}
          >
            Process Log
          </Button>
          <Button
            className="mr-5 w-32"
            color={report === 'workers-wage' ? 'default' : 'grey'}
            onClick={() => history.push({ search: 'report=workers-wage' })}
          >
            Workers Wage
          </Button>
          <Button
            className="mr-5 w-32"
            color={report === 'workers-log' ? 'default' : 'grey'}
            onClick={() => history.push({ search: 'report=workers-log' })}
          >
            Workers Log
          </Button>
        </div>
      </div>
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <DatePicker
            selected={dayjs(startDate).toDate()}
            onChange={(date: Date) =>
              dispatch(
                SetStartDate(
                  'soakingUnitReport',
                  dayjs(date).format('YYYY-MM-DD')
                )
              )
            }
            dateFormat="E, d MMM yyyy"
            containerClassName="mr-3.5"
            maxDate={dayjs(endDate).toDate()}
          />
          <DatePicker
            selected={dayjs(endDate).toDate()}
            onChange={(date: Date) =>
              dispatch(
                SetEndDate(
                  'soakingUnitReport',
                  dayjs(date).format('YYYY-MM-DD')
                )
              )
            }
            dateFormat="E, d MMM yyyy"
            popperPlacement="bottom-end"
            maxDate={dayjs().toDate()}
          />
        </div>
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Export
          </span>
          <button onClick={handleDownload} className="p-2 rounded-md border border-grey-1">
            <IconExcel />
          </button>
        </div>
      </div>
      {report === 'summary' && <SoakingUnitSummaryTable />}
      {report === 'soaking-analysis' && <SoakingUnitSoakingAnalysisTable />}
      {report === 'unit-performance' && <SoakingUnitPerformanceTable />}
      {report === 'workers-wage' && <SoakingUnitWorkersWageTable />}
      {report === 'workers-log' && <WorkersLogTable start_date={dayjs(startDate).format('YYYY-MM-DD')} end_date={dayjs(endDate).format('YYYY-MM-DD')} unit={UnitIds.Soaking}  />}
      {report === 'process-log' && <ProcessLogTable start_date={dayjs(startDate).format('YYYY-MM-DD')} end_date={dayjs(endDate).format('YYYY-MM-DD')} unit={UnitIds.Soaking} />}
    </div>
  );
};

export default SoakingUnitReportPage;
