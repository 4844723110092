import classNames from 'classnames';
import humanizeDuration from 'humanize-duration';
import dayjs from 'dayjs';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { IconClose, IconLaunch } from 'assets';
import { ColdStorageColorMapping } from 'colors';
import { ColdStorageList } from 'utils/types';
import { urlAPIMechanic } from 'utils';
import { Button, ChartLegend } from 'components';

interface ColdStorageCriticalTempProps {
  className?: string;
  startDate?: string;
  endDate?: string;
  initialActive?: number;
}

type ColdStorageCriticalTempData = {
  durationInCriticalTime: number;
  durationNotInCriticalTime: number;
};

const ColdStorageCriticalTemp: React.FC<ColdStorageCriticalTempProps> = ({
  className = '',
  startDate = dayjs().subtract(3, 'days').format('YYYY-MM-DD'),
  endDate = dayjs().format('YYYY-MM-DD'),
  initialActive = 1,
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [reRender, setReRender] = useState<boolean>(true);
  const [active, setActive] = useState<number>(initialActive);
  const [options, setOptions] = useState<Highcharts.Options>({
    title: {
      text: '',
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: 'Hours',
      },
    },
    series: [],
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return `${this.point.name} <br><br> <strong>${humanizeDuration(
          this.y * 1000,
          { round: true }
        )}</strong>`;
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 60,
          },
        },
      ],
    },
  });
  const [total, setTotal] = useState<number>(0);

  const queryClient = useQueryClient();
  const lists = queryClient.getQueryData<ColdStorageList[]>('coldStorageList');

  useEffect(() => {
    if (lists) setActive(lists[0].id);
  }, [lists]);

  const { data } = useQuery<
    ColdStorageCriticalTempData,
    unknown,
    ColdStorageCriticalTempData
  >(
    ['coldStorageCriticalTemp', startDate, endDate, active],
    () =>
      fetch(
        urlAPIMechanic +
          `/mechanic/cold-storage/dashboard/critical-temperature?start=${startDate}&end=${endDate}&id=${active}`
      )
        .then((res) => res.json())
        .then((data) => data.data),
    {
      initialData: {
        durationInCriticalTime: 0,
        durationNotInCriticalTime: 0,
      },
      keepPreviousData: true,
      onSuccess: (data) => {
        let seriesData = [
          {
            name: 'Duration in critical time',
            color: ColdStorageColorMapping[1],
            y: data.durationInCriticalTime,
            dataLabels: {
              enabled: false,
            },
          },
          {
            name: 'Duration not in critical time',
            color: ColdStorageColorMapping[2],
            y: data.durationNotInCriticalTime,
            dataLabels: {
              enabled: false,
            },
          },
        ];

        let series: Highcharts.SeriesOptionsType[] = [
          {
            data: seriesData,
            name: lists?.find((val) => val.id === active)?.nama_node,
            type: 'pie',
            innerSize: '80%',
            size: '75%',
          },
        ];

        setTotal(data.durationInCriticalTime + data.durationNotInCriticalTime);

        setOptions({
          ...options,
          series,
        });
      },
    }
  );

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
    // Hack the chart so it can fill entire screen during fullscreen state
    setReRender(false);
    setTimeout(() => {
      setReRender(true);
    }, 0);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        [className]: className,
      })}
    >
      <button onClick={handleFullScreen} className="absolute right-5 top-5">
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">
          Critical Temperature -{' '}
          {lists?.find((val) => val.id === active)?.nama_node}
        </h4>
        <div className="grid grid-cols-3 gap-3 items-center">
          <div className="col-span-2">
            {reRender && (
              <HighchartsReact options={options} highcharts={Highcharts} />
            )}
          </div>
          <div>
            <ChartLegend
              percentage={
                total
                  ? ((data!.durationInCriticalTime / total) * 100).toFixed(0)
                  : '0'
              }
              color={ColdStorageColorMapping[1]}
              title="Critical"
              className="mb-4"
            />
            <ChartLegend
              percentage={
                total
                  ? ((data!.durationNotInCriticalTime / total) * 100).toFixed(0)
                  : '0'
              }
              color={ColdStorageColorMapping[2]}
              title="Not Critical"
              className="mb-4"
            />
            <p className="text-caption-1 font-bold text-grey mt-6">
              <span className="mb-2 block">Total Duration</span>
              <span className="text-blue-2">
                {humanizeDuration(total * 1000, { round: true })}
              </span>
            </p>
          </div>
        </div>
        <div className="flex items-center flex-wrap justify-center mt-6">
          {lists?.map((val) => (
            <Button
              key={`cold-storage-critical-temp-${val.id}`}
              color={val.id === active ? 'default' : 'grey'}
              className="m-3"
              variant="outlined"
              onClick={() => setActive(val.id)}
            >
              {val.nama_node}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ColdStorageCriticalTemp;
