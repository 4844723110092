import { TableGrid } from 'components';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { urlAPIUnit } from 'utils';

const ReceivingUnitSummaryTable: React.FC<IBaseTable> = ({
  endDate,
  startDate,
}) => {
  const { data: incomingOutput } = useQuery<
    ReceivingUnitIncomingOutputResponse[]
  >(
    ['receiving-unit-summary-incoming-and-output', startDate, endDate],
    async () => {
      const res = await fetch(
        urlAPIUnit +
          `/unit/receiving-station/summary/incoming-output/?start=${dayjs(
            startDate
          ).format('YYYY-MM-DD')}&end=${dayjs(endDate).format('YYYY-MM-DD')}`
      );
      const data = await res.json();

      return data.data;
    }
  );

  const { data: pendingReprocessing } = useQuery<
    ReceivingUnitPendingReprocessingResponse[]
  >(
    ['receiving-unit-summary-pending-and-reprocessing', startDate, endDate],
    async () => {
      const res = await fetch(
        urlAPIUnit +
          `/unit/receiving-station/summary/pending-reprocessing/?start=${dayjs(
            startDate
          ).format('YYYY-MM-DD')}&end=${dayjs(endDate).format('YYYY-MM-DD')}`
      );

      const data = await res.json();

      return data.data;
    }
  );

  return (
    <>
      <h6 className="font-bold mb-2">Incoming and Output</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              name: 'Species',
              field: 'nama_spesies',
            },
            {
              name: 'Purchasing Size',
              field: 'purchasing_size',
              textAlign: 'center',
            },
            {
              Component: (
                <div
                  key="incoming-size-column"
                  className="grid grid-cols-3 gap-y-4"
                >
                  <div className="text-center col-span-3 pb-2 border-b border-grey-placeholder">
                    Incoming
                  </div>
                  <div className="text-center">Detail</div>
                  <div className="text-center">Per Species</div>
                  <div className="text-center">Total</div>
                </div>
              ),
              width: '3fr',
              cols: [
                'detail_incoming',
                'incomingWeight',
                'totalIncomingWeight',
              ],
            },
            {
              Component: (
                <div
                  key="output-size-column"
                  className="grid grid-cols-3 gap-y-4"
                >
                  <div className="text-center col-span-3 pb-2 border-b border-grey-placeholder">
                    Output
                  </div>
                  <div className="text-center">Detail</div>
                  <div className="text-center">Per Species</div>
                  <div className="text-center">Total</div>
                </div>
              ),
              width: '3fr',
              cols: ['detail_output', 'outputWeight', 'totalOutputWeight'],
            },
          ]}
          data={Array.from(incomingOutput || [], ({ nama_spesies, sizes }) => ({
            nama_spesies,
            purchasing_size: Array.from(
              sizes,
              ({ purchasingSize }) => purchasingSize
            ),
            incomingWeight: Array.from(sizes, ({ incomingWeight }) =>
              incomingWeight.toString()
            ),
            outputWeight: Array.from(sizes, ({ outputWeight }) =>
              outputWeight.toString()
            ),
            totalIncomingWeight: sizes
              .reduce((val, acc) => val + acc.incomingWeight, 0)
              .toString(),
            totalOutputWeight: sizes
              .reduce((val, acc) => val + acc.outputWeight, 0)
              .toString(),
          }))}
          onRenderField={(field, value, { key }) => {
            if (field !== 'nama_spesies')
              return (
                <div key={key} className="text-center">
                  {value}
                </div>
              );
          }}
        />
      </div>
      <h6 className="font-bold mb-2">Pending and Reprocessing</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              name: 'Species',
              field: 'nama_spesies',
            },
            {
              name: 'Purchasing Size',
              field: 'purchasing_size',
              textAlign: 'center',
            },
            {
              Component: (
                <div
                  key="incoming-size-column"
                  className="grid grid-cols-3 gap-y-4"
                >
                  <div className="text-center col-span-3 pb-2 border-b border-grey-placeholder">
                    Last Pending
                  </div>
                  <div className="text-center">Detail</div>
                  <div className="text-center">Per Species</div>
                  <div className="text-center">Total</div>
                </div>
              ),
              width: '3fr',
              cols: [
                'detail_incoming',
                'incomingWeight',
                'totalIncomingWeight',
              ],
            },
            {
              Component: (
                <div
                  key="output-size-column"
                  className="grid grid-cols-3 gap-y-4"
                >
                  <div className="text-center col-span-3 pb-2 border-b border-grey-placeholder">
                    Reprocessing
                  </div>
                  <div className="text-center">Detail</div>
                  <div className="text-center">Per Species</div>
                  <div className="text-center">Total</div>
                </div>
              ),
              width: '3fr',
              cols: ['detail_output', 'outputWeight', 'totalOutputWeight'],
            },
          ]}
          data={Array.from(
            pendingReprocessing || [],
            ({ nama_spesies, sizes }) => ({
              nama_spesies,
              purchasing_size: Array.from(
                sizes,
                ({ purchasingSize }) => purchasingSize
              ),
              incomingWeight: Array.from(sizes, ({ pendingWeight }) =>
                pendingWeight.toString()
              ),
              outputWeight: Array.from(sizes, ({ pendingWeight }) =>
                pendingWeight.toString()
              ),
              totalIncomingWeight: sizes
                .reduce((val, acc) => val + acc.pendingWeight, 0)
                .toString(),
              totalOutputWeight: sizes
                .reduce((val, acc) => val + acc.pendingWeight, 0)
                .toString(),
            })
          )}
          onRenderField={(field, value, { key }) => {
            if (field !== 'nama_spesies')
              return (
                <div key={key} className="text-center">
                  {value}
                </div>
              );
          }}
        />
      </div>
      <div className="flex items-start mb-8">
        <div className="w-96 mr-20">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Cost Labor per Kg</div>
            <div>Rp 1.000</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Cost of Materials</div>
            <div>Rp 1.000</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Cost of Labor</div>
            <div>Rp 1.000</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Headcount</div>
            <div>8 People</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Working Time</div>
            <div>50 Man-Hours</div>
          </div>
          <div className="flex justify-between text-caption-1">
            <div className="font-semibold">Total Time</div>
            <div>8 Hours</div>
          </div>
        </div>
        <div className="w-96">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Cost of Material per Kg</div>
            <div>Rp 1.000</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Average Size Difference</div>
            <div>+5.5</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Overall Size STD</div>
            <div>1.23</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Shrinkage</div>
            <div>4%</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Unit Productivity Rate</div>
            <div>50kg/Man-Hours</div>
          </div>
          <div className="flex justify-between text-caption-1">
            <div className="font-semibold">Unit Productivity</div>
            <div>500kg/Hour</div>
          </div>
        </div>
      </div>
      <div className="w-80">
        <div className="flex justify-between mb-3 text-caption-1">
          <div className="font-semibold">Total Pending Raw Materials</div>
          <div>200 Kg</div>
        </div>
        <div className="flex justify-between mb-3 text-caption-1">
          <div className="font-semibold">Longest Pending Raw Materials</div>
          <div>2 Day</div>
        </div>
      </div>
    </>
  );
};

export default ReceivingUnitSummaryTable;
