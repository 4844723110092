import { forwardRef, useEffect, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  ListChildComponentProps,
  VariableSizeList as List,
  VariableSizeList,
} from 'react-window';
import { AddEditConstantState } from '..';
import { IconClose, IconEdit, IconSort, IconTrash } from '../../../assets';
import { IUser } from '../../../utils/types';
import { urlAPI } from '../../../utils/urlApi';
import { useUser } from '../../../utils/useUser';
import { LoadingAnimation } from 'components';
import { Loading } from 'components';
import AddEditConstant from '../add-edit-constant';
import { Dialog, ProceedConfirmation } from 'components';
import './index.css';

type RowHeights = {
  [key: string]: number;
};

interface ConstantManagementTableProps {
  searchQuery?: string;
  filterQuery?: string;
}

const ConstantManagementTable: React.FC<ConstantManagementTableProps> = ({
  searchQuery = '',
  filterQuery = '',
}) => {
  const listRef = useRef<VariableSizeList>(null);
  const rowHeights = useRef<RowHeights>({});

  function setRowHeight(index: number, size: number) {
    listRef.current?.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  function getRowHeight(index: number) {
    const height = rowHeights.current[Number(index)] || 60;

    return height < 60 ? 60 : height;
  }

  // Row Element for home table
  const Row = forwardRef<any, ListChildComponentProps>(
    ({ data, index, style }, _) => {
      const rowRef = useRef<HTMLDivElement>(null);

      useEffect(() => {
        if (rowRef.current) setRowHeight(index, rowRef.current.offsetHeight);
      }, [rowRef, index]);

      const handleEdit = () => {
        setEditConstant(true);
        setState(data[index]);
      };

      const handleDelete = () => {
        setState(data[index]);
        setDialogDelete(true);
      };

      return (
        <>
          <div style={style} className="constant-management-table-row px-4">
            <div className="text-footnote text-black font-bold">
              {index + 1}
            </div>
            <div ref={rowRef} className="text-footnote text-black font-bold">
              <span className="inline-block">{data[index].id}</span>
            </div>
            <div className="text-footnote text-black font-bold">
              <span>{data[index].nama_konstanta}</span>
            </div>
            <div className="text-footnote text-black font-bold">
              <span>{data[index].konstanta_type}</span>
            </div>
            <div className="text-footnote text-black font-bold">
              <span>{data[index].value}</span>
            </div>
            <div className="flex items-center justify-self-center">
              <button
                onClick={handleEdit}
                className="mr-4 border-0 bg-transparent "
              >
                <IconEdit height="24" width="24" />
              </button>
              <button
                onClick={handleDelete}
                className=" border-0 bg-transparent "
              >
                <IconClose height="24" width="24" />
              </button>
            </div>
          </div>
        </>
      );
    }
  );

  // State for returned device management table element
  const [constants, setConstants] = useState<AddEditConstantState[]>([]);
  const [loadingConstants, setLoadingConstants] = useState<boolean>(true);
  const [state, setState] = useState<AddEditConstantState>();
  const [editConstant, setEditConstant] = useState<boolean>(false);
  const [dialogEdit, setDialogEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>();
  const [successEdit, setSuccessEdit] = useState<boolean>(false);
  const [dialogDelete, setDialogDelete] = useState<boolean>(false);
  const [successDelete, setSuccessDelete] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>('');
  const [isAscending, setAscending] = useState<boolean>(true);

  const attempt = useRef<number>(0);

  useUser(async (userFromLocal, err) => {
    if (err) console.log(err);
    else {
      setUser(userFromLocal);
      const res = await fetch(urlAPI + '/konstanta?limit=999999', {
        headers: {
          Authorization: 'Bearer ' + userFromLocal?.token,
        },
      });

      const data = await res.json();

      setConstants(data.data || []);
      setLoadingConstants(false);
    }
  });

  // Effect for handling filtering, search query and sort
  useEffect(() => {
    if (attempt.current > 1) {
      let s = '';
      let name = '';
      let asc = '';
      let type = '';

      if (sortBy.length > 0) s = `&sortBy=${sortBy}`;
      if (searchQuery?.length > 0) name = `&name=${searchQuery}`;
      if (filterQuery.length > 0) type = filterQuery;
      isAscending ? (asc = '&sort=ASC') : (asc = '&sort=DESC');

      const fetchData = async function () {
        const res = await fetch(
          urlAPI + `/konstanta?limit=999999${s + asc + name + type}`,
          {
            headers: {
              Authorization: 'Bearer ' + user?.token,
            },
          }
        );

        const data = await res.json();
        setConstants(data.data || []);
      };

      fetchData();
    } else {
      attempt.current++;
    }
  }, [sortBy, isAscending, searchQuery, user, filterQuery]);

  const handleSort = (sort: string, asc: boolean) => {
    if (sort !== sortBy || sortBy.length === 0) setAscending(true);
    else setAscending(asc);

    setSortBy(sort);
  };

  const handleEditDevice = (val: AddEditConstantState) => {
    setState(val);
    setEditConstant(false);
    setDialogEdit(true);
  };

  const handleCancelDialog = () => {
    setEditConstant(true);
    setDialogEdit(false);
  };

  const handleProceedEdit = async () => {
    // fetch API to edit konstanta here
    setLoading(true);
    const res = await fetch(urlAPI + `/konstanta/${state?.id}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + user?.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(state),
    });

    if (res.status === 200) {
      setLoading(false);
      setDialogEdit(false);
      setSuccessEdit(true);

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else console.log('Error');
  };

  const handleProceedDelete = async () => {
    // fetch API to delete konstanta here
    setLoading(true);
    const res = await fetch(urlAPI + `/konstanta/${state?.id}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
    });

    if (res.status === 200) {
      setLoading(false);
      setDialogDelete(false);
      setSuccessDelete(true);

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else console.log('Error');
  };

  return (
    <>
      {editConstant && (
        <AddEditConstant
          onSubmit={handleEditDevice}
          onCancel={() => setEditConstant(false)}
          type="edit"
          state={state}
        />
      )}
      {dialogEdit && (
        <Dialog
          onSubmit={handleProceedEdit}
          onCancel={handleCancelDialog}
          message={`Do you really want to update the ${state?.id} information?`}
          heading="Update Konstanta Information"
        />
      )}
      {loading && <Loading />}
      {successEdit && (
        <ProceedConfirmation
          message={`The ${state?.id} information has been changed`}
          onClose={() => setSuccessEdit(false)}
        />
      )}
      {dialogDelete && (
        <Dialog
          onSubmit={handleProceedDelete}
          message={`Are you sure you want to delete this ${state?.id} konstanta?`}
          onCancel={() => setDialogDelete(false)}
        />
      )}
      {successDelete && (
        <ProceedConfirmation
          onClose={() => setSuccessDelete(false)}
          Icon={(props) => <IconTrash {...props} className="mb-6 ic-red" />}
          message={`The ${state?.id} information has been deleted`}
        />
      )}
      <div className="constant-management-table bg-white rounded-lg px-8 py-10 border rounded-lg border-grey-light">
        <div className="constant-management-table-row py-4 px-4">
          <div className="text-body text-grey font-bold flex items-center">
            <span className="block mr-2">NO.</span>
          </div>
          <button
            onClick={() => handleSort('id', !isAscending)}
            className="text-body text-grey font-bold flex items-center cursor-pointer"
          >
            <span className="block mr-2">Konstanta ID</span>
            <IconSort />
          </button>
          <button
            onClick={() => handleSort('nama_konstanta', !isAscending)}
            className="text-body text-grey font-bold flex items-center cursor-pointer"
          >
            <span className="block mr-2">Konstanta Name</span>
            <IconSort />
          </button>
          <button
            onClick={() => handleSort('konstanta_type', !isAscending)}
            className="text-body text-grey font-bold flex items-center cursor-pointer"
          >
            <span className="block mr-2">Konstanta Type</span>
            <IconSort />
          </button>
          <button
            onClick={() => handleSort('value', !isAscending)}
            className="text-body text-grey font-bold flex items-center cursor-pointer"
          >
            <span className="block mr-2">Value</span>
            <IconSort />
          </button>
          <div className="text-body text-grey font-bold flex items-center cursor-pointer justify-self-center">
            Action
          </div>
        </div>
        {loadingConstants ? (
          <div className="flex justify-center">
            <LoadingAnimation size="md" className="mt-6" />
          </div>
        ) : (
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height - 80}
                itemData={constants}
                itemCount={constants.length}
                itemSize={getRowHeight}
                width={width + 5}
                ref={listRef}
                className="list"
              >
                {({ ...props }) => {
                  return <Row {...props} />;
                }}
              </List>
            )}
          </AutoSizer>
        )}
      </div>
    </>
  );
};

export default ConstantManagementTable;
