import { IconClose, IconLaunch } from 'assets';
import classNames from 'classnames';
import { ColdStorageColorMapping } from 'colors';
import { ChartLegend, MultiSelectDropdown } from 'components';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useMemo, useState } from 'react';

interface CostDistroProps extends IBaseChart {
  className?: string;
  queryKey?: string;
}

const CostDistro: React.FC<CostDistroProps> = ({ className }) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [reRender, setReRender] = useState<boolean>(true);

  const pieChartOptions = useMemo<Highcharts.Options>(
    () => ({
      title: {
        text: '',
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: 'kg',
        },
      },
      series: [
        {
          type: 'pie',
          data: [
            {
              name: 'Cost Material',
              y: 4,
              color: ColdStorageColorMapping[1],
              dataLabels: { enabled: false },
            },
            {
              name: 'Cost Labor',
              y: 4,
              color: ColdStorageColorMapping[2],
              dataLabels: { enabled: false },
            },
            {
              name: 'Cost Electricity',
              y: 4,
              color: ColdStorageColorMapping[4],
              dataLabels: { enabled: false },
            },
          ],
          innerSize: '80%',
          size: '75%',
          name: 'Cost Analysis',
        },
      ],
      legend: {
        enabled: false,
      },
      tooltip: {
        valueSuffix: ' kg',
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 60,
            },
          },
        ],
      },
    }),
    []
  );

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
    // Hack the chart so it can fill entire screen during fullscreen state
    setReRender(false);
    setTimeout(() => {
      setReRender(true);
    }, 0);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', className, {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
      })}
    >
      <button onClick={handleFullScreen} className="absolute right-5 top-5">
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">Cost Distribution</h4>
        <div className="grid grid-cols-4 gap-x-2 mb-5 relative z-20">
          <div></div>
          <div></div>
          <MultiSelectDropdown placeholder="Species" />
          <MultiSelectDropdown placeholder="Size" />
        </div>

        {reRender && (
          <div>
            <div className="grid grid-cols-3 items-center">
              <div className="col-span-2">
                <HighchartsReact
                  options={pieChartOptions}
                  highcharts={Highcharts}
                />
              </div>
              <div>
                <ChartLegend
                  title="8.000.000 IDR"
                  percentage="33"
                  className="mb-4"
                  color={ColdStorageColorMapping[1]}
                />
                <ChartLegend
                  title="8.000.000 IDR"
                  percentage="33"
                  className="mb-4"
                  color={ColdStorageColorMapping[2]}
                />
                <ChartLegend
                  title="8.000.000 IDR"
                  percentage="33"
                  color={ColdStorageColorMapping[4]}
                />
              </div>
            </div>
            <div className="flex items-center flex-wrap pl-10 mt-3">
              <ChartLegend
                className="m-3"
                color={ColdStorageColorMapping[1]}
                title="Cost Material"
              />
              <ChartLegend
                className="m-3"
                color={ColdStorageColorMapping[2]}
                title="Cost Labor"
              />
              <ChartLegend
                className="m-3"
                color={ColdStorageColorMapping[4]}
                title="Cost Electricity"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CostDistro;
