import classNames from 'classnames';

interface WorkingTimeDistLegendProps {
  className?: string;
  percentage?: string | number;
  title?: string;
  hours?: string;
  color?: string;
}

const WorkingTimeDistLegend: React.FC<WorkingTimeDistLegendProps> = ({
  className = '',
  percentage,
  title,
  hours,
  color,
}) => {
  return (
    <div
      className={classNames('flex items-center', { [className]: className })}
    >
      <div className="text-caption-1 text-grey font-bold mr-4">
        <span className="block mb-0.5">{percentage}%</span>
        <div
          style={{ background: color }}
          className="h-1 w-11 rounded-full"
        ></div>
      </div>
      <div className="flex flex-col font-bold text-caption-1">
        <span className="mb-0 5">{title}</span>
        <span className="text-blue">{hours}</span>
      </div>
    </div>
  );
};

export default WorkingTimeDistLegend;
