import classNames from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState } from 'react';
import { IconArrowBreadcrumb, IconClose, IconLaunch } from '../../../../assets';
import { ContactFreezerColorMapping } from '../../../../colors';
import { ChartLegend, MultiSelectDropdown } from 'components';

interface SortingMachineUnitSizeDistroProps {
  className?: string;
}

const CostperKgOptions: Highcharts.Options = {
  title: {
    text: '',
  },
  chart: {
    type: 'column',
  },
  xAxis: {
    gridLineWidth: 1,
    categories: ['6-8', '9-11', '12-15', '16-18', '19-21'],
  },
  yAxis: [
    {
      gridLineWidth: 0,
      title: {
        text: 'Quantity (kg)',
        rotation: -90,
      },
    },
  ],
  series: [
    {
      data: [
        {
          color: ContactFreezerColorMapping[1],
          y: 8,
        },
        {
          color: ContactFreezerColorMapping[1],
          y: 5,
        },
        {
          color: ContactFreezerColorMapping[1],
          y: 7,
        },
        {
          color: ContactFreezerColorMapping[1],
          y: 7,
        },
        {
          color: ContactFreezerColorMapping[1],
          y: 7,
        },
      ],
      name: 'Sampling',
      type: 'column',
    },
    {
      data: [
        {
          color: ContactFreezerColorMapping[2],
          y: 8,
        },
        {
          color: ContactFreezerColorMapping[2],
          y: 5,
        },
        {
          color: ContactFreezerColorMapping[2],
          y: 7,
        },
        {
          color: ContactFreezerColorMapping[2],
          y: 7,
        },
        {
          color: ContactFreezerColorMapping[2],
          y: 7,
        },
      ],
      name: 'Output',
      type: 'column',
    },
  ],
  legend: {
    enabled: false,
  },
};

const SortingMachineUnitSizeDistro: React.FC<SortingMachineUnitSizeDistroProps> =
  ({ className }) => {
    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const [reRender, setReRender] = useState<boolean>(true);
    const [outputDistroUnit, setOutputDistroUnit] =
      useState<'kg' | 'percentage'>('kg');

    const handleFullScreen = () => {
      setFullScreen(!fullScreen);
      // Hack the chart so it can fill entire screen during fullscreen state
      setReRender(false);
      setTimeout(() => {
        setReRender(true);
      }, 0);
    };

    return (
      <div
        style={{ zIndex: fullScreen ? 1200 : 0 }}
        className={classNames('flex justify-center items-center', className, {
          relative: !fullScreen,
          'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        })}
      >
        <button onClick={handleFullScreen} className="absolute right-5 top-5">
          {!fullScreen ? <IconLaunch /> : <IconClose />}
        </button>
        <div className="w-full h-full border rounded-lg border-grey-light p-6">
          <h4 className="text-footnote font-bold mb-8">Size Distribution</h4>
          <div className="grid grid-cols-4 gap-x-2 mb-5 relative z-20 ml-4">
            <div></div>
            <div></div>
            <MultiSelectDropdown placeholder="Supplier" />
            <MultiSelectDropdown placeholder="Species" />
          </div>
          <button
            onClick={() => {
              if (outputDistroUnit === 'kg') setOutputDistroUnit('percentage');
              else setOutputDistroUnit('kg');
            }}
            className="text-caption-1 text-blue flex items-center font-bold my-3 focus:outline-none"
          >
            {outputDistroUnit === 'kg' ? (
              <>
                <span className="mr-0.5">Kilogram</span>
                <IconArrowBreadcrumb
                  height={18}
                  width={18}
                  className="transform rotate-180 ic-blue"
                />
              </>
            ) : (
              <>
                <span className="mr-0.5">Percentage</span>
                <IconArrowBreadcrumb
                  height={18}
                  width={18}
                  className="ic-blue"
                />
              </>
            )}
          </button>
          {reRender && (
            <>
              <div>
                <HighchartsReact
                  options={CostperKgOptions}
                  highcharts={Highcharts}
                />
                <div className="flex items-center justify-center mt-6">
                  <ChartLegend
                    color={ContactFreezerColorMapping[1]}
                    title="Sampling"
                    className="mr-3"
                  />
                  <ChartLegend
                    color={ContactFreezerColorMapping[2]}
                    title="Output"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

export default SortingMachineUnitSizeDistro;
