import { useState } from 'react';
import { urlAPI } from '../../../utils/urlApi';
import { useUser } from '../../../utils/useUser';
import { Button, DropdownItem, TextField } from 'components';
import { Dropdown } from 'components';

interface AddEditConstantProps {
  onCancel?: () => void;
  onSubmit?: (state: AddEditConstantState) => void;
  state?: AddEditConstantState;
  type?: 'add' | 'edit';
}

export type AddEditConstantState = {
  id?: string;
  nama_konstanta?: string;
  konstanta_type?: string;
  value?: string | number;
};

export type KonstantaType = {
  konstanta_type: string;
};

type ErrorState = {
  id?: boolean;
  nama_konstanta?: boolean;
  konstanta_type?: boolean;
  value?: boolean;
};

const AddEditConstant: React.FC<AddEditConstantProps> = ({
  onCancel,
  onSubmit,
  state = {
    id: '',
    nama_konstanta: '',
    konstanta_type: '',
    value: '',
  },
  type = 'add',
}) => {
  const [formState, setFormState] = useState<AddEditConstantState>(state);
  const [constantsType, setConstantsType] = useState<KonstantaType[]>([]);
  const [error, setError] = useState<ErrorState>({});

  useUser(async (user, err) => {
    if (err) console.log(err);
    else {
      const res = await fetch(urlAPI + '/konstanta/filter', {
        headers: {
          Authorization: 'Bearer ' + user?.token,
        },
      });

      const data = await res.json();

      setConstantsType(data.konstantaTypes || []);
    }
  });

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    let err: ErrorState = {};

    if (!formState.id) err.id = true;
    if (!formState.konstanta_type) err.konstanta_type = true;
    if (!formState.nama_konstanta) err.nama_konstanta = true;
    if (!formState.value) err.value = true;

    if (Object.keys(err).length > 0) setError(err);
    else if (onSubmit) onSubmit(formState);
  };

  const handleChangeTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormState({
      ...formState,
      [name]: value,
    });

    setError({
      ...error,
      [name]: false,
    });
  };

  const handleChangeDropdown = (val: string, name: string) => {
    setFormState({
      ...formState,
      [name]: val,
    });

    setError({
      ...error,
      [name]: false,
    });
  };

  return (
    <div className="bg-white flex flex-col z-10 items-center rounded-lg px-16 py-12 fixed top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4 shadow-lg">
      <h2 className="text-title-2 font-extrabold mb-11">
        {type === 'edit' ? 'Edit' : 'Add New'} Device
      </h2>
      <form onSubmit={(e) => e.preventDefault()} className="flex flex-col mb-9">
        <TextField
          onChange={handleChangeTextField}
          value={formState.id}
          name="id"
          disabled={type === 'edit'}
          fullWidth
          labelText="Konstanta ID"
          placeholder="Masukan ID Konstanta"
          className="mb-4"
          errorMessage="This field is required"
          isError={error.id}
        />
        <TextField
          onChange={handleChangeTextField}
          value={formState.nama_konstanta}
          name="nama_konstanta"
          fullWidth
          labelText="Nama Konstanta"
          placeholder="Masukan nama konstanta"
          className="mb-4"
          errorMessage="This field is required"
          isError={error.nama_konstanta}
        />
        <Dropdown
          value={formState.konstanta_type}
          name="konstanta_type"
          onChange={handleChangeDropdown}
          labelText="Konstanta Type"
          width={340}
          placeholder="Pilih tipe konstanta"
          className="mb-4"
          errorMessage="This field is required"
          isError={error.konstanta_type}
        >
          {constantsType.map((val) => (
            <DropdownItem key={val.konstanta_type} value={val.konstanta_type}>
              {val.konstanta_type}
            </DropdownItem>
          ))}
        </Dropdown>
        <TextField
          onChange={handleChangeTextField}
          value={formState.value}
          name="value"
          fullWidth
          labelText="Konstanta Value"
          placeholder="Masukan konstanta value"
          errorMessage="This field is required"
          isError={error.value}
        />
      </form>
      <div className="flex items-center">
        <Button onClick={onCancel} className="mr-4" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSubmit} className="w-20">
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddEditConstant;
