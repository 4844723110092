import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import classNames from 'classnames';
import { IconEventNote } from 'assets';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';

interface DatePickerProps extends ReactDatePickerProps {
  containerClassName?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({
  disabled,
  containerClassName = '',
  ...props
}) => {
  return (
    <div
      className={classNames(
        'flex items-center border border-grey-1 pl-4',
        containerClassName,
        {
          'bg-grey-light': disabled,
          'rounded-lg': !/rounded-/g.test(containerClassName),
        }
      )}
    >
      <IconEventNote className="mr-2.5" />
      <ReactDatePicker
        className="text-caption-1 bg-transparent date-picker focus:outline-none text-grey font-semibold"
        disabled={disabled}
        {...props}
      />
    </div>
  );
};

export default DatePicker;
