import { useState } from 'react';
import { Button, TextField } from 'components';

interface AddEditRoleProps {
  onCancel?: () => void;
  onSubmit?: (state: AddEditRoleState) => void;
  state?: AddEditRoleState;
  type?: 'add' | 'edit';
}

export type AddEditRoleState = {
  id?: string;
  Role_name?: string;
};

type ErrorState = {
  id?: boolean;
  Role_name?: boolean;
};

const AddEditRole: React.FC<AddEditRoleProps> = ({
  onCancel,
  onSubmit,
  state = {
    id: '',
    Role_name: '',
  },
  type = 'add',
}) => {
  const [formState, setFormState] = useState<AddEditRoleState>(state);
  const [error, setError] = useState<ErrorState>();

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    let err: ErrorState = {};
    if (!formState.id) err.id = true;
    if (!formState.Role_name) err.Role_name = true;

    if (Object.keys(err).length > 0) setError(err);
    else if (onSubmit) onSubmit(formState);
  };

  const handleChangeTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormState({
      ...formState,
      [name]: value,
    });

    setError({
      ...error,
      [name]: false,
    });
  };

  return (
    <div className="bg-white flex flex-col z-10 items-center rounded-lg px-16 py-12 fixed top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4 shadow-lg">
      <h2 className="text-title-2 font-extrabold mb-11">
        {type === 'edit' ? 'Edit' : 'Add New'} Role
      </h2>
      <form onSubmit={(e) => e.preventDefault()} className="flex flex-col mb-9">
        <TextField
          onChange={handleChangeTextField}
          value={formState.id}
          name="id"
          fullWidth
          labelText="Role ID"
          placeholder="Masukan Role ID"
          className="mb-4"
          width={340}
          errorMessage="This field is required"
          isError={error?.id}
        />
        <TextField
          onChange={handleChangeTextField}
          value={formState.Role_name}
          name="Role_name"
          fullWidth
          labelText="Role Name"
          placeholder="Masukan nama role"
          errorMessage="This field is required"
          isError={error?.Role_name}
        />
      </form>
      <div className="flex items-center">
        <Button onClick={onCancel} className="mr-4" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSubmit} className="w-20">
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddEditRole;
