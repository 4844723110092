import { IconArrowBreadcrumb, IconClose, IconLaunch } from 'assets';
import classNames from 'classnames';
import { ContactFreezerColorMapping } from 'colors';
import { MultiSelectDropdown } from 'components';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useMemo, useState } from 'react';

interface BarProps extends IBaseChart {
  className?: string;
  queryKey?: string;
  chartTitle?: string;
  yAxisTitle?: string;
  averageTitle?: string;
  withHighest?: boolean;
  customizeAverage?: (value: number) => string;
}

const Bar: React.FC<BarProps> = ({
  className,
  chartTitle,
  yAxisTitle,
  averageTitle,
  withHighest = true,
  customizeAverage,
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [reRender, setReRender] = useState<boolean>(true);
  const [highest, setHeight] = useState<number>(0);

  const barChartOptions = useMemo<Highcharts.Options>(
    () => ({
      title: {
        text: '',
      },
      chart: {
        type: 'column',
      },
      xAxis: {
        type: 'category',
        gridLineWidth: 1,
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: yAxisTitle,
          rotation: -90,
        },
      },
      series: [
        {
          data: [
            {
              name: 'Supplier 1',
              color: ContactFreezerColorMapping[1],
              y: 8,
            },
            {
              name: 'Supplier 2',
              color: ContactFreezerColorMapping[2],
              y: 5,
            },
            {
              name: 'Supplier 3',
              color: ContactFreezerColorMapping[3],
              y: 7,
            },
            {
              name: 'Supplier 4',
              color: ContactFreezerColorMapping[4],
              y: 7,
            },
            {
              name: 'Supplier 5',
              color: ContactFreezerColorMapping[5],
              y: 7,
            },
            {
              name: 'Supplier 6',
              color: ContactFreezerColorMapping[6],
              y: 7,
            },
          ],
          name: chartTitle,
          type: 'column',
          borderRadius: 30,
        },
      ],
      legend: {
        enabled: false,
      },
    }),
    [chartTitle, yAxisTitle]
  );

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
    // Hack the chart so it can fill entire screen during fullscreen state
    setReRender(false);
    setTimeout(() => {
      setReRender(true);
    }, 0);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', className, {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
      })}
    >
      <button onClick={handleFullScreen} className="absolute right-5 top-5">
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">{chartTitle}</h4>
        <div className="grid grid-cols-4 gap-x-2 mb-5 relative z-20">
          <div></div>
          <div></div>
          <MultiSelectDropdown placeholder="Species" />
          <MultiSelectDropdown placeholder="Size" />
        </div>
        {withHighest && (
          <div className="flex justify-end">
            <button
              onClick={() => setHeight((highest + 1) % 3)}
              className="flex items-center focus:outline-none"
            >
              <span
                style={{ marginTop: 2 }}
                className="text-caption-1 font-bold text-blue"
              >
                Highest
              </span>
              {(highest === 0 || highest === 1) && (
                <IconArrowBreadcrumb
                  height={18}
                  width={18}
                  className="transform rotate-180 ic-blue"
                />
              )}
              {(highest === 0 || highest === 2) && (
                <IconArrowBreadcrumb
                  height={18}
                  width={18}
                  className={classNames('ic-blue', { '-ml-2': highest === 0 })}
                />
              )}
            </button>
          </div>
        )}

        {reRender && (
          <div>
            <HighchartsReact
              options={barChartOptions}
              highcharts={Highcharts}
            />
            <div className="flex items-center flex-wrap pl-10 mt-3">
              <p className="text-caption-1 font-bold text-grey">
                {averageTitle}:{' '}
                <span className="text-blue-2">
                  {customizeAverage ? customizeAverage(3) : 3}
                </span>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Bar;
