const SoakingUnitWorkersWageTable = () => {
  return (
    <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
      <div
        style={{ gridTemplateColumns: '80px repeat(9, 1fr)' }}
        className="grid grid-rows-2 gap-3 items-center font-semibold text-caption-1 py-2.5"
      >
        <div className="row-span-2">No</div>
        <div className="row-span-2">Name</div>
        <div className="row-span-2">Workers ID</div>
        <div className="row-span-2">Main Job</div>
        <div className="row-span-2">Main Unit</div>
        <div className="row-span-2">Date</div>
        <div className="col-span-4 text-center border-b border-grey-placeholder pb-2">
          Work Done
        </div>
        <div>Recorded Job</div>
        <div>Wage Type</div>
        <div>Quantity</div>
        <div>Wage</div>
      </div>
      {/* Put the children here later */}
      <div
        style={{
          gridTemplateColumns: '80px repeat(9, 1fr)',
          background: '#fbfbfb',
        }}
        className="grid gap-3 font-semibold text-caption-1 py-2.5"
      >
        <div className="col-span-9 text-right">TOTAL WAGE</div>
        <div>Rp 1.800.000</div>
      </div>
    </div>
  );
};

export default SoakingUnitWorkersWageTable;
