import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { IconFile } from 'assets';

interface LabInspectionCardProps {
  className?: string;
  timeMode?: string;
  approved?: boolean;
  color?: 'blue' | 'yellow' | 'purple' | 'green' | 'red';
  href?: string;
}

const LabInspectionCard: React.FC<LabInspectionCardProps> = ({
  className,
  timeMode,
  approved,
  color = 'yellow',
  href = '/lab-inspection-unit/report',
}) => {
  return (
    <Link to={href}>
      <div
        className={classNames(
          'px-8 py-6 border border-grey-light flex items-center rounded-lg',
          className
        )}
      >
        <IconFile className={`text-colorful-${color} mr-6`} />
        <div className="text-caption-1">
          <div className=" mb-2">
            Time Mode : <span className="font-bold">{timeMode}</span>
          </div>
          <div>
            Statue :{' '}
            {approved ? (
              <span className="text-colorful-green font-bold">Approved</span>
            ) : (
              <span className="font-bold">Not Approved</span>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default LabInspectionCard;
