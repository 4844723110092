import classNames from 'classnames';
import './index.css';

interface SwitchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  withoutMessage?: boolean;
}

const Switch: React.FC<SwitchProps> = ({
  checked,
  onChange,
  className = '',
  withoutMessage,
  ...otherProps
}) => {
  return (
    <label className={classNames('switch', { [className]: className })}>
      <input
        {...otherProps}
        onChange={onChange}
        checked={checked}
        type="checkbox"
      />
      <span className="slider round"></span>
      <span
        className={classNames('message text-caption-2 font-bold', {
          without: withoutMessage,
        })}
      ></span>
    </label>
  );
};

export default Switch;
