import { combineReducers } from '@reduxjs/toolkit';
import { errorReducer, ErrorState } from './errors/error.reducer';
import {
  filterDateReducer,
  FilterDateState,
} from './filterDates/filterDate.reducer';
import {
  TakeoutOrderState,
  takeoutOrderReducer,
} from './takeoutOrder/takeoutOrder.reducer';

export type RootReducerState = {
  error: ErrorState;
  filterDate: FilterDateState;
  takeoutOrder: TakeoutOrderState;
};

const rootReducer = combineReducers<RootReducerState>({
  error: errorReducer,
  filterDate: filterDateReducer,
  takeoutOrder: takeoutOrderReducer,
});

export { rootReducer };
