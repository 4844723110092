import { TableGrid } from 'components/universal';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { urlAPIUnit } from 'utils';

const SortingMachineUnitReportSummaryTable: React.FC<IBaseTable> = ({
  startDate,
  endDate,
}) => {
  const { data: incomingOutputPending } = useQuery<
    SortingMachineUnitIncomingOutputPendingResponse[]
  >(
    ['sorting-machine-unit-incoming-output-pending', startDate, endDate],
    async () => {
      const res = await fetch(
        urlAPIUnit +
          `/unit/sorting-machine/summary/incoming-output-pending/?start=${dayjs(
            startDate
          ).format('YYYY-MM-DD')}&end=${dayjs(endDate).format('YYYY-MM-DD')}`
      );
      const data = await res.json();

      return data.data;
    }
  );

  const { data: summary } = useQuery<SortingMachineUnitSummaryReponse>(
    ['sorting-machine-unit-summary', startDate, endDate],
    async () => {
      const res = await fetch(
        urlAPIUnit +
          `/unit/sorting-machine/summary/report/?start=${dayjs(
            startDate
          ).format('YYYY-MM-DD')}&end=${dayjs(endDate).format('YYYY-MM-DD')}`
      );
      const data = await res.json();

      return data.data;
    }
  );

  return (
    <>
      <h6 className="font-bold mb-2">Incoming, Output, and Pending</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              name: 'Species',
              field: 'nama_spesies',
            },
            {
              name: 'Input',
              field: 'input',
            },
            {
              name: 'Output',
              field: 'output',
            },
            {
              name: 'Shrinkage',
              field: 'shrinkage',
            },
            {
              name: 'Latest Pending',
              field: 'pending',
            },
          ]}
          data={Array.from(
            incomingOutputPending || [],
            ({ nama_spesies, data: { input, output, pending } }) => ({
              nama_spesies,
              input: input.toString(),
              output: output.toString(),
              pending: pending.toString(),
            })
          )}
        />
      </div>

      <div className="flex items-start mb-8">
        <div className="w-96 mr-20">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Cost Labor per Kg</div>
            <div>Rp 1.000</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Cost of Materials</div>
            <div>Rp 1.000</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Cost of Labor</div>
            <div>Rp 1.000</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Headcount</div>
            <div>8 People</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Working Time</div>
            <div>50 Man-Hours</div>
          </div>
        </div>
        <div className="w-96">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Cost of Material per Kg</div>
            <div>{summary?.costOfMaterialPerKg}</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Time</div>
            <div>+5.5 Hours</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Size Accuracay Machine</div>
            <div>97%</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Unit Productivity Rate</div>
            <div>50kg/Man-Hours</div>
          </div>
          <div className="flex justify-between text-caption-1">
            <div className="font-semibold">Unit Productivity</div>
            <div>{summary?.unitProductivity}</div>
          </div>
        </div>
      </div>
      <div className="w-80">
        <div className="flex justify-between mb-3 text-caption-1">
          <div className="font-semibold">Total Pending Raw Materials</div>
          <div>{summary?.totalPendingRawMaterial}</div>
        </div>
        <div className="flex justify-between mb-3 text-caption-1">
          <div className="font-semibold">Longest Pending Raw Materials</div>
          <div>{summary?.longestPending}</div>
        </div>
      </div>
    </>
  );
};

export default SortingMachineUnitReportSummaryTable;
