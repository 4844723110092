import { useState } from 'react';
import { IconAdd } from 'assets';
import {
  AddEditDevice,
  AddEditDeviceState,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  DeviceManagementTable,
  Dialog,
  FilterButton,
  Loading,
  ProceedConfirmation,
  TextField,
  type_node,
  Filter,
} from 'components';
import { IUser } from 'utils/types';
import { urlAPI } from 'utils/urlApi';
import { useUser } from 'utils/useUser';

const DeviceManagementPage = () => {
  const [addDevice, setAddDevice] = useState<boolean>(false);
  const [dialog, setDialog] = useState<boolean>(false);
  const [state, setState] = useState<AddEditDeviceState>();
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [types, setTypes] = useState<type_node[]>([]);
  const [filterState, setFilterState] = useState<{ [key: string]: any }>({});
  const [filterQuery, setFilterQuery] = useState<string>('');

  useUser((userFromLocal) => {
    setUser(userFromLocal);
  });

  useUser(async (userFromLocal) => {
    const res = await fetch(urlAPI + '/device/filter', {
      headers: {
        Authorization: 'Bearer ' + userFromLocal?.token,
      },
    });

    const data = await res.json();

    setTypes(data.deviceTypes || []);
  });

  const handleSubmitFilter = (val: { [key: string]: any }) => {
    setFilterState(val);
    setShowFilter(false);

    let filterQuery = Object.keys(val)
      .filter((value) => val[value])
      .join('@!@');

    if (Object.values(val).some((val) => val))
      setFilterQuery(`&type=${filterQuery}`);
    else setFilterQuery('');
  };

  const handleAddDevice = (val: AddEditDeviceState) => {
    setAddDevice(false);
    setDialog(true);

    // Set state to be sent to API below here
    setState(val);
  };

  const handleProceedDialog = async () => {
    // Fetch API to add Device here
    setLoading(true);
    const res = await fetch(urlAPI + '/device', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + user?.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(state),
    });

    if (res.status === 200) {
      setLoading(false);
      setDialog(false);
      setConfirmation(true);

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else console.log('Error');
  };

  const handleCancelDialog = () => {
    setDialog(false);
    setAddDevice(true);
  };

  const handleCancelAddEditDevice = () => {
    setAddDevice(false);
    setState(undefined);
  };

  const handleCancelProceedDialog = () => {
    setConfirmation(false);
    setState(undefined);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  return (
    <>
      {addDevice && (
        <AddEditDevice
          onCancel={handleCancelAddEditDevice}
          onSubmit={handleAddDevice}
          state={state}
        />
      )}
      {dialog && (
        <Dialog
          onSubmit={handleProceedDialog}
          onCancel={handleCancelDialog}
          heading="Confirm New Employee"
          message={`Do you really want to add ${state?.id} device?`}
          width={600}
        />
      )}
      {confirmation && (
        <ProceedConfirmation
          onClose={handleCancelProceedDialog}
          message={`The new device ${state?.nama_node} has been added`}
        />
      )}
      {showFilter && (
        <Filter
          filterState={filterState}
          onSubmit={handleSubmitFilter}
          filterChoices={types}
          onCancel={() => setShowFilter(false)}
          filterChoiceValueKey="type_node"
          filterChoiceLabelKey="type_node"
          heading="Choose Device Type"
        />
      )}
      {loading && <Loading />}
      <div className="user-management">
        <h1 className="text-heading font-bold mb-3">Device Management</h1>
        <Breadcrumb className="mb-5">
          <BreadcrumbItem>Home</BreadcrumbItem>
          <BreadcrumbItem isActive>Device Management</BreadcrumbItem>
        </Breadcrumb>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center">
            <h2 className="text-heading font-extrabold mr-8">Device List</h2>
            <TextField
              value={searchQuery}
              onChange={handleChange}
              placeholder="Cari nama device"
              variant="search"
            />
            <FilterButton
              onClick={() => setShowFilter(true)}
              className="ml-4"
            />
          </div>
          <div className="flex items-center">
            <Button onClick={() => setAddDevice(true)}>
              <IconAdd className="mr-2" />
              <span className="">Add New Device</span>
            </Button>
          </div>
        </div>
        <DeviceManagementTable
          filterQuery={filterQuery}
          searchQuery={searchQuery}
        />
      </div>
    </>
  );
};

export default DeviceManagementPage;
