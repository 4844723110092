export const checkDirection = (arr: Line) => {
  const pointOne = arr[0];
  const pointTwo = arr[1];

  if (pointOne[0] === pointTwo[0]) return 'vertical';

  if (pointOne[1] === pointTwo[1]) return 'horizontal';

  return 'diagonal';
};

export const crossedCheck = (lineOne: Line, lineTwo: Line) => {
  const lineOneDir = checkDirection(lineOne);
  const lineTwoDir = checkDirection(lineTwo);

  if (lineOneDir === lineTwoDir) return false;

  if (lineOneDir === 'horizontal') {
    if (
      lineOne[0][0] <= lineTwo[0][0] &&
      lineOne[1][0] >= lineTwo[0][0] &&
      lineTwo[0][1] <= lineOne[0][1] &&
      lineTwo[1][1] >= lineOne[0][1]
    ) {
      return true;
    }
  }

  if (lineOneDir === 'vertical') {
    if (
      lineTwo[0][0] <= lineOne[0][0] &&
      lineTwo[1][0] >= lineOne[0][0] &&
      lineOne[0][1] <= lineTwo[0][1] &&
      lineOne[1][1] >= lineTwo[0][1]
    ) {
      return true;
    }
  }

  return false;
};

export const comparePoint = (point1: Point, point2: Point) => {
  const [x1, y1] = point1;
  const [x2, y2] = point2;

  return x1 === x2 && y1 === y2;
};

export const findSnapPoint = (
  unitLayout: Omit<UnitLayout, 'key' | 'title'>
) => {
  const { x, y, height, width } = unitLayout;

  const top: Point = [x, y + height / 2];
  const left: Point = [x + width / 2, y];
  const right: Point = [x + width, y + height / 2];
  const bottom: Point = [x + width / 2, y + height];

  return [top, left, right, bottom];
};

export const whichSnapped = (
  unitLayout: Omit<UnitLayout, 'key' | 'title'>,
  point: Point
) => {
  const [top, left, right, bottom] = findSnapPoint(unitLayout);

  if (comparePoint(top, point)) return 'top';
  else if (comparePoint(left, point)) return 'left';
  else if (comparePoint(right, point)) return 'right';
  else if (comparePoint(bottom, point)) return 'bottom';

  return 'random';
};

export const findDistance = (coord1: Point, coord2: Point) => {
  const [x1, y1] = coord1;
  const [x2, y2] = coord2;

  return Math.hypot(x2 - x1, y2 - y1);
};

export const findClosestSnap = (
  snappingPoints: Point[],
  targetCoord: Point
) => {
  let lowestIndex = 0;
  let lowestValue = 0;

  snappingPoints.forEach((snapPoint, i) => {
    const d = findDistance(snapPoint, targetCoord);

    if (d < lowestValue || lowestValue === 0) {
      lowestValue = d;
      lowestIndex = i;
    }
  });

  return snappingPoints[lowestIndex];
};

export const findCenter = (unitLayout: Omit<UnitLayout, 'key' | 'title'>) => {
  const { x, y, width, height } = unitLayout;

  const point: Point = [x + width / 2, y + height / 2];

  return point;
};

export const checkPosition = (coord1: Point, coord2: Point) => {
  const h = coord2[0] - coord1[0];
  const v = coord2[1] - coord1[1];

  let posH: 'right' | 'left' = 'right';
  let posV: 'top' | 'bottom' = 'bottom';

  if (h < 0) posH = 'left';
  if (v < 0) posV = 'top';

  return { posH, posV };
};
