import {
  Breadcrumb,
  BreadcrumbItem,
  DatePicker,
  ManagementLeavePermissionTable,
} from 'components';
import { useState } from 'react';

const ManagementLeavePermissionPage = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  return (
    <div className="user-management">
      <h1 className="text-heading font-bold mb-3">Leave Permission</h1>
      <Breadcrumb className="mb-5">
        <BreadcrumbItem href="/management/">Home</BreadcrumbItem>
        <BreadcrumbItem isActive>Leave Permission</BreadcrumbItem>
      </Breadcrumb>
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <DatePicker
            selected={startDate}
            // @ts-ignore
            onChange={(date) => setStartDate(date)}
            dateFormat="E, d MMM yyyy"
            containerClassName="mr-3.5"
          />
          <DatePicker
            selected={endDate}
            // @ts-ignore
            onChange={(date) => setEndDate(date)}
            dateFormat="E, d MMM yyyy"
            popperPlacement="bottom-end"
          />
        </div>
      </div>
      <ManagementLeavePermissionTable startDate={startDate} endDate={endDate} />
    </div>
  );
};

export default ManagementLeavePermissionPage;
