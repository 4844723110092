import classNames from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState } from 'react';
import { IconClose, IconLaunch } from '../../../../assets';
import {
  ColdStorageColorMapping,
  ContactFreezerColorMapping,
} from '../../../../colors';
import { Button } from 'components';
import { ChartLegend, SortThreeState } from 'components';

interface LayeringUnitHeadcountPerformanceProps {
  className?: string;
}

const WorkersPerformanceOptions: Highcharts.Options = {
  title: {
    text: '',
  },
  chart: {
    type: 'column',
  },
  xAxis: {
    type: 'category',
    gridLineWidth: 1,
  },
  yAxis: {
    gridLineWidth: 0,
    title: {
      text: 'Kg / Hour',
      rotation: -90,
    },
  },
  series: [
    {
      data: [
        {
          name: 'Worker 1',
          color: ContactFreezerColorMapping[1],
          y: 8,
        },
        {
          name: 'Worker 2',
          color: ContactFreezerColorMapping[2],
          y: 5,
        },
        {
          name: 'Worker 3',
          color: ContactFreezerColorMapping[3],
          y: 7,
        },
        {
          name: 'Worker 4',
          color: ContactFreezerColorMapping[4],
          y: 7,
        },
        {
          name: 'Worker 5',
          color: ContactFreezerColorMapping[5],
          y: 7,
        },
        {
          name: 'Worker 6',
          color: ContactFreezerColorMapping[6],
          y: 7,
        },
      ],
      name: 'Headcount Performance',
      type: 'column',
      borderRadius: 30,
    },
  ],
  legend: {
    enabled: false,
  },
};

const OutputContributionOptions: Highcharts.Options = {
  title: {
    text: '',
  },
  yAxis: {
    gridLineWidth: 0,
    title: {
      text: 'kg',
    },
  },
  series: [
    {
      type: 'pie',
      data: [
        {
          name: 'White',
          y: 4,
          color: ColdStorageColorMapping[1],
          dataLabels: { enabled: false },
        },
        {
          name: 'Black Pink',
          y: 4,
          color: ColdStorageColorMapping[2],
          dataLabels: { enabled: false },
        },
      ],
      innerSize: '80%',
      size: '75%',
      name: 'Incoming Distribution',
    },
  ],
  legend: {
    enabled: false,
  },
  tooltip: {
    valueSuffix: ' kg',
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 60,
        },
      },
    ],
  },
};

type LayeringUnitHeadcountPerformanceActive =
  | 'Workers Performance'
  | 'Output-Contribution';

const LayeringUnitHeadcountPerformance: React.FC<LayeringUnitHeadcountPerformanceProps> =
  ({ className }) => {
    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const [reRender, setReRender] = useState<boolean>(true);
    const [highest, setHeight] = useState<number>(0);
    const [active, setActive] =
      useState<LayeringUnitHeadcountPerformanceActive>('Workers Performance');

    const handleFullScreen = () => {
      setFullScreen(!fullScreen);
      // Hack the chart so it can fill entire screen during fullscreen state
      setReRender(false);
      setTimeout(() => {
        setReRender(true);
      }, 0);
    };

    const handleActive = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      const { name } = e.currentTarget;

      setActive(name as LayeringUnitHeadcountPerformanceActive);
    };

    return (
      <div
        style={{ zIndex: fullScreen ? 1200 : 0 }}
        className={classNames('flex justify-center items-center', className, {
          relative: !fullScreen,
          'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        })}
      >
        <button onClick={handleFullScreen} className="absolute right-5 top-5">
          {!fullScreen ? <IconLaunch /> : <IconClose />}
        </button>
        <div className="w-full h-full border rounded-lg border-grey-light p-6">
          <h4 className="text-footnote font-bold mb-8">Headcount Peformance</h4>
          <div className="flex justify-end items-center mb-3.5">
            <Button
              onClick={handleActive}
              name="Workers Performance"
              color={active === 'Workers Performance' ? 'default' : 'grey'}
              className="mr-1.5"
            >
              Workers Performance
            </Button>
            <Button
              onClick={handleActive}
              name="Output-Contribution"
              color={active === 'Output-Contribution' ? 'default' : 'grey'}
            >
              Output Contribution
            </Button>
          </div>
          {active === 'Workers Performance' && (
            <div className="flex justify-end">
              <SortThreeState value={highest} onChange={(h) => setHeight(h)} />
            </div>
          )}
          <h4 className="text-footnote font-bold mb-8">
            {active.split('-').join(' ')}
          </h4>
          {reRender && (
            <>
              {active === 'Workers Performance' && (
                <div>
                  <HighchartsReact
                    options={WorkersPerformanceOptions}
                    highcharts={Highcharts}
                  />
                </div>
              )}

              {active === 'Output-Contribution' && (
                <div>
                  <div className="grid grid-cols-3 items-center">
                    <div className="col-span-2">
                      <HighchartsReact
                        options={OutputContributionOptions}
                        highcharts={Highcharts}
                      />
                    </div>
                    <div>
                      <ChartLegend
                        title="4 kg"
                        percentage="50"
                        className="mb-4"
                        color={ColdStorageColorMapping[1]}
                      />
                      <ChartLegend
                        title="4 kg"
                        percentage="50"
                        color={ColdStorageColorMapping[2]}
                      />
                    </div>
                  </div>
                  <div className="flex items-center flex-wrap pl-10 mt-3">
                    <ChartLegend
                      className="m-3"
                      color={ColdStorageColorMapping[1]}
                      title="Worker 1"
                    />
                    <ChartLegend
                      className="m-3"
                      color={ColdStorageColorMapping[2]}
                      title="Worker 2"
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

export default LayeringUnitHeadcountPerformance;
