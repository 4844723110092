import classNames from 'classnames';
import dayjs from 'dayjs';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { IconClose, IconLaunch } from 'assets';
import { ColdStorageColorMapping } from 'colors';
import { urlAPIMechanic } from 'utils';
import { ChartLegend } from 'components';

interface MachineElectricityCostProps {
  className?: string;
  startDate?: string;
  endDate?: string;
}

type MachineElectricityCostData = {
  id: number;
  nama_node: string;
  electricityCost: number;
};

const MachineElectricityCost: React.FC<MachineElectricityCostProps> = ({
  className = '',
  startDate = dayjs().subtract(3, 'days').format('YYYY-MM-DD'),
  endDate = dayjs().format('YYYY-MM-DD'),
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [reRender, setReRender] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(0);
  const [options, setOptions] = useState<Highcharts.Options>({
    title: {
      text: '',
    },
    series: [],
    legend: {
      enabled: false,
    },
    tooltip: {
      valueSuffix: ' Million',
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 60,
          },
        },
      ],
    },
  });

  const { data } = useQuery<MachineElectricityCostData[]>(
    ['machineElectricityCost', startDate, endDate],
    () =>
      fetch(
        urlAPIMechanic +
          `/mechanic/machine/dashboard/electricity-cost?start=${startDate}&end=${endDate}`
      )
        .then((res) => res.json())
        .then((data) => data.data),
    {
      onSuccess: (data) => {
        let total = 0;
        let manipulatedData = data.map((val, i) => {
          let s: Highcharts.PointOptionsObject = {
            name: val.nama_node,
            color:
              ColdStorageColorMapping[
                (i % Object.keys(ColdStorageColorMapping).length) + 1
              ],
            y: Number(val.electricityCost.toFixed(2)),
            dataLabels: {
              enabled: false,
            },
          };
          total += val.electricityCost;
          return s;
        });

        let series: Highcharts.SeriesOptionsType[] = [
          {
            name: 'Machine Electricity Cost',
            type: 'pie',
            innerSize: '80%',
            size: '75%',
            data: manipulatedData,
          },
        ];

        setTotal(total);

        setOptions({
          ...options,
          series,
        });
      },
    }
  );

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
    // Hack the chart so it can fill entire screen during fullscreen state
    setReRender(false);
    setTimeout(() => {
      setReRender(true);
    }, 0);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        [className]: className,
      })}
    >
      <button onClick={handleFullScreen} className="absolute right-5 top-5">
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">
          Machine Electricity Cost
        </h4>
        <div className="grid grid-cols-3 gap-3 items-center">
          <div className="col-span-2">
            {reRender && (
              <HighchartsReact options={options} highcharts={Highcharts} />
            )}
          </div>
          <div>
            {data?.map((val, i) => (
              <ChartLegend
                key={`label-percentage-electricity-cost-${val.id}`}
                percentage={((val.electricityCost / total) * 100).toFixed(0)}
                color={
                  ColdStorageColorMapping[
                    (i % Object.keys(ColdStorageColorMapping).length) + 1
                  ]
                }
                title={val.nama_node}
                className="mb-3"
              />
            ))}
          </div>
        </div>
        <p className="text-caption-1 font-bold text-grey pl-10 mt-3">
          Total Electricity Cost &nbsp;
          <span className="text-blue-2">{total.toFixed(2)} Million</span>
        </p>
        <div className="flex items-center flex-wrap pl-10 mt-3">
          {data?.map((val, i) => (
            <ChartLegend
              key={`label-electricity-cost-${val.id}`}
              className="mr-3"
              color={
                ColdStorageColorMapping[
                  (i % Object.keys(ColdStorageColorMapping).length) + 1
                ]
              }
              title={val.nama_node}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MachineElectricityCost;
