import { IconArrowBreadcrumb } from 'assets';
import classNames from 'classnames';
import { useState } from 'react';

type Column = {
  field?: string;
  width?: string;
  name?: string;
  Component?: JSX.Element;
  cols?: string[];
  rows?: number;
  merge?: {
    fieldToMerge: string[];
    mergeInto: string;
    excludeRows?: number[];
    style?: React.CSSProperties;
    enabled?: (data: Record<string, string | string[]>[]) => boolean;
  }[];
  textAlign?: 'left' | 'center' | 'right';
};

interface TableGridProps {
  data?: Record<string, string | string[]>[];
  columns?: Column[];
  onRenderField?: (
    field: string,
    value: any,
    ctx: {
      className: string;
      key: string;
      data: Record<string, string | string[]>;
    }
  ) => any;
  className?: string;
  config?: {
    textColorHead?: 'black' | 'grey-1' | 'grey-2' | 'blue';
    minWidth?: string;
  };
  rowsClassName?: string;
  collapse?: (data: Record<string, string | string[]>) => JSX.Element;
  fixWidth?: string;
}

const TableGrid: React.FC<TableGridProps> = ({
  columns,
  data,
  className,
  config,
  onRenderField,
  rowsClassName,
  collapse,
  fixWidth = '1fr'
}) => {
  const [activeCollapse, setActiveCollapse] = useState<Record<string, boolean>>(
    {}
  );

  const gridTemplateColumns = columns
    ?.map(({ width }) => {
      if (!width) return 'minmax(auto, 1fr)';

      return width;
    })
    .join(' ');

  if (!config) config = {};

  const { textColorHead = 'black', minWidth } = config;

  return (
    <div style={{ minWidth }} className={className}>
      <div
        style={{ gridTemplateColumns, minWidth  }}
        className={`grid items-center font-semibold text-caption-1 gap-y-2 gap-x-4 border-t mb-3 text-center border-b w-full`}
      >
        {columns?.map(
          ({ name = '', field = '', Component, textAlign = 'left' }, i) => {
            if (Component) return Component;

            return (
              <div
                key={`${field}-${name}-${i}`}
                className={`text-${textColorHead} text-${textAlign} font-semibold`}
              >
                {name}
              </div>
            );
          }
        )}
      </div>
      <div style={{ minWidth }} className="w-full">
        {data?.map((value, i) => {
          return (
            <div
              key={`${value}-${i}`}
              onClick={() => {
                if (collapse !== undefined)
                  setActiveCollapse({
                    ...activeCollapse,
                    [i]: !activeCollapse[i],
                  });
              }}
              className={classNames('', rowsClassName, {
                'relative cursor-pointer': collapse !== undefined,
                'even:bg-white-snow': !rowsClassName,
              })}
            >
              <div
                style={{ gridTemplateColumns }}
                className={classNames(
                  'grid items-center text-caption-1 gap-y-2 gap-x-4 ',
                  {
                    relative: collapse !== undefined,
                  }
                )}
              >
                {collapse !== undefined && (
                  <IconArrowBreadcrumb
                    className={classNames(
                      'ic-black w-6 h-6 absolute right-2 top-2/4 transform -translate-y-1/2 transition-all',
                      {
                        '-rotate-90': activeCollapse[i],
                        'rotate-90': !activeCollapse[i],
                      }
                    )}
                  />
                )}
                {columns?.map(({ field = '', cols, rows, merge }, index) => {
                  const key = `${field}-${value[field]}-${index}`;
                  const className = 'text-black';

                  const isAnyRows = rowCheck(value);

                  if (cols && rows && isAnyRows) {
                    const gridTemplateColumns = `repeat(${cols.length}, ${fixWidth})`;
                    const gridTemplateRows = `repeat(${maxRowCheck(
                      value,
                      rows
                    )}, 1fr)`;

                    return (
                      <div
                        style={{
                          gridTemplateColumns,
                          gridTemplateRows,
                          gridAutoFlow: 'column',
                        }}
                        className="grid my-4 items-center h-full"
                        key={key}
                      >
                        {cols.map((field, i) => {
                          const dummyArray = [];

                          for (let i = 0; i < rows; i++) {
                            dummyArray.push(i + 1);
                          }

                          return (
                            <div key={`${field}-${i}`} className='contents'>
                              {dummyArray.map((number) => {
                                const fieldValue = value[`${field}.${number}`];

                                if (fieldValue === undefined) return null;

                                if (merge)
                                  for (let j = 0; j < merge?.length; j++) {
                                    const mergeItem = merge[j];
                                    const { enabled = () => true } = mergeItem;
                                    if (
                                      mergeItem?.fieldToMerge.includes(
                                        `${field}.${number}`
                                      ) &&
                                      enabled(data)
                                    ) {
                                      const {
                                        colSpan,
                                        rowSpan,
                                        rowStart,
                                        rowEnd,
                                      } = parseMergeField(
                                        mergeItem.fieldToMerge
                                      );

                                      const gridColumn = `span ${colSpan}`;
                                      const gridRow = `span ${rowSpan}`;

                                      if (
                                        `${field}.${number}` ===
                                        mergeItem.fieldToMerge[0]
                                      ) {
                                const borderLeft = i === 0 ? 'border-l' : '';
                                const borderTop = number === 1 ? 'border-t' : '';
                                        return (
                                          <div
                                            key={`${field}.${number}-${i}`}
                                            style={{
                                              gridColumn,
                                              gridRow,
                                              gridRowStart: rowStart,
                                              gridRowEnd: rowEnd,
                                              ...mergeItem.style,
                                            }}
                                            className={`w-full h-full border-b border-r text-center border-grey-2 ${borderLeft} ${borderTop} flex items-center justify-center`}
                                          >
                                            <p className="break-all">{value[mergeItem.mergeInto]}</p>                                                                                
                                          </div>
                                        );
                                      }

                                      return null;
                                    }
                                  }

                                if (typeof fieldValue === 'object') {
                                  const val = fieldValue as string[];

                                  const gridTemplateRows = `repeat(${val.length}, 1fr)`;

                                  return (
                                    <div
                                      style={{ gridTemplateRows }}
                                      className="grid my-4 items-center h-full"
                                      key={`${field}.${number}-${i}`}
                                    >
                                      {val?.map((str, i) => {
                                        const renderField = onRenderField
                                          ? onRenderField(field, str, {
                                              key: `${str}-${i}`,
                                              className: '',
                                              data: value,
                                            })
                                          : undefined;

                                        if (renderField) return renderField;

                                        return (
                                          <div key={`${str}-${i}`}>{str}</div>
                                        );
                                      })}
                                    </div>
                                  );
                                }

                                const renderField = onRenderField
                                  ? onRenderField(
                                      field,
                                      value[`${field}.${number}`],
                                      {
                                        key: `${field}.${number}-${i}`,
                                        className: '',
                                        data: value,
                                      }
                                    )
                                  : undefined;

                                if (renderField) return renderField;

                                const borderLeft = i === 0 ? 'border-l' : '';
                                const borderTop = number === 1 ? 'border-t' : '';

                                

                                return (
                                  <div key={`${field}.${number}-${i}`} className={`w-full h-full border-b border-r text-center border-grey-2 ${borderLeft} ${borderTop} flex items-center justify-center`}>
                                    <p className="break-all">{fieldValue} {!fieldValue && '-'}</p> 
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    );
                  }

                  if (rows && isAnyRows) {
                    const gridTemplateRows = `repeat(${maxRowCheck(
                      value,
                      rows
                    )}, 1fr)`;

                    const dummyArray = [];

                    for (let i = 0; i < rows; i++) {
                      dummyArray.push(i + 1);
                    }

                    return (
                      <div
                        style={{ gridTemplateRows }}
                        className="grid my-4 items-center h-full"
                        key={key}
                      >
                        {dummyArray.map((number, i) => {
                          if (value[`${field}.${number}`] === undefined)
                            return null;

                          const renderField = onRenderField
                            ? onRenderField(
                                field,
                                value[`${field}.${number}`],
                                {
                                  key: `${field}.${number}-${i}`,
                                  className: '',
                                  data: value,
                                }
                              )
                            : undefined;

                          if (renderField) return renderField;
                          return (
                            <div key={`${field}.${number}-${i}`}>
                              {value[`${field}.${number}`]}
                            </div>
                          );
                        })}
                      </div>
                    );
                  }

                  if (cols) {
                    const gridTemplateColumns = `repeat(${cols.length}, ${fixWidth})`;

                    return (
                      <div
                        style={{ gridTemplateColumns }}
                        className="grid my-4 items-center h-full"
                        key={key}
                      >
                        {cols.map((field, i) => {
                          if (typeof value[field] === 'object') {
                            const val = value[field] as string[];

                            const gridTemplateRows = `repeat(${val.length}, 1fr)`;

                            return (
                              <div
                                style={{ gridTemplateRows }}
                                className="grid my-4 items-center h-full"
                                key={key}
                              >
                                {val?.map((str, i) => {
                                  const renderField = onRenderField
                                    ? onRenderField(field, str, {
                                        key: `${str}-${i}`,
                                        className: '',
                                        data: value,
                                      })
                                    : undefined;

                                  if (renderField) return renderField;

                                  return <div key={`${str}-${i}`}>{str}</div>;
                                })}
                              </div>
                            );
                          }

                          const renderField = onRenderField
                            ? onRenderField(field, value[field], {
                                key: `${field}-${i}`,
                                className: '',
                                data: value,
                              })
                            : undefined;

                          if (renderField) return renderField;

                          return (
                            <div key={`${field}-${i}`}>{value[field]}</div>
                          );
                        })}
                      </div>
                    );
                  }

                  if (Array.isArray(value[field])) {
                    const val = value[field] as string[];

                    const gridTemplateRows = `repeat(${val.length}, 1fr)`;

                    return (
                      <div
                        style={{ gridTemplateRows }}
                        className="grid my-4 items-center h-full"
                        key={key}
                      >
                        {val?.map((str, i) => {
                          const renderField = onRenderField
                            ? onRenderField(field, str, {
                                key,
                                className,
                                data: value,
                              })
                            : undefined;

                          if (renderField) return renderField;

                          return <div key={`${str}-${i}`}>{str}</div>;
                        })}
                      </div>
                    );
                  }

                  const renderField = onRenderField
                    ? onRenderField(field, value[field], {
                        key,
                        className,
                        data: value,
                      })
                    : undefined;

                  if (renderField) return renderField;

                  return <div key={key}>{value[field]}</div>;
                })}
              </div>
              {collapse !== undefined && activeCollapse[i] && collapse(value)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TableGrid;

const rowCheck = (obj: Record<string, any>) => {
  return Object.keys(obj).some((key) => {
    return parseKey(key);
  });
};

const maxRowCheck = (obj: Record<string, any>, max?: number): number => {
  let number = 0;

  Object.keys(obj).forEach((key) => {
    if (parseKey(key)) {
      const num = key.split('.')[1];

      number = Math.max(number, Number(num));
    }
  });

  if (max) return max < number ? max : number;

  return number;
};

const parseKey = (key: string): boolean => {
  const number = Number(key.split('.')[1]);

  if (isNaN(number)) return false;

  return true;
};

const parseMergeField = (fieldToMerge: string[]) => {
  const cols = fieldToMerge.map((string) => {
    return string.split('.')[0];
  });

  const rows = fieldToMerge.map((string) => {
    return Number(string.split('.')[1]);
  });

  const colSpan = Array.from(new Set(cols)).length;
  const rowSpan = Math.max(...rows) + 1 - Math.min(...rows);

  return {
    colSpan,
    rowSpan,
    rowStart: Math.min(...rows),
    rowEnd: Math.max(...rows) + 1,
  };
};
