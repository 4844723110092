export class TableAdapter {
    tableData;
    cols;
    worksheet: Worksheet;
    constructor(tableData: TableData, cols: Array<string>) {
        this.tableData = tableData;
        this.cols = cols;
        this.worksheet = new Worksheet(this.cols, this.tableData.getRowSize());
    };

    getTableGridData(): ITableGridPackage {
        this.fill();
        return this.worksheet.getTableGridData();
    }

    fill() {
        this.fillTableData(1, 0, this.tableData)
    }

    fillTableData(row:number, column:number, tableData: TableData) {
        const rowSize = tableData.getRowSize();
        let currentRow = row;
        let currentColumn = column;
        for (const value of tableData.getValues()) {
            const isMerge = rowSize!==1;
            if(isMerge) {
                this.worksheet.mergeCells(currentRow, currentColumn, currentRow+rowSize-1, currentColumn);
            }
            this.worksheet.setValue(currentRow, currentColumn, String(value), isMerge);
            currentColumn++;
        }
        for (const rowChildren of tableData.children) {
            let rowCurrentColumn = currentColumn;
            for (const columnChild of rowChildren) {
                this.fillTableData(currentRow, rowCurrentColumn, columnChild);
                rowCurrentColumn = rowCurrentColumn + columnChild.getColumnSize();
            }
            currentRow = currentRow + Math.max(...(rowChildren.map(columnChild => columnChild.getRowSize())));
        }
    }
};

class Worksheet {
    fieldToMerge: IMerge[];
    data: ITableGridRowData;

    constructor(private cols:string[], private rowSize:number){
        this.fieldToMerge = [];
        this.data = {};
        for (let colIndex = 0; colIndex < this.cols.length; colIndex++) {
            const col = this.cols[colIndex];
            for (let rowIndex = 1; rowIndex <= rowSize; rowIndex++) {
                this.data[`${col}.${rowIndex}`] = '';
            }
        }
    }

    mergeCells(rowStart: number, colStart: number, rowEnd: number, colEnd: number){
        if(colStart !== colEnd) {
            console.error("TableAdapter.Worksheet: Merge column not supported yet");
        } else {
            const col=this.cols[colStart];
            let rowMergeIndex: number[] = [];
            for (let index = rowStart; index < rowEnd+1; index++) {
                rowMergeIndex = [
                    ...rowMergeIndex,
                    index
                ]
            }
            const fieldToMerge = rowMergeIndex.map(rowIndex => (col+'.'+String(rowIndex)));
            this.fieldToMerge = [
                ...this.fieldToMerge,
                {
                    fieldToMerge: fieldToMerge,
                    mergeInto: `${col}Field.${rowStart}`
                }
            ]
        }
    }

    setValue(row: number, col: number, value: string, merge: boolean){
        this.data[`${this.cols[col]}${merge?'Field':''}.${row}`] = value;
    }

    getTableGridData(): ITableGridPackage {
        return {
            rowSize: this.rowSize,
            tableGridData: {
                data: this.data,
                merge: this.fieldToMerge
            }
        };
    }
}

export class TableData {
    cellValues;
    children: TableData[][];

    constructor(plain: ITableDataPlain) {
        this.cellValues = plain.values;
        this.children = plain.children.map(row => row.map(plain => new TableData(plain)));
    }

    get values() {
        return this.getValues();
    }


    getValues() {
        return this.cellValues;
    }

    getColumnSize(): number {
        return this.values.length + this.children.reduce((rowChildColumnSize: number, rowChild: TableData[]) => {
            const newRowChildColumnSize = rowChild.reduce(
                (columnSize, columnChild)=>columnSize+columnChild.getColumnSize()
            , 0);
            return (rowChildColumnSize>newRowChildColumnSize ? rowChildColumnSize : newRowChildColumnSize);
        }, 0);
    }

    getRowSize(): number {
        const childrenRowSize = this.children.reduce((rowChildRowSize, rowChild) => {
            return rowChildRowSize+rowChild.reduce((rowSize, columnChild)=>{
                const newRowSize = columnChild.getRowSize();
                return (rowSize>newRowSize ? rowSize : newRowSize);
            }, 0);
        }, 0);
        return (childrenRowSize > 0 ? childrenRowSize : (this.values.length !== 0 ? 1:0));
    }

    get plain(): ITableDataPlain {
        const childrenResult = this.children.map(
            childrenRows => childrenRows.map(
                child => child.plain
            )
        );

        const result = {
            values: this.values,
            children: childrenResult
        }
        return result;
    }

    toJSON() {
        return this.plain;
    } 
}



export const transformTableData = async (data:any, key:string, callbackFn: transformFunction) => {
    const targetKeys = Object.keys(data)?.filter((e:string) => e?.includes(key));
    // console.log('target keys', targetKeys);
    let i = 0;
    let addedData = data;
    while (i < targetKeys?.length) {
        addedData[targetKeys[i]] = await callbackFn(data[targetKeys[i]]);
        i++;
    }
    console.log('added data', JSON.stringify(addedData));
    return addedData;
}

export function transformHeaderToColumn(header: ITableGridMainHeader){
    let cols:string[] = [];
    for (let index = 0; index < header.length; index++) {
        const tableGridheader = header[index];
        cols = [
            ...cols,
            ...transformSubHeaderToColumn(tableGridheader)
        ]
    }
    return cols;
}

function transformSubHeaderToColumn(subHeader: ITableGridHeader | string, prefix: string = ""): string[]{
    if(typeof subHeader === "string") {
        return [prefix+subHeader];
    }
    if(typeof (subHeader?.main) === "string") {
        return subHeader.sub.reduce<Array<string>>(
            (result, currentSub) => {
                const currentResult = transformSubHeaderToColumn(currentSub, prefix+subHeader.main);
                return [
                    ...result,
                    ...currentResult
                ];
            }, 
            []
        );
    }
    return [];
}

export class DynamicHeader {
    constructor(private header: ITableGridHeader) {};
    
}