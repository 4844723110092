import SoakingUnitSummaryTable from './summary';
import SoakingUnitSoakingAnalysisTable from './soaking-analysis';
import SoakingUnitPerformanceTable from './performance';
import SoakingUnitWorkersWageTable from './workers-wage';
import SoakingUnitWorkersLogTable from './workers-log';
import SoakingUnitProcesLogTable from './process-log';

export {
  SoakingUnitSummaryTable,
  SoakingUnitSoakingAnalysisTable,
  SoakingUnitPerformanceTable,
  SoakingUnitWorkersWageTable,
  SoakingUnitWorkersLogTable,
  SoakingUnitProcesLogTable,
};
