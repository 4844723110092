import classNames from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useState } from 'react';
import { IconClose, IconLaunch } from '../../../../assets';
import { ColdStorageColorMapping } from '../../../../colors';
import { Button } from 'components';
import { ChartLegend, MultiSelectDropdown } from 'components';

interface NobashiUnitProductionProps {
  className?: string;
}

const AccumulatedProductionOptions: Highcharts.Options = {
  title: {
    text: '',
  },
  xAxis: {
    type: 'datetime',
    title: {
      text: 'Hours',
    },
    dateTimeLabelFormats: {
      hour: '%H:%M',
    },
    gridLineWidth: 1,
  },
  yAxis: {
    gridLineWidth: 0,
    title: {
      text: null,
    },
    labels: {
      format: '{value} ton',
    },
  },
  series: [
    {
      type: 'spline',
      name: 'Incoming',
      data: [2, 3, 4, 5, 6, 34, 3, 2],
      marker: {
        enabled: false,
      },
      color: ColdStorageColorMapping[1],
    },
    {
      type: 'spline',
      name: 'Outcoming',
      data: [1, 3, 9, 15, 2, 14, 9, 4],
      marker: {
        enabled: false,
      },
      color: ColdStorageColorMapping[2],
    },
    {
      type: 'spline',
      name: 'Broken',
      data: [2, 6, 1, 5, 6, 22, 4, 7],
      marker: {
        enabled: false,
      },
      color: ColdStorageColorMapping[3],
    },
    {
      type: 'spline',
      name: 'Rejected',
      data: [4, 31, 19, 15, 12, 4, 9, 8],
      marker: {
        enabled: false,
      },
      color: ColdStorageColorMapping[4],
    },
  ],
  plotOptions: {
    line: {
      lineWidth: 4,
      states: {
        hover: {
          lineWidth: 5,
        },
      },
      pointInterval: 60 * 60 * 1000,
      marker: {
        enabled: false,
      },
    },
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    valueSuffix: ' Celcius',
  },
};

const BrokenAndRejectedDistributionOptions: Highcharts.Options = {
  title: {
    text: '',
  },
  yAxis: {
    gridLineWidth: 0,
    title: {
      text: 'kg',
    },
  },
  series: [
    {
      type: 'pie',
      data: [
        {
          name: 'White',
          y: 4,
          color: ColdStorageColorMapping[1],
          dataLabels: { enabled: false },
        },
        {
          name: 'Black Pink',
          y: 2,
          color: ColdStorageColorMapping[2],
          dataLabels: { enabled: false },
        },
        {
          name: 'Black Pink',
          y: 2,
          color: ColdStorageColorMapping[4],
          dataLabels: { enabled: false },
        },
      ],
      innerSize: '80%',
      size: '75%',
      name: 'Incoming Distribution',
    },
  ],
  legend: {
    enabled: false,
  },
  tooltip: {
    valueSuffix: ' kg',
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 60,
        },
      },
    ],
  },
};

type NobashiUnitProductionActive =
  | 'Accumulated Production'
  | 'Broken & Rejected Distribution'
  | 'Incoming Distribution'
  | 'Output-Distribution';

const NobashiUnitProduction: React.FC<NobashiUnitProductionProps> = ({
  className,
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [reRender, setReRender] = useState<boolean>(true);
  const [active, setActive] = useState<NobashiUnitProductionActive>(
    'Accumulated Production'
  );

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
    // Hack the chart so it can fill entire screen during fullscreen state
    setReRender(false);
    setTimeout(() => {
      setReRender(true);
    }, 0);
  };

  const handleActive = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { name } = e.currentTarget;

    setActive(name as NobashiUnitProductionActive);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', className, {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
      })}
    >
      <button onClick={handleFullScreen} className="absolute right-5 top-5">
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">Production</h4>
        <div className="flex justify-end items-center mb-3.5">
          <Button
            onClick={handleActive}
            name="Accumulated Production"
            color={active === 'Accumulated Production' ? 'default' : 'grey'}
            className="mr-1.5"
          >
            Accumulated Production
          </Button>
          <Button
            onClick={handleActive}
            name="Broken & Rejected Distribution"
            color={
              active === 'Broken & Rejected Distribution' ? 'default' : 'grey'
            }
            className="mr-1.5"
          >
            Broken & Rejected Distribution
          </Button>
          <Button
            onClick={handleActive}
            name="Incoming Distribution"
            color={active === 'Incoming Distribution' ? 'default' : 'grey'}
            className="mr-1.5"
          >
            Incoming Distribution
          </Button>
          <Button
            onClick={handleActive}
            name="Output-Distribution"
            color={active === 'Output-Distribution' ? 'default' : 'grey'}
          >
            Output Distribution
          </Button>
        </div>
        {active === 'Accumulated Production' && (
          <div className="grid gap-3 grid-cols-4">
            <div></div>
            <div></div>
            <div></div>
            <MultiSelectDropdown placeholder="Species" />
          </div>
        )}
        {active === 'Broken & Rejected Distribution' && (
          <div className="grid gap-3 grid-cols-4">
            <div></div>
            <div></div>
            <MultiSelectDropdown placeholder="Species" />
            <MultiSelectDropdown placeholder="Size" />
          </div>
        )}
        {active === 'Incoming Distribution' && (
          <div className="grid gap-3 grid-cols-4 mt-4">
            <div></div>
            <div></div>
            <MultiSelectDropdown placeholder="Species" />
            <MultiSelectDropdown placeholder="Size" />
          </div>
        )}
        {active === 'Output-Distribution' && (
          <div className="grid gap-3 grid-cols-4 mt-4">
            <div></div>
            <MultiSelectDropdown placeholder="Product" />
            <MultiSelectDropdown placeholder="Species" />
            <MultiSelectDropdown placeholder="Size" />
          </div>
        )}
        <h4 className="text-footnote font-bold mb-8">
          {active.split('-').join(' ')}
        </h4>
        {reRender && (
          <>
            {active === 'Accumulated Production' && (
              <div>
                <HighchartsReact
                  options={AccumulatedProductionOptions}
                  highcharts={Highcharts}
                />
                <div className="flex items-center justify-center mt-4">
                  <ChartLegend
                    title="Incoming"
                    color={ColdStorageColorMapping[1]}
                    className="mr-8"
                  />
                  <ChartLegend
                    title="Outcoming"
                    color={ColdStorageColorMapping[2]}
                    className="mr-8"
                  />
                  <ChartLegend
                    title="Broken"
                    color={ColdStorageColorMapping[3]}
                    className="mr-8"
                  />
                  <ChartLegend
                    title="Rejected"
                    color={ColdStorageColorMapping[4]}
                  />
                </div>
              </div>
            )}
            {active === 'Broken & Rejected Distribution' && (
              <div>
                <div className="grid grid-cols-3 items-center">
                  <div className="col-span-2">
                    <HighchartsReact
                      options={BrokenAndRejectedDistributionOptions}
                      highcharts={Highcharts}
                    />
                  </div>
                  <div>
                    <ChartLegend
                      title="4 kg"
                      percentage="50"
                      className="mb-4"
                      color={ColdStorageColorMapping[1]}
                    />
                    <ChartLegend
                      title="2 kg"
                      percentage="25"
                      className="mb-4"
                      color={ColdStorageColorMapping[2]}
                    />
                    <ChartLegend
                      title="2 kg"
                      percentage="25"
                      color={ColdStorageColorMapping[4]}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-center flex-wrap pl-10 mt-3">
                  <ChartLegend
                    className="m-3"
                    color={ColdStorageColorMapping[1]}
                    title="Output"
                  />
                  <ChartLegend
                    className="m-3"
                    color={ColdStorageColorMapping[2]}
                    title="Broken"
                  />
                  <ChartLegend
                    className="m-3"
                    color={ColdStorageColorMapping[4]}
                    title="Rejected"
                  />
                </div>
              </div>
            )}
            {active === 'Incoming Distribution' && (
              <div>
                <div
                  style={{
                    gridTemplateColumns:
                      'repeat(auto-fit, minmax(0, max-content))',
                  }}
                  className="grid gap-3"
                >
                  <label className="flex-items-center text-caption-1 font-bold text-grey mr-3">
                    <input
                      value="species"
                      name="quantity"
                      className="mr-2"
                      type="radio"
                    />
                    <span>Species</span>
                  </label>
                  <label className="flex-items-center text-caption-1 font-bold text-grey mr-3">
                    <input
                      value="size"
                      name="quantity"
                      className="mr-2"
                      type="radio"
                    />
                    <span>Size</span>
                  </label>
                </div>
                <div className="grid grid-cols-3 items-center">
                  <div className="col-span-2">
                    <HighchartsReact
                      options={BrokenAndRejectedDistributionOptions}
                      highcharts={Highcharts}
                    />
                  </div>
                  <div>
                    <ChartLegend
                      title="4 kg"
                      percentage="50"
                      className="mb-4"
                      color={ColdStorageColorMapping[1]}
                    />
                    <ChartLegend
                      title="4 kg"
                      percentage="50"
                      color={ColdStorageColorMapping[2]}
                    />
                  </div>
                </div>
                <div className="flex items-center flex-wrap pl-10 mt-3">
                  <ChartLegend
                    className="m-3"
                    color={ColdStorageColorMapping[1]}
                    title="White"
                  />
                  <ChartLegend
                    className="m-3"
                    color={ColdStorageColorMapping[2]}
                    title="Black Pink"
                  />
                </div>
              </div>
            )}
            {active === 'Output-Distribution' && (
              <div>
                <div
                  style={{
                    gridTemplateColumns:
                      'repeat(auto-fit, minmax(0, max-content))',
                  }}
                  className="grid gap-3"
                >
                  <label className="flex-items-center text-caption-1 font-bold text-grey mr-3">
                    <input
                      value="product"
                      name="quantity"
                      className="mr-2"
                      type="radio"
                    />
                    <span>Product</span>
                  </label>
                  <label className="flex-items-center text-caption-1 font-bold text-grey mr-3">
                    <input
                      value="species"
                      name="quantity"
                      className="mr-2"
                      type="radio"
                    />
                    <span>Species</span>
                  </label>
                  <label className="flex-items-center text-caption-1 font-bold text-grey mr-3">
                    <input
                      value="size"
                      name="quantity"
                      className="mr-2"
                      type="radio"
                    />
                    <span>Size</span>
                  </label>
                </div>
                <div className="grid grid-cols-3 items-center">
                  <div className="col-span-2">
                    <HighchartsReact
                      options={BrokenAndRejectedDistributionOptions}
                      highcharts={Highcharts}
                    />
                  </div>
                  <div>
                    <ChartLegend
                      title="4 kg"
                      percentage="50"
                      className="mb-4"
                      color={ColdStorageColorMapping[1]}
                    />
                    <ChartLegend
                      title="4 kg"
                      percentage="50"
                      color={ColdStorageColorMapping[2]}
                    />
                  </div>
                </div>
                <div className="flex items-center flex-wrap pl-10 mt-3">
                  <ChartLegend
                    className="m-3"
                    color={ColdStorageColorMapping[1]}
                    title="White"
                  />
                  <ChartLegend
                    className="m-3"
                    color={ColdStorageColorMapping[2]}
                    title="Black Pink"
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default NobashiUnitProduction;
