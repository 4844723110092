import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import {
  Breadcrumb,
  BreadcrumbItem,
  DatePicker,
  MachineCondition,
  MachineElectricityCost,
  MachinePowerConsumption,
  MachineProductivity,
  MachineRunTime,
  MachineVibration,
} from 'components';
import { SetEndDate, SetStartDate } from 'redux/filterDates/filterDate.action';
import { RootReducerState } from 'redux/root-reducer';

const MachinePerformancePage = () => {
  const startDate = useSelector(
    (state: RootReducerState) => state.filterDate.machineDashboard.startDate
  );
  const endDate = useSelector(
    (state: RootReducerState) => state.filterDate.machineDashboard.endDate
  );

  const dispatch = useDispatch();

  return (
    <div className="user-management">
      <h1 className="text-heading font-bold mb-3">
        Dashboard Machine Performance
      </h1>
      <Breadcrumb className="mb-5">
        <BreadcrumbItem href="/mechanic/overview">Home</BreadcrumbItem>
        <BreadcrumbItem href="/mechanic/machine-performance">
          Machine Performance
        </BreadcrumbItem>
        <BreadcrumbItem isActive>Dashboard</BreadcrumbItem>
      </Breadcrumb>
      <div className="flex justify-between items-center mb-8">
        <div></div>
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <DatePicker
            selected={new Date(startDate)}
            onChange={(date: Date) =>
              dispatch(
                SetStartDate(
                  'machineDashboard',
                  dayjs(date).format('YYYY-MM-DD')
                )
              )
            }
            dateFormat="E, d MMM yyyy"
            containerClassName="mr-3.5"
          />
          <DatePicker
            selected={new Date(endDate)}
            onChange={(date: Date) =>
              dispatch(
                SetEndDate('machineDashboard', dayjs(date).format('YYYY-MM-DD'))
              )
            }
            dateFormat="E, d MMM yyyy"
            popperPlacement="bottom-end"
          />
        </div>
      </div>
      <div className="grid-cols-2 grid gap-6">
        <MachinePowerConsumption startDate={startDate} endDate={endDate} />
        <MachineElectricityCost startDate={startDate} endDate={endDate} />
        <MachineCondition />
        <MachineRunTime startDate={startDate} endDate={endDate} />
        <MachineVibration startDate={startDate} endDate={endDate} />
        <MachineProductivity />
      </div>
    </div>
  );
};

export default MachinePerformancePage;
