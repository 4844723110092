import { TableGrid } from 'components';
  import { useQuery } from 'react-query';
  import { urlAPIUnit } from 'utils';
  import { TableAdapter, TableData, transformTableData } from 'utils/table-adapter';
  import dayjs from 'dayjs';
 
const WorkersLogTable: React.FC<ProcessLogProperty> = ({start_date, end_date, unit}) => { 
    const cols: Array<string> = [
      'no',
      'name',
      'nik',
      'loginTime',
      'logoutTime',
      'jobName',
    ];
  
    const transformDateTime = (dateTime: any) => {
      return (dateTime !== 'null' ? dayjs(dateTime).format('DD-MM-YYYY HH:mm') : '-');
    }
  
    const { data } = useQuery<ITableGridPackage>(['receiving-login', start_date, end_date], async () => {
      console.log('run');
      const result = await fetch(urlAPIUnit + `/general/login/${unit}?start_date=${start_date}&end_date=${end_date}`);
      const data = await result.json();
      const tableData: TableData = new TableData(data?.data);
      console.log(JSON.stringify(tableData.plain));
      const tableAdapter = new TableAdapter(tableData, cols);
      const tableGridData = tableAdapter.getTableGridData();
      const dataLogin = await transformTableData(tableGridData?.tableGridData?.data, 'loginTime', transformDateTime);
      const dataLogout = await transformTableData(dataLogin, 'logoutTime', transformDateTime);
      tableGridData.tableGridData.data = await dataLogout;
      return tableGridData;
    });
  
    return (
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              Component: (
                <div
                  key="workers-wage-main-column"
                  className="grid grid-cols-6 grid-rows-2 items-center"
                >
                    {
                        ['No', 'Name', 'NIK', 'Login Time', 'Logout Time', 'Job Type'].map((item, index) => (
                            <div key ={index} className={`row-span-3 border-r ${!index ? 'border-l': ''}`}>{item}</div>
                        ))
                    }
                </div>
              ),
              cols: cols,
              width: '6fr',
              rows: data?.rowSize,
              merge: data?.tableGridData?.merge,
            },
          ]}
          data={[data?.tableGridData?.data || {}]}
        />
      </div>
    );
  };
  

export default WorkersLogTable;