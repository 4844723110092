import { TableGrid } from 'components';

const FreezingUnitWorkersWageTable = () => {
  return (
    <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
      <TableGrid
        columns={[
          {
            field: 'no',
            name: 'No.',
            width: '40px',
          },
          {
            Component: (
              <div
                key="workers-wage-main-column"
                className="grid grid-cols-9 grid-rows-2 items-center"
              >
                <div className="row-span-2">Name</div>
                <div className="row-span-2">Workers ID</div>
                <div className="row-span-2">Main Job</div>
                <div className="row-span-2">Main Unit</div>
                <div className="row-span-2">Date</div>
                <div className="col-span-4 text-center border-b border-grey-placeholder pb-2">
                  Work Done
                </div>
                <div>Recorded Job</div>
                <div>Wage Type</div>
                <div>Quantity</div>
                <div>Wage</div>
              </div>
            ),
            cols: [
              'name',
              'workersID',
              'mainJob',
              'mainUnit',
              'date',
              'recordedJob',
              'wageType',
              'quantity',
              'wage',
            ],
            width: '9fr',
            rows: 5,
            merge: [
              {
                fieldToMerge: [
                  'name.4',
                  'workersID.4',
                  'mainJob.4',
                  'mainUnit.4',
                  'date.4',
                  'recordedJob.4',
                  'wageType.4',
                  'quantity.4',
                ],
                mergeInto: 'totalWage',
                style: {
                  textAlign: 'center',
                  background: '#fbfbfb',
                  padding: '12px 0',
                },
              },
              {
                fieldToMerge: ['name.1', 'name.2', 'name.3'],
                mergeInto: 'fieldName',
              },
              {
                fieldToMerge: ['workersID.1', 'workersID.2', 'workersID.3'],
                mergeInto: 'fieldWorkersID',
              },
              {
                fieldToMerge: ['mainJob.1', 'mainJob.2', 'mainJob.3'],
                mergeInto: 'fieldMainJob',
              },
              {
                fieldToMerge: ['mainUnit.1', 'mainUnit.2', 'mainUnit.3'],
                mergeInto: 'fieldMainUnit',
              },
              {
                fieldToMerge: ['wage.4'],
                mergeInto: 'totalWageValue',
                style: {
                  background: '#fbfbfb',
                  padding: '12px 0',
                },
              },
            ],
          },
        ]}
        data={[
          {
            no: '1',
            'name.1': '',
            'name.2': '',
            'name.3': '',
            'name.4': '',
            fieldName: 'Justin',
            'workersID.1': '',
            'workersID.2': '',
            'workersID.3': '',
            'workersID.4': '',
            fieldWorkersID: '137687623',
            'mainJob.1': '',
            'mainJob.2': '',
            'mainJob.3': '',
            'mainJob.4': '',
            fieldMainJob: 'Sorting Worker',
            'mainUnit.1': '',
            'mainUnit.2': '',
            'mainUnit.3': '',
            'mainUnit.4': '',
            fieldMainUnit: 'Nobashi Unit',
            'date.1': '26-Jan-21',
            'date.2': '26-Jan-21',
            'date.3': '26-Jan-21',
            'date.4': '',
            'recordedJob.1': 'Receiving Worker',
            'recordedJob.2': 'Receiving Worker',
            'recordedJob.3': 'Receiving Worker',
            'recordedJob.4': '',
            'wageType.1': 'Highest Paid',
            'wageType.2': 'Highest Paid',
            'wageType.3': 'Highest Paid',
            'wageType.4': '',
            'quantity.1': '1 ls',
            'quantity.2': '1 ls',
            'quantity.3': '1 ls',
            'quantity.4': '',
            'wage.1': 'Rp 125.000',
            'wage.2': 'Rp 125.000',
            'wage.3': 'Rp 125.000',
            'wage.4': 'Rp 375.000',
            totalWage: 'Total Wage',
            totalWageValue: 'Rp 375.000',
          },
          {
            no: '2',
            'name.1': '',
            'name.2': '',
            'name.3': '',
            'name.4': '',
            fieldName: 'Justin',
            'workersID.1': '',
            'workersID.2': '',
            'workersID.3': '',
            'workersID.4': '',
            fieldWorkersID: '137687623',
            'mainJob.1': '',
            'mainJob.2': '',
            'mainJob.3': '',
            'mainJob.4': '',
            fieldMainJob: 'Sorting Worker',
            'mainUnit.1': '',
            'mainUnit.2': '',
            'mainUnit.3': '',
            'mainUnit.4': '',
            fieldMainUnit: 'Nobashi Unit',
            'date.1': '26-Jan-21',
            'date.2': '26-Jan-21',
            'date.3': '26-Jan-21',
            'date.4': '',
            'recordedJob.1': 'Receiving Worker',
            'recordedJob.2': 'Receiving Worker',
            'recordedJob.3': 'Receiving Worker',
            'recordedJob.4': '',
            'wageType.1': 'Highest Paid',
            'wageType.2': 'Highest Paid',
            'wageType.3': 'Highest Paid',
            'wageType.4': '',
            'quantity.1': '1 ls',
            'quantity.2': '1 ls',
            'quantity.3': '1 ls',
            'quantity.4': '',
            'wage.1': 'Rp 125.000',
            'wage.2': 'Rp 125.000',
            'wage.3': 'Rp 125.000',
            'wage.4': 'Rp 375.000',
            totalWage: 'Total Wage',
            totalWageValue: 'Rp 375.000',
          },
        ]}
      />
    </div>
  );
};

export default FreezingUnitWorkersWageTable;
