const LayeringUnitProductMaterialCostTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">
        Average Material Cost by Product, Grade, Size, and Species
      </h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <div
          style={{ gridTemplateColumns: '40px repeat(6, 1fr)' }}
          className="grid items-center font-semibold text-caption-1 gap-y-2 gap-x-4 px-2 py-2.5"
        >
          <div>No.</div>
          <div>Product</div>
          <div>Grade</div>
          <div>Size</div>
          <div>Species</div>
          <div>Cost</div>
          <div>Cost/kg</div>
        </div>
        {/* Put the children here later */}
      </div>
    </>
  );
};

export default LayeringUnitProductMaterialCostTable;
