import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import {
  Breadcrumb,
  BreadcrumbItem,
  ColdStorageCriticalTemp,
  ColdStorageDoorStatus,
  ColdStorageTemp,
  DatePicker,
  DoorStatusAndTemp,
  DoorStatusDistribution,
} from 'components';
import { SetEndDate, SetStartDate } from 'redux/filterDates/filterDate.action';
import { RootReducerState } from 'redux/root-reducer';

const ColdStoragePerforamancePage = () => {
  const startDate = useSelector(
    (state: RootReducerState) => state.filterDate.coldStorageDashboard.startDate
  );
  const endDate = useSelector(
    (state: RootReducerState) => state.filterDate.coldStorageDashboard.endDate
  );

  const dispatch = useDispatch();

  return (
    <div style={{ minWidth: 1200 }}>
      <h1 className="text-heading font-bold mb-3">Cold Storage Performance</h1>
      <Breadcrumb className="mb-5">
        <BreadcrumbItem href="/mechanic/overview">Home</BreadcrumbItem>
        <BreadcrumbItem href="/mechanic/cold-storage">
          Cold Storage
        </BreadcrumbItem>
        <BreadcrumbItem isActive>Dashboard</BreadcrumbItem>
      </Breadcrumb>
      <div className="flex justify-between items-center mb-8">
        <div></div>
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <DatePicker
            selected={new Date(startDate)}
            onChange={(date: Date) =>
              dispatch(
                SetStartDate(
                  'coldStorageDashboard',
                  dayjs(date).format('YYYY-MM-DD')
                )
              )
            }
            dateFormat="E, d MMM yyyy"
            containerClassName="mr-3.5"
          />
          <DatePicker
            selected={new Date(endDate)}
            onChange={(date: Date) =>
              dispatch(
                SetEndDate(
                  'coldStorageDashboard',
                  dayjs(date).format('YYYY-MM-DD')
                )
              )
            }
            dateFormat="E, d MMM yyyy"
            popperPlacement="bottom-end"
          />
        </div>
      </div>
      <div className="grid-cols-2 grid gap-6">
        <ColdStorageTemp startDate={startDate} endDate={endDate} />
        <ColdStorageDoorStatus startDate={startDate} endDate={endDate} />
        <DoorStatusAndTemp startDate={startDate} endDate={endDate} />
        <ColdStorageCriticalTemp startDate={startDate} endDate={endDate} />
        <DoorStatusDistribution startDate={startDate} endDate={endDate} />
      </div>
    </div>
  );
};

export default ColdStoragePerforamancePage;
