import classNames from 'classnames';
import dayjs from 'dayjs';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { IconClose, IconLaunch } from 'assets';
import { ColdStorageColorMapping } from 'colors';
import { urlAPIMechanic } from 'utils';
import { ChartLabel } from 'components';

interface MachinePowerConsumptionProps {
  className?: string;
  startDate?: string;
  endDate?: string;
}

type MachinePowerConsumptionData = {
  id: number;
  nama_node: string;
  data: Array<{
    id: number;
    data1: number;
    createdAt: string;
  }>;
};

const MachinePowerConsumption: React.FC<MachinePowerConsumptionProps> = ({
  className = '',
  startDate = dayjs(new Date()).subtract(3, 'days').format('YYYY-MM-DD'),
  endDate = dayjs(new Date()).format('YYYY-MM-DD'),
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [reRender, setReRender] = useState<boolean>(true);
  const [selected, setSelected] = useState<{ [key: string]: boolean }>({});
  const [total, setTotal] = useState<number>(0);
  const [immutableSeries, setImmutableSeries] = useState<
    Highcharts.SeriesLineOptions[]
  >([]);
  const [options, setOptions] = useState<Highcharts.Options>({
    title: {
      text: '',
    },
    xAxis: {
      gridLineWidth: 1,
      type: 'datetime',
      title: {
        text: 'Hours',
      },
      dateTimeLabelFormats: {
        hour: '%H:%M',
      },
    },
    yAxis: {
      gridLineWidth: 0,
      labels: {
        format: '{value} W',
      },
      title: {
        text: null,
      },
    },
    series: [],
    plotOptions: {
      line: {
        lineWidth: 4,
        states: {
          hover: {
            lineWidth: 5,
          },
        },
        pointInterval: 60 * 60 * 1000,
        marker: {
          enabled: false,
        },
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      valueSuffix: ' Watt',
    },
  });

  const { data } = useQuery<MachinePowerConsumptionData[]>(
    ['machinePowerConsumption', startDate, endDate],
    () =>
      fetch(
        urlAPIMechanic +
          `/mechanic/machine/dashboard/power-consumption?start=${startDate}&end=${endDate}`
      ).then((res) => res.json().then((data) => data.data)),
    {
      onSuccess: (data) => {
        let selected: { [key: string]: boolean } = {};
        let manipulatedData = data.map((val, i) => {
          let colorIndex =
            (i % Object.keys(ColdStorageColorMapping).length) + 1;

          let s: Highcharts.SeriesOptionsType = {
            id: val.id.toString(),
            name: val.nama_node,
            color: ColdStorageColorMapping[colorIndex],
            type: 'line',
            data: val.data.map((seriesData) => ({
              x: dayjs(new Date(seriesData.createdAt)).valueOf(),
              y: seriesData.data1,
            })),
          };

          selected[val.id] = true;

          return s;
        });

        setSelected(selected);

        let total = 0;
        data.forEach((val) => {
          total += val.data.reduce(
            (acc, currentVal) => acc + currentVal.data1,
            0
          );
        });

        setTotal(total);

        // cloning manipulated data with spread operator
        setImmutableSeries([...manipulatedData]);

        setOptions({
          ...options,
          series: manipulatedData,
        });
      },
    }
  );

  const handleChangeLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    setSelected({
      ...selected,
      [name]: checked,
    });
    let series = options.series;

    if (!checked) {
      const s = series?.find((val) => val.id === name);

      if (s && series) {
        let index = series.indexOf(s);
        series.splice(index, 1);
      }
      setOptions({
        ...options,
        series,
      });
    } else {
      let s = immutableSeries.find((val) => val.id === name);

      if (s) {
        series?.push(s);
      }

      setOptions({
        ...options,
        series,
      });
    }
    let total = 0;
    series?.forEach((val) => {
      // @ts-ignore
      total += val.data.reduce((acc, currentVal) => acc + currentVal.y, 0);
    });

    setTotal(total);
  };

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
    // Hack the chart so it can fill entire screen during fullscreen state
    setReRender(false);
    setTimeout(() => {
      setReRender(true);
    }, 0);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        [className]: className,
      })}
    >
      <button onClick={handleFullScreen} className="absolute right-5 top-5">
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">
          Machine Power Consumption
        </h4>
        {reRender && (
          <HighchartsReact options={options} highcharts={Highcharts} />
        )}
        <p className="text-caption-1 text-grey pl-10 font-bold">
          Total Energy Consumption{' '}
          <span className="text-blue-2">{total} kWh</span>
        </p>
        <div className="flex items-center flex-wrap pl-10 mt-3">
          {data?.map((val, i) => (
            <ChartLabel
              bgColor={
                ColdStorageColorMapping[
                  (i % Object.keys(ColdStorageColorMapping).length) + 1
                ]
              }
              title={val.nama_node}
              className="mx-1.5 my-2"
              name={val.id.toString()}
              onChange={handleChangeLabel}
              key={`power-consumption-${val.id}`}
              checked={selected[val.id.toString()]}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MachinePowerConsumption;
