import classNames from 'classnames';
import { useState } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  CardUnit,
  DatePicker,
  LabInspectionUnitHeadcountPerformance,
  LabInspectionUnitProcessingSpeed,
  LabInspectionUnitWorkers,
} from 'components';

const LabsInspectionUnitDashboardPage = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  return (
    <div style={{ minWidth: 1200 }}>
      <h1 className="text-heading font-bold mb-3">
        Lab Inspection Unit Dashboard
      </h1>
      <Breadcrumb className="mb-5">
        <BreadcrumbItem href="/unit/">Home</BreadcrumbItem>
        <BreadcrumbItem href="/unit/labs-inspection-unit">
          Lab Inspection Unit
        </BreadcrumbItem>
        <BreadcrumbItem isActive>Dashboard</BreadcrumbItem>
      </Breadcrumb>
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <DatePicker
            selected={startDate}
            // @ts-ignore
            onChange={(date) => setStartDate(date)}
            dateFormat="E, d MMM yyyy"
            containerClassName="mr-3.5"
          />
          <DatePicker
            selected={endDate}
            // @ts-ignore
            onChange={(date) => setEndDate(date)}
            dateFormat="E, d MMM yyyy"
            popperPlacement="bottom-end"
          />
        </div>
      </div>
      <div className={classNames('grid gap-x-4 mb-8', 'grid-cols-4')}>
        <CardUnit
          color="green"
          title="Total Inspection Frequency:"
          value="100"
        />
        <CardUnit
          color="purple"
          title="Total Inspection Frequency General:"
          value="20"
        />
        <CardUnit
          color="blue"
          title="Total Inspection Frequency PTO:"
          value="21"
        />
        <CardUnit
          color="green"
          title="Total Inspection Speed:"
          value="50 Inspection / day"
        />
      </div>
      <div className="grid grid-cols-2 gap-6">
        <LabInspectionUnitWorkers />
        <LabInspectionUnitHeadcountPerformance />
        <LabInspectionUnitProcessingSpeed />
      </div>
    </div>
  );
};

export default LabsInspectionUnitDashboardPage;
