import dayjs from 'dayjs';
import { Reducer, Action } from '@reduxjs/toolkit';

export type FilterDateState = {
  machineDashboard: Dates;
  machineReport: Dates;
  coldStorageDashboard: Dates;
  coldStorageReport: Dates;
  overviewReport: Dates;
  sortingMachineUnitReport: Dates;
  receivingUnitReport: Dates;
  finalSortingUnitReport: Dates;
  cookingUnitReport: Dates;
  finalSortingPeeledUnitReport: Dates;
  layeringUnitReport: Dates;
  nobashiUnitReport: Dates;
  peelingUnitReport: Dates;
  soakingUnitReport: Dates;
  freezingUnitReport: Dates;
  iqfUnitReport: Dates;
  packingCFUnitReport: Dates;
  coldStorageUnitReport: Dates;
  finalSortingFiveStarUnitReport: Dates;
  distributionUnitReport: Dates;
};

export enum FilterDateReducerActionTypes {
  SET_START_DATE = 'SET_START_DATE',
  SET_END_DATE = 'SET_END_DATE',
}

interface FilterDateAction extends Action<any> {
  payload: {
    key: keyof FilterDateState;
    value: any;
  };
}

const initialDate = {
  startDate: dayjs().format('YYYY-MM-DD'),
  endDate: dayjs().format('YYYY-MM-DD'),
};

const INITIAL_STATE: FilterDateState = {
  machineDashboard: { ...initialDate },
  machineReport: { ...initialDate },
  coldStorageDashboard: { ...initialDate },
  coldStorageReport: { ...initialDate },
  overviewReport: { ...initialDate },
  sortingMachineUnitReport: { ...initialDate },
  receivingUnitReport: { ...initialDate },
  finalSortingUnitReport: { ...initialDate },
  cookingUnitReport: {...initialDate},
  finalSortingPeeledUnitReport: {...initialDate},
  layeringUnitReport: {...initialDate},
  nobashiUnitReport: {...initialDate},
  peelingUnitReport: {...initialDate},
  soakingUnitReport: {...initialDate},
  freezingUnitReport: {...initialDate},
  iqfUnitReport: {...initialDate},
  packingCFUnitReport: {...initialDate},
  coldStorageUnitReport: {...initialDate},
  finalSortingFiveStarUnitReport: {...initialDate},
  distributionUnitReport: {...initialDate}
};

export const filterDateReducer: Reducer<FilterDateState, FilterDateAction> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case FilterDateReducerActionTypes.SET_START_DATE:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          startDate: action.payload.value,
        },
      };
    case FilterDateReducerActionTypes.SET_END_DATE:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          endDate: action.payload.value,
        },
      };
    default:
      return state;
  }
};
