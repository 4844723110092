const SoakingUnitSummaryTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">Incoming and Output</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <div className="grid grid-cols-8 items-center font-semibold text-caption-1 gap-y-2 gap-x-4 px-2 py-2.5">
          <div>Treatment</div>
          <div>Product</div>
          <div>Grade</div>
          <div>Size</div>
          <div>Species</div>
          <div>Input</div>
          <div>Output</div>
          <div>Latest Tampung</div>
        </div>
        {/* Put the children here later */}
      </div>

      <div className="flex items-start mb-8">
        <div className="w-96 mr-20">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Cost Labor per Kg</div>
            <div>Rp 1.000</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Average Batch Time</div>
            <div>4 hours</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Cost of Labor</div>
            <div>Rp 1.000</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Headcount</div>
            <div>8 People</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Working Time</div>
            <div>50 Man-Hours</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Time</div>
            <div>50 Hours</div>
          </div>
        </div>
        <div className="w-96">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Average Stiring Time</div>
            <div>Rp 1.000</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Treatment</div>
            <div>5 treatments</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Average Soaking Time</div>
            <div>3 hours</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Average Preparation Time</div>
            <div>3 hours</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Unit Productivity Rate</div>
            <div>50kg/Man-Hours</div>
          </div>
          <div className="flex justify-between text-caption-1">
            <div className="font-semibold">Unit Productivity</div>
            <div>500kg/Hour</div>
          </div>
        </div>
      </div>
      <div className="flex items-start">
        <div className="w-96 mr-20">
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Latest Tampung Ulang</div>
            <div>100 Kg</div>
          </div>
        </div>
        <div className="w-80">
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Total Incoming</div>
            <div>100 Kg</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Total Output</div>
            <div>100 Kg</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Yield</div>
            <div>100 %</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SoakingUnitSummaryTable;
