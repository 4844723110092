import dayjs from 'dayjs';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import { urlAPIMechanic } from 'utils';

interface MachineConsumptionTableProps {
  startDate?: string;
  endDate?: string;
}

type MachineConsumptionTableData = {
  id: number;
  nama_node: string;
  totalConsumption: number;
  avgConsumption: number;
  electricityCost: number;
};

const MachineConsumptionTable: React.FC<MachineConsumptionTableProps> = ({
  startDate = dayjs().subtract(3, 'days').format('YYYY-MM-DD'),
  endDate = dayjs().format('YYYY-MM-DD'),
}) => {
  const { data } = useQuery<MachineConsumptionTableData[]>(
    ['machineConsumptionTable', startDate, endDate],
    () =>
      fetch(
        urlAPIMechanic +
          `/mechanic/machine/report/consumption?start=${startDate}&end=${endDate}`
      )
        .then((res) => res.json())
        .then((data) => data.data)
  );

  return (
    <div className="rounded-lg px-8 py-7 border border-grey-1">
      <div className="grid grid-cols-4 font-semibold text-caption-1 py-2.5 px-2 gap-2.5">
        <div>Machine Name</div>
        <div>Avg Power Consumption</div>
        <div>Energy Consumption</div>
        <div>Electricity Cost</div>
      </div>
      {/* Put the children here later */}
      {data?.map((val, i) => (
        <div
          key={`machine-consumption-table-${val.id}`}
          className={classNames(
            'grid grid-cols-4 text-caption-1 py-2.5 px-2 gap-2.5',
            { 'bg-white-snow': i % 2 }
          )}
        >
          <div>{val.nama_node}</div>
          <div>{val.avgConsumption} W</div>
          <div>{val.totalConsumption} kWh</div>
          <div>{val.electricityCost.toFixed(2)} IDR</div>
        </div>
      ))}
      <div className="grid grid-cols-4 text-caption-1 py-2.5 mt-11 gap-2.5">
        <div></div>
        <div className="font-semibold">Total Avg Power Consumption</div>
        <div className="font-semibold">Total Energy Consumption</div>
        <div className="font-semibold">Total Electricity Cost</div>
      </div>
      <div className="grid grid-cols-4 text-caption-1 py-2.5 px-2 gap-2.5">
        <div></div>
        <div>
          {data?.reduce(
            (acc, currentVal) => acc + currentVal.avgConsumption,
            0
          )}{' '}
          W
        </div>
        <div>
          {data?.reduce(
            (acc, currentVal) => acc + currentVal.totalConsumption,
            0
          )}{' '}
          kWh
        </div>
        <div>
          {data
            ?.reduce((acc, currentVal) => acc + currentVal.electricityCost, 0)
            .toFixed(2)}{' '}
          IDR
        </div>
      </div>
    </div>
  );
};

export default MachineConsumptionTable;
