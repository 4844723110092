import { TableGrid } from 'components';

const ColdStorageUnitLatestStockTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">MC Branded</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9 overflow-auto">
        <TableGrid
          className="mb-6"
          columns={[
            {
              field: 'product',
              name: 'Product',
            },
            {
              field: 'supplier',
              name: 'Supplier',
            },
            {
              field: 'size',
              name: 'Size',
              width: '0.5fr',
            },
            {
              field: 'species',
              name: 'Species',
            },
            {
              field: 'quantity',
              name: 'Quantity',
              cols: ['quantity1', 'quantity2'],
            },
          ]}
          data={[
            {
              product: 'HL Treatment',
              supplier: ['Budi', 'Bambang'],
              size: ['8-12', '8-12'],
              species: ['Black Tiger', 'Black Tiger'],
              quantity1: ['100 MC', '100 MC'],
              quantity2: ['100 Kg', '100 Kg'],
            },
            {
              no: 'S-2',
              salesOrder: 'Jepang-0012',
              product: ['HL Treatment', 'PPD Treatment'],
              supplier: ['Budi', 'Bambang'],
              size: ['8-12', '8-12'],
              species: ['Black Tiger', 'Black Tiger'],
              quantity1: ['100 MC', '100 MC'],
              quantity2: ['100 Kg', '100 Kg'],
            },
          ]}
          onRenderField={(field, value, { key }) => {
            if (field.startsWith('inner'))
              return (
                <div className="text-center" key={key}>
                  {value}
                </div>
              );
          }}
        />
      </div>

      <h6 className="font-bold mb-2">MC No Brand</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9 overflow-auto">
        <TableGrid
          className="mb-6"
          columns={[
            {
              field: 'product',
              name: 'Product',
            },
            {
              field: 'supplier',
              name: 'Supplier',
            },
            {
              field: 'size',
              name: 'Size',
              width: '0.5fr',
            },
            {
              field: 'species',
              name: 'Species',
            },
            {
              field: 'quantity',
              name: 'Quantity',
              cols: ['quantity1', 'quantity2'],
            },
          ]}
          data={[
            {
              product: 'HL Treatment',
              supplier: ['Budi', 'Bambang'],
              size: ['8-12', '8-12'],
              species: ['Black Tiger', 'Black Tiger'],
              quantity1: ['100 MC', '100 MC'],
              quantity2: ['100 Kg', '100 Kg'],
            },
            {
              no: 'S-2',
              salesOrder: 'Jepang-0012',
              product: ['HL Treatment', 'PPD Treatment'],
              supplier: ['Budi', 'Bambang'],
              size: ['8-12', '8-12'],
              species: ['Black Tiger', 'Black Tiger'],
              quantity1: ['100 MC', '100 MC'],
              quantity2: ['100 Kg', '100 Kg'],
            },
          ]}
          onRenderField={(field, value, { key }) => {
            if (field.startsWith('inner'))
              return (
                <div className="text-center" key={key}>
                  {value}
                </div>
              );
          }}
        />
      </div>

      <h6 className="font-bold mb-2">MCO</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9 overflow-auto">
        <TableGrid
          className="mb-6"
          columns={[
            {
              field: 'product',
              name: 'Product',
            },
            {
              field: 'supplier',
              name: 'Supplier',
            },
            {
              field: 'size',
              name: 'Size',
              width: '0.5fr',
            },
            {
              field: 'species',
              name: 'Species',
            },
            {
              field: 'quantity',
              name: 'Quantity',
              cols: ['quantity1', 'quantity2'],
            },
          ]}
          data={[
            {
              product: 'HL Treatment',
              supplier: ['Budi', 'Bambang'],
              size: ['8-12', '8-12'],
              species: ['Black Tiger', 'Black Tiger'],
              quantity1: ['100 MCO', '100 MCO'],
              quantity2: ['100 Kg', '100 Kg'],
            },
            {
              no: 'S-2',
              salesOrder: 'Jepang-0012',
              product: ['HL Treatment', 'PPD Treatment'],
              supplier: ['Budi', 'Bambang'],
              size: ['8-12', '8-12'],
              species: ['Black Tiger', 'Black Tiger'],
              quantity1: ['100 MCO', '100 MCO'],
              quantity2: ['100 Kg', '100 Kg'],
            },
          ]}
          onRenderField={(field, value, { key }) => {
            if (field.startsWith('inner'))
              return (
                <div className="text-center" key={key}>
                  {value}
                </div>
              );
          }}
        />
      </div>
    </>
  );
};

export default ColdStorageUnitLatestStockTable;
