import SoakingQuantityPerProduct from './quantity-per-product';
import SoakingUnitWorkers from './workers';
import SoakingUnitProduction from './production';
import SoakingUnitHeadcountPerformance from './headcount-performance';
import SoakingUnitProducitivityAnalysis from './productivity-analysis';

export {
  SoakingQuantityPerProduct,
  SoakingUnitWorkers,
  SoakingUnitProduction,
  SoakingUnitHeadcountPerformance,
  SoakingUnitProducitivityAnalysis,
};
