import { TableGrid } from 'components';

const FinalSortingPeeledUnitPerfTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">Workers Performance</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              field: 'no',
              name: 'No.',
              width: '40px',
            },
            {
              field: 'name',
              name: 'Name',
            },
            {
              field: 'workersID',
              name: 'Workers ID',
            },
            {
              field: 'working_time',
              name: 'Working Time',
            },
            {
              field: 'headcount_performance',
              name: 'Headcount Performance',
            },
            {
              field: 'cost_labor',
              name: 'Cost Labor',
            },
          ]}
        />
      </div>
      <h6 className="font-bold mb-2">
        Unit Performance by Product, Grade, Size, and Species
      </h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              field: 'no',
              name: 'No.',
              width: '40px',
            },
            {
              field: 'product',
              name: 'Product',
              width: '0.5fr',
            },
            {
              field: 'grade',
              name: 'Grade',
              width: '0.5fr',
            },
            {
              field: 'size',
              name: 'Size',
              width: '0.4fr',
            },
            {
              field: 'species',
              name: 'Species',
              width: '0.6fr',
            },
            {
              field: 'working_time',
              name: 'Working Time',
            },
            {
              field: 'productivity_rate',
              name: 'Productivity Rate',
            },
            {
              field: 'cost_labor',
              name: 'Cost Labor',
            },
          ]}
          data={[
            {
              no: '1',
              product: 'HL Treatment',
              grade: '1st',
              size: ['4-8', '8-12'],
              species: ['Black Pink', 'Black Tiger'],
              working_time: ['1 hour', '1 hour'],
              productivity_rate: ['50 kg/man-hour', '50 kg/man-hour'],
              cost_labor: ['50.000 IDR/kg', '50.000 IDR/kg'],
            },
            {
              no: '2',
              product: 'HL Treatment',
              grade: '2nd',
              size: '4-8',
              species: 'Black Pink',
              working_time: '1 hour',
              productivity_rate: '50 kg/man-hour',
              cost_labor: '50.000 IDR/kg',
            },
          ]}
        />
      </div>

      <div className="flex items-start mb-8">
        <div className="w-96 mr-20">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Headcount</div>
            <div>8 People</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Working Time</div>
            <div>50 Man-Hours</div>
          </div>
        </div>
        <div className="w-96">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Unit Productivity Rate</div>
            <div>50kg/Man-Hours</div>
          </div>
          <div className="flex justify-between text-caption-1">
            <div className="font-semibold">Unit Productivity</div>
            <div>500kg/Hour</div>
          </div>
        </div>
      </div>
      <div className="w-80">
        <div className="flex justify-between text-caption-1 mb-3">
          <div className="font-semibold">Total Time</div>
          <div>+5.5 Hours</div>
        </div>
      </div>
    </>
  );
};

export default FinalSortingPeeledUnitPerfTable;
