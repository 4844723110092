import dayjs from 'dayjs';
import classNames from 'classnames';
import humanizeDuration from 'humanize-duration';
import { useState } from 'react';
import { IconClose, IconLaunch } from 'assets';
import { useQuery } from 'react-query';
import { urlAPIMechanic } from 'utils';

interface MachineRuntimeProps {
  className?: string;
  startDate?: string;
  endDate?: string;
}

type MachineRuntimeData = {
  id: number;
  nama_node: string;
  runningTime: number;
};

const MachineRuntime: React.FC<MachineRuntimeProps> = ({
  className = '',
  startDate = dayjs().subtract(3, 'days').format('YYYY-MM-DD'),
  endDate = dayjs().format('YYYY-MM-DD'),
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const { data } = useQuery<MachineRuntimeData[]>(
    ['machineRuntime', startDate, endDate],
    () =>
      fetch(
        urlAPIMechanic +
          `/mechanic/machine/dashboard/running-time?start=${startDate}&end=${endDate}`
      )
        .then((res) => res.json())
        .then((data) => data.data)
  );

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        [className]: className,
      })}
    >
      <button
        onClick={handleFullScreen}
        className="absolute right-5 top-5 z-10"
      >
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">Machine Running Time</h4>
        <div className="grid grid-cols-2 text-footnote text-grey font-bold mb-5">
          <div>Machine ID</div>
          <div className="text-right">Machine Running Time</div>
        </div>
        {data?.map((val) => (
          <div
            key={`machine-runtime-${val.id}`}
            className="grid grid-cols-2 text-caption-1 font-bold py-2 border-b border-grey-1"
          >
            <div>{val.nama_node}</div>
            <div className="text-right">
              {humanizeDuration(val.runningTime * 3600 * 1000, { round: true })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MachineRuntime;
