const AvgFrozenTimeByOperatorTable = () => {
  return (
    <div className="rounded-lg px-8 py-7 border border-grey-1">
      <div className="grid grid-cols-6 grid-rows-2 items-center font-semibold text-caption-1 py-2.5 gap-2.5">
        <div className="row-span-2">Operator Name</div>
        <div className="col-span-5 text-center border-b border-blue-lighter pb-2">
          Avg Frozen Time by CF
        </div>
        <div>CF 1</div>
        <div>CF 2</div>
        <div>CF 3</div>
        <div>CF 4</div>
        <div>CF 5</div>
      </div>
      {/* Put the children here later */}
    </div>
  );
};

export default AvgFrozenTimeByOperatorTable;
