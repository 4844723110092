import { useState } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  ResetPassTable,
  TextField,
} from 'components';

const ResetPassManagement = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setSearchQuery(value);
  };

  return (
    <>
      <div className="user-management">
        <h1 className="text-heading font-bold mb-3">User Management</h1>
        <Breadcrumb className="mb-5">
          <BreadcrumbItem>Home</BreadcrumbItem>
          <BreadcrumbItem href="/user-management">
            User Management
          </BreadcrumbItem>
          <BreadcrumbItem isActive>Reset Password Management</BreadcrumbItem>
        </Breadcrumb>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center">
            <h2 className="text-heading font-extrabold mr-8">
              Reset Password Permission
            </h2>
            <TextField
              onChange={handleChange}
              placeholder="Cari nama employee"
              variant="search"
            />
          </div>
        </div>
        <ResetPassTable searchQuery={searchQuery} />
      </div>
    </>
  );
};

export default ResetPassManagement;
