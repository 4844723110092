import { Children, cloneElement, useEffect } from 'react';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { IconDropdown } from 'assets';
import { useOutside } from 'utils/useOutside';

interface DropdownProps {
  fullWidth?: boolean;
  labelText?: string;
  placeholder?: string;
  value?: string;
  height?: string | number;
  width?: string | number;
  className?: string;
  name?: string;
  onChange?(value: string, name: string): void;
  errorMessage?: string;
  isError?: boolean;
  textSize?: 'caption-1' | 'footnote';
  top?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  fullWidth,
  labelText,
  placeholder,
  height = 48,
  width,
  className = '',
  children,
  name = '',
  value = '',
  onChange,
  isError,
  errorMessage,
  textSize = 'footnote',
  top = '4.5rem',
}) => {
  const [dropdownvalue, setValue] = useState<string>(value);
  const [hidden, setHidden] = useState<boolean>(true);
  const attempt = useRef<number>(0);
  const boxEl = useRef(null);
  const triggerEl = useRef(null);

  const emitChange = useRef((_val: string, _name: string) => {});

  if (onChange) emitChange.current = onChange;
  useEffect(() => {
    if (attempt.current > 0) emitChange.current(dropdownvalue, name);
  }, [dropdownvalue, name]);

  useEffect(() => {
    attempt.current = 1;
  }, []);

  // @ts-ignore
  const newChildren = Children.map(children, (child) =>
    // @ts-ignore
    cloneElement(child, { setHidden, setValue, textSize })
  );

  useOutside(boxEl, triggerEl, () => {
    setHidden(true);
  });

  return (
    <div
      className={classNames('relative', { [className]: className?.length > 0 })}
    >
      {labelText && (
        <label className="block text-caption-1 font-bold text-blue-light mb-1.5">
          {labelText}
        </label>
      )}
      {!hidden && (
        <div
          ref={boxEl}
          style={{ minHeight: 48, top, zIndex: 99 }}
          className="absolute w-full py-4 bg-white left-0 rounded-lg shadow-lg"
        >
          {newChildren}
        </div>
      )}
      <button
        ref={triggerEl}
        onClick={() => setHidden(!hidden)}
        style={{ height, width }}
        className={classNames(
          `flex items-center justify-between border text-${textSize} px-4 rounded-lg`,
          {
            'w-full': fullWidth,
            'border-red': isError,
            'border-blue-light': !isError,
          }
        )}
      >
        <span
          className={classNames({ 'text-grey-placeholder': !dropdownvalue })}
        >
          {value ? value : dropdownvalue ? dropdownvalue : placeholder}
        </span>
        <IconDropdown />
      </button>
      {isError && (
        <span className="text-caption-1 text-red mt-3 block">
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default Dropdown;
