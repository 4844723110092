import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IconExcel } from 'assets';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  DatePicker,
  OverviewColdStorageTable,
  OverviewContactFreezerTable,
  OverviewMachineTable,
  OverviewOperatorTable,
} from 'components';
import { SetEndDate, SetStartDate } from 'redux/filterDates/filterDate.action';
import { RootReducerState } from 'redux/root-reducer';
import { useQuery } from 'utils/useQuery';

const OverviewReportPage = () => {
  const startDate = useSelector(
    (state: RootReducerState) => state.filterDate.overviewReport.startDate
  );
  const endDate = useSelector(
    (state: RootReducerState) => state.filterDate.overviewReport.endDate
  );

  const dispatch = useDispatch();
  const report = useQuery().get('report') || 'machine';
  const history = useHistory();

  return (
    <div className="user-management">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-heading font-bold mb-3">Report Overview</h1>
          <Breadcrumb className="mb-5">
            <BreadcrumbItem href="/mechanic/overview">Home</BreadcrumbItem>
            <BreadcrumbItem href="/mechanic/overview">Overview</BreadcrumbItem>
            <BreadcrumbItem isActive>Report</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="flex items-center">
          <Button
            className="mr-5 w-32"
            color={report === 'machine' ? 'default' : 'grey'}
            onClick={() => history.push({ search: 'report=machine' })}
          >
            Machine
          </Button>
          <Button
            className="mr-5 w-32"
            color={report === 'contact-freezer' ? 'default' : 'grey'}
            onClick={() => history.push({ search: 'report=contact-freezer' })}
          >
            Contact Freezer
          </Button>
          <Button
            className="mr-5 w-32"
            color={report === 'operator' ? 'default' : 'grey'}
            onClick={() => history.push({ search: 'report=operator' })}
          >
            Operator
          </Button>
          <Button
            className="w-32"
            color={report === 'cold-storage' ? 'default' : 'grey'}
            onClick={() => history.push({ search: 'report=cold-storage' })}
          >
            Cold Storage
          </Button>
        </div>
      </div>
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <DatePicker
            selected={new Date(startDate)}
            onChange={(date: Date) =>
              dispatch(
                SetStartDate('overviewReport', dayjs(date).format('YYYY-MM-DD'))
              )
            }
            dateFormat="E, d MMM yyyy"
            containerClassName="mr-3.5"
          />
          <DatePicker
            selected={new Date(endDate)}
            onChange={(date: Date) =>
              dispatch(
                SetEndDate('overviewReport', dayjs(date).format('YYYY-MM-DD'))
              )
            }
            dateFormat="E, d MMM yyyy"
            popperPlacement="bottom-end"
          />
        </div>
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Export
          </span>
          <button className="p-2 rounded-md border border-grey-1">
            <IconExcel />
          </button>
        </div>
      </div>
      {report === 'machine' && (
        <OverviewMachineTable startDate={startDate} endDate={endDate} />
      )}
      {report === 'contact-freezer' && <OverviewContactFreezerTable />}
      {report === 'operator' && <OverviewOperatorTable />}
      {report === 'cold-storage' && (
        <OverviewColdStorageTable startDate={startDate} endDate={endDate} />
      )}
    </div>
  );
};

export default OverviewReportPage;
