import classNames from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useState } from 'react';
import { IconClose, IconLaunch } from 'assets';
import { ContactFreezerColorMapping } from 'colors';
import { ContactFreezerProgress } from 'components';

interface ContactFreezerTempProps {
  mode?: 'default' | 'realtime';
  className?: string;
}

const options: Highcharts.Options = {
  title: {
    text: '',
  },
  chart: {
    type: 'column',
  },
  xAxis: {
    type: 'category',
    gridLineWidth: 1,
  },
  yAxis: {
    gridLineWidth: 0,
    title: {
      text: 'hours',
      rotation: -90,
    },
  },
  series: [
    {
      data: [
        {
          name: 'Contact Frozen 1',
          color: ContactFreezerColorMapping[1],
          y: 8,
        },
        {
          name: 'Contact Frozen 2',
          color: ContactFreezerColorMapping[2],
          y: 5,
        },
        {
          name: 'Contact Frozen 3',
          color: ContactFreezerColorMapping[3],
          y: 7,
        },
        {
          name: 'Contact Frozen 4',
          color: ContactFreezerColorMapping[4],
          y: 7,
        },
        {
          name: 'Contact Frozen 5',
          color: ContactFreezerColorMapping[5],
          y: 7,
        },
        {
          name: 'Contact Frozen 6',
          color: ContactFreezerColorMapping[6],
          y: 7,
        },
      ],
      name: 'Contact Freezer Temperature',
      type: 'column',
      borderRadius: 30,
    },
  ],
  legend: {
    enabled: false,
  },
  tooltip: {
    valueSuffix: ' Hours',
  },
};

const ContactFreezer: React.FC<ContactFreezerTempProps> = ({
  mode = 'default',
  className = '',
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [reRender, setReRender] = useState<boolean>(true);

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
    // Hack the chart so it can fill entire screen during fullscreen state
    setReRender(false);
    setTimeout(() => {
      setReRender(true);
    }, 0);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        [className]: className,
      })}
    >
      <button onClick={handleFullScreen} className="absolute right-5 top-5">
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">
          Contact Freezer Temperature
        </h4>
        {mode === 'realtime' ? (
          <>
            <ContactFreezerProgress
              color={ContactFreezerColorMapping[1]}
              className="mb-5"
              progress={80}
              title="Contact Freezer 1"
              runtime="11 Hours 3 Minutes"
              temperature={-40}
            />
            <ContactFreezerProgress
              color={ContactFreezerColorMapping[2]}
              className="mb-5"
              progress={80}
              title="Contact Freezer 1"
              runtime="11 Hours 3 Minutes"
              temperature={-40}
            />
            <ContactFreezerProgress
              color={ContactFreezerColorMapping[3]}
              className="mb-5"
              progress={80}
              title="Contact Freezer 1"
              runtime="11 Hours 3 Minutes"
              temperature={-40}
            />
            <ContactFreezerProgress
              color={ContactFreezerColorMapping[4]}
              progress={80}
              title="Contact Freezer 1"
              runtime="11 Hours 3 Minutes"
              temperature={-40}
            />
          </>
        ) : (
          <>
            {reRender && (
              <HighchartsReact options={options} highcharts={Highcharts} />
            )}
            <div className="flex items-center flex-wrap pl-10 mt-3">
              <p className="text-caption-1 font-bold text-grey">
                Average CF frozen time{' '}
                <span className="text-blue-2">3 Hours 15 Minutes</span>
              </p>
            </div>{' '}
          </>
        )}
      </div>
    </div>
  );
};

export default ContactFreezer;
