const PeelingUnitWorkersLogTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">Work Log</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9 overflow-auto">
        <div
          style={{
            gridTemplateColumns: '80px repeat(10, 1fr)',
            minWidth: 1300,
          }}
          className="grid font-semibold text-caption-1 gap-y-2 gap-x-4 px-2 py-2.5"
        >
          <div>No</div>
          <div>Name</div>
          <div>Workers ID</div>
          <div>Main Job</div>
          <div>Main Unit</div>
          <div>Start Time</div>
          <div>End Time</div>
          <div>Recorded Job</div>
          <div>Recorded Unit</div>
          <div>Quantity</div>
          <div>Wage</div>
        </div>
        {/* Put the children here later */}
      </div>
      <h6 className="font-bold mb-2">Permission Log</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1">
        <div
          style={{
            gridTemplateColumns: '80px repeat(8, 1fr)',
          }}
          className="grid font-semibold text-caption-1 gap-y-2 gap-x-4 px-2 py-2.5"
        >
          <div>No</div>
          <div>Name</div>
          <div>Workers ID</div>
          <div>Main Job</div>
          <div>Main Unit</div>
          <div>Type of Permission</div>
          <div>Permission Start</div>
          <div>Permission End</div>
          <div>Reason</div>
        </div>
        {/* Put the children here later */}
      </div>
    </>
  );
};

export default PeelingUnitWorkersLogTable;
