import { IconExcel, LogoSKA } from 'assets';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  MultiSelectDropdown,
  TableGrid,
} from 'components';
import { useState } from 'react';
import ReactDropdown from 'react-dropdown';

const ManagementWagePage = () => {
  const [active, setActive] = useState<'wage' | 'log'>('wage');

  return (
    <div style={{ minWidth: 1200 }}>
      <h1 className="text-heading font-bold mb-3">Wage</h1>
      <Breadcrumb className="mb-5">
        <BreadcrumbItem href="/management/wage-productivity">
          Wage & Productivity
        </BreadcrumbItem>
        <BreadcrumbItem isActive>Wage</BreadcrumbItem>
      </Breadcrumb>
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <MultiSelectDropdown
            className="mr-2 w-64"
            placeholder="Workers name..."
          />
          <MultiSelectDropdown className="w-64" placeholder="Unit..." />
        </div>
        <ReactDropdown
          controlClassName="border-grey-1 border "
          placeholder="Range Wage Dates..."
          className="text-black text-caption-1 w-96"
          options={[
            {
              label: 'Supplier 1',
              value: 'shipment',
            },
            {
              label: 'Supplier 2',
              value: 'shipment2',
            },
          ]}
        />
      </div>
      <div className="flex items-center text-caption-1 justify-between">
        <div className="flex items-center text-blue font-bold">
          <span className="mr-2">Downloads:</span>
          <button>
            <IconExcel />
          </button>
        </div>
        <div style={{ width: 566 }} className="grid grid-cols-2 gap-x-4">
          <Button
            color={active === 'wage' ? 'default' : 'grey'}
            onClick={() => setActive('wage')}
          >
            Workers Wage
          </Button>
          <Button
            color={active === 'log' ? 'default' : 'grey'}
            onClick={() => setActive('log')}
          >
            Workers Log
          </Button>
        </div>
      </div>
      {active === 'wage' && <WorkersWage />}
      {active === 'log' && <WorkersLog />}
    </div>
  );
};

export default ManagementWagePage;

const WorkersWage = () => {
  return (
    <div className="px-16 py-8 border border-grey-1 rounded-lg py-5 px-8">
      <div className="flex items-center justify-between mb-7">
        <img src={LogoSKA} alt="logo SKA" />
        <div className="text-caption-1">
          <div className="font-bold mb-1">PT Sumber Kalimantan Abadi</div>
          <div>
            Tanjung Pasir - Mamburungan <br />
            Kalimantan Utara, Tarakan, Indonesia
          </div>
        </div>
      </div>
      <div className="text-sub-heading font-bold text-center underline mb-6">
        WORKER WAGE REPORT
      </div>
      <div
        style={{ gridTemplateColumns: '85px 1fr', maxWidth: 420 }}
        className="grid items-center text-caption-1 mx-auto mb-3 text-black"
      >
        <div className="font-bold">Date:</div>
        <div className="border border-blue-light rounded-lg px-3 py-2">
          26 Januari 2020 - 28 Januari 2020
        </div>
      </div>
      <div
        style={{ gridTemplateColumns: '85px 1fr', maxWidth: 420 }}
        className="grid items-center text-caption-1 mx-auto mb-3"
      >
        <div className="font-bold">R/M Code:</div>
        <div className="border border-blue-light rounded-lg px-3 py-2">
          Form 17
        </div>
      </div>
      <TableGrid
        columns={[
          {
            field: 'no',
            name: 'No.',
            width: '40px',
          },
          {
            Component: (
              <div
                key="workers-wage-main-column"
                className="grid grid-cols-9 grid-rows-2 items-center"
              >
                <div className="row-span-2">Name</div>
                <div className="row-span-2">Workers ID</div>
                <div className="row-span-2">Main Job</div>
                <div className="row-span-2">Main Unit</div>
                <div className="row-span-2">Date</div>
                <div className="col-span-4 text-center border-b border-grey-placeholder pb-2 mb-2">
                  Work Done
                </div>
                <div>Recorded Job</div>
                <div>Wage Type</div>
                <div>Quantity</div>
                <div>Wage</div>
              </div>
            ),
            cols: [
              'name',
              'workersID',
              'mainJob',
              'mainUnit',
              'date',
              'recordedJob',
              'wageType',
              'quantity',
              'wage',
            ],
            width: '9fr',
            rows: 5,
            merge: [
              {
                fieldToMerge: [
                  'name.4',
                  'workersID.4',
                  'mainJob.4',
                  'mainUnit.4',
                  'date.4',
                  'recordedJob.4',
                  'wageType.4',
                  'quantity.4',
                ],
                mergeInto: 'totalWage',
                style: {
                  textAlign: 'center',
                  background: '#fbfbfb',
                  padding: '12px 0',
                },
              },
              {
                fieldToMerge: ['name.1', 'name.2', 'name.3'],
                mergeInto: 'fieldName',
              },
              {
                fieldToMerge: ['workersID.1', 'workersID.2', 'workersID.3'],
                mergeInto: 'fieldWorkersID',
              },
              {
                fieldToMerge: ['mainJob.1', 'mainJob.2', 'mainJob.3'],
                mergeInto: 'fieldMainJob',
              },
              {
                fieldToMerge: ['mainUnit.1', 'mainUnit.2', 'mainUnit.3'],
                mergeInto: 'fieldMainUnit',
              },
              {
                fieldToMerge: ['wage.4'],
                mergeInto: 'totalWageValue',
                style: {
                  background: '#fbfbfb',
                  padding: '12px 0',
                },
              },
            ],
          },
        ]}
        data={[
          {
            no: '1',
            'name.1': '',
            'name.2': '',
            'name.3': '',
            'name.4': '',
            fieldName: 'Justin',
            'workersID.1': '',
            'workersID.2': '',
            'workersID.3': '',
            'workersID.4': '',
            fieldWorkersID: '137687623',
            'mainJob.1': '',
            'mainJob.2': '',
            'mainJob.3': '',
            'mainJob.4': '',
            fieldMainJob: 'Sorting Worker',
            'mainUnit.1': '',
            'mainUnit.2': '',
            'mainUnit.3': '',
            'mainUnit.4': '',
            fieldMainUnit: 'Nobashi Unit',
            'date.1': '26-Jan-21',
            'date.2': '26-Jan-21',
            'date.3': '26-Jan-21',
            'date.4': '',
            'recordedJob.1': 'Receiving Worker',
            'recordedJob.2': 'Receiving Worker',
            'recordedJob.3': 'Receiving Worker',
            'recordedJob.4': '',
            'wageType.1': 'Highest Paid',
            'wageType.2': 'Highest Paid',
            'wageType.3': 'Highest Paid',
            'wageType.4': '',
            'quantity.1': '1 ls',
            'quantity.2': '1 ls',
            'quantity.3': '1 ls',
            'quantity.4': '',
            'wage.1': 'Rp 125.000',
            'wage.2': 'Rp 125.000',
            'wage.3': 'Rp 125.000',
            'wage.4': 'Rp 375.000',
            totalWage: 'Total Wage',
            totalWageValue: 'Rp 375.000',
          },
          {
            no: '2',
            'name.1': '',
            'name.2': '',
            'name.3': '',
            'name.4': '',
            fieldName: 'Justin',
            'workersID.1': '',
            'workersID.2': '',
            'workersID.3': '',
            'workersID.4': '',
            fieldWorkersID: '137687623',
            'mainJob.1': '',
            'mainJob.2': '',
            'mainJob.3': '',
            'mainJob.4': '',
            fieldMainJob: 'Sorting Worker',
            'mainUnit.1': '',
            'mainUnit.2': '',
            'mainUnit.3': '',
            'mainUnit.4': '',
            fieldMainUnit: 'Nobashi Unit',
            'date.1': '26-Jan-21',
            'date.2': '26-Jan-21',
            'date.3': '26-Jan-21',
            'date.4': '',
            'recordedJob.1': 'Receiving Worker',
            'recordedJob.2': 'Receiving Worker',
            'recordedJob.3': 'Receiving Worker',
            'recordedJob.4': '',
            'wageType.1': 'Highest Paid',
            'wageType.2': 'Highest Paid',
            'wageType.3': 'Highest Paid',
            'wageType.4': '',
            'quantity.1': '1 ls',
            'quantity.2': '1 ls',
            'quantity.3': '1 ls',
            'quantity.4': '',
            'wage.1': 'Rp 125.000',
            'wage.2': 'Rp 125.000',
            'wage.3': 'Rp 125.000',
            'wage.4': 'Rp 375.000',
            totalWage: 'Total Wage',
            totalWageValue: 'Rp 375.000',
          },
        ]}
      />
    </div>
  );
};

const WorkersLog = () => {
  return (
    <div className="px-16 py-8 border border-grey-1 rounded-lg py-5 px-8">
      <div className="flex items-center justify-between mb-7">
        <img src={LogoSKA} alt="logo SKA" />
        <div className="text-caption-1">
          <div className="font-bold mb-1">PT Sumber Kalimantan Abadi</div>
          <div>
            Tanjung Pasir - Mamburungan <br />
            Kalimantan Utara, Tarakan, Indonesia
          </div>
        </div>
      </div>
      <div className="text-sub-heading font-bold text-center underline mb-6">
        WORKER LOG
      </div>
      <div
        style={{ gridTemplateColumns: '85px 1fr', maxWidth: 420 }}
        className="grid items-center text-caption-1 mx-auto mb-3 text-black"
      >
        <div className="font-bold">Date:</div>
        <div className="border border-blue-light rounded-lg px-3 py-2">
          26 Januari 2020 - 28 Januari 2020
        </div>
      </div>
      <div
        style={{ gridTemplateColumns: '85px 1fr', maxWidth: 420 }}
        className="grid items-center text-caption-1 mx-auto mb-6"
      >
        <div className="font-bold">R/M Code:</div>
        <div className="border border-blue-light rounded-lg px-3 py-2">
          Form 17
        </div>
      </div>
      <div className="mb-10">
        <div
          style={{
            gridTemplateColumns: '80px repeat(10, 1fr)',
            minWidth: 1300,
          }}
          className="grid font-semibold text-caption-1 gap-y-2 gap-x-4 px-2 py-2.5"
        >
          <div>No</div>
          <div>Name</div>
          <div>Workers ID</div>
          <div>Main Job</div>
          <div>Main Unit</div>
          <div>Start Time</div>
          <div>End Time</div>
          <div>Recorded Job</div>
          <div>Recorded Unit</div>
          <div>Quantity</div>
          <div>Wage</div>
        </div>
      </div>
      <div className="text-sub-heading font-bold text-center underline mb-6">
        PERMISSION LOG
      </div>
      <div>
        <div
          style={{
            gridTemplateColumns: '80px repeat(8, 1fr)',
          }}
          className="grid font-semibold text-caption-1 gap-y-2 gap-x-4 px-2 py-2.5"
        >
          <div>No</div>
          <div>Name</div>
          <div>Workers ID</div>
          <div>Main Job</div>
          <div>Main Unit</div>
          <div>Type of Permission</div>
          <div>Permission Start</div>
          <div>Permission End</div>
          <div>Reason</div>
        </div>
      </div>
    </div>
  );
};
