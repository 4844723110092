import { IconEdit, IconTrash } from 'assets';
import classNames from 'classnames';
import { TableGrid, Button, Loading } from 'components';
import dayjs from 'dayjs';
import { useState } from 'react';
import ReactModal from 'react-modal';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { setModalOpen } from 'redux/takeoutOrder/takeoutOrder.action';
import { urlAPIManagement, urlAPIManagementBPN } from 'utils';

const ManagementTakeoutOrderCommandTable: React.FC<IBaseTable> = ({
  endDate,
  startDate,
  setData
}) => {



  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const [confirm, setConfirm] = useState<boolean>(false);
  const [factory, setFactory] = useState<string>('TARAKAN');
  const [id, setId] = useState<string>('');
  const { data: takeoutOrderList } = useQuery<TakeOutOrderList[]>(
    ['takeout-order-lists', startDate, endDate],
    async () => {
      const res1 = await fetch(
        urlAPIManagement +
          `/order?date_start=${dayjs(startDate).format('YYYY-MM-DD')}&date_end=${dayjs(
            endDate
          ).format('YYYY-MM-DD')}`
      );

      const data = await res1.json();

      console.log(data)
      return data?.data;
    }
  );

  const { data: takeoutOrderList2 } = useQuery<TakeOutOrderList[]>(
    ['takeout-order-lists-2', startDate, endDate],
    async () => {
      const res2 = await fetch(
        urlAPIManagementBPN +
          `/takeout-order?start=${dayjs(startDate).format('YYYY-MM-DD')}&end=${dayjs(
            endDate
          ).format('YYYY-MM-DD')}`
      );
      const dataRaw2 = await res2.json();
      const data = dataRaw2?.data?.filter((element: TakeOutOrderList) => element?.Factory_id === 3)  

      console.log(data)
      return data;
    }
  );


  const combineList = (a: TakeOutOrderList[] | undefined, b: TakeOutOrderList[] | undefined) => {
    const temp1 = Array.from(
      a || [],
      ({ orderType, createdAt, id, sapOrder, orderItems, finish_at, factory }, index) => ({
        orderType: orderType?.name ?? '-',
        createdAt: dayjs(createdAt).format('DD/MM/YYYY'),
        orderId: id?.toString() ?? '-',
        factory: factory?.branch_name ?? '-',
        sapOrder: sapOrder?.SAP_Order ?? '-',
        status: finish_at ?  'done' : checkQuantityProcessed(orderItems) ? 'on going' : 'not yet',
        index: `${index}`,
        finish_at: finish_at ? dayjs(finish_at).format('DD/MM/YYYY') : '-'
      })
    )

    const temp2 = Array.from(
      b || [],
      ({ orderType, created_at, id, sapOrder, orderItems, finished_at, factory }, index) => ({
        orderType: orderType?.name ?? '-',
        createdAt: dayjs(created_at).format('DD/MM/YYYY'),
        orderId: id?.toString() ?? '-',
        factory: factory?.branch_name ?? '-',
        sapOrder: sapOrder?.SAP_Order ?? '-',
        status: finished_at ?  'done' : checkQuantityProcessed(orderItems) ? 'on going' : 'not yet',
        index: `${index}`,
        finish_at: finished_at ? dayjs(finished_at).format('DD/MM/YYYY') : '-'
      })
    )

    const temp = temp1.concat(temp2);
    console.log('ini temp 1', temp1);
    console.log('ini temp 2', temp2);
    console.log('ini temp', temp);

    return temp;
  }

  const checkQuantityProcessed = (orderItems:OrderItem[] ) => {
    let isStart = false;
    if (orderItems.length > 0) {
      let i = 0;
      while (i < orderItems.length) {
        if (Number(orderItems[i]?.quantity_out) > 0) {
          isStart = true;
          break;
        }
        i++;
      }
    }
    return isStart;
  }

  const handleDelete = useMutation(
    async () => {
      setConfirm(false); 
      await fetch((factory === 'TARAKAN' ? urlAPIManagement : urlAPIManagementBPN) + (`/${factory === 'TARAKAN' ? 'order' : 'takeout-order'}/${id}`), {
        method: 'DELETE',
      });
    },
    {
      onSuccess: () => {
        if (factory === 'TARAKAN') {
          queryClient.refetchQueries({ queryKey: 'takeout-order-lists' });
        } else {
          queryClient.refetchQueries({ queryKey: 'takeout-order-lists-2' });
        }
      },
    }
  );

  return (
    <>
      {handleDelete.isLoading && <Loading />}
      <ReactModal
        style={{
          content: {
            width: 430,
          },
        }}
        isOpen={confirm}
      >
        <div className="text-center my-10 text-footnote">
          Are you sure you want to Delete Takeout Order?
        </div>
        <div className="grid grid-cols-2 gap-x-2 items-center">
          <Button
            onClick={() => setConfirm(false)}
            color="grey"
            variant="outlined"
            className="rounded-full"
          >
            No
          </Button>
          <Button
            onClick={() => handleDelete.mutate()}
            color="dark-blue"
            className="rounded-full"
          >
            Yes
          </Button>
        </div>
      </ReactModal>
      <div className="rounded-lg px-8 py-7 border border-grey-1">
        <div className="text-caption-1 mb-4">
          {/* {dayjs(startDate).format('dddd, DD MMM YYYY')} -{' '}
          {dayjs(endDate).format('dddd, DD MMM YYYY')} */}
        </div>

        <TableGrid
          columns={[
            {
              field: 'orderId',
              name: 'ID Order',
            },
            {
              field: 'factory',
              name: 'Factory',
            },
            {
              field: 'orderType',
              name: 'Jenis Takeout',
            },
            {
              field: 'createdAt',
              name: 'Created at',
            },
            {
              field: 'sapOrder',
              name: 'SAP Order',
            },
            {
              field: 'status',
              name: 'Status',
            },
            {
              field: 'finish_at',
              name: 'Finished At',
            },
            {
              field: 'action',
              name: '',
            },
          ]}
          data={combineList(takeoutOrderList, takeoutOrderList2)}
          onRenderField={(field, _value, { className, key, data }) => {
            if (field === 'action') {
              return (
                <div
                  className={classNames(
                    className,
                    'grid auto-cols-max gap-x-2 grid-flow-col items-center'
                  )}
                  key={key}
                >
                {
                  data?.status === 'not yet' &&
                <>
                <button
                    onClick={(e:any) => {
                      e.preventDefault();
                      setConfirm(true);
                      setId(data.orderId as string);
                      setFactory(data.factory as string);
                    }}
                  >
                    <IconTrash width="20" height="20" className="ic-blue" />
                  </button>
                  <button
                    onClick={() => {
                      if (data?.factory === 'TARAKAN') {
                        if (setData && takeoutOrderList?.length) {
                          setData(takeoutOrderList[parseInt(data.index as string)])
                        }
                      } else {
                        if (setData && takeoutOrderList2?.length) {
                          const something = takeoutOrderList2[parseInt(data?.index as string)];
                          const temp = something?.orderItems.map(element => ({
                            ...element,                
                            Species_id : undefined,
                            Spesies_id :  element.Species_id
                          })) 
                          setData({
                            ...something,
                            orderItems: temp
                          });
                          console.log({
                            ...something,
                            orderItems: temp
                          });
                        }
                      }
                      console.log('data order id', data.orderId)
                      console.log('data id', data.id)
                      dispatch(setModalOpen('edit', data.orderId as string));
                    }}
                  >
                    <IconEdit width="20" height="20" className="ic-blue" />
                  </button>
                  </>
                  }
                </div>
              );
            }
          }}
          collapse={(d) => (
            <div className="px-6 mt-4">
              <TableGrid
                columns={[
                  {
                    field: 'product',
                    name: 'Product',
                  },
                  {
                    field: 'species',
                    name: 'Species',
                    width: '0.6fr'
                  },
                  {
                    field: 'size',
                    name: 'Size',
                    width: '0.4fr'
                  },
                  {
                    field: 'supplier',
                    name: 'Supplier',
                    width: '0.8fr'
                  },
                  {
                    field: 'production_date',
                    name: 'Production Date',
                  },
                  {
                    field: 'quantity',
                    name: 'Quantity Out/ Order',
                    width: '1.2fr'
                  },
                  {
                    field: 'quantity_processed',
                    name: 'Quantity Processed',
                    width: '0.5fr'
                  },
                ]}
                data={ d.factory === 'TARAKAN' ?
                  Array.from(
                        takeoutOrderList?.[Number(d.index)].orderItems || [],
                        ({
                          product,
                          spesies,
                          size,
                          supplier,
                          date_start,
                          date_end,
                          quantity_processed,
                          quantity_order,
                          quantity_out
                        }) => ({
                          product: `${product?.productType?.name ?? ''} ${product?.grade?.nama_grade ?? ''} ${product?.frozenType?.name ?? ''} ${product?.brand?.name ?? ''} ${product?.package.name}`,
                          species: spesies?.nama_spesies || '',
                          size: size?.production_size || '',
                          supplier: `${supplier?.supplier_code || ''}-${supplier?.nama_supplier || ''}`,
                          production_date: `${dayjs(date_start).format(
                            'DD/MM/YYYY'
                          )} - ${dayjs(date_end).format('DD/MM/YYYY')}`,
                          quantity: `${quantity_order}/${Number(quantity_out)}`,
                          quantity_processed: `${Number(quantity_processed) ? Math.floor(Number(quantity_processed)/product?.package?.FG_QTY) : 0}`
                        })
                      )
                      :
                      Array.from(
                        takeoutOrderList2?.[Number(d.index)].orderItems || [],
                        ({
                          product_name,
                          nama_species,
                          size,
                          supplier_name,
                          date_start,
                          date_end,
                          quantity_processed,
                          quantity_order,
                          package_quantity,
                          quantity_out
                        }) => ({
                          product: product_name || '',
                          species: nama_species || '',
                          size: size || '',
                          supplier: supplier_name || '',
                          production_date: `${dayjs(date_start).format(
                            'DD/MM/YYYY'
                          )} - ${dayjs(date_end).format('DD/MM/YYYY')}`,
                          quantity: `${quantity_order}/${Math.floor(quantity_out/package_quantity)}`,
                          quantity_processed: `${Math.floor(quantity_processed ? quantity_processed/package_quantity : 0)}`
                        })
                      )
                }
                onRenderField={(field, value, { key  }) => {
                  if (field === 'quantity') {
                    const quantity = value.split('/');

                    return (
                      <ProgressBar key={key} current={quantity[1]} target={quantity[0]} />
                    );  
                  }
                }}
              />
            </div>
          )}
          rowsClassName="bg-white-snow rounded-lg py-2 mb-2 last:mb-0"
        />
      </div>
    </>
  );
};

export default ManagementTakeoutOrderCommandTable;

type ProgressBarProps = {
  current: string;
  target: string;
  unit?: string;
};

const ProgressBar: React.FC<ProgressBarProps> = ({
  current,
  target,
  unit = 'packs',
}) => {
  const percentage = (Number(current) / Number(target)) * 100;

  return (
    <div className="grid grid-cols-4 text-caption-1 gap-x-2 items-start">
      <div className="flex flex-col items-end col-span-3">
        <div className="w-full bg-grey-placeholder h-4 rounded-full mb-2 relative flex justify-center items-center text-caption-2 text-white">
          <div
            style={{ width: percentage + '%' }}
            className="absolute rounded-full z-0 h-full top-0 left-0 bg-blue-3"
          ></div>
          <span className="relative z-10">{percentage.toFixed(0)} %</span>
        </div>
        <div className="text-caption-2">
          Progress:{' '}
          <span className="font-bold">
            {current} {unit}
          </span>
        </div>
      </div>
      <div>
        {target} {unit}
      </div>
    </div>
  );
};
