import { useState } from 'react';
import { IconAdd } from 'assets';
import {
  AddEditEmployee,
  AddEditEmployeeState,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Dialog,
  FilterButton,
  Loading,
  ProceedConfirmation,
  Role,
  TextField,
  UserManagementTable,
  Filter,
} from 'components';
import { IUser } from 'utils/types';
import { urlAPI } from 'utils/urlApi';
import { useUser } from 'utils/useUser';
import './index.css';

const UserManagementPage = () => {
  const [addEmployee, setAddEmployee] = useState<boolean>(false);
  const [dialog, setDialog] = useState<boolean>(false);
  const [state, setState] = useState<AddEditEmployeeState>();
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [filterChoices, setFilterChoices] = useState<Role[]>([]);
  const [filterState, setFilterState] = useState<{ [key: string]: any }>({});
  const [filterQuery, setFilterQuery] = useState<string>('');

  useUser((userFromLocal, err) => {
    if (err) console.log(err);
    else {
      setUser(userFromLocal);
    }
  });

  useUser(async (userFromLocal) => {
    const res = await fetch(urlAPI + '/employee/filter', {
      headers: {
        Authorization: 'Bearer ' + userFromLocal?.token,
      },
    });

    const data = await res.json();

    setFilterChoices(data.userRoles || []);
  });

  const handleSubmitFilter = (val: { [key: string]: any }) => {
    setFilterState(val);
    setShowFilter(false);

    let filterQuery = Object.keys(val)
      .filter((value) => val[value])
      .join('@!@');

    if (Object.values(val).some((val) => val))
      setFilterQuery(`&role=${filterQuery}`);
    else setFilterQuery('');
  };

  const handleAddEmployee = (val: AddEditEmployeeState) => {
    setAddEmployee(false);
    setDialog(true);

    // Set state to be sent to API below here
    setState(val);
  };

  const handleProceedDialog = async () => {
    // Fetch API to add employee here
    setLoading(true);
    setDialog(false);
    const body = new FormData();
    body.append('nama_employee', state?.nama_employee || '');
    if (state?.avatar) body.append('avatar', state?.avatar);
    body.append('is_superadmin', state?.is_superadmin?.toString() || '0');
    body.append('initial_password', state?.initial_password || '');
    body.append('id', state?.id?.toString() || '');
    body.append('email', state?.email || '');
    body.append('registered_date', state?.registered_date || '');
    body.append('User_Role_id', state?.User_Role_id?.toString() || '');
    body.append('Factory_id', state?.Factory_id?.toString() || '');
    body.append('phone', state?.phone?.toString() || '');

    const res = await fetch(urlAPI + '/employee', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
      body,
    });

    if (res.status === 200) {
      setConfirmation(true);

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }

    setLoading(false);
  };

  const handleCancelDialog = () => {
    setDialog(false);
    setAddEmployee(true);
  };

  const handleCancelAddEditEmployee = () => {
    setAddEmployee(false);
    setState(undefined);
  };

  const handleCancelProceedDialog = () => {
    setConfirmation(false);
    setState(undefined);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  return (
    <>
      {addEmployee && (
        <AddEditEmployee
          state={state}
          onSubmit={handleAddEmployee}
          onCancel={handleCancelAddEditEmployee}
        />
      )}
      {dialog && (
        <Dialog
          onSubmit={handleProceedDialog}
          onCancel={handleCancelDialog}
          heading="Confirm New Employee"
          message={`Do you really want to add ${state?.nama_employee} as a ${state?.role}?`}
          width={600}
        />
      )}
      {confirmation && (
        <ProceedConfirmation
          onClose={handleCancelProceedDialog}
          message={`The new employee ${state?.nama_employee} has been added`}
        />
      )}
      {loading && <Loading />}
      {showFilter && (
        <Filter
          filterState={filterState}
          filterChoices={filterChoices}
          filterChoiceValueKey="id"
          filterChoiceLabelKey="Role_name"
          onCancel={() => setShowFilter(false)}
          heading="Choose Role"
          onSubmit={handleSubmitFilter}
        />
      )}
      <div className="user-management">
        <h1 className="text-heading font-bold mb-3">User Management</h1>
        <Breadcrumb className="mb-5">
          <BreadcrumbItem>Home</BreadcrumbItem>
          <BreadcrumbItem isActive>User Management</BreadcrumbItem>
        </Breadcrumb>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center">
            <h2 className="text-heading font-extrabold mr-8">User List</h2>
            <TextField
              onChange={handleChange}
              value={searchQuery}
              placeholder="Cari nama employee"
              variant="search"
            />
            <FilterButton
              onClick={() => setShowFilter(true)}
              className="ml-4"
            />
          </div>
          <div className="flex items-center">
            <Button onClick={() => setAddEmployee(true)}>
              <IconAdd className="mr-2" />
              <span>Add New Employee</span>
            </Button>
          </div>
        </div>
        <UserManagementTable
          filterQuery={filterQuery}
          searchQuery={searchQuery}
        />
      </div>
    </>
  );
};

export default UserManagementPage;
