import dayjs from 'dayjs';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import { urlAPIMechanic } from 'utils';

interface OverviewMachineTableProps {
  startDate?: string;
  endDate?: string;
}

type OverviewMachineTableData = {
  id: number;
  nama_node: string;
  avgConsumption: number;
  totalConsumption: number;
};

const OverviewMachineTable: React.FC<OverviewMachineTableProps> = ({
  startDate = dayjs().subtract(6, 'days').format('YYYY-MM-DD'),
  endDate = dayjs().format('YYYY-MM-DD'),
}) => {
  const { data } = useQuery<OverviewMachineTableData[]>(
    ['overviewMachineReport', startDate, endDate],
    () =>
      fetch(
        urlAPIMechanic +
          `/mechanic/overview/machine?start=${startDate}&end=${endDate}`
      )
        .then((res) => res.json())
        .then((data) => data.data)
  );

  return (
    <div className="rounded-lg px-8 py-7 border border-grey-1">
      <div className="grid grid-cols-3 font-semibold text-caption-1 px-2 py-2.5">
        <div>Name</div>
        <div>Avg Power Consumption</div>
        <div>Total Energy Consumption</div>
      </div>
      {/* Put the children here later */}
      {data?.map((val, i) => (
        <div
          key={`overview-machine-report-${val.id}`}
          className={classNames('grid grid-cols-3 text-caption-1 px-2 py-2.5', {
            'bg-white-snow': i % 2,
          })}
        >
          <div>{val.nama_node}</div>
          <div>{val.avgConsumption.toFixed(2)} W</div>
          <div>{val.totalConsumption.toFixed(2)} kWh</div>
        </div>
      ))}
      <div className="grid grid-cols-3 font-semibold text-caption-1 px-2 py-2.5">
        <div className="col-span-2">Total Energy</div>
        <div>
          {data
            ?.reduce((acc, currentVal) => acc + currentVal.totalConsumption, 0)
            .toFixed(2)}{' '}
          kWh
        </div>
      </div>
    </div>
  );
};

export default OverviewMachineTable;
