import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { IconClose, IconLaunch } from 'assets';
import { ChartLabel, ChartLegend, Button } from 'components';
import { ColdStorageColorMapping } from 'colors';
import { useQuery, useQueryClient } from 'react-query';
import dayjs from 'dayjs';
import { ColdStorageList, MapAny } from 'utils/types';
import { urlAPIMechanic } from 'utils';

interface DoorStatusAndTempProps {
  mode?: 'default' | 'realtime';
  className?: string;
  startDate?: string;
  endDate?: string;
}

type DoorStatusAndTempData = {
  id: number;
  createdAt: string;
  data1: number;
  data2: number;
  data3: number;
};

const DoorStatusAndTemp: React.FC<DoorStatusAndTempProps> = ({
  className = '',
  startDate = dayjs().subtract(9, 'days').format('YYYY-MM-DD'),
  endDate = dayjs().format('YYYY-MM-DD'),
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [reRender, setReRender] = useState<boolean>(true);
  const [active, setActive] = useState<number>(1);
  const [selected, setSelected] = useState<MapAny>({});
  const [avgTemp, setAvgTemp] = useState<number>(0);
  const [immutableSeries, setImmutableSeries] = useState<
    Highcharts.SeriesOptionsType[]
  >([]);
  const [options, setOptions] = useState<Highcharts.Options>({
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Hours',
      },
      dateTimeLabelFormats: {
        hour: '%H:%M',
      },
      gridLineWidth: 1,
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: '<p><sup>o</sup>C</p>',
        rotation: 0,
        useHTML: true,
      },
    },
    series: [],
    plotOptions: {
      line: {
        lineWidth: 4,
        states: {
          hover: {
            lineWidth: 5,
          },
        },
        marker: {
          enabled: false,
        },
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return `${dayjs(this.x - 7 * 3600 * 1000).format(
          'dddd, D MMM YYYY, hh:mm:ss'
        )}<br>
        ${this.series.name}: <b>${this.y} Celcius</b><br>
        <b>${this.point.name}</b>`;
      },
    },
    annotations: [],
  });

  useQuery<DoorStatusAndTempData[]>(
    ['doorStatusAndTemp', startDate, endDate, active],
    () =>
      fetch(
        urlAPIMechanic +
          `/mechanic/cold-storage/dashboard/door-status-temperature?start=${startDate}&end=${endDate}&id=${active}`
      )
        .then((res) => res.json())
        .then((data) => data.data),
    {
      onSuccess: (data) => {
        function generateMarkers(): Highcharts.PointOptionsObject[] {
          let arr: Highcharts.PointOptionsObject[] = [];
          let p1 = 0;
          let prev = 0;

          while (p1 < data.length) {
            if (p1 > 0 && prev && data[p1].data3) {
              arr.push({
                x: dayjs(data[p1].createdAt).valueOf(),
                y: data[p1].data1,
                name: 'Open',
              });

              prev = data[p1].data3;
              p1++;
            } else if (p1 > 0 && prev && !data[p1].data3) {
              arr.push({
                x: dayjs(data[p1].createdAt).valueOf(),
                y: data[p1].data1,
                marker: {
                  fillColor: '#fff',
                  enabled: true,
                  lineColor: '#333',
                  lineWidth: 2,
                },
                name: 'Close',
              });

              prev = data[p1].data3;
              p1++;
            } else if (data[p1].data3) {
              arr.push({
                x: dayjs(data[p1].createdAt).valueOf(),
                y: data[p1].data1,
                marker: {
                  fillColor: '#fff',
                  enabled: true,
                  lineColor: '#333',
                  lineWidth: 2,
                },
                name: 'Open',
              });

              prev = data[p1].data3;
              p1++;
            } else {
              arr.push({
                x: dayjs(data[p1].createdAt).valueOf(),
                y: data[p1].data1,
                name: '',
              });

              prev = data[p1].data3;
              p1++;
            }
          }

          return arr;
        }

        let series: Highcharts.SeriesOptionsType[] = [
          {
            type: 'line',
            name: 'Sensor 1',
            color: ColdStorageColorMapping[1],
            data: generateMarkers(),
          },
          {
            type: 'line',
            name: 'Sensor 2',
            color: ColdStorageColorMapping[2],
            data: data.map((val) => ({
              x: dayjs(val.createdAt).valueOf(),
              y: val.data2,
              name: '',
            })),
          },
        ];

        setSelected({
          'Sensor 1': true,
          'Sensor 2': true,
        });

        // deep copy new references for readonly purposes
        setImmutableSeries(JSON.parse(JSON.stringify(series)));

        function generatePlotBands(): Highcharts.XAxisPlotBandsOptions[] {
          let arr: Highcharts.XAxisPlotBandsOptions[] = [];
          let p1 = 0;
          let p2 = 1;

          while (p1 < data.length) {
            if (data[p1].data3 && p2 < data.length) {
              if (!data[p2].data3) {
                arr.push({
                  from: dayjs(data[p1].createdAt).valueOf(),
                  to: dayjs(data[p2].createdAt).valueOf(),
                  color: 'rgba(255, 206, 206, 0.5)',
                });
                p1 = p2 + 1;
                p2 = p1 + 1;
              } else if (data[p2].data3 && p2 === data.length - 1) {
                arr.push({
                  from: dayjs(data[p1].createdAt).valueOf(),
                  to: dayjs(data[p2].createdAt).valueOf(),
                  color: 'rgba(255, 206, 206, 0.5)',
                });
                p1 = p2 + 1;
                p2 = p1 + 1;
              } else {
                p2++;
              }
            } else {
              p1++;
              p2 = p1 + 1;
            }
          }

          return arr;
        }

        let total = data.reduce(
          (acc, currentVal) => acc + currentVal.data1 + currentVal.data2,
          0
        );

        setAvgTemp(total / (data.length * 2));

        setOptions({
          ...options,
          xAxis: {
            ...options.xAxis,
            plotBands: generatePlotBands(),
          },
          series,
        });
      },
    }
  );

  const queryClient = useQueryClient();
  const lists = queryClient.getQueryData<ColdStorageList[]>('coldStorageList');

  useEffect(() => {
    if (lists) setActive(lists[0].id);
  }, [lists]);

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
    // Hack the chart so it can fill entire screen during fullscreen state
    setReRender(false);
    setTimeout(() => {
      setReRender(true);
    }, 0);
  };

  const handleChangeLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;

    let newSelected = { ...selected, [name]: checked };

    setSelected(newSelected);

    let selectedKeys = Object.keys(selected).filter((k) => newSelected[k]);
    const series: Highcharts.SeriesOptionsType[] = JSON.parse(
      JSON.stringify(immutableSeries)
    );

    const newSeries = series.map((val) => {
      let condition = selectedKeys.some((k) => val.name === k);

      if (!condition) return { ...val, data: [] };

      return val;
    });

    setOptions({
      ...options,
      // @ts-ignore
      series: newSeries,
    });
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        [className]: className,
      })}
    >
      <button onClick={handleFullScreen} className="absolute right-5 top-5">
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">
          Door Status and Temperature - Cold Storage 1
        </h4>
        {reRender && (
          <HighchartsReact options={options} highcharts={Highcharts} />
        )}
        <div className="flex items-center justify-between pl-10 my-3">
          <span className="text-caption-1 text-grey font-bold">
            Avg Temp{' '}
            <span className="text-blue">
              {avgTemp} <sup>o</sup>C
            </span>
          </span>
          <div className="flex items-center">
            <ChartLegend
              className="mr-4"
              title="Open Status"
              color={ColdStorageColorMapping[4]}
            />
            <ChartLabel
              bgColor={ColdStorageColorMapping[1]}
              title="Sensor 1"
              className="mr-4"
              name="Sensor 1"
              checked={selected['Sensor 1']}
              onChange={handleChangeLabel}
            />
            <ChartLabel
              name="Sensor 2"
              bgColor={ColdStorageColorMapping[2]}
              title="Sensor 2"
              checked={selected['Sensor 2']}
              onChange={handleChangeLabel}
            />
          </div>
        </div>
        <div className="flex items-center flex-wrap justify-center">
          {lists?.map((val) => (
            <Button
              key={`door-status-temp-${val.id}`}
              className="m-3"
              color={val.id === active ? 'default' : 'grey'}
              variant="outlined"
              onClick={() => setActive(val.id)}
            >
              {val.nama_node}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DoorStatusAndTemp;
