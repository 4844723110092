const LayeringUnitProcessLogTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">Detailed Layering Input and Output</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <div
          style={{ gridTemplateColumns: '1fr 40px repeat(11, 1fr)' }}
          className="grid items-center font-semibold text-caption-1 gap-y-2 gap-x-4 px-2 py-2.5"
        >
          <div>Worker</div>
          <div>No</div>
          <div>Basket ID</div>
          <div>Supplier</div>
          <div>Species</div>
          <div>Product</div>
          <div>Size</div>
          <div>Grade</div>
          <div>Start</div>
          <div>Finish</div>
          <div className="col-span-3 text-center">Output Inner</div>
        </div>
        {/* Put the children here later */}
      </div>
      <h6 className="font-bold mb-2">Detailed MCO After Day</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <div
          style={{ gridTemplateColumns: '40px repeat(8, 1fr)' }}
          className="grid items-center font-semibold text-caption-1 gap-y-2 gap-x-4 px-2 py-2.5"
        >
          <div>No</div>
          <div>Timestamp</div>
          <div>Species</div>
          <div>Product</div>
          <div>Size</div>
          <div>Grade</div>
          <div>Weight</div>
        </div>
        {/* Put the children here later */}
      </div>
      <h6 className="font-bold mb-2">Detailed Rejected</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <div
          style={{ gridTemplateColumns: '40px repeat(8, 1fr)' }}
          className="grid items-center font-semibold text-caption-1 gap-y-2 gap-x-4 px-2 py-2.5"
        >
          <div>No</div>
          <div>Worker</div>
          <div>Timestamp</div>
          <div>Basket ID</div>
          <div>Species</div>
          <div>Product</div>
          <div>Size</div>
          <div>Grade</div>
          <div>Weight</div>
        </div>
        {/* Put the children here later */}
      </div>
    </>
  );
};

export default LayeringUnitProcessLogTable;
