import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IconExcel } from 'assets';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  DatePicker,
  MachineConditionTable,
  MachineConsumptionTable,
  MachineProductivityTable,
} from 'components';
import { SetEndDate, SetStartDate } from 'redux/filterDates/filterDate.action';
import { RootReducerState } from 'redux/root-reducer';
import { useQuery } from 'utils/useQuery';

const MachinePerformanceReportPage = () => {
  const report = useQuery().get('report') || 'machine-consumption';
  const history = useHistory();

  const startDate = useSelector(
    (state: RootReducerState) => state.filterDate.machineReport.startDate
  );
  const endDate = useSelector(
    (state: RootReducerState) => state.filterDate.machineReport.endDate
  );

  const dispatch = useDispatch();

  return (
    <div className="user-management">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-heading font-bold mb-3">
            Machine Performance Report
          </h1>
          <Breadcrumb className="mb-5">
            <BreadcrumbItem href="/mechanic/overview">Home</BreadcrumbItem>
            <BreadcrumbItem href="/mechanic/machine-performance">
              Machine Performance
            </BreadcrumbItem>
            <BreadcrumbItem isActive>Report</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="flex items-center">
          <Button
            className="mr-5 w-44"
            color={report === 'machine-consumption' ? 'default' : 'grey'}
            onClick={() =>
              history.push({ search: 'report=machine-consumption' })
            }
          >
            Machine Consumption
          </Button>
          <Button
            className="w-40 mr-5"
            color={report === 'machine-productivity' ? 'default' : 'grey'}
            onClick={() =>
              history.push({ search: 'report=machine-productivity' })
            }
          >
            Machine Producitivity
          </Button>
          <Button
            className="w-40"
            color={report === 'machine-condition' ? 'default' : 'grey'}
            onClick={() => history.push({ search: 'report=machine-condition' })}
          >
            Machine Condition
          </Button>
        </div>
      </div>
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <DatePicker
            selected={new Date(startDate)}
            onChange={(date) =>
              dispatch(
                // @ts-ignore
                SetStartDate('machineReport', dayjs(date).format('YYYY-MM-DD'))
              )
            }
            dateFormat="E, d MMM yyyy"
            containerClassName="mr-3.5"
          />
          <DatePicker
            selected={new Date(endDate)}
            onChange={(date) =>
              dispatch(
                // @ts-ignore
                SetEndDate('machineReport', dayjs(date).format('YYYY-MM-DD'))
              )
            }
            dateFormat="E, d MMM yyyy"
            popperPlacement="bottom-end"
          />
        </div>
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Export
          </span>
          <button className="p-2 rounded-md border border-grey-1">
            <IconExcel />
          </button>
        </div>
      </div>
      {report === 'machine-consumption' && (
        <MachineConsumptionTable startDate={startDate} endDate={endDate} />
      )}
      {report === 'machine-productivity' && <MachineProductivityTable />}
      {report === 'machine-condition' && (
        <MachineConditionTable startDate={startDate} endDate={endDate} />
      )}
    </div>
  );
};

export default MachinePerformanceReportPage;
