import { IconExcel } from 'assets';
import classNames from 'classnames';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  DatePicker,
  MultiSelectDropdown,
  TableGrid,
} from 'components';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import ReactDropdown from 'react-dropdown';

const ManagementStockTransferPage = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [active, setActive] = useState<
    'packing' | 'shipment' | 'reprocessing' | 'repacking' | 'entertainment'
  >('packing');

  return (
    <div style={{ minWidth: 1200 }}>
      <h1 className="text-heading font-bold mb-3">Stock Transfer</h1>
      <Breadcrumb className="mb-5">
        <BreadcrumbItem href="/management/stock">Stock</BreadcrumbItem>

        <BreadcrumbItem isActive>Stock Transfer</BreadcrumbItem>
      </Breadcrumb>
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <span className="text-caption-1 text-grey mr-3 font-semibold">
            Filter
          </span>
          <DatePicker
            selected={startDate}
            onChange={(date: Date) => setStartDate(date)}
            dateFormat="E, d MMM yyyy"
            containerClassName="mr-3.5"
          />
          <DatePicker
            selected={endDate}
            onChange={(date: Date) => setEndDate(date)}
            dateFormat="E, d MMM yyyy"
          />
        </div>
        <div style={{ width: 520 }} className="grid grid-cols-4 gap-x-2">
          <MultiSelectDropdown placeholder="Product" />
          <MultiSelectDropdown placeholder="Species" />
          <MultiSelectDropdown placeholder="Brand" />
          <MultiSelectDropdown placeholder="Size" />
        </div>
      </div>
      <Card
        color="yellow"
        title="Global Stock"
        className="font-bold mb-4"
        maxTableWidth={488}
      ></Card>
      <div className="grid grid-cols-3 gap-x-3 mb-12">
        <Card title="Tarakan Stock" color="green" className="font-bold"></Card>
        <Card
          title="Balikpapan Stock"
          color="purple"
          className="font-bold"
        ></Card>
        <Card title="Surabaya Stock" color="blue" className="font-bold"></Card>
      </div>
      <div
        style={{ width: 800 }}
        className="grid grid-cols-4 gap-x-2 grid-flow-col ml-auto"
      >
        <Button
          onClick={() => setActive('packing')}
          color={active === 'packing' ? 'default' : 'grey'}
        >
          Packing
        </Button>
        <Button
          onClick={() => setActive('shipment')}
          color={active === 'shipment' ? 'default' : 'grey'}
        >
          Shipment
        </Button>
        <Button
          onClick={() => setActive('reprocessing')}
          color={active === 'reprocessing' ? 'default' : 'grey'}
        >
          Reprocessing
        </Button>
        <Button
          onClick={() => setActive('repacking')}
          color={active === 'repacking' ? 'default' : 'grey'}
        >
          Repacking
        </Button>
        <Button
          onClick={() => setActive('entertainment')}
          color={active === 'entertainment' ? 'default' : 'grey'}
        >
          Entertainment
        </Button>
      </div>
      {active === 'packing' && (
        <Report
          startDate={startDate}
          endDate={endDate}
          queryKey="stock-transfer-packing"
        />
      )}
      {active === 'shipment' && (
        <Report
          startDate={startDate}
          endDate={endDate}
          queryKey="stock-transfer-shipment"
        />
      )}
      {active === 'reprocessing' && (
        <Report
          startDate={startDate}
          endDate={endDate}
          queryKey="stock-transfer-reprocessing"
        />
      )}
      {active === 'repacking' && (
        <Report
          startDate={startDate}
          endDate={endDate}
          queryKey="stock-transfer-repacking"
        />
      )}
      {active === 'entertainment' && (
        <Report
          startDate={startDate}
          endDate={endDate}
          queryKey="stock-transfer-entertainment"
        />
      )}
    </div>
  );
};

export default ManagementStockTransferPage;

interface CardProps {
  className?: string;
  color?: 'green' | 'purple' | 'blue' | 'yellow';
  queryKey?: string;
  title?: string;
  maxTableWidth?: string | number;
}

const Card: React.FC<CardProps> = ({
  color = 'green',
  className,
  title,
  maxTableWidth,
}) => {
  return (
    <div
      className={classNames(
        'rounded-lg border-2 text-caption-1 px-4 py-6',
        className,
        {
          'border-colorful-green': color === 'green',
          'border-colorful-yellow': color === 'yellow',
          'border-colorful-purple': color === 'purple',
          'border-colorful-blue': color === 'blue',
        }
      )}
    >
      <div className="text-center text-footnote font-bold mb-4">{title}</div>
      <div className="mx-auto" style={{ maxWidth: maxTableWidth }}>
        <TableGrid
          columns={[
            {
              field: 'type',
              name: 'Type',
              textAlign: 'left',
            },
            {
              field: 'mc',
              name: 'MC',
              textAlign: 'center',
            },
            {
              field: 'kg',
              name: 'Kg',
              textAlign: 'right',
            },
          ]}
          data={[
            {
              type: 'Packing',
              kg: '1.298',
              mc: '788',
            },
            {
              type: 'Shipment',
              kg: '1.298',
              mc: '788',
            },
            {
              type: 'Reprocessing',
              kg: '1.298',
              mc: '788',
            },
            {
              type: 'Repacking',
              kg: '1.298',
              mc: '788',
            },
            {
              type: 'Entertainment',
              kg: '1.298',
              mc: '788',
            },
          ]}
          onRenderField={(field, value, { key }) => {
            if (field === 'mc')
              return (
                <div key={key} className="text-center">
                  {value}
                </div>
              );

            if (field === 'kg')
              return (
                <div key={key} className="text-right">
                  {value}
                </div>
              );
          }}
        />
      </div>
    </div>
  );
};

interface ReportProps {
  queryKey?: string;
  startDate?: Date;
  endDate?: Date;
}

const Report: React.FC<ReportProps> = ({ startDate, endDate }) => {
  const [active, setActive] = useState<string>('global');

  return (
    <div className="border border-grey-1 rounded-lg py-5 px-8 overflow-auto">
      <div className="flex items-center justify-between">
        <div className="flex items-center text-blue text-caption-1 font-bold">
          <span className="mr-2">Downloads :</span>
          <button>
            <IconExcel />
          </button>
        </div>
        <ReactDropdown
          controlClassName="border-grey-placeholder border w-64"
          className="text-black text-caption-1"
          value={active}
          onChange={({ value }) => setActive(value)}
          options={[
            {
              label: 'Global',
              value: 'global',
            },
            {
              label: 'Tarakan',
              value: 'tarakan',
            },
            {
              label: 'Balikpapan',
              value: 'balikpapan',
            },
            {
              label: 'Surabaya',
              value: 'surabaya',
            },
          ]}
        />
      </div>
      <div className="text-center font-bold underline uppercase">
        LAPORAN STOCK {dayjs(startDate).format('DD MMMM YYYY')} -{' '}
        {dayjs(endDate).format('DD MMMM YYYY')} {active}
      </div>
      <div className="flex flex-col items-start mt-4 text-caption-1 mb-6">
        <div
          style={{ gridTemplateColumns: '72px 1fr', width: 320 }}
          className="grid mb-3 mx-auto"
        >
          <div className="font-bold">Product</div>
          <div>: Headless PDTO</div>
        </div>

        <div
          style={{ gridTemplateColumns: '72px 1fr', width: 320 }}
          className="grid mb-3 mx-auto"
        >
          <div className="font-bold">Species</div>
          <div>: All Species</div>
        </div>

        <div
          style={{ gridTemplateColumns: '72px 1fr', width: 320 }}
          className="grid mb-3 mx-auto"
        >
          <div className="font-bold">Brand</div>
          <div>: All Brand</div>
        </div>

        <div
          style={{ gridTemplateColumns: '72px 1fr', width: 320 }}
          className="grid mb-6 mx-auto"
        >
          <div className="font-bold">Size</div>
          <div>: All Size</div>
        </div>
        <div
          style={{ gridTemplateColumns: '72px 1fr', width: 320 }}
          className="grid mb-3 mx-auto"
        >
          <div className="font-bold">Total MC</div>
          <div>: 1771</div>
        </div>
        <div
          style={{ gridTemplateColumns: '72px 1fr', width: 320 }}
          className="grid mx-auto"
        >
          <div className="font-bold">Total kg</div>
          <div>: 17.500.810</div>
        </div>
      </div>
      <div className="px-64">
        <TableGrid
          className="border border-grey-1 rounded-lg mb-10"
          config={{
            minWidth: '837px',
          }}
          columns={[
            {
              name: 'size',
              field: 'size',
              width: '2fr',
              Component: (
                <div className="grid grid-cols-2 grid-rows-4 font-bold text-caption-1 text-blue items-center">
                  <div className="row-span-4 justify-self-center">Size</div>
                  <div className="py-3 even:bg-white bg-white-snow px-2">
                    Product
                  </div>
                  <div className="py-3 even:bg-white bg-white-snow px-2">
                    Species
                  </div>
                  <div className="py-3 even:bg-white bg-white-snow px-2">
                    Brand
                  </div>
                  <div className="py-3 even:bg-white bg-white-snow px-2">
                    Weight/MC (kg)
                  </div>
                </div>
              ),
            },
            {
              name: 'headless',
              field: 'headless',
              width: '4fr',
              cols: ['headless1', 'headless2', 'headless3', 'headless4'],
              Component: (
                <div className="grid grid-cols-4 grid-rows-4 items-center">
                  <div className="col-span-4 text-center py-3">Headless</div>
                  <div className="py-3 px-2 bg-white-snow">BT 1st</div>
                  <div className="py-3 px-2 bg-white-snow">ST 1st</div>
                  <div className="py-3 px-2 bg-white-snow">BT 1st</div>
                  <div className="py-3 px-2 bg-white-snow">BT 2nd</div>
                  <div className="py-3 px-2">G. HILL</div>
                  <div className="py-3 px-2">G. HILL</div>
                  <div className="py-3 px-2">G. SC</div>
                  <div className="py-3 px-2">G. HILL</div>
                  <div className="py-3 px-2 bg-white-snow">10.8</div>
                  <div className="py-3 px-2 bg-white-snow">10.8</div>
                  <div className="py-3 px-2 bg-white-snow">10.8</div>
                  <div className="py-3 px-2 bg-white-snow">10.8</div>
                </div>
              ),
            },
          ]}
          data={[
            {
              size: '4-6',
            },
            {
              size: '6-8',
            },
            {
              size: '8-12',
            },
            {
              size: '13-15',
            },
            {
              size: '16-20',
            },
          ]}
          onRenderField={(field, value, { key }) => {
            if (field === 'size')
              return (
                <div key={key} className="text-center font-bold">
                  {value}
                </div>
              );

            return (
              <div key={key} className="text-center">
                {value}
              </div>
            );
          }}
        />
        <TableGrid
          className="border border-grey-1 rounded-lg mb-10"
          config={{
            minWidth: '682px',
          }}
          columns={[
            {
              name: 'size',
              field: 'size',
              width: '2fr',
              Component: (
                <div className="grid grid-cols-2 grid-rows-4 font-bold text-caption-1 text-blue items-center">
                  <div className="row-span-4 justify-self-center">Size</div>
                  <div className="py-3 even:bg-white bg-white-snow px-2">
                    Product
                  </div>
                  <div className="py-3 even:bg-white bg-white-snow px-2">
                    Species
                  </div>
                  <div className="py-3 even:bg-white bg-white-snow px-2">
                    Brand
                  </div>
                  <div className="py-3 even:bg-white bg-white-snow px-2">
                    Weight/MC (kg)
                  </div>
                </div>
              ),
            },
            {
              name: 'headless',
              field: 'headless',
              width: '3fr',
              cols: ['headless1', 'headless2', 'headless3'],
              Component: (
                <div className="grid grid-cols-3 grid-rows-4 items-center">
                  <div className="col-span-3 text-center py-3">Headless</div>
                  <div className="py-3 px-2 bg-white-snow">W 1st</div>
                  <div className="py-3 px-2 bg-white-snow">Grey 1st</div>
                  <div className="py-3 px-2 bg-white-snow">W 2nd</div>
                  <div className="py-3 px-2">G. HILL</div>
                  <div className="py-3 px-2">G. HILL</div>
                  <div className="py-3 px-2">SC</div>
                  <div className="py-3 px-2 bg-white-snow">10.8</div>
                  <div className="py-3 px-2 bg-white-snow">10.8</div>
                  <div className="py-3 px-2 bg-white-snow">10.8</div>
                </div>
              ),
            },
          ]}
          data={[
            {
              size: '18-12',
            },
            {
              size: '13-15',
            },
            {
              size: '16 / LL / 36',
            },
            {
              size: '21 / L',
            },
            {
              size: '26 / M',
            },
          ]}
          onRenderField={(field, value, { key }) => {
            if (field === 'size')
              return (
                <div key={key} className="text-center font-bold">
                  {value}
                </div>
              );

            return (
              <div key={key} className="text-center">
                {value}
              </div>
            );
          }}
        />
        <div style={{ minWidth: 1400 }} className="pr-48">
          <TableGrid
            className="border border-grey-1 rounded-lg mb-10 mr-48"
            config={{
              minWidth: '1354px',
            }}
            columns={[
              {
                name: 'size',
                field: 'size',
                width: '2fr',
                Component: (
                  <div className="grid grid-cols-2 grid-rows-4 font-bold text-caption-1 text-blue items-center">
                    <div className="row-span-4 justify-self-center">Size</div>
                    <div className="py-3 even:bg-white bg-white-snow px-2">
                      Product
                    </div>
                    <div className="py-3 even:bg-white bg-white-snow px-2">
                      Species
                    </div>
                    <div className="py-3 even:bg-white bg-white-snow px-2">
                      Brand
                    </div>
                    <div className="py-3 even:bg-white bg-white-snow px-2">
                      Weight/MC (kg)
                    </div>
                  </div>
                ),
              },
              {
                name: 'headless',
                field: 'headless',
                width: '8fr',
                cols: [
                  'headless1',
                  'headless2',
                  'headless3',
                  'headless4',
                  'headless5',
                  'headless6',
                  'headless7',
                  'headless8',
                ],
                Component: (
                  <div className="grid grid-cols-8 grid-rows-4 items-center">
                    <div className="col-span-8 text-center py-3">
                      PDTO (T) IQF
                    </div>
                    <div className="py-3 px-2 bg-white-snow">BT</div>
                    <div className="py-3 px-2 bg-white-snow">BT</div>
                    <div className="py-3 px-2 bg-white-snow">BT</div>
                    <div className="py-3 px-2 bg-white-snow">BT</div>
                    <div className="py-3 px-2 bg-white-snow">BT</div>
                    <div className="py-3 px-2 bg-white-snow">BT</div>
                    <div className="py-3 px-2 bg-white-snow">BT</div>
                    <div className="py-3 px-2 bg-white-snow">BT</div>
                    <div className="py-3 px-2">PS</div>
                    <div className="py-3 px-2">PS</div>
                    <div className="py-3 px-2">PS</div>
                    <div className="py-3 px-2">CENSEA</div>
                    <div className="py-3 px-2">F. ONE</div>
                    <div className="py-3 px-2">F. ONE</div>
                    <div className="py-3 px-2">NEPT</div>
                    <div className="py-3 px-2">F. ONE</div>
                    <div className="py-3 px-2 bg-white-snow">9.08</div>
                    <div className="py-3 px-2 bg-white-snow">9.08</div>
                    <div className="py-3 px-2 bg-white-snow">9.08</div>
                    <div className="py-3 px-2 bg-white-snow">4.54</div>
                    <div className="py-3 px-2 bg-white-snow">4.54</div>
                    <div className="py-3 px-2 bg-white-snow">4.54</div>
                    <div className="py-3 px-2 bg-white-snow">9.08</div>
                    <div className="py-3 px-2 bg-white-snow">4.54</div>
                  </div>
                ),
              },
            ]}
            data={[
              {
                size: '18-12',
              },
              {
                size: '13-15',
              },
              {
                size: '16 / LL / 36',
              },
              {
                size: '21 / L',
              },
              {
                size: '26 / M',
              },
            ]}
            onRenderField={(field, value, { key }) => {
              if (field === 'size')
                return (
                  <div key={key} className="text-center font-bold">
                    {value}
                  </div>
                );

              return (
                <div key={key} className="text-center">
                  {value}
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};
