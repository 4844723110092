import HomeManagementItems from 'json/home-management-items.json';
import {
  Breadcrumb,
  BreadcrumbItem,
  HomeUnitIcon,
  IconSidebarUnitMap,
} from 'components';

const HomeManagementPage = () => {
  return (
    <div style={{ minWidth: 900 }}>
      <h1 className="text-heading font-bold mb-3">
        Smart Factory Supervisor Dashboard
      </h1>
      <Breadcrumb className="mb-8">
        <BreadcrumbItem isActive>Home</BreadcrumbItem>
      </Breadcrumb>
      <h2 className="text-sub-heading font-bold mb-8">Menu</h2>
      <div
        style={{ gridTemplateColumns: 'repeat(auto-fit, 80px)' }}
        className="grid gap-x-10 gap-y-10 mb-10"
      >
        {HomeManagementItems.menu.map(({ color, icon, name, route }) => {
          const Icon = IconSidebarUnitMap[icon];
          return (
            <HomeUnitIcon
              Icon={() => <Icon className="ic-white" height={48} width={48} />}
              color={color}
              title={name}
              to={route}
            />
          );
        })}
      </div>
      <h2 className="text-sub-heading font-bold mb-8">Unit Dashboard</h2>
      <div
        style={{ gridTemplateColumns: 'repeat(auto-fit, 80px)' }}
        className="grid gap-x-10 gap-y-10 mb-10"
      >
        {HomeManagementItems.unit.map(({ color, icon, name, route }) => {
          const Icon = IconSidebarUnitMap[icon];
          return (
            <HomeUnitIcon
              Icon={() => <Icon className="ic-white" height={48} width={48} />}
              color={color}
              title={name}
              to={route}
            />
          );
        })}
      </div>
      <h2 className="text-sub-heading font-bold mb-8">Mechanic Dashboard</h2>
      <div
        style={{ gridTemplateColumns: 'repeat(auto-fit, 80px)' }}
        className="grid gap-x-10 gap-y-10"
      >
        {HomeManagementItems.mechanic.map(({ color, icon, name, route }) => {
          const Icon = IconSidebarUnitMap[icon];
          return (
            <HomeUnitIcon
              Icon={() => <Icon className="ic-white" height={48} width={48} />}
              color={color}
              title={name}
              to={route}
            />
          );
        })}
      </div>
    </div>
  );
};

export default HomeManagementPage;
