import { IconEventNote, IconTrash, IconEdit } from 'assets';
import classNames from 'classnames';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  DatePicker,
  Loading,
  ManagementTakeoutOrderCommandTable,
} from 'components';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import ReactDropdown, { Option } from 'react-dropdown';
import ReactModal from 'react-modal';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
// import { SetStartDate } from 'redux/filterDates/filterDate.action';
import { RootReducerState } from 'redux/root-reducer';
import {
  setModalClose,
  setModalOpen,
} from 'redux/takeoutOrder/takeoutOrder.action';
import { urlAPIManagement, urlAPITablet, urlAPITabletBPN, urlAPIManagementBPN } from 'utils';

interface TakeoutOrderPopupProps {
  isOpen?: boolean;
  onRequestClose?: () => void;
  onSubmit?: (value: TakeOutOrderPopupState) => void;
  initialState?: TakeOutOrderPopupState;
  type: 'create' | 'edit';
}



type ProductItem = {
  id?: string;
  Product_id?: string;
  Spesies_id?: string | undefined;
  Species_id?: string | undefined;
  Size_id?: string;
  Supplier_id? : string;
  date_start?: string;
  date_end?: string;
  quantity?: string;
  setProductItems?: React.Dispatch<
    React.SetStateAction<Record<string, ProductItem>>
  >;
  productItems?: Record<string, ProductItem>;
  onChange?: (name: string, value: string) => void;
  className?: string;
  deleteAble?: boolean;
  state?: TakeOutOrderPopupState;
  optionsProduct2?: ProductOption2[];
  optionsProduct?: Array<{product: {id: number, productType: {name: string}, grade: {nama_grade: string}, brand: {name: string}, frozenType: {name: string}, package: {name: string}}}>;
};

type OrderItem = Omit<
  ProductItem,
  'productItems' | 'onChange' | 'className' | 'deleteAble' | 'setProductItems'
>;

const ProductItemComponent: React.FC<ProductItem> = ({
  date_end,
  Size_id,
  Spesies_id,
  quantity,
  Product_id,
  Supplier_id,
  date_start,
  id,
  setProductItems,
  productItems,
  onChange,
  className,
  deleteAble,
  state,
  optionsProduct,
  optionsProduct2
}) => {

  interface productModalProps  {
    optionsProduct2?: ProductOption2[],
    optionsProduct?: Array<{product: {id: number, productType: {name: string}, grade: {nama_grade: string}, brand: {name: string}, frozenType: {name: string}, package: {name: string}}}>;
    showModal?: boolean;
    setShowModal?: (value: boolean) => void;
    handleChoose?: (a: {value: number, label: string} | undefined) =>  void;
    afterOpenModal?: () => void;
    refetchProduct?: (i:number) => Promise<boolean>;
    selectedProduct?: {value: number, label: string};
    setSelectedProduct?: (value: {value: number, label: string}) => void;
  }

  // useEffect (() => {
  //   if (setProductItems && productItems) {
  //     Object.keys(productItems).map((key) => {
  //       const prod = productItems[key]
  //       if (prod.id === id) {
  //         setProductItems({
  //           ...productItems,
  //           [key]: {
  //             ...productItems[key],
  //             Product_id: Product_id,
  //           },
  //         });
  //       }
  //       return null;
  //     })
  //   }
  // }, [])

  const handleChoose = (a: {value: number, label:string} | undefined) => {
    const args = {
      value: String(a?.value),
      label: a?.label
    };
    handleChangeDropdown(args, 'Product_id');
  }
  

  const handleAfterOpenProduct = () => {
    console.log('modal product opened')
  }

  const [modalProduct, setModalProduct] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<{value: number, label: string}>({value: 0, label:''})

  const handleProductClick = (e: any) => {
    e.preventDefault();
    console.log('open product modal');
    if (state?.Factory_id) {
      setModalProduct(true);
    } else {
      alert('pilih factory dahulu');
    }
      }

  const ProductModal: React.FC<productModalProps> = ({
    showModal = false,
    optionsProduct,
    optionsProduct2,
    setShowModal,
    handleChoose,
    afterOpenModal,
    refetchProduct,
    selectedProduct,
    setSelectedProduct
    }) => {
    const [params, setParams] = useState<{unitStationId : number, productTypeId : number, frozenTypeId: number, gradeId: number, brandId: number, packageId:number}>({unitStationId : 18, productTypeId : 0, frozenTypeId: 0, gradeId: 0, brandId: 0, packageId:0})
    const [params2, setParams2] = useState<{Type_id : number, Frozen_Type_id: number, Grade_id: number, Brand_id: number, Package_id:number}>({Type_id : 0, Frozen_Type_id: 0, Grade_id: 0, Brand_id: 0, Package_id:0})
    
    const closeModal = () => {
        setShowModal?.(false);
        setSelectedProduct?.({value: 0, label:''});
    }

    const handleRegister2 = useMutation(
      async () => {
        console.log(`post ${JSON.stringify(params2)}`)
        const res = await fetch(urlAPITabletBPN + `/global/product/13`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params2),
        });
        const {data} = await res.json();
        console.log({value: data?.id, label:data?.product_name});
        return (
          {value: data?.id, label:data?.product_name}
        );

      },
       {
        onSuccess: async (data) => {
          console.log('succcess')
          const done = await refetchProduct?.(2);
          console.log(done);
          setParams2({Type_id : 0, Frozen_Type_id: 0, Grade_id: 0, Brand_id: 0, Package_id:0});
          if (done) {
            console.log(`set data ${JSON.stringify(data)}`);
            setSelectedProduct?.(data);
          }
        },
      }
    );

    const handleRegister = useMutation(
      async () => {
        console.log(`post ${JSON.stringify(params)}`)
        const res = await fetch(urlAPITablet + '/product/add', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        });
        const {data} = await res.json();
        return (
          {value: data?.id, label:`${data.productType?.name ?? ''} ${data.grade?.nama_grade ?? ''} ${data.frozenType?.name ?? ''} ${data.brand?.name ?? ''} ${data.package.name}`}
        );

      },
       {
        onSuccess: async (data) => {
          const done = await refetchProduct?.(1);
          console.log(done);
          setParams({unitStationId : 18, productTypeId : 0, frozenTypeId: 0, gradeId: 0, brandId: 0, packageId:0});
          if (done) {
            console.log(`set data ${JSON.stringify(data)}`);
            setSelectedProduct?.(data);
          }
        },
      }
    );
      return (
        <ReactModal
        style={{
          content: {
            width: 900,
          },
        }}
        isOpen={showModal}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        >
          <h1 className="font-bold">{`Choose Product`}</h1>
          <br />
           <ReactDropdown
        onChange={({value, label}) => setSelectedProduct?.({
          value: Number(value),
          label : String(label)
        })}
        controlClassName="border-grey-placeholder border line-clamp-1"
        placeholder="Product"
        className="text-black text-footnote h-max"
        value={String(selectedProduct?.value || '')}
        options={ state?.Factory_id === '1' ?
          (Array.from(optionsProduct || [], ({ product }) => ({
          label: `${product.productType?.name ?? ''} ${product.grade?.nama_grade ?? ''} ${product.frozenType?.name ?? ''} ${product.brand?.name ?? ''} ${product.package.name}`,
          value: product.id.toString(),
        }))) : (Array.from(optionsProduct2 || [], ({ id, product_name }) => ({
          label: product_name,
          value: id.toString(),
        })))}
      /> 
      <div className="flex items-center justify-end my-4">
        <Button
          onClick={closeModal}
          color="grey"
          variant="outlined"
          className="mr-3 rounded-full"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {handleChoose?.(selectedProduct); closeModal();}}
          color="dark-blue"
          className="rounded-full"
        >
          Choose
        </Button>
      </div>
      <br />
      <br /> 
            <h1 className="font-bold">Register New Product</h1>
            <br />
            <div
      style={{ gridTemplateColumns: 'repeat(6, auto)' }}
      className={classNames('grid gap-x-1 items-center', className)}
    >
               <ReactDropdown
        onChange={({value}) => 
          state?.Factory_id === '1' ?
        (setParams(prevState => ({
          ...prevState,
          productTypeId: Number(value) ?? 0
        }))): 
      (
        setParams2(prevState => ({
          ...prevState,
          Type_id: Number(value) ?? 0
        }))
      )
      }
        controlClassName="border-grey-placeholder border line-clamp-1"
        placeholder="Type"
        className="text-black text-footnote h-max"
        value={state?.Factory_id === '1' ? String(params?.productTypeId || '') : String(params2?.Type_id || '')}
        options={Array.from(productTypeOptions?.productType || [], ({ id, name }) => ({
          label: name,
          value: id.toString(),
        }))}
      /> 
                 <ReactDropdown
        onChange={({value}) => 
        state?.Factory_id === '1' ?
        (setParams(prevState => ({
          ...prevState,
          gradeId: Number(value) ?? 0
        }))): 
      (
        setParams2(prevState => ({
          ...prevState,
          Grade_id: Number(value) ?? 0
        }))
      )
      }
        controlClassName="border-grey-placeholder border line-clamp-1"
        placeholder="Grade"
        className="text-black text-footnote h-max"
        value={state?.Factory_id === '1' ? String(params?.gradeId || '') : String(params2?.Grade_id || '')}
        options={Array.from(productTypeOptions?.grade || [], ({ id, nama_grade }) => ({
          label: nama_grade,
          value: id.toString(),
        }))}
      /> 
                 <ReactDropdown
        onChange={({value}) => 
        state?.Factory_id === '1' ?
        (setParams(prevState => ({
          ...prevState,
          brandId: Number(value) ?? 0
        }))): 
      (
        setParams2(prevState => ({
          ...prevState,
          Brand_id: Number(value) ?? 0
        }))
      )
      }
        controlClassName="border-grey-placeholder border line-clamp-1"
        placeholder="Brand"
        className="text-black text-footnote h-max"
        value={state?.Factory_id === '1' ?  String(params?.brandId || '') : String(params2?.Brand_id || '')}
        options={Array.from(productTypeOptions?.brand || [], ({ id, name }) => ({
          label: name,
          value: id.toString(),
        }))}
      /> 
                 <ReactDropdown
        onChange={({value}) => 
        state?.Factory_id === '1' ?
        (setParams(prevState => ({
          ...prevState,
          frozenTypeId: Number(value) ?? 0
        }))): 
      (
        setParams2(prevState => ({
          ...prevState,
          Frozen_Type_id: Number(value) ?? 0
        }))
      )
      }
        controlClassName="border-grey-placeholder border line-clamp-1"
        placeholder="Frozen Type"
        className="text-black text-footnote h-max"
        value={state?.Factory_id === '1' ?  String(params?.frozenTypeId || '') : String(params2?.Frozen_Type_id || '')}
        options={Array.from(productTypeOptions?.frozenType || [], ({ id, name }) => ({
          label: name,
          value: id.toString(),
        }))}
      /> 
                 <ReactDropdown
        onChange={({value}) => 
        state?.Factory_id === '1' ?
        (setParams(prevState => ({
          ...prevState,
          packageId: Number(value) ?? 0
        }))): 
      (
        setParams2(prevState => ({
          ...prevState,
          Package_id: Number(value) ?? 0
        }))
      )
      }
        controlClassName="border-grey-placeholder border line-clamp-1"
        placeholder="Package"
        className="text-black text-footnote h-max"
        value={state?.Factory_id === '1' ? String(params?.packageId || '') : String(params2?.Package_id || '')}
        options={Array.from(productTypeOptions?.package || [], ({ id, name }) => ({
          label: name,
          value: id.toString(),
        }))}
      /> 
      <Button
        onClick={(e:any)=> {
          e.preventDefault()
          if (state?.Factory_id === '1') {
            handleRegister.mutate();
          } else {
            handleRegister2.mutate();
          }
        }}
       color="purple"
       variant="outlined"
       className="mr-3 rounded-full">Register</Button>
      </div>
      <br />
      <br />
      <br />
      <br />
        </ReactModal>
      )
    }

  const queryClient = useQueryClient();

  const options = queryClient.getQueryData<TakeoutOrderOptions>(
    'takeout-order-options'
  );

  


  const { data : productTypeOptions} = useQuery<{productType : {id: number, name: string}[], grade : {id: number, nama_grade: string}[], brand: {id: number, name: string}[], frozenType:{id: number, name: string}[], package: {id: number, name: string}[]}>(
    'takeout-order-options-product-type',
    async () => {
      const res = await fetch(urlAPITablet + '/product/option');
      const data = await res.json();
      console.log(data?.data)
      return data
    }
  );


  const handleChangeDropdown = ({ value }: Option, name: string) => {
    if (setProductItems && productItems)
        Object.keys(productItems).map((key) => {
          const prod = productItems[key]
          if (prod.id === id) {
            setProductItems({
              ...productItems,
              [key]: {
                ...productItems[key],
                [name]: value,
              },
            });
          }
          return null;
        })    
    // if (setProductItems && productItems && id)
    //   setProductItems({
    //     ...productItems,
    //     [id]: {
    //       ...productItems[id],
    //       [name]: value,
    //     },
    //   });

    if (onChange) onChange(name, value);
  };

  const handleChangeDate = (date: Date, name: 'date_start' | 'date_end') => {
    if (setProductItems && productItems && id)
      Object.keys(productItems).map((key) => {
        const prod = productItems[key]
        if (prod.id === id) {
          setProductItems({
            ...productItems,
            [key]: {
              ...productItems[key],
              [name]: dayjs(date).toISOString(),
            },
          });
        }
        return null;
      })

    // if (setProductItems && productItems && id)
    //   setProductItems({
    //     ...productItems,
    //     [id]: {
    //       ...productItems[id],
    //       [name]: dayjs(date).toISOString(),
    //     },
    //   });

    if (onChange) onChange(name, dayjs(date).toISOString());
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    console.log('berubah')

    if (setProductItems && productItems)
      Object.keys(productItems).map((key) => {
        const prod = productItems[key]
        if (prod.id === id) {
          setProductItems({
            ...productItems,
            [key]: {
              ...productItems[key],
              [name]: value,
            },
          });
        }
        return null;
      })

    // if (setProductItems && productItems && id)
    //   setProductItems({
    //     ...productItems,
    //     [id]: {
    //       ...productItems[id],
    //       [name]: value,
    //     },
    //   });

    if (onChange) onChange(name, value);
  };

  const handleDelete = () => {
    if (setProductItems && productItems && id && id !== 'new') {
      Object.keys({ ...productItems })
        .map((key) => {
          const prop = productItems[key]
          if (prop.id === id) {
            const newProductItems = { ...productItems };
            delete newProductItems[key];
            setProductItems(newProductItems);
          }
          return null;
        })
      // const newProductItems = { ...productItems };
      // delete newProductItems[id];
      // setProductItems(newProductItems);
    }
  };

  const handleRefetchProduct = async (i: number) => {
    if (i === 1) {
      await queryClient.refetchQueries({ queryKey: 'takeout-order-options-product' });
    } else {
      await queryClient.refetchQueries({ queryKey: 'takeout-order-options-product-2' });
    }
   return true
  }


  return (
    <div
      style={{ gridTemplateColumns: '2.8fr 0.2fr 1.2fr 1.2fr repeat(3, 1fr) 0.7fr auto' }}
      className={classNames('grid gap-x-1 items-center', className)}
    >
      <ReactDropdown
        disabled
        controlClassName="border-grey-placeholder border line-clamp-1"
        placeholder="Product"
        className="text-black text-footnote h-max"
        value={Product_id || ''}
        options={
          state?.Factory_id === "1" ?
          (Array.from((optionsProduct) || [], ({ product }) => ({
          label: `${product.productType?.name ?? ''} ${product.grade?.nama_grade ?? ''} ${product.frozenType?.name ?? ''} ${product.brand?.name ?? ''} ${product.package.name}`,
          value: product.id.toString(),
        }))): 
        (Array.from((optionsProduct2) || [], ({ id, product_name }) => ({
          label: product_name,
          value: id.toString(),
        })))}
      />
      <button onClick={handleProductClick}>
      <IconEdit />
      </button>
      <ReactDropdown
        onChange={(args) => handleChangeDropdown(args, 'Supplier_id')}
        controlClassName="border-grey-placeholder border line-clamp-1"
        placeholder="Supplier"
        className="text-black text-footnote h-max"
        value={Supplier_id || ''}
        options={Array.from(options?.supplier?.filter(el => el?.supplier_code) || [], ({ id, nama_supplier, supplier_code }) => ({
          label: `${supplier_code}-${nama_supplier}`,
          value: id.toString(),
        }))}
      />
      <ReactDropdown
        onChange={(args) => handleChangeDropdown(args, 'Spesies_id')}
        controlClassName="border-grey-placeholder border line-clamp-1"
        placeholder="Species"
        className="text-black text-footnote h-max"
        value={Spesies_id || ''}
        options={Array.from(options?.spesies || [], ({ id, nama_spesies }) => ({
          label: nama_spesies,
          value: id.toString(),
        }))}
      />
      <ReactDropdown
        onChange={(args) => handleChangeDropdown(args, 'Size_id')}
        controlClassName="border-grey-placeholder border line-clamp-1"
        placeholder="Size"
        className="text-black text-footnote h-max"
        value={Size_id || ''}
        options={Array.from(options?.size || [], ({ id, production_size }) => ({
          label: production_size,
          value: id.toString(),
        }))}
      />
      <div className="flex items-center justify-between w-full h-10.5 border border-grey-placeholder rounded-sm pl-2.5 pr-2">
        <ReactDatePicker
          onChange={(date: Date) => handleChangeDate(date, 'date_start')}
          selected={dayjs(date_start).toDate()}
          dateFormat="dd/MM/yyyy"
          className="text-footnote text-black w-full focus:outline-none"
          wrapperClassName="w-full"
          id={`${id}-startDate`}
          popperPlacement="top"
          placeholderText="Start Date"
        />
        <label className="cursor-pointer" htmlFor={`${id}-startDate`}>
          <IconEventNote />
        </label>
      </div>
      <div className="flex items-center justify-between w-full h-10.5 border border-grey-placeholder rounded-sm pl-2.5 pr-2">
        <ReactDatePicker
          onChange={(date: Date) => handleChangeDate(date, 'date_end')}
          selected={dayjs(date_end).toDate()}
          dateFormat="dd/MM/yyyy"
          className="text-footnote text-black w-full focus:outline-none"
          wrapperClassName="w-full"
          id={`${id}-endDate`}
          popperPlacement="top"
          placeholderText="End Date"
        />
        <label className="cursor-pointer" htmlFor={`${id}-endDate`}>
          <IconEventNote />
        </label>
      </div>
      <input
        name="quantity"
        onChange={handleChange}
        value={quantity || ''}
        type="number"
        className="text-footnote text-black h-10.5 w-full border border-grey-placeholder rounded-sm px-2.5 focus:outline-none"
        placeholder="Quantity"
      />
      <button disabled={!deleteAble} onClick={handleDelete}>
        <IconTrash
          className={classNames('-mr-1.5', {
            'ic-blue': deleteAble,
            'ic-grey-placeholder': !deleteAble,
          })}
          width="24"
          height="24"
        />
      </button>
      <ProductModal 
      optionsProduct2={optionsProduct2}
      optionsProduct={optionsProduct}
      showModal ={modalProduct}
      setShowModal = {setModalProduct}
      handleChoose = {handleChoose}
      afterOpenModal={handleAfterOpenProduct}
      refetchProduct={handleRefetchProduct}
      setSelectedProduct={setSelectedProduct}
      selectedProduct={selectedProduct}
      />
    </div>
  );
};

type TakeOutOrderPopupState = {
  SAP_Order_id?: string;
  Order_Type_id?: string;
  items?: OrderItem[];
  Factory_id?: string;
};

const TakeoutOrderPopup: React.FC<TakeoutOrderPopupProps> = ({
  isOpen = false,
  onRequestClose,
  onSubmit,
  initialState = {},
  type = 'create',
}) => {
  // const queryClient = useQueryClient();
  const [productItems, setProductItems] = useState<Record<string, ProductItem>>(
    {
      new: {
        id: 'new',
      },
    }
  );


  const [state, setState] = useState<TakeOutOrderPopupState>(initialState);
  // const [sapOrder, setSapOrder] = useState<SAPOrder[]>([]);

  

  useEffect(() => {
    // if (initialState?.Factory_id ==='3') {
      // const newItems = initialState?.items(element => ({
      //   ...element,
      //   Species_id: undefined,
      //   Species_id: 
      // }))
    // } else {
      setState(initialState);
      console.log(initialState);
    // }

    const newProductItems: Record<string, OrderItem> = {};

    initialState.items?.forEach((value, idx) => {
      newProductItems[idx] = { ...value, id: type === 'create' ? idx.toString() : value.id };
    });

    setProductItems({
      ...newProductItems,
      new: {
        id: 'new',
      },
    });

  }, [initialState, type]);

  const { data: options } = useQuery<TakeoutOrderOptions>(
    'takeout-order-options',
    async () => {
      const res = await fetch(urlAPIManagement + '/order/option');

      const data = await res.json();
      console.log(data.supplier)
      return data;
    }
  );

  const { data: optionsProduct } = useQuery<ProductOption[]>(
    'takeout-order-options-product',
    async () => {
      const res = await fetch(urlAPITablet + '/product/get', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({unitStationId: 18}),
      });

      const data = await res.json();
      
      return data.data;
    }
  );

  const { data: optionsProduct2 } = useQuery<ProductOption2[]>(
    'takeout-order-options-product-2',
    async () => {
      const res = await fetch(urlAPITabletBPN + '/global/product/13');

      const data = await res.json();
      
      return data.data;
    }
  );


    const {data: sapOrder1} = useQuery<SAPOrder[]>(
      ['sap-order-options-1'],
       async () => {
        const res = await fetch(urlAPIManagement + '/order/sap-order?Order_Type_id=1');
        const data = await res.json();
        return data?.data      
       },
    )

    const {data: sapOrder3} = useQuery<SAPOrder[]>(
      ['sap-order-options-3'],
       async () => {
        const res = await fetch(urlAPIManagement + '/order/sap-order?Order_Type_id=3');
        const data = await res.json();
        return data?.data      
       },
    )
  
    const checkItems = (a: OrderItem[]) => {
      let isOkay = true;
      const length = a?.length;
      if (length > 0) {
          let i = 0;
          while (i < length) {
            const element = a && a[i];
            if (!(element?.date_end && element?.date_start && element?.id && element?.Product_id, element?.Supplier_id && element?.quantity && element?.Size_id)) {
              isOkay = false;
              break;
            } 
            i++;
          }
      } else {
        isOkay = false
      }
      return isOkay
    }

  const handleSubmit = async () => {
    const body: TakeOutOrderPopupState = {
      ...state,
      items: Object.keys(productItems)
        .filter((key) => key !== 'new')
        .map((key) => {
          if (!productItems[key].date_start)
            productItems[key].date_start = dayjs().format('YYYY-MM-DD');

          if (!productItems[key].date_end)
            productItems[key].date_end = dayjs().format('YYYY-MM-DD');

          return productItems[key];
        }),
    };

    console.log (`body ${JSON.stringify(body)}`)
    if (onSubmit && body?.Order_Type_id && body?.Factory_id && checkItems(body?.items ?? [])) {
      const items2 = body?.items?.map (element => (
        {
          ...element,
          Spesies_id : undefined,
          Species_id: element?.Spesies_id
        }
      ))
      const body2 = {
        ...body,
        items: items2
      }
      
      if (body?.Factory_id === '1') {
        onSubmit(body)
      } else {
        onSubmit(body2)
      }
        
    } else {
      alert('mohon dilengkapi semua informasi pada form');
    }
  };



  return (
    <ReactModal
      style={{
        content: {
          width: 1200,
        },
      }}
      isOpen={isOpen}
      shouldCloseOnEsc
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}
    >
      <div className="text-heading text-black font-semibold mb-5">
        {type === 'create' ? 'Create New' : 'Edit'} Takeout Order
      </div>
      <div className="mb-6">
        <div className="text-black text-footnote mb-2">Factory</div>
        <ReactDropdown
          controlClassName="border-grey-placeholder border "
          placeholder="Choose the Factory...."
          className="text-black text-footnote"
          options={Array.from(
            options?.factory || [],
            ({ id, branch_name }, index) => ({
              label: branch_name,
              value: id.toString(),
              key: index
            })
          )}
          value={state.Factory_id?.toString()}
          onChange={({ value }) => {
            setState({ ...state, Factory_id: value, Order_Type_id: undefined, SAP_Order_id: undefined  });
            setProductItems({
              new: {
                id: 'new',
              },
            })
        }}
        />
      </div>
      <div className="mb-6">
        <div className="text-black text-footnote mb-2">Jenis Takeout</div>
        <ReactDropdown
          controlClassName="border-grey-placeholder border "
          placeholder="Choose the Jenis Takeout...."
          className="text-black text-footnote"
          options={state?.Factory_id === '1' ? 
            Array.from(options?.orderType || [], ({ id, name }, index) => ({
            value: id.toString(),
            label: name,
            key: index
          }))
          :
          Array.from(options?.orderType || [], ({ id, name }, index) => ({
            value: id.toString(),
            label: name,
            key: index
          })).filter(e => e?.value === '1' || e?.value === '2')
        }
          disabled={state?.Factory_id === undefined}
          value={state.Order_Type_id}
          onChange={({ value }) => {
            setState({ ...state, Order_Type_id: value, SAP_Order_id: undefined })
            // queryClient.refetchQueries({ queryKey: 'takeout-order-options-sap-order' })
            // getSapOrder(value)
          }}
        />
      </div>
      <div className="mb-6">
        <div className="text-black text-footnote mb-2">Sap Order</div>
        <ReactDropdown
          controlClassName="border-grey-placeholder border "
          placeholder={"Choose the Sap Order...."}
          className="text-black text-footnote"
          disabled={state?.Factory_id === undefined || state?.Order_Type_id === undefined}
          options={state?.Order_Type_id === '1' ? Array.from(
            sapOrder1 || [],
            ({ id, SAP_Order }, index) => ({
              label: SAP_Order,
              value: id.toString(),
              key: index
            })
          ) : state?.Order_Type_id === '3' ?
           Array.from(    
            sapOrder3 || [],
            ({ id, SAP_Order }, index) => ({
              label: SAP_Order,
              value: id.toString(),
              key: index
            })
          ) : []
        }
          value={state?.SAP_Order_id?.toString()}
          onChange={({ value }) => setState({ ...state, SAP_Order_id: value })}
        />
      </div> 
      <div className="mb-6">
        <div
            style={{ gridTemplateColumns: '2.8fr 0.2fr 1.2fr 1.2fr repeat(3, 1fr) 0.7fr auto' }}
            className={classNames('grid gap-x-1 items-center mr-5')}
        >
          <div className="text-black text-footnote mb-2">Product</div>
          <div className="text-black text-footnote mb-2"></div>
          <div className="text-black text-footnote mb-2">Supplier</div>
          <div className="text-black text-footnote mb-2">Species</div>
          <div className="text-black text-footnote mb-2">Size</div>
          <div className="text-black text-footnote mb-2">Start Date</div>
          <div className="text-black text-footnote mb-2">End Date</div>
          <div className="text-black text-footnote mb-2">Quantity</div>
        </div>
        {/* If you are from the future and see this commented code, and found this code is not used, please just delete it */}
        {/* <input
          type="text"
          className="text-footnote text-black h-10.5 w-full border border-grey-placeholder rounded-sm px-2.5 focus:outline-none mb-4"
        /> */}

        {Object.keys(productItems).map((key, index) => {
          const props = productItems[key];

          return (
            <ProductItemComponent
              optionsProduct={optionsProduct}
              optionsProduct2={optionsProduct2}
              state={state}
              key={index}
              deleteAble={key !== 'new'}
              className="mb-4 last:mb-0"
              onChange={(name, value) => {
                if (key === 'new')
                  setProductItems({
                    ...productItems,
                    [Object.keys(productItems).length]: {
                      [name]: value,
                      // id: type === 'create' ? Object.keys(productItems).length : '',
                      id: Object.keys(productItems).length,
                    },
                  });
              }}
              id={type === 'create' ? key : props.id}
              productItems={productItems}
              setProductItems={setProductItems}
              {...props}
            />
          );
        })}
      </div>
      <div className="flex items-center justify-end">
        <Button
          onClick={onRequestClose}
          color="grey"
          variant="outlined"
          className="mr-3 rounded-full"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="dark-blue"
          className="rounded-full"
        >
          {type === 'create' ? 'Add New' : 'Edit'} Order
        </Button>
      </div>
    </ReactModal>
  );
};

const ManagementTakeoutOrderCommandPage = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { id, type, isModalOpen } = useSelector(
    (state: RootReducerState) => state.takeoutOrder
  );

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [confirm, setConfirm] = useState<boolean>(false);
  const [bodyRequest, setBodyRequest] = useState<TakeOutOrderPopupState>({});
  const [idToEdit, setIdToEdit] = useState<string>('');

  const handleCreateOrder = useMutation(
    async () => {
      setConfirm(false);

      console.log(`bodyRequest ${JSON.stringify(bodyRequest)}`)

      const res = await fetch((bodyRequest?.Factory_id === '1' ? urlAPIManagement : urlAPIManagementBPN ) + `/${bodyRequest?.Factory_id === '1' ? 'order' : 'takeout-order'}/${idToEdit}`, {
        method: type === 'create' ? 'POST' : 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyRequest),
      });

      const data = await res.json();
      console.log(data)
      return data;
    },
    {
      onSuccess: () => {
        setConfirm(false);
        if (bodyRequest?.Factory_id === '1') {
          queryClient.refetchQueries({ queryKey: 'takeout-order-lists' });
        } else {
          queryClient.refetchQueries({ queryKey: 'takeout-order-lists-2' });
        }
      },
    }
  );

  // useQuery<TakeOutOrderList>(
  //   ['edit-takeout-order-detail', id],
  //   async () => {
  //     if (!id) return;

  //     const res = await fetch(urlAPIManagement + `/order/${id}`);

  //     const data = await res.json();

  //     return data.data;
  //   },
  //   {
  //     refetchOnWindowFocus: false,
  //     onSuccess: (data) => {
  //       if (!data) return;

  //       setBodyRequest({
  //         Order_Type_id: data.Order_Type_id.toString(),
  //         SAP_Order_id: data.SAP_Order_id.toString(),
  //         created_at: data.created_at,
  //         items: Array.from(
  //           data.orderItems,
  //           ({
  //             date_end,
  //             date_start,
  //             id,
  //             Product_id,
  //             quantity_order,
  //             size,
  //             Spesies_id,
  //           }) => ({
  //             dateEnd: date_end,
  //             dateStart: date_start,
  //             id: id.toString(),
  //             productId: Product_id.toString(),
  //             quantity: quantity_order.toString(),
  //             sizeId: size.id.toString(),
  //             spesiesId: Spesies_id.toString(),
  //           })
  //         ),
  //       });
  //     },
  //   }
  // );

  return (
    <>
      {handleCreateOrder.isLoading && <Loading />}
      <ReactModal
        style={{
          content: {
            width: 430,
          },
        }}
        isOpen={confirm}
      >
        <div className="text-center my-10 text-footnote">
          Are you sure to Add a New Takeout Order?
        </div>
        <div className="grid grid-cols-2 gap-x-2 items-center">
          <Button
            onClick={() => setConfirm(false)}
            color="grey"
            variant="outlined"
            className="rounded-full"
          >
            No
          </Button>
          <Button
            onClick={() => {
              handleCreateOrder.mutate()
              setIdToEdit('')
            }}
            // onClick={() => {
            //   console.log(urlAPIManagement + `/order/${idToEdit}`)
            //   console.log(bodyRequest)
            // }}
            color="dark-blue"
            className="rounded-full"
          >
            Yes
          </Button>
        </div>
      </ReactModal>
      <TakeoutOrderPopup
        isOpen={isModalOpen}
        onRequestClose={() => {
          dispatch(setModalClose());
          setBodyRequest({});
          setIdToEdit('')
        }}
        onSubmit={(value) => {
          setIdToEdit(id);
          dispatch(setModalClose());
          setConfirm(true);
          setBodyRequest(value);
        }}
        initialState={bodyRequest}
        type={type}
      />
      <div className="user-management">
        <h1 className="text-heading font-bold mb-3">Takeout Order Command</h1>
        <Breadcrumb className="mb-5">
          <BreadcrumbItem href="/management/">Home</BreadcrumbItem>
          <BreadcrumbItem isActive>Takeout Order Command</BreadcrumbItem>
        </Breadcrumb>
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center">
            <span className="text-caption-1 text-grey mr-3 font-semibold">
              Filter
            </span>
            <DatePicker
              selected={startDate}
              // @ts-ignore
              onChange={(date) => setStartDate(date)}
              dateFormat="E, d MMM yyyy"
              containerClassName="mr-3.5"
            />
            <DatePicker
              selected={endDate}
              // @ts-ignore
              onChange={(date) => setEndDate(date)}
              dateFormat="E, d MMM yyyy"
              popperPlacement="bottom-end"
              minDate={startDate}
            />
          </div>
          <Button
            onClick={() => dispatch(setModalOpen('create'))}
            variant="outlined"
            color="dark-blue"
            className="flex items-center"
          >
            <div
              style={{ lineHeight: 1 }}
              className="bg-blue text-white h-3 w-3 mr-1 rounded-sm flex items-center justify-center"
            >
              +
            </div>
            <span>Takeout Order</span>
          </Button>
        </div>
        <ManagementTakeoutOrderCommandTable
          startDate={startDate}
          endDate={endDate}
          setData={(data: TakeOutOrderList) => {
            setIdToEdit(data.id.toString())
            setBodyRequest({
              Order_Type_id: data.Order_Type_id?.toString(),
              SAP_Order_id: data.SAP_Order_id?.toString() || undefined,
              Factory_id: data.Factory_id?.toString(),
              items: Array.from(
                data.orderItems,
                ({
                  date_end,
                  date_start,
                  id,
                  Product_id,
                  quantity_order,
                  Supplier_id,
                  Size_id,
                  Spesies_id
                }) => ({
                  date_end,
                  date_start,
                  id: id.toString(),
                  Product_id: Product_id?.toString(),
                  Supplier_id: Supplier_id?.toString(),
                  quantity: quantity_order?.toString(),
                  Size_id: Size_id?.toString(),
                  Spesies_id: (Spesies_id)?.toString(),
                })
              ),
            });
          }}
        />
      </div>
    </>
  );
};

export default ManagementTakeoutOrderCommandPage;
