import { TableGrid } from 'components';

const FreezingUnitPerfTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">Workers Performance</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              field: 'no',
              name: 'No.',
              width: '40px',
            },
            {
              field: 'name',
              name: 'Name',
            },
            {
              field: 'workersID',
              name: 'Workers ID',
            },
            {
              field: 'working_time',
              name: 'Working Time',
            },
            {
              field: 'headcount_performance',
              name: 'Headcount Performance',
            },
            {
              field: 'cost_labor',
              name: 'Cost Labor',
            },
          ]}
        />
      </div>
      <h6 className="font-bold mb-2">Unit Performance by Contact Freezer</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              field: 'no',
              name: 'No.',
              width: '40px',
            },
            {
              field: 'contactFreezer',
              name: 'Contact Freezer',
              width: '0.5fr',
            },
            {
              field: 'machineTime',
              name: 'MachineTime',
              width: '0.6fr',
            },
            {
              field: 'machineProductivity',
              name: 'Machine Productivity',
              width: '0.4fr',
            },
            {
              field: 'innerSuccessRate',
              name: 'Inner Success Rate',
            },
            {
              field: 'workingTime',
              name: 'Working Time',
            },
            {
              field: 'laborProductivity',
              name: 'Labor Productivity',
            },
            {
              field: 'unitProductivity',
              name: 'Unit Productivity',
            },
          ]}
          data={[
            {
              no: '1',
              contactFreezer: 'F-001',
              machineTime: '5 hours',
              machineProductivity: '80 inner / hour',
              innerSuccessRate: '80%',
              workingTime: '1 man-hours',
              laborProductivity: '80 inner / man-hour',
              unitProductivity: '57 inner / hour',
            },
            {
              no: '2',
              contactFreezer: 'F-001',
              machineTime: '5 hours',
              machineProductivity: '80 inner / hour',
              innerSuccessRate: '80%',
              workingTime: '1 man-hours',
              laborProductivity: '80 inner / man-hour',
              unitProductivity: '57 inner / hour',
            },
          ]}
        />
      </div>

      <div className="flex items-start mb-8">
        <div className="w-96 mr-20">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Headcount</div>
            <div>8 People</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Working Time</div>
            <div>50 Man-Hours</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Machine Time</div>
            <div>50 Hours</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Time</div>
            <div>50 Hours</div>
          </div>
        </div>
        <div className="w-96">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Labor Productivity</div>
            <div>500 inner/Man-Hour</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Machine Productivity</div>
            <div>Rp 200.000</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Labor Productivity</div>
            <div>500 inner/Man-Hour</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Labor Cost</div>
            <div>Rp 750.000</div>
          </div>
          <div className="flex justify-between text-caption-1">
            <div className="font-semibold">Labor Cost / Inner</div>
            <div>Rp 70.000</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FreezingUnitPerfTable;
