import { useState } from 'react';
import classNames from 'classnames';
import { IconClose, IconLaunch } from 'assets';

interface ContactFreezerProductivityProps {
  className?: string;
}

const ContactFreezerProductivity: React.FC<ContactFreezerProductivityProps> = ({
  className = '',
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        [className]: className,
      })}
    >
      <button
        onClick={handleFullScreen}
        className="absolute right-5 top-5 z-10"
      >
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">CF Productivity Rate</h4>
        <div className="grid grid-cols-2 text-footnote text-grey font-bold mb-5">
          <div>Contact Freezer Name</div>
          <div className="text-right">The Productivity Rate</div>
        </div>
        <div className="grid grid-cols-2 text-caption-1 font-bold py-2 border-b border-grey-1">
          <div>CF 1</div>
          <div className="text-right">20 pan/hours</div>
        </div>
        <div className="grid grid-cols-2 text-caption-1 font-bold py-2 border-b border-grey-1">
          <div>CF 2</div>
          <div className="text-right">8 pan/hours</div>
        </div>
        <div className="grid grid-cols-2 text-caption-1 font-bold py-2 border-b border-grey-1">
          <div>CF 3</div>
          <div className="text-right">14 pan/hours</div>
        </div>
      </div>
    </div>
  );
};

export default ContactFreezerProductivity;
