import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import classNames from 'classnames';
import { useState } from 'react';
import { IconClose, IconLaunch } from 'assets';
import { ChartLabel } from 'components';
import { ColdStorageColorMapping } from 'colors';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { urlAPIMechanic } from 'utils';
import { MapAny } from 'utils/types';

interface ColdStorageTempProps {
  mode?: 'default' | 'realtime';
  className?: string;
  startDate?: string;
  endDate?: string;
}

type ColdStorageTempData = {
  id: number;
  nama_node: string;
  data: Array<{
    id: number;
    data1: number;
    createdAt: string;
  }>;
};

const ColdStorageTemp: React.FC<ColdStorageTempProps> = ({
  mode = 'default',
  className = '',
  startDate = dayjs().subtract(9, 'days').format('YYYY-MM-DD'),
  endDate = dayjs().format('YYYY-MM-DD'),
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [reRender, setReRender] = useState<boolean>(true);
  const [options, setOptions] = useState<Highcharts.Options>({
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Hours',
      },
      dateTimeLabelFormats: {
        hour: '%H:%M',
      },
      gridLineWidth: 1,
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: '<p><sup>o</sup>C</p>',
        rotation: 0,
        useHTML: true,
      },
    },
    series: [],
    plotOptions: {
      line: {
        lineWidth: 4,
        states: {
          hover: {
            lineWidth: 5,
          },
        },
        pointInterval: 60 * 60 * 1000,
        marker: {
          enabled: false,
        },
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      valueSuffix: ' Celcius',
    },
  });
  const [selected, setSelected] = useState<MapAny>({});
  const [immutableSeries, setImmutableSeries] = useState<
    Highcharts.SeriesOptionsType[]
  >([]);

  const { data } = useQuery<ColdStorageTempData[]>(
    ['coldStorageTemp', startDate, endDate],
    () =>
      fetch(
        urlAPIMechanic +
          `/mechanic/cold-storage/dashboard/temperature?start=${startDate}&end=${endDate}`
      )
        .then((res) => res.json())
        .then((data) => data.data),
    {
      onSuccess: (data) => {
        let selected: MapAny = {};
        let series: Highcharts.SeriesOptionsType[] = data.map((val, i) => {
          const colorIndex =
            (i % Object.keys(ColdStorageColorMapping).length) + 1;

          selected[val.id] = true;

          return {
            id: val.id.toString(),
            name: val.nama_node,
            type: 'line',
            color: ColdStorageColorMapping[colorIndex],
            data: val.data.map((val) => ({
              y: val.data1,
              x: dayjs(val.createdAt).valueOf(),
            })),
          };
        });

        setSelected(selected);
        setImmutableSeries([...series]);
        setOptions({
          ...options,
          series,
        });
      },
    }
  );

  const handleChangeLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    setSelected({
      ...selected,
      [name]: checked,
    });
    let series = options.series;

    if (!checked) {
      const s = series?.find((val) => val.id === name);

      if (s && series) {
        let index = series.indexOf(s);
        series.splice(index, 1);
      }
      setOptions({
        ...options,
        series,
      });
    } else {
      let s = immutableSeries.find((val) => val.id === name);

      if (s) {
        series?.push(s);
      }

      setOptions({
        ...options,
        series,
      });
    }
  };

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
    // Hack the chart so it can fill entire screen during fullscreen state
    setReRender(false);
    setTimeout(() => {
      setReRender(true);
    }, 0);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        [className]: className,
      })}
    >
      <button onClick={handleFullScreen} className="absolute right-5 top-5">
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">
          Cold Storage Temperature
        </h4>
        {reRender && (
          <HighchartsReact options={options} highcharts={Highcharts} />
        )}
        <div className="flex items-center flex-wrap pl-10 mt-3">
          {data?.map((val, i) => {
            let avgTemp =
              val.data.reduce((acc, currentVal) => acc + currentVal.data1, 0) /
              val.data.length;

            avgTemp = isNaN(avgTemp) ? 0 : avgTemp;

            return (
              <ChartLabel
                bgColor={
                  ColdStorageColorMapping[
                    (i % Object.keys(ColdStorageColorMapping).length) + 1
                  ]
                }
                name={val.id.toString()}
                onChange={handleChangeLabel}
                checked={selected[val.id]}
                title={val.nama_node}
                className="mx-1.5 my-2"
                averageTemp={
                  mode === 'default' ? avgTemp.toFixed(1) : undefined
                }
                key={`cold-storage-temp-${val.id}`}
                currentTemp={
                  mode === 'realtime' && val.data.length
                    ? val.data[data.length - 1].data1.toFixed(1)
                    : mode === 'realtime'
                    ? '0'
                    : undefined
                }
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ColdStorageTemp;
