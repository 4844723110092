import classNames from 'classnames';
import { useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import {
  IconArrowLeft,
  IconArrowRight,
  IconBarChart,
  IconBug,
  IconCalendar,
  IconColdStorage,
  IconCookingUnit,
  IconDailyReport,
  IconFinalSortingHL,
  IconFinalSortingPeeled,
  IconFreezingPacking,
  IconFreezingUnit,
  IconHistoryEvents,
  IconHomeUnit,
  IconIQFPacking,
  IconIQFUnit,
  IconLabInspection,
  IconLayeringUnit,
  IconLogout,
  IconNobashiUnit,
  IconPeelingUnit,
  IconPerson,
  IconProdFlow,
  IconReceivingUnit,
  IconSettings,
  IconShortLeave,
  IconSoakingUnit,
  IconSortingMachine,
  IconThermostat,
  IconTime,
  IconToys,
  IconTakeoutOrder,
  IconInspection,
  IconProduction,
  IconUnitDashboard,
  IconWage,
  IconStock,
  IconTraceability,
  IconSupplier,
  IconCostAnalysis,
  IconManualInput,
  IconOverviewMechanic,
  IconScheduleMechanic,
  IconOperatorMechanic,
  IconMachineMechanic,
  IconContactMechanic,
  IconHistoryMechanic,
  IconColdStorageMechanic
} from 'assets';
import SidebarUnitItems from 'json/sidebar-unit-items.json';
import SidebarManagementItems from 'json/sidebar-management-items.json';
import { IUser } from 'utils/types';
import { urlAPI } from 'utils/urlApi';
import { useUser } from 'utils/useUser';
import { Loading } from '../../mollecules';
import './index.css';

export const IconSidebarUnitMap: Record<
  string,
  React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >
> = {
  home: IconHomeUnit,
  'cold-storage': IconColdStorage,
  'cooking-unit': IconCookingUnit,
  'daily-report': IconDailyReport,
  'final-sorting-hl': IconFinalSortingHL,
  'final-sorting-peeled': IconFinalSortingPeeled,
  'freezing-packing': IconFreezingPacking,
  'freezing-unit': IconFreezingUnit,
  'history-events': IconHistoryEvents,
  'iqf-packing': IconIQFPacking,
  'iqf-unit': IconIQFUnit,
  'lab-inspection': IconLabInspection,
  'layering-unit': IconLayeringUnit,
  'nobashi-unit': IconNobashiUnit,
  'peeling-unit': IconPeelingUnit,
  'prod-flow': IconProdFlow,
  'receiving-unit': IconReceivingUnit,
  'short-leave': IconShortLeave,
  'soaking-unit': IconSoakingUnit,
  'sorting-machine': IconSortingMachine,
  'takeout-order': IconTakeoutOrder,
  'inspection' : IconInspection,
  'product-priority': IconProduction,
  'unit-dashboard' : IconUnitDashboard,
  'wage-productivity' : IconWage,
  'stock' : IconStock,
  'traceability' : IconTraceability,
  'supplier-analysis' : IconSupplier,
  'cost-analysis' : IconCostAnalysis,
  'manual-input' : IconManualInput,
  'overview' : IconOverviewMechanic,
  'schedule-management' : IconScheduleMechanic,
  'operator-performance' : IconOperatorMechanic,
  'machine-performance' : IconMachineMechanic,
  'cold-storage-performance' : IconColdStorageMechanic,
  'contact-freezer' : IconContactMechanic,
  'history-events-mechanic' : IconHistoryMechanic
};

interface SidebarProps {
  type?: 'admin' | 'mechanic' | 'unit' | 'management';
  isOpen?: boolean;
  onMenuToggle: Function;
}

type SidebarChildren = {
  name: string;
  route: string;
  icon?: string;
  children?: SidebarChildren[];
};

interface SidebarChildrenProps {
  items: SidebarChildren[];
}

const SidebarItem: React.FC<SidebarChildrenProps> = ({ items }) => {
  const { pathname } = useLocation();

  return (
    <div className="pl-9">
      {items.map(({ name, route, children, icon }, index) => {
        let IconComponent;

        if (icon) {
          IconComponent = IconSidebarUnitMap[icon] || IconBug;
        }

        return (
          <div key={index}>
            <NavLink
              activeClassName="active-child"
              exact
              className={classNames(
                'nav-item flex items-center py-2 px-6 rounded-full mb-3 text-grey-2',
                {
                  'active-child': children?.some(
                    ({ route }) => pathname === route
                  ),
                }
              )}
              to={route}
              key={`child-${route}-${index}`}
            >
              {IconComponent && <IconComponent className="mr-3" />}
              <span className="text-caption-1 font-bold">{name}</span>
            </NavLink>
            {children && pathname.startsWith(route) && (
              <SidebarItem items={children} />
            )}
          </div>
        );
      })}
    </div>
  );
};

const Sidebar: React.FC<SidebarProps> = ({ type = 'admin', isOpen = false, onMenuToggle  }) => {
  const [user, setUser] = useState<IUser>();
  const [loading, setLoading] = useState<boolean>(false);
  const { pathname } = useLocation();
  const history = useHistory();

  useUser((userFromLocal, _err) => {
    setUser(userFromLocal);
  });

  const handleLogout = async () => {
    setLoading(true);
    await fetch(urlAPI + '/auth/logout', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
    });
    localStorage.removeItem('user');
    setLoading(false);
    history.replace('/login');
  };

  if (type === 'management')
    return (
      <>
        {loading && <Loading />}
        <div className={`sidebar flex flex-col overflow-y-auto ${isOpen === true ? ' w-64' : ' w-20'}`}>
          <span
            style={{ color: '#6E6E76' }}
            className="font-bold text-footnote my-6 px-6"
          >
            {isOpen === true ? ' Main menu' : ''}
          </span>
          <div className="nav-items mb-10">
            {SidebarManagementItems.map(
              ({ name, route, icon, children = [] }, index) => {
                const IconComponent = IconSidebarUnitMap[icon] || IconBug;

                return (
                  <div key={index}>
                    <NavLink
                      activeClassName="active"
                      exact
                      className={classNames(
                        'nav-item flex items-center py-2.5 px-6 rounded-full mb-3 text-black latest:mb-0',
                        {
                          active:
                            pathname.startsWith(route) &&
                            route !== '/management/',
                        }
                      )}
                      to={route}
                      key={`${route}-${index}`}
                    >
                      <IconComponent className={`${isOpen === true ? 'mr-3' : ' '}`}/>
                      {isOpen === true ? 
                        <span className="text-caption-1 font-bold">{name}</span>
                      :
                        ''
                      }
                    </NavLink>
                    {pathname.startsWith(route) && isOpen && (
                      <SidebarItem
                        key={`lists-of-children-${route}-${index}`}
                        items={children}
                      />
                    )}
                  </div>
                );
              }
            )}
          </div>
          <div className="sidebar-logout mt-auto cursor-pointer px-6 mb-10">
            <div onClick={handleLogout} className="px-4 flex items-center">
              {isOpen === true ? 
                <>
                  <IconLogout className="mr-3" />
                  <span className="text-caption-1 text-grey">Logout</span>
                </>
              :
                ''
              }
            </div>
          </div>
        </div>
        <div className={`toggle-menu ${isOpen === true ? ' left' : ' right'}`} onClick={() => onMenuToggle()}>
          {isOpen === true ? 
            <IconArrowLeft className="toggle-icon"/>
            :
            <IconArrowRight className="toggle-icon"/>
          }
        </div>
      </>
    );

  if (type === 'unit')
    return (
      <>
        {loading && <Loading />}
        <div className="sidebar w-64 fixed flex flex-col overflow-y-auto">
          <span
            style={{ color: '#6E6E76' }}
            className="font-bold text-footnote my-6 px-6"
          >
            Main menu
          </span>
          <div className="nav-items mb-10">
            {SidebarUnitItems.map(({ name, route, extended, icon }, index) => {
              const IconComponent = IconSidebarUnitMap[icon];

              return (
                <div key={index}>
                  <NavLink
                    activeClassName="active"
                    exact
                    className={classNames(
                      'nav-item flex items-center py-2.5 px-6 text-black rounded-full mb-3 latest:mb-0',
                      { active: pathname.startsWith(route) && route !== '/' }
                    )}
                    to={route}
                    key={route}
                  >
                    <IconComponent className="mr-3" />
                    <span className="text-caption-1 font-bold">{name}</span>
                  </NavLink>
                  {pathname.startsWith(route) && extended && (
                    <div key={index} className="pl-9">
                      <NavLink
                        activeClassName="active-child"
                        exact
                        className="nav-item flex items-center py-2 px-6 text-grey-2 rounded-full mb-3"
                        to={route}
                      >
                        <span className="text-caption-1 font-bold">
                          Dashboard
                        </span>
                      </NavLink>
                      <NavLink
                        activeClassName="active-child"
                        exact
                        className="nav-item flex items-center py-2 text-grey-2 px-6 rounded-full mb-3"
                        to={`${route}/report`}
                      >
                        <span className="text-caption-1 font-bold">Report</span>
                      </NavLink>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="sidebar-logout mt-auto cursor-pointer px-6 mb-10">
            <div onClick={handleLogout} className="px-4 flex items-center">
              <IconLogout className="mr-3" />
              <span className="text-caption-1 text-grey">Logout</span>
            </div>
          </div>
        </div>
      </>
    );

  if (type === 'mechanic')
    return (
      <>
        {loading && <Loading />}
        <div className="sidebar w-64 fixed flex flex-col overflow-y-auto">
          <span
            style={{ color: '#6E6E76' }}
            className="font-bold text-footnote my-6 px-6"
          >
            Main menu
          </span>
          <div className="nav-items mb-10">
            <NavLink
              exact
              activeClassName="active"
              className={classNames(
                'nav-item flex items-center py-2.5 px-6 text-black rounded-full mb-3',
                {
                  active: pathname.startsWith('/mechanic/overview'),
                }
              )}
              to="/mechanic/overview"
            >
              <IconBarChart className="mr-3" />
              <span className="text-caption-1 font-bold">Overview</span>
            </NavLink>
            {pathname.startsWith('/mechanic/overview') && (
              <div className="pl-9">
                <NavLink
                  activeClassName="active-child"
                  exact
                  className="nav-item flex items-center py-2 text-black px-6 rounded-full mb-3"
                  to="/mechanic/overview"
                >
                  <span className="text-caption-1 font-bold">Dashboard</span>
                </NavLink>
                <NavLink
                  activeClassName="active-child"
                  exact
                  className="nav-item flex items-center py-2 text-black px-6 rounded-full mb-3"
                  to="/mechanic/overview/report"
                >
                  <span className="text-caption-1 font-bold">Report</span>
                </NavLink>
              </div>
            )}
            <NavLink
              activeClassName="active"
              exact
              className="nav-item flex items-center py-2.5 px-6 text-black rounded-full mb-3"
              to="/mechanic/schedule-management"
            >
              <IconTime className="mr-3" />
              <span className="text-caption-1 font-bold">
                Schedule Management
              </span>
            </NavLink>
            <NavLink
              activeClassName="active"
              exact
              className={classNames(
                'nav-item flex items-center py-2.5 px-6 rounded-full mb-3',
                {
                  active: pathname.startsWith('/mechanic/operator-performance'),
                }
              )}
              to="/mechanic/operator-performance"
            >
              <IconPerson className="mr-3" />
              <span className="text-caption-1 font-bold">
                Operator Performance
              </span>
            </NavLink>
            {pathname.startsWith('/mechanic/operator-performance') && (
              <div className="pl-9">
                <NavLink
                  activeClassName="active-child"
                  exact
                  className="nav-item flex items-center text-black py-2 px-6 rounded-full mb-3"
                  to="/mechanic/operator-performance"
                >
                  <span className="text-caption-1 font-bold">Dashboard</span>
                </NavLink>
                <NavLink
                  activeClassName="active-child"
                  exact
                  className="nav-item flex items-center text-black py-2 px-6 rounded-full mb-3"
                  to="/mechanic/operator-performance/report"
                >
                  <span className="text-caption-1 font-bold">Report</span>
                </NavLink>
              </div>
            )}
            <NavLink
              activeClassName="active"
              exact
              className={classNames(
                'nav-item flex items-center py-2.5 px-6 text-black rounded-full mb-3',
                {
                  active: pathname.startsWith('/mechanic/machine-performance'),
                }
              )}
              to="/mechanic/machine-performance"
            >
              <IconSettings className="mr-3" />
              <span className="text-caption-1  font-bold">
                Machine Performance
              </span>
            </NavLink>
            {pathname.startsWith('/mechanic/machine-performance') && (
              <div className="pl-9">
                <NavLink
                  activeClassName="active-child"
                  exact
                  className="nav-item flex items-center text-black py-2 px-6 rounded-full mb-3"
                  to="/mechanic/machine-performance"
                >
                  <span className="text-caption-1 font-bold">Dashboard</span>
                </NavLink>
                <NavLink
                  activeClassName="active-child"
                  exact
                  className="nav-item flex items-center text-black py-2 px-6 rounded-full mb-3"
                  to="/mechanic/machine-performance/report"
                >
                  <span className="text-caption-1 font-bold">Report</span>
                </NavLink>
              </div>
            )}
            <NavLink
              activeClassName="active"
              exact
              className={classNames(
                'nav-item flex items-center py-2.5 px-6 text-black rounded-full mb-3',
                {
                  active: pathname.startsWith('/mechanic/cold-storage'),
                }
              )}
              to="/mechanic/cold-storage"
            >
              <IconToys className="mr-3" />
              <span className="text-caption-1 font-bold">
                Cold Storage Management
              </span>
            </NavLink>
            {pathname.startsWith('/mechanic/cold-storage') && (
              <div className="pl-9">
                <NavLink
                  activeClassName="active-child"
                  exact
                  className="nav-item flex items-center text-black py-2 px-6 rounded-full mb-3"
                  to="/mechanic/cold-storage"
                >
                  <span className="text-caption-1 font-bold">Dashboard</span>
                </NavLink>
                <NavLink
                  activeClassName="active-child"
                  exact
                  className="nav-item flex items-center text-black py-2 px-6 rounded-full mb-3"
                  to="/mechanic/cold-storage/report"
                >
                  <span className="text-caption-1 font-bold">Report</span>
                </NavLink>
              </div>
            )}
            <NavLink
              activeClassName="active"
              exact
              className={classNames(
                'nav-item flex items-center py-2.5 text-black px-6 rounded-full mb-3',
                {
                  active: pathname.startsWith('/mechanic/contact-freezer'),
                }
              )}
              to="/mechanic/contact-freezer"
            >
              <IconThermostat className="mr-3" />
              <span className="text-caption-1 font-bold">
                Contact Freezer Performance
              </span>
            </NavLink>
            {pathname.startsWith('/mechanic/contact-freezer') && (
              <div className="pl-9">
                <NavLink
                  activeClassName="active-child"
                  exact
                  className="nav-item flex items-center text-black py-2 px-6 rounded-full mb-3"
                  to="/mechanic/contact-freezer"
                >
                  <span className="text-caption-1 font-bold">Dashboard</span>
                </NavLink>
                <NavLink
                  activeClassName="active-child"
                  exact
                  className="nav-item flex items-center text-black py-2 px-6 rounded-full mb-3"
                  to="/mechanic/contact-freezer/report"
                >
                  <span className="text-caption-1 font-bold">Report</span>
                </NavLink>
              </div>
            )}
            <NavLink
              activeClassName="active"
              exact
              className="nav-item flex items-center py-2.5 text-black px-6 rounded-full"
              to="/mechanic/history-events"
            >
              <IconCalendar className="mr-3" />
              <span className="text-caption-1 font-bold">
                History Of Events
              </span>
            </NavLink>
          </div>
          <div className="sidebar-logout mt-auto cursor-pointer px-6 mb-10">
            <div onClick={handleLogout} className="px-4 flex items-center">
              <IconLogout className="mr-3" />
              <span className="text-caption-1 text-grey">Logout</span>
            </div>
          </div>
        </div>
      </>
    );

  return (
    <>
      {loading && <Loading />}
      <div className="sidebar w-64 fixed flex flex-col">
        <span
          style={{ color: '#6E6E76' }}
          className="font-bold text-footnote my-6 px-6"
        >
          Main menu
        </span>
        <div className="nav-items">
          <NavLink
            exact
            activeClassName="active"
            className="nav-item flex items-center text-black py-2.5 px-6 rounded-full mb-3"
            to="/admin/"
          >
            <IconBarChart className="mr-3" />
            <span className="text-caption-1 font-bold">Home</span>
          </NavLink>
          <NavLink
            activeClassName="active"
            exact
            className="nav-item flex items-center text-black py-2.5 px-6 rounded-full mb-3"
            to="/admin/device-management"
          >
            <IconBug className="mr-3" />
            <span className="text-caption-1 font-bold">Device Management</span>
          </NavLink>
          <NavLink
            activeClassName="active"
            exact
            className="nav-item flex items-center text-black py-2.5 px-6 rounded-full mb-3"
            to="/admin/user-management"
          >
            <IconBug className="mr-3" />
            <span className="text-caption-1 font-bold">User Management</span>
          </NavLink>
          {pathname.startsWith('/admin/user-management') && (
            <div className="pl-9">
              <NavLink
                activeClassName="active-child"
                exact
                className="nav-item flex items-center text-black py-2 px-6 rounded-full mb-3"
                to="/admin/user-management/role"
              >
                <span className="text-caption-1  font-bold">Role</span>
              </NavLink>
              <NavLink
                activeClassName="active-child"
                exact
                className="nav-item flex items-center text-black py-2 px-6 rounded-full mb-3"
                to="/admin/user-management/reset-password-management"
              >
                <span className="text-caption-1  font-bold">
                  Password Reset Status
                </span>
              </NavLink>
            </div>
          )}
          <NavLink
            activeClassName="active"
            exact
            className="nav-item flex items-center text-black py-2.5 px-6 rounded-full mb-3"
            to="/admin/constant-management"
          >
            <IconBug className="mr-3" />
            <span className="text-caption-1  font-bold">
              Constant Management
            </span>
          </NavLink>
          <NavLink
            activeClassName="active"
            exact
            className="nav-item flex items-center text-black py-2.5 px-6 rounded-full"
            to="/admin/log-history"
          >
            <IconBug className="mr-3" />
            <span className="text-caption-1  font-bold">Log History</span>
          </NavLink>
        </div>
        <div className="sidebar-logout mt-auto cursor-pointer px-6 mb-10">
          <div onClick={handleLogout} className="px-4 flex items-center">
            <IconLogout className="mr-3" />
            <span className="text-caption-1 text-grey">Logout</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
