const HistoryOfEventsTable = () => {
  return (
    <div className="rounded-lg px-8 py-7 border border-grey-1">
      <div
        style={{ gridTemplateColumns: '1fr 200px' }}
        className="grid font-semibold text-caption-1 py-2.5"
      >
        <div>Events</div>
        <div>Time</div>
      </div>
      {/* Put the children here later */}
    </div>
  );
};

export default HistoryOfEventsTable;
