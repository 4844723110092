import classNames from 'classnames';
import { forwardRef } from 'react';
import { IconFilter } from 'assets';

interface FilterButtonProps {
  className?: string;
  onClick?(): void;
  ref?: React.RefObject<HTMLButtonElement>;
}

const FilterButton = forwardRef<HTMLButtonElement, FilterButtonProps>(
  ({ className = '', onClick }, ref) => {
    return (
      <button
        ref={ref}
        onClick={onClick}
        className={classNames(
          'flex items-center rounded-full h-10 border border-white-snow shadow-sm w-28 bg-white px-5 focus:outline-none',
          { [className]: className.length > 0 }
        )}
      >
        <IconFilter className="mr-1" />
        <span className="text-caption-1 text-grey-placeholder">Filter</span>
      </button>
    );
  }
);

export default FilterButton;
