import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import './index.css';

interface BreadcrumbItemProps extends React.HTMLAttributes<HTMLDivElement> {
  isActive?: boolean;
  href?: string;
}

const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
  children,
  isActive,
  href = '/',
  className = '',
}) => {
  const { pathname } = useLocation();

  return (
    <div
      style={{ color: '#BBC8D4' }}
      className={classNames('breadcrumb-item flex items-center', {
        active: isActive,
        [className]: className?.length > 0,
      })}
    >
      <Link
        className={classNames('breadcrum-link text-footnote flex-shrink', {
          'cursor-default': isActive,
        })}
        to={isActive ? pathname : href}
      >
        {children}
      </Link>
    </div>
  );
};

export default BreadcrumbItem;
