import { FilterDateReducerActionTypes } from './takeoutOrder.reducer';

export const setModalOpen = (type: 'create' | 'edit', id?: string) => ({
  type: FilterDateReducerActionTypes.SET_MODAL_OPEN,
  payload: {
    type,
    id,
  },
});

export const setModalClose = () => ({
  type: FilterDateReducerActionTypes.SET_MODAL_CLOSE,
});
