import { Breadcrumb, BreadcrumbItem, CardHome } from 'components';
import { IconDevice, IconUsers } from 'assets';
import './index.css';

const HomePage = () => {
  return (
    <div className="home">
      <h1 className="text-heading font-bold mb-3">Homepage</h1>
      <Breadcrumb className="mb-10">
        <BreadcrumbItem isActive>Home</BreadcrumbItem>
      </Breadcrumb>
      <div className="border rounded-xl border-grey-1 px-12 py-9 grid grid-cols-2 gap-9">
        <CardHome
          to="/admin/device-management"
          Icon={IconDevice}
          heading="Device Management"
          subheading="Menu ini berfungsi untuk mendaftarkan, menghapus dan mengedit
            perangkat node module IoT yang digunakan pada sistem Emifly Smart
            Manufacturing"
        />
        <CardHome
          to="/admin/user-management"
          Icon={IconUsers}
          heading="User Management"
          subheading="Menu ini berfungsi untuk mendaftarkan, menghapus dan mengedit user yang terdapat  pada sistem Emifly Smart Manufacturing"
        />
        <CardHome
          to="/admin/constant-management"
          Icon={IconDevice}
          heading="Konstanta"
          subheading="Menu in berfungsi untuk mendaftarkan, menghapus dan mengedit konstanta yang akan digunakan pada sistem Emifly Smart Manufacturing"
        />
      </div>
    </div>
  );
};

export default HomePage;
