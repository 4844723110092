import classNames from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useState } from 'react';
import { IconClose, IconLaunch } from 'assets';
import { ContactFreezerColorMapping } from 'colors';
import { ChartLegend } from 'components';

interface MachineProductivityProps {
  className?: string;
}

const options: Highcharts.Options = {
  title: {
    text: '',
  },
  chart: {
    type: 'column',
  },
  xAxis: {
    type: 'datetime',
    gridLineWidth: 1,
  },
  yAxis: [
    {
      gridLineWidth: 0,
      title: {
        text: 'inner pan quality',
      },
      min: 0,
    },
    {
      gridLineWidth: 0,
      title: {
        text: 'Power Consumption',
      },
      opposite: true,
      labels: {
        format: '{value} W',
      },
    },
  ],
  series: [
    {
      data: [
        {
          x: Date.now() - 5 * 60 * 60 * 1000,
          color: ContactFreezerColorMapping[1],
          y: 8,
        },
        {
          x: Date.now() - 4 * 60 * 60 * 1000,
          color: ContactFreezerColorMapping[1],
          y: 5,
        },
        {
          x: Date.now() - 3 * 60 * 60 * 1000,
          color: ContactFreezerColorMapping[1],
          y: 7,
        },
        {
          x: Date.now() - 2 * 60 * 60 * 1000,
          color: ContactFreezerColorMapping[1],
          y: 7,
        },
        {
          x: Date.now() - 1 * 60 * 60 * 1000,
          color: ContactFreezerColorMapping[1],
          y: 7,
        },
      ],
      name: 'Machine 1',
      type: 'column',
      stack: 'machine',
    },
    {
      data: [
        {
          x: Date.now() - 5 * 60 * 60 * 1000,
          color: ContactFreezerColorMapping[2],
          y: 6,
        },
        {
          x: Date.now() - 4 * 60 * 60 * 1000,
          color: ContactFreezerColorMapping[2],
          y: 3,
        },
        {
          x: Date.now() - 3 * 60 * 60 * 1000,
          color: ContactFreezerColorMapping[2],
          y: 5,
        },
        {
          x: Date.now() - 2 * 60 * 60 * 1000,
          color: ContactFreezerColorMapping[2],
          y: 9,
        },
        {
          x: Date.now() - 1 * 60 * 60 * 1000,
          color: ContactFreezerColorMapping[2],
          y: 14,
        },
      ],
      name: 'Machine 2',
      type: 'column',
      stack: 'machine',
    },
    {
      data: [
        {
          x: Date.now() - 5 * 60 * 60 * 1000,
          color: ContactFreezerColorMapping[3],
          y: 6,
        },
        {
          x: Date.now() - 4 * 60 * 60 * 1000,
          color: ContactFreezerColorMapping[3],
          y: 7,
        },
        {
          x: Date.now() - 3 * 60 * 60 * 1000,
          color: ContactFreezerColorMapping[3],
          y: 8,
        },
        {
          x: Date.now() - 2 * 60 * 60 * 1000,
          color: ContactFreezerColorMapping[3],
          y: 4,
        },
        {
          x: Date.now() - 1 * 60 * 60 * 1000,
          color: ContactFreezerColorMapping[3],
          y: 10,
        },
      ],
      name: 'Machine 3',
      type: 'column',
      stack: 'machine',
    },
    {
      data: [
        {
          x: Date.now() - 5 * 60 * 60 * 1000,
          color: ContactFreezerColorMapping[3],
          y: 20,
        },
        {
          x: Date.now() - 4 * 60 * 60 * 1000,
          color: ContactFreezerColorMapping[3],
          y: 15,
        },
        {
          x: Date.now() - 3 * 60 * 60 * 1000,
          color: ContactFreezerColorMapping[3],
          y: 20,
        },
        {
          x: Date.now() - 2 * 60 * 60 * 1000,
          color: ContactFreezerColorMapping[3],
          y: 20,
        },
        {
          x: Date.now() - 1 * 60 * 60 * 1000,
          color: ContactFreezerColorMapping[3],
          y: 31,
        },
      ],
      name: 'Total Power Consumption',
      type: 'spline',
      yAxis: 1,
      marker: {
        enabled: false,
      },
      color: ContactFreezerColorMapping[4],
    },
  ],
  legend: {
    enabled: false,
  },
  tooltip: {
    valueSuffix: ' Watt',
    shared: true,
  },
  plotOptions: {
    column: {
      stacking: 'normal',
    },
  },
};

const MachineProductivity: React.FC<MachineProductivityProps> = ({
  className = '',
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [reRender, setReRender] = useState<boolean>(true);

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
    // Hack the chart so it can fill entire screen during fullscreen state
    setReRender(false);
    setTimeout(() => {
      setReRender(true);
    }, 0);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        [className]: className,
      })}
    >
      <button onClick={handleFullScreen} className="absolute right-5 top-5">
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">Machine Productivity</h4>
        {reRender && (
          <HighchartsReact options={options} highcharts={Highcharts} />
        )}

        <ChartLegend
          className="pl-10"
          title="Total Machine Power Consumption"
          color={ContactFreezerColorMapping[4]}
        />
        <div className="flex items-center flex-wrap pl-10 mt-3">
          <ChartLegend
            title="CFI 1"
            color={ContactFreezerColorMapping[1]}
            className="mr-3"
          />
          <ChartLegend
            title="CFI 2"
            color={ContactFreezerColorMapping[2]}
            className="mr-3"
          />
          <ChartLegend title="CFI 3" color={ContactFreezerColorMapping[3]} />
        </div>
      </div>
    </div>
  );
};

export default MachineProductivity;
