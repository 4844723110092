export const idSortingMachine = 3;
export const idReceiving = 1;
export const idFinalSorting1 = 5;
export const namaUnitSortingMachine = 'sorting-machine';
export const namaUnitFinalSorting = 'final-sorting';

export const UnitIds = {
    Receiving_Station: 1,
    Distribution: 2,
    Sorting_Machine: 3,
    Final_Sorting_Transfer: 4,
    Final_Sorting: 5,
    Final_Sorting_Five_Star: 19,
    Layering: 6,
    Final_Sorting_Peeled: 7,
    Peeling_Station: 8,
    Soaking: 9,
    Cooking: 10,
    Nobashi: 11,
    Freezing: 12,
    IQF: 13,
    Packing_CF: 14,
    Packing_IQF: 15,
    Outsource: 16,
    Inspection: 17,
    Cold_Storage: 18
}