import FreezingUnitHeadcountPerformance from './headcount-performance';
import FreezingUnitQuantityPerFreezer from './quantity-per-freezer';
import FreezingUnitWorkers from './workers';
import FreezingUnitProduction from './production';
import FreezingUnitProducitivityAnalysis from './productivity-analysis';

export {
  FreezingUnitHeadcountPerformance,
  FreezingUnitQuantityPerFreezer,
  FreezingUnitWorkers,
  FreezingUnitProduction,
  FreezingUnitProducitivityAnalysis,
};
