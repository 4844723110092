import { TableGrid } from 'components';

const FreezingUnitReportSummaryTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">MC</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              field: 'product',
              name: 'Product',
            },
            {
              field: 'supplier',
              name: 'Supplier',
            },
            {
              field: 'size',
              name: 'Size',
            },
            {
              field: 'inputQuantity',
              cols: ['inputQuantity1', 'inputQuantity2'],
              name: 'Input Quantity',
              width: '2fr',
            },
            {
              field: 'outputQuantity',
              cols: ['outputQuantity1', 'outputQuantity2'],
              name: 'Output Quantity',
              width: '2fr',
            },
            {
              field: 'stockQuantity',
              cols: ['stockQuantity1', 'stockQuantity2'],
              name: 'Stock Quantity',
              width: '2fr',
            },
          ]}
          data={[
            {
              product: 'HL Treatment',
              supplier: 'Budi',
              size: '8 - 12',
              species: 'Black Tiger',
              inputQuantity1: '100 MC',
              inputQuantity2: '1 ton',
              outputQuantity1: '100 MC',
              outputQuantity2: '1 ton',
              stockQuantity1: '20 MC',
              stockQuantity2: '500 kg',
            },
            {
              product: 'HL Treatment',
              supplier: 'Budi',
              size: '8 - 12',
              species: 'Black Tiger',
              inputQuantity1: '100 MC',
              inputQuantity2: '1 ton',
              outputQuantity1: '100 MC',
              outputQuantity2: '1 ton',
              stockQuantity1: '20 MC',
              stockQuantity2: '500 kg',
            },
          ]}
        />
      </div>

      <h6 className="font-bold mb-2">MCO</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              field: 'product',
              name: 'Product',
            },
            {
              field: 'supplier',
              name: 'Supplier',
            },
            {
              field: 'size',
              name: 'Size',
            },
            {
              field: 'inputQuantity',
              cols: ['inputQuantity1', 'inputQuantity2'],
              name: 'Input Quantity',
              width: '2fr',
            },
            {
              field: 'outputQuantity',
              cols: ['outputQuantity1', 'outputQuantity2'],
              name: 'Output Quantity',
              width: '2fr',
            },
            {
              field: 'stockQuantity',
              cols: ['stockQuantity1', 'stockQuantity2'],
              name: 'Stock Quantity',
              width: '2fr',
            },
          ]}
          data={[
            {
              product: 'HL Treatment',
              supplier: 'Budi',
              size: '8 - 12',
              species: 'Black Tiger',
              inputQuantity1: '100 MC',
              inputQuantity2: '1 ton',
              outputQuantity1: '100 MC',
              outputQuantity2: '1 ton',
              stockQuantity1: '20 MC',
              stockQuantity2: '500 kg',
            },
            {
              product: 'HL Treatment',
              supplier: 'Budi',
              size: '8 - 12',
              species: 'Black Tiger',
              inputQuantity1: '100 MC',
              inputQuantity2: '1 ton',
              outputQuantity1: '100 MC',
              outputQuantity2: '1 ton',
              stockQuantity1: '20 MC',
              stockQuantity2: '500 kg',
            },
          ]}
        />
      </div>

      <div className="flex items-start mb-8">
        <div className="w-96 mr-20">
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Total MC Input</div>
            <div>200 MC</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Total Weight MC Input</div>
            <div>5 ton</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Total MC Output</div>
            <div>550 MC</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Weight MC Output</div>
            <div>5.5 ton</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total MC Stock</div>
            <div>100 MC</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Weight MC Stock</div>
            <div>1 ton</div>
          </div>
        </div>
        <div className="w-96 mr-20">
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Total MCO Input</div>
            <div>200 MCO</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Total Weight MCO Input</div>
            <div>5 ton</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Total MCO Output</div>
            <div>550 MCO</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Weight MCO Output</div>
            <div>5.5 ton</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total MCO Stock</div>
            <div>100 MCO</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Weight MCO Stock</div>
            <div>1 ton</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FreezingUnitReportSummaryTable;
