import classNames from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useState } from 'react';
import { IconClose, IconLaunch } from 'assets';
import { ColdStorageColorMapping } from 'colors';
import { ChartLegend } from 'components';

interface BatchUsageDistroProps {
  className?: string;
}

const options: Highcharts.Options = {
  title: {
    text: '',
  },
  series: [
    {
      data: [
        {
          name: 'Contact Freezer 1',
          color: ColdStorageColorMapping[1],
          y: 60,
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: 'Contact Freezer 2',
          color: ColdStorageColorMapping[2],
          y: 35,
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: 'Contact Freezer 3',
          color: ColdStorageColorMapping[3],
          y: 15,
          dataLabels: {
            enabled: false,
          },
        },
      ],
      name: 'Operator 1',
      type: 'pie',
      innerSize: '80%',
      size: '75%',
    },
  ],
  legend: {
    enabled: false,
  },
  tooltip: {
    valueSuffix: ' Batches',
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 60,
        },
      },
    ],
  },
};

const BatchUsageDistro: React.FC<BatchUsageDistroProps> = ({
  className = '',
}) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [reRender, setReRender] = useState<boolean>(true);

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
    // Hack the chart so it can fill entire screen during fullscreen state
    setReRender(false);
    setTimeout(() => {
      setReRender(true);
    }, 0);
  };

  return (
    <div
      style={{ zIndex: fullScreen ? 1200 : 0 }}
      className={classNames('flex justify-center items-center', {
        relative: !fullScreen,
        'fixed top-0 left-0 w-full h-full z-50 bg-white': fullScreen,
        [className]: className,
      })}
    >
      <button onClick={handleFullScreen} className="absolute right-5 top-5">
        {!fullScreen ? <IconLaunch /> : <IconClose />}
      </button>
      <div className="w-full h-full border rounded-lg border-grey-light p-6">
        <h4 className="text-footnote font-bold mb-8">
          Batch Usage Distribution
        </h4>
        <div className="grid grid-cols-3 gap-3 items-center">
          <div className="col-span-2">
            {reRender && (
              <HighchartsReact options={options} highcharts={Highcharts} />
            )}
          </div>
          <div>
            <ChartLegend
              percentage={60}
              color={ColdStorageColorMapping[1]}
              title="CF 1"
              className="mb-3"
            />
            <ChartLegend
              percentage={35}
              color={ColdStorageColorMapping[2]}
              title="CF 2"
              className="mb-3"
            />
            <ChartLegend
              percentage={15}
              color={ColdStorageColorMapping[3]}
              title="CF 3"
            />
          </div>
        </div>
        <div className="flex items-center flex-wrap pl-10 mt-3">
          <ChartLegend
            className="mr-3"
            color={ColdStorageColorMapping[1]}
            title="60 batches"
          />
          <ChartLegend
            className="mr-3"
            color={ColdStorageColorMapping[2]}
            title="35 batches"
          />
          <ChartLegend color={ColdStorageColorMapping[3]} title="15 batches" />
        </div>
        <p className="text-caption-1 font-bold text-grey pl-10 mt-3">
          Total usage usage is 100
        </p>
      </div>
    </div>
  );
};

export default BatchUsageDistro;
