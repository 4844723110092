import { TableGrid } from 'components';

const FinalSortingPeeledUnitReportSummaryTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">Incoming</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              field: 'no',
              name: 'No.',
              width: '40px',
            },
            {
              field: 'product',
              name: 'Product',
            },
            {
              field: 'grade',
              name: 'Grade',
            },
            {
              field: 'size',
              name: 'Size',
              width: '0.4fr',
            },
            {
              field: 'species',
              name: 'Species',
              width: '0.6fr',
            },
            {
              field: 'quantity',
              name: 'Quantity',
            },
          ]}
        />
      </div>
      <h6 className="font-bold mb-2">Output</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <TableGrid
          columns={[
            {
              field: 'no',
              name: 'No.',
              width: '40px',
            },
            {
              field: 'product',
              name: 'Product',
            },
            {
              field: 'grade',
              name: 'Grade',
            },
            {
              field: 'size',
              name: 'Size',
              width: '0.4fr',
            },
            {
              field: 'species',
              name: 'Species',
              width: '0.6fr',
            },
            {
              field: 'quantity',
              name: 'Quantity',
            },
          ]}
          data={[
            {
              no: '1',
              product: 'HL Treatment',
              grade: '1st',
              size: ['4-8', '8-12'],
              species: ['Black Pink', 'Black Tiger'],
              quantity: ['1 ton', '1 ton'],
            },
            {
              no: '2',
              product: 'HL Treatment',
              grade: '2nd',
              size: '4-8',
              species: 'Black Pink',
              quantity: '1 ton',
            },
          ]}
        />
      </div>

      <div className="flex items-start mb-8">
        <div className="w-96 mr-20">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Incoming</div>
            <div>5 ton</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Output</div>
            <div>4.9 ton</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Shrinkage</div>
            <div>-1 %</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Kotoran</div>
            <div>1 ton</div>
          </div>
        </div>
        <div className="w-96">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Product</div>
            <div>2</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Product and Grade</div>
            <div>4</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total 1st</div>
            <div>3 ton</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total 2nd</div>
            <div>3 ton</div>
          </div>
        </div>
      </div>
      <div className="w-80">
        <div className="flex justify-between mb-3 text-caption-1">
          <div className="font-semibold">Total Broken</div>
          <div>200 Kg</div>
        </div>
      </div>
    </>
  );
};

export default FinalSortingPeeledUnitReportSummaryTable;
