import classNames from 'classnames';
import { ContactFreezerColorMapping } from 'colors';
import { SortThreeState } from 'components';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useMemo, useState } from 'react';

interface ManagementProductivityChartProps extends IBaseChart {
  className?: string;
  queryKey?: string;
  title?: string;
  unit?: string;
}

const ManagementProductivityChart: React.FC<ManagementProductivityChartProps> =
  ({ className, title = 'Headcount Performance', unit = 'Hours' }) => {
    const [highest, setHeight] = useState<number>(0);

    const chartOptions = useMemo<Highcharts.Options>(
      () => ({
        title: {
          text: '',
        },
        chart: {
          type: 'column',
        },
        xAxis: {
          type: 'category',
          gridLineWidth: 1,
        },
        yAxis: {
          gridLineWidth: 0,
          title: {
            text: unit,
            rotation: -90,
          },
        },
        series: [
          {
            data: [
              {
                name: 'Worker 1',
                color: ContactFreezerColorMapping[1],
                y: 8,
              },
              {
                name: 'Worker 2',
                color: ContactFreezerColorMapping[2],
                y: 5,
              },
              {
                name: 'Worker 3',
                color: ContactFreezerColorMapping[3],
                y: 7,
              },
              {
                name: 'Worker 4',
                color: ContactFreezerColorMapping[4],
                y: 7,
              },
              {
                name: 'Worker 5',
                color: ContactFreezerColorMapping[5],
                y: 7,
              },
              {
                name: 'Worker 6',
                color: ContactFreezerColorMapping[6],
                y: 7,
              },
            ],
            name: title,
            type: 'column',
            borderRadius: 20,
          },
        ],
        legend: {
          enabled: false,
        },
      }),
      [title, unit]
    );

    return (
      <div
        className={classNames('flex justify-center items-center', className)}
      >
        <div className="w-full h-full p-6">
          <h4 className="text-footnote font-bold mb-8">{title}</h4>

          <div className="flex justify-end">
            <SortThreeState value={highest} onChange={(h) => setHeight(h)} />
          </div>
          <HighchartsReact options={chartOptions} highcharts={Highcharts} />
        </div>
      </div>
    );
  };

export default ManagementProductivityChart;
