const SortingMachineUnitPerfTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">Workers Performance</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <div
          style={{ gridTemplateColumns: '80px repeat(5, 1fr)' }}
          className="grid font-semibold text-caption-1 py-2.5"
        >
          <div>No</div>
          <div>Name</div>
          <div>Workers ID</div>
          <div>Working Time</div>
          <div>Headcount Performance</div>
          <div>Cost Labor</div>
        </div>
        {/* Put the children here later */}
      </div>
      <h6 className="font-bold mb-2">Unit Performance by Species</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <div
          style={{ gridTemplateColumns: '80px repeat(5, 1fr)' }}
          className="grid font-semibold text-caption-1 py-2.5"
        >
          <div>No</div>
          <div>Species</div>
          <div>Working Time</div>
          <div>Productivity</div>
          <div>Cost Labor</div>
          <div>Productivity Rate</div>
        </div>
        {/* Put the children here later */}
      </div>
      <h6 className="font-bold mb-2">Shrinkage</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9">
        <div
          style={{ gridTemplateColumns: '80px repeat(5, 1fr)' }}
          className="grid font-semibold text-caption-1 py-2.5"
        >
          <div>No</div>
          <div>Species</div>
          <div>Input Weight</div>
          <div>Output Weight</div>
          <div>Shrinkage</div>
          <div></div>
          {/* Put the children here later */}
        </div>
      </div>

      <div className="flex items-start mb-8">
        <div className="w-96 mr-20">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Headcount</div>
            <div>8 People</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Working Time</div>
            <div>50 Man-Hours</div>
          </div>
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Total Time</div>
            <div>+5.5 Hours</div>
          </div>
        </div>
        <div className="w-96">
          <div className="flex justify-between text-caption-1 mb-3">
            <div className="font-semibold">Unit Productivity Rate</div>
            <div>50kg/Man-Hours</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Total Pending Raw Materials</div>
            <div>200 Kg</div>
          </div>
          <div className="flex justify-between text-caption-1">
            <div className="font-semibold">Unit Productivity</div>
            <div>500kg/Hour</div>
          </div>
        </div>
      </div>
      <div className="w-80">
        <div className="flex justify-between mb-3 text-caption-1">
          <div className="font-semibold">Longest Pending Raw Materials</div>
          <div>2 Day</div>
        </div>
        <div className="flex justify-between mb-3 text-caption-1">
          <div className="font-semibold">Size Accuracy Machine</div>
          <div>27%</div>
        </div>
        <div className="flex justify-between mb-3 text-caption-1">
          <div className="font-semibold">Total Shrinkage</div>
          <div>10%</div>
        </div>
      </div>
    </>
  );
};

export default SortingMachineUnitPerfTable;
