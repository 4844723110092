import { useState } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Loading,
  ProceedConfirmation,
  TextField,
} from '../../components';
import { IUser } from '../../utils/types';
import { urlAPI } from '../../utils/urlApi';
import { useUser } from '../../utils/useUser';

interface ChangePasswordState {
  old_password: string;
  new_password: string;
  confirm_password: string;
}

interface ChangePasswordErrorState {
  old_password?: boolean;
  new_password?: boolean;
  confirm_password?: boolean;
}

const ChangePasswordPage = () => {
  const [state, setState] = useState<ChangePasswordState>({
    confirm_password: '',
    new_password: '',
    old_password: '',
  });

  const [errorState, setErrorState] = useState<ChangePasswordErrorState>({
    old_password: false,
    new_password: false,
    confirm_password: false,
  });

  const [user, setUser] = useState<IUser>();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  useUser((userFromLocal) => {
    setUser(userFromLocal);
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    setState({
      ...state,
      [name]: value,
    });

    setErrorState({
      ...errorState,
      [name]: false,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let error: ChangePasswordErrorState = {};

    if (state.new_password.length === 0) error.new_password = true;
    if (state.old_password.length === 0) error.old_password = true;
    if (state.new_password !== state.confirm_password)
      error.confirm_password = true;

    setErrorState({
      ...errorState,
      ...error,
    });

    if (Object.values(error).every((val) => !val)) {
      setLoading(true);
      await fetch(urlAPI + '/auth/change-password', {
        headers: {
          Authorization: 'Bearer ' + user?.token,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          old_password: state.old_password,
          new_password: state.new_password,
        }),
      });

      setState({
        confirm_password: '',
        new_password: '',
        old_password: '',
      });

      setLoading(false);
      setSuccess(true);
    }
  };
  return (
    <>
      {loading && <Loading />}
      {success && (
        <ProceedConfirmation
          message="The password has been changed"
          onClose={() => setSuccess(false)}
        />
      )}
      <div style={{ minWidth: 900 }}>
        <h1 className="text-heading font-bold mb-3">Change Password</h1>
        <Breadcrumb className="mb-10">
          <BreadcrumbItem>Home</BreadcrumbItem>
          <BreadcrumbItem isActive>Change Password</BreadcrumbItem>
        </Breadcrumb>
        <div className="border border-grey-light rounded-lg px-8 py-7">
          <form onSubmit={handleSubmit} className="w-80">
            <TextField
              fullWidth
              className="mb-3.5"
              labelText="Old Password"
              placeholder="Old Password"
              name="old_password"
              onChange={handleChange}
              value={state.old_password}
              errorMessage="This field is required"
              isError={errorState.old_password}
              type="password"
            />
            <TextField
              fullWidth
              className="mb-3.5"
              labelText="New Password"
              placeholder="New Password"
              name="new_password"
              onChange={handleChange}
              value={state.new_password}
              errorMessage="This field is required"
              isError={errorState.new_password}
              type="password"
            />
            <TextField
              fullWidth
              className="mb-8"
              labelText="Confirmation New Password"
              placeholder="New Password"
              name="confirm_password"
              onChange={handleChange}
              value={state.confirm_password}
              errorMessage="The password doesn't match"
              isError={errorState.confirm_password}
              type="password"
            />
            <Button>Change Password</Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangePasswordPage;
