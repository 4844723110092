import { useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  IconMenu,
  LogoEmifly,
  DummyAvatarPlaceholder,
  IconDropdown,
  IconBell,
} from 'assets';
import { IUser } from 'utils/types';
import { urlAPI } from 'utils/urlApi';
import { useOutside } from 'utils/useOutside';
import { useUser } from 'utils/useUser';
import Loading from '../loading';

const Navbar = () => {
  const [user, setUser] = useState<IUser>();
  const [dropdown, setDropdown] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);

  useUser((userFromLocal) => {
    setUser(userFromLocal);
  });

  useOutside(dropdownRef, triggerRef, () => {
    setDropdown(false);
  });

  const handleLogout = async () => {
    setLoading(true);
    await fetch(urlAPI + '/auth/logout', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + user?.token,
      },
    });
    setLoading(false);
    history.replace('/login');
  };

  return (
    <>
      {loading && <Loading />}
      <div
        style={{ borderColor: '#e5e5e5' }}
        className="flex items-center justify-between h-20 px-8 border-b"
      >
        <div className="flex items-center">
          <IconMenu className="mr-8 cursor-pointer" />
          <img className="h-8" src={LogoEmifly} alt="Logo Emifly" />
        </div>
        <div className="flex items-center">
          <button className="mr-6 focus:outline-none">
            <IconBell />
          </button>
          <div
            ref={triggerRef}
            onClick={() => setDropdown(!dropdown)}
            className="flex items-center cursor-pointer relative"
          >
            <span
              style={{ color: '#5F6368' }}
              className="font-bold text-caption-1 mr-4"
            >
              {user?.employee.nama_employee}
            </span>
            {/* eslint-disable-next-line */}
            <img
              src={user?.employee.photo ?? DummyAvatarPlaceholder}
              className="h-11 w-11 rounded-full object-center object-cover"
            />
            <IconDropdown />
            {dropdown && (
              <div
                ref={dropdownRef}
                className="absolute z-20 bg-white rounded-lg px-6 py-6 shadow-lg top-12 right-0"
              >
                <Link
                  className="text-caption-1 font-bold mb-4 block whitespace-nowrap"
                  to="/change-password"
                >
                  Change Password
                </Link>
                <div
                  onClick={handleLogout}
                  className="text-caption-1 font-bold"
                >
                  Logout
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
