import { TableGrid } from 'components';

const ColdStorageUnitTakeoutOrderTable = () => {
  return (
    <>
      <h6 className="font-bold mb-2">Shipment</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9 overflow-auto">
        <TableGrid
          className="mb-6"
          columns={[
            {
              field: 'no',
              name: 'Takeout Order Number',
            },
            {
              field: 'salesOrder',
              name: 'Sales Order',
            },
            {
              field: 'product',
              name: 'Product',
            },
            {
              field: 'supplier',
              name: 'Supplier',
              width: '0.6fr',
            },
            {
              field: 'size',
              name: 'Size',
              width: '0.4fr',
            },
            {
              field: 'species',
              name: 'Species',
            },
            {
              field: 'quantityOrder',
              name: 'Quantity Order',
            },
            {
              field: 'quantityTakeout',
              name: 'Quantity Takeout',
            },
            {
              field: 'status',
              name: 'Status',
            },
          ]}
          data={[
            {
              no: 'S-1',
              salesOrder: 'Jepang-0012',
              product: ['HL Treatment', 'PPD Treatment'],
              supplier: ['Budi', 'Bambang', 'Budi', 'Bambang'],
              size: ['8-12', '8-12', '8-12', '8-12'],
              species: [
                'Black Tiger',
                'Black Tiger',
                'Black Tiger',
                'Black Tiger',
              ],
              quantityOrder: ['100 MC', '100 MC', '100 MC', '100 MC'],
              quantityTakeout: ['100 MC', '100 MC', '100 MC', '100 MC'],
              status: 'Finished',
            },
            {
              no: 'S-2',
              salesOrder: 'Jepang-0012',
              product: ['HL Treatment', 'PPD Treatment'],
              supplier: ['Budi', 'Bambang', 'Budi', 'Bambang'],
              size: ['8-12', '8-12', '8-12', '8-12'],
              species: [
                'Black Tiger',
                'Black Tiger',
                'Black Tiger',
                'Black Tiger',
              ],
              quantityOrder: ['100 MC', '100 MC', '100 MC', '100 MC'],
              quantityTakeout: ['100 MC', '100 MC', '100 MC', '100 MC'],
              status: 'Finished',
            },
          ]}
          onRenderField={(field, value, { key }) => {
            if (field.startsWith('inner'))
              return (
                <div className="text-center" key={key}>
                  {value}
                </div>
              );
          }}
        />
      </div>

      <h6 className="font-bold mb-2">Reprocessing</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9 overflow-auto">
        <TableGrid
          className="mb-6"
          columns={[
            {
              field: 'no',
              name: 'Takeout Order Number',
            },
            {
              field: 'product',
              name: 'Product',
            },
            {
              field: 'supplier',
              name: 'Supplier',
              width: '0.6fr',
            },
            {
              field: 'size',
              name: 'Size',
              width: '0.4fr',
            },
            {
              field: 'species',
              name: 'Species',
            },
            {
              field: 'quantityOrder',
              name: 'Quantity Order',
            },
            {
              field: 'quantityTakeout',
              name: 'Quantity Takeout',
            },
            {
              field: 'status',
              name: 'Status',
            },
          ]}
          data={[
            {
              no: 'REPRO-1',
              product: ['HL Treatment', 'PPD Treatment'],
              supplier: ['Budi', 'Bambang', 'Budi', 'Bambang'],
              size: ['8-12', '8-12', '8-12', '8-12'],
              species: [
                'Black Tiger',
                'Black Tiger',
                'Black Tiger',
                'Black Tiger',
              ],
              quantityOrder: ['100 MC', '100 MC', '100 MC', '100 MC'],
              quantityTakeout: ['100 MC', '100 MC', '100 MC', '100 MC'],
              status: 'Finished',
            },
            {
              no: 'REPRO-2',
              product: ['HL Treatment', 'PPD Treatment'],
              supplier: ['Budi', 'Bambang', 'Budi', 'Bambang'],
              size: ['8-12', '8-12', '8-12', '8-12'],
              species: [
                'Black Tiger',
                'Black Tiger',
                'Black Tiger',
                'Black Tiger',
              ],
              quantityOrder: ['100 MC', '100 MC', '100 MC', '100 MC'],
              quantityTakeout: ['100 MC', '100 MC', '100 MC', '100 MC'],
              status: 'Finished',
            },
          ]}
          onRenderField={(field, value, { key }) => {
            if (field.startsWith('inner'))
              return (
                <div className="text-center" key={key}>
                  {value}
                </div>
              );
          }}
        />
      </div>

      <h6 className="font-bold mb-2">Repacking</h6>
      <div className="rounded-lg px-8 py-7 border border-grey-1 mb-9 overflow-auto">
        <TableGrid
          className="mb-6"
          columns={[
            {
              field: 'no',
              name: 'Takeout Order Number',
            },
            {
              field: 'product',
              name: 'Product',
            },
            {
              field: 'supplier',
              name: 'Supplier',
              width: '0.6fr',
            },
            {
              field: 'size',
              name: 'Size',
              width: '0.4fr',
            },
            {
              field: 'species',
              name: 'Species',
            },
            {
              field: 'quantityOrder',
              name: 'Quantity Order',
            },
            {
              field: 'quantityTakeout',
              name: 'Quantity Takeout',
            },
            {
              field: 'status',
              name: 'Status',
            },
          ]}
          data={[
            {
              no: 'REPAC-1',
              salesOrder: 'Jepang-0012',
              product: ['HL Treatment', 'PPD Treatment'],
              supplier: ['Budi', 'Bambang', 'Budi', 'Bambang'],
              size: ['8-12', '8-12', '8-12', '8-12'],
              species: [
                'Black Tiger',
                'Black Tiger',
                'Black Tiger',
                'Black Tiger',
              ],
              quantityOrder: ['100 MC', '100 MC', '100 MC', '100 MC'],
              quantityTakeout: ['100 MC', '100 MC', '100 MC', '100 MC'],
              status: 'Finished',
            },
            {
              no: 'REPAC-2',
              salesOrder: 'Jepang-0012',
              product: ['HL Treatment', 'PPD Treatment'],
              supplier: ['Budi', 'Bambang', 'Budi', 'Bambang'],
              size: ['8-12', '8-12', '8-12', '8-12'],
              species: [
                'Black Tiger',
                'Black Tiger',
                'Black Tiger',
                'Black Tiger',
              ],
              quantityOrder: ['100 MC', '100 MC', '100 MC', '100 MC'],
              quantityTakeout: ['100 MC', '100 MC', '100 MC', '100 MC'],
              status: 'Finished',
            },
          ]}
          onRenderField={(field, value, { key }) => {
            if (field.startsWith('inner'))
              return (
                <div className="text-center" key={key}>
                  {value}
                </div>
              );
          }}
        />
      </div>

      <div className="flex items-start mb-8">
        <div className="w-96 mr-20">
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Shipment Order</div>
            <div>2</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Shipment Order Finished</div>
            <div>2</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Reprocessing Order</div>
            <div>2</div>
          </div>
        </div>
        <div className="w-96 mr-20">
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Repacking Order</div>
            <div>2</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Repacking Order Finished</div>
            <div>2</div>
          </div>
          <div className="flex justify-between mb-3 text-caption-1">
            <div className="font-semibold">Reprocessing Order Finished</div>
            <div>2</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ColdStorageUnitTakeoutOrderTable;
