import { useState } from 'react';
import { IconClose } from 'assets';
import { Button, FilterChoice } from '../../atoms';

interface FilterProps {
  onCancel?: () => void;
  onSubmit?: (val: FilterState) => void;
  filterState?: FilterState;
  filterChoices?: any[];
  heading?: string;
  filterChoiceValueKey?: string;
  filterChoiceLabelKey?: string;
}

interface FilterState {
  [key: string]: boolean;
}

const Filter: React.FC<FilterProps> = ({
  onCancel,
  onSubmit,
  filterState = {},
  filterChoices = [],
  heading,
  filterChoiceValueKey = '',
  filterChoiceLabelKey = '',
}) => {
  const [state, setState] = useState<FilterState>(filterState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;

    setState({
      ...state,
      [value]: checked,
    });
  };

  const handleSubmit = () => {
    if (onSubmit) onSubmit(state);
  };

  return (
    <div
      style={{ zIndex: 1200 }}
      className="py-2 fixed top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4 bg-white shadow-lg w-80"
    >
      <button onClick={onCancel} className="absolute right-4 top-5">
        <IconClose />
      </button>
      <div className="px-3 py-3">
        <h5 className="ml-1 mb-3 text-black font-bold">{heading}</h5>
        <div className="flex flex-wrap items-center mb-6">
          {filterChoices.map((val) => (
            <FilterChoice
              checked={state[val[filterChoiceValueKey]]}
              key={val[filterChoiceValueKey]}
              onChange={handleChange}
              value={val[filterChoiceValueKey]}
              className="m-1"
            >
              {val[filterChoiceLabelKey]}
            </FilterChoice>
          ))}
        </div>
        <Button onClick={handleSubmit} fullWidth>
          Filter
        </Button>
      </div>
    </div>
  );
};

export default Filter;
