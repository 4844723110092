import { IconCircleCheck } from 'assets';

interface SnackBarProps {
  message?: string;
}

const SnackBar: React.FC<SnackBarProps> = ({ message }) => {
  return (
    <div
      style={{ zIndex: 1500, width: 537 }}
      className="fixed shadow-lg top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4 bg-colorful-green rounded-xl py-3.5 px-4 flex items-center"
    >
      <IconCircleCheck className="mr-3" />
      <span className="text-white font-extrabold text-footnote">{message}</span>
    </div>
  );
};

export default SnackBar;
