import classNames from 'classnames';
import { IconPing } from 'assets';
import { Checkbox } from '../../atoms';

interface ChartLabelProps {
  checked?: boolean;
  title?: string;
  bgColor?: string;
  textColor?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  averageTemp?: string;
  currentTemp?: string;
  value?: string;
  defaultChecked?: boolean;
  name?: string;
}

const ChartLabel: React.FC<ChartLabelProps> = ({
  checked,
  onChange,
  title,
  bgColor,
  textColor,
  className = '',
  averageTemp,
  currentTemp,
  value,
  defaultChecked,
  name,
}) => {
  return (
    <div className={classNames('flex flex-col', { [className]: className })}>
      <div className="flex items-center mb-2">
        <Checkbox
          defaultChecked={defaultChecked}
          value={value}
          onChange={onChange}
          checked={checked}
          name={name}
        />
        <div
          style={{
            background: bgColor,
            color: textColor,
            fontSize: 10,
            height: 18,
          }}
          className="rounded-full ml-2 flex items-center justify-center px-3.5 font-bold text-white"
        >
          {title}
        </div>
      </div>
      {averageTemp && (
        <p className="text-caption-2 mt-2 font-bold text-grey pl-7">
          Avg Temp{' '}
          <span style={{ color: bgColor }}>
            {averageTemp} <sup>o</sup>C
          </span>
        </p>
      )}
      {currentTemp && (
        <p className="text-caption-2 mt-2 font-bold text-grey flex items-center">
          <IconPing className="mr-3" />
          <span>Current Temp &nbsp;</span>
          <span style={{ color: bgColor }}>
            {currentTemp} <sup>o</sup>C
          </span>
        </p>
      )}
    </div>
  );
};

export default ChartLabel;
